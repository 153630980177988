import React, { useState } from "react";

//import scss
import "./tabs.scss";

export const Tabs = (props) => {
  const [selected, setSelected] = useState(0);
  return (
    <div className="df mta tabs_wrpr">
      {props.tabs.map((tab, i) => (
        <div
          key={i}
          onClick={() => {
            setSelected(i);
            props.function(tab.value);
          }}
          className={`tab_wrpr ${selected === i ? "selected" : ""}`}
        >
          <p className="ffqsr fs14">{tab.label}</p>
        </div>
      ))}
    </div>
  );
};
