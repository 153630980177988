import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { formatFromCurrencyToMoney } from "../../../../helpers";
import AddBracket from "./add-bracket/AddBracket";

import styles from "./Insurance.module.scss";

const Brackets = ({ companyId }) => {
  const [brackets, setBrackets] = useState([]);
  const [showAdd, setShowAdd] = useState(undefined);

  const getBrackets = async () => {
    let re = await axios.get(`/api/insurance-brackets/${companyId}`);
    console.log(re.data.data);
    setBrackets(re.data.data);
  };

  useEffect(() => {
    getBrackets();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {brackets.map((bracket) => (
        <div
          className={`ffnr fs14 df acsa ${styles.bracketLine}`}
          key={bracket._id}
          onClick={() => console.log(bracket)}
        >
          <div className={`m-r-30 ${styles.bracketSec}`}>
            <p className={`fs12 ${styles.label}`}>Covered amount</p>
            <p>{formatFromCurrencyToMoney(bracket.coverageAmount)}</p>
          </div>
          <div className={`${styles.bracketSec}`}>
            <p className={`fs12 ${styles.label}`}>Premium</p>
            <p>{formatFromCurrencyToMoney(bracket.premium)}</p>
          </div>

          <div className={`mla`} onClick={() => setShowAdd(bracket._id)}>
            Edit
          </div>

          {showAdd === bracket._id ? (
            <div onClick={(e) => e.stopPropagation()}>
              <AddBracket
                bracket={bracket}
                companyId={bracket.insuranceCompanyId}
                close={() => setShowAdd(undefined)}
              />
            </div>
          ) : undefined}
        </div>
      ))}
    </>
  );
};

export default Brackets;
