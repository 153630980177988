import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import CalPortal from "./CalPortal";
import "./DatePicker.scss";

export const DatePicker = ({
  field,
  // form: {touched, errors, values, dirty, isValid, status},
  ...props
}) => {
  const datePickerPos = useRef(null);
  const getStartOfMonth = (date) =>
    moment(date).startOf("day").startOf("month").startOf("week");

  const getEndOfMonth = (date) =>
    moment(date).startOf("day").endOf("month").endOf("week");

  // const [displayDate, updateDisplayDate] = useState(
  // 	moment(Number(new Date())).format("MM/DD/YYYY")
  // );

  const [showDatePicker, updateShowDatePicker] = useState(false);
  const [weeksLength, setWeeksLength] = useState();
  const [currentMonth, updateCurrentMonth] = useState([]);
  const [selectedDate, updateSelectedDate] = useState(
    Number(moment(props.defaultDate).startOf("day")) ||
      Number(moment(new Date()).startOf("day"))
  );

  const proccessMonth = () => {
    // console.log("Moving month");
    let weeks = [];
    let currentDate = startOfMonth;

    for (let i = 0; i < weeksLength + 1; i++) {
      let week = [];

      for (let i = 0; i < 7; i++) {
        week.push(currentDate);
        currentDate = moment(currentDate).startOf("day").add(1, "day");
      }
      weeks.push(week);
    }
    // console.log(weeks);
    updateCurrentMonth(weeks);
  };

  const getWeeksLength = () =>
    setWeeksLength(endOfMonth.diff(startOfMonth, "weeks"));

  // const [month, updateMonth] = useState([]);
  // const [currentMonthDay, updateCurrentMonthDay] = useState(undefined);

  const [startOfMonth, updateStartOfMonth] = useState(
    props.startDate
      ? getStartOfMonth(props.startDate)
      : getStartOfMonth(Number(new Date()))
  );

  // eslint-disable-next-line
  const [endOfMonth, updateEndOfMonth] = useState(
    props.startDate
      ? getEndOfMonth(props.startDate)
      : getEndOfMonth(Number(new Date()))
  );

  const moveDate = (date, dir) => {
    if (dir === "f")
      updateStartOfMonth(
        moment(startOfMonth)
          .add(10, "days")
          .add(1, "month")
          .startOf("month")
          .startOf("week")
      );

    if (dir === "p")
      updateStartOfMonth(
        moment(startOfMonth)
          .add(10, "days")
          .subtract(1, "month")
          .startOf("month")
          .startOf("week")
      );
    // getStartOfMonth(props.startDate)
  };

  useEffect(() => {
    props.onChange(field?.name, Number(new Date(selectedDate)));
    getWeeksLength();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.onChange(field?.name, Number(new Date(selectedDate)));
    // eslint-disable-next-line
  }, [selectedDate]);

  useEffect(() => {
    getWeeksLength();
    // eslint-disable-next-line
  }, [endOfMonth]);

  useEffect(() => {
    proccessMonth();
    // eslint-disable-next-line
  }, [weeksLength]);

  useEffect(() => {
    proccessMonth();
    // eslint-disable-next-line
  }, [startOfMonth]);

  const getTop = () => {
    // {console.log(window.innerHeight)}

    let currentPosition =
      datePickerPos.current.getBoundingClientRect().top +
      datePickerPos.current.clientHeight +
      10;

    if (currentPosition + 370 >= window.innerHeight)
      currentPosition = window.innerHeight - 380;
    return `${currentPosition}px`;
  };

  return (
    <div
      className={`__inpt_wrpr ${
        props.size === "small"
          ? "small"
          : props.size === "medium"
          ? "medium"
          : ""
      }`}
    >
      <p className={`__inpt_lbl move ffnsb fs11`}>{props.label}</p>
      <p
        style={{
          height: `${
            props.size === "small"
              ? "35px"
              : props.size === "medium"
              ? "42px"
              : "48px"
          }`,
        }}
        className={`df acsa date_display dummy_inpt_wrpr ffnsb ${
          props.size === "small" ? "fs12" : "fs14"
        }`}
        ref={datePickerPos}
        onClick={() => {
          updateShowDatePicker(!showDatePicker);
        }}
      >
        {selectedDate ? moment(selectedDate).format("MM/DD/YYYY") : undefined}
      </p>

      {/* <p
      onClick={() =>
        updateDisplayDate(
          moment(Number(new Date()))
            .startOf("day")
            .add(1, "day")
            .format("MM/DD/YYYY")
        )
      }
    >
      Tomrrow
    </p> */}
      {datePickerPos.current && showDatePicker ? (
        <CalPortal>
          <div
            className="df ffc acc __date_picker_popup"
            style={{
              position: "fixed",
              top: getTop(),
              left: `${
                datePickerPos.current.getBoundingClientRect().left +
                +datePickerPos.current.clientWidth / 2
              }px`,
              zIndex: 99999999,
            }}
          >
            <div className="df acsa jcsb  __date_picker_hdr">
              <div
                className="btn prev"
                onClick={() => moveDate(undefined, "p")}
              ></div>
              <p className="tac ffnb fs19">
                {currentMonth.length
                  ? moment(currentMonth[2][1]).format("MMMM")
                  : undefined}
              </p>
              <div
                className="btn next"
                onClick={() => moveDate(undefined, "f")}
              ></div>
            </div>

            <div className="df weekdays_wrpr">
              {/* {selectedDate} */}
              <div className="day_wrpr ffnb fs12">S</div>
              <div className="day_wrpr ffnb fs12">M</div>
              <div className="day_wrpr ffnb fs12">T</div>
              <div className="day_wrpr ffnb fs12">W</div>
              <div className="day_wrpr ffnb fs12">T</div>
              <div className="day_wrpr ffnb fs12">F</div>
              <div className="day_wrpr ffnb fs12">S</div>
            </div>
            {currentMonth.length &&
              currentMonth.map((week, idx) => (
                <div className="df acsa week_wrpr" key={`W-${idx}`}>
                  {week.map((day) => (
                    <div
                      key={`D-${moment(day).format("DD")}-M-${moment(
                        day
                      ).format("MM")}`}
                      className={`day_wrpr ffnr fs12 ${
                        // eslint-disable-next-line
                        Number(moment(day)) == Number(selectedDate)
                          ? "selected"
                          : ""
                      } ${
                        props.minDate && moment(day).isBefore(props.minDate)
                          ? "disabled"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          props.minDate &&
                          moment(day).isBefore(props.minDate)
                        ) {
                          return;
                        }

                        updateSelectedDate(Number(moment(day).startOf("day")));
                        setTimeout(() => {
                          updateShowDatePicker(!showDatePicker);
                        }, 150);
                      }}
                    >
                      {moment(day).format("DD")}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </CalPortal>
      ) : (
        ""
      )}
    </div>
  );
};

export default DatePicker;
// import SAInput from "../ui/form-elements/stand-alone-input/Input";
// import moment from "moment";

// //import scss
// import "./DatePicker.scss";

// class DatePicker extends Component {
//   state = {
//     month: undefined,
//     currentMonthDay: undefined,
//     showPopup: false
//   };

//   getMonth = async coreDate => {
//     // console.log(this.props.startDate, this.props.defaultDate);
//     let startOfMonth = moment(coreDate)
//       .startOf("month")
//       .startOf("week");
//     let endOfMonth = moment(coreDate)
//       .endOf("month")
//       .endOf("week");

//     let weeksLength = endOfMonth.diff(startOfMonth, "weeks");

//     let weeks = [];
//     let currentDate = startOfMonth;
//     for (let i = 0; i < weeksLength + 1; i++) {
//       //   let days = [];
//       let week = [];

//       for (let i = 0; i < 7; i++) {
//         week.push(currentDate);
//         currentDate = moment(currentDate).add(1, "day");
//       }
//       weeks.push(week);
//     }

//     await this.setState({ month: weeks, currentMonthDay: weeks[1][0] });
//   };

//   moveDate = async val => {
//     let date =
//       (await val) === "next"
//         ? moment(this.state.currentMonthDay).add(1, "month")
//         : moment(this.state.currentMonthDay).subtract(1, "month");

//     await this.setState({ currentMonthDay: date });

//     this.getMonth(this.state.currentMonthDay);
//   };

//   togglePopup = () =>
//     this.setState(prevState => ({
//       showPopup: !prevState.showPopup
//     }));

//   isSelected = day =>
//     Number(moment(day)) === Number(moment(this.props.defaultDate));
//   componentDidMount() {
//     this.getMonth(
//       this.props.defaultDate ? this.props.defaultDate : this.props.startDate
//     );
//   }
//   render() {
//     return (
//       <div className="__date_picker_wrpr">
//         <SAInput
//           ifHasError={this.props.ifHasError}
//           label={this.props.label}
//           onChange={this.props.onChange}
//           readonly={this.props.readonly}
//           value={
//             this.props.value && moment(this.props.value).format("MM/DD/YYYY")
//           }
//           formatType="date"
//           onClick={this.togglePopup}
//           updateButtonAction={this.props.updateAction}
//           disabled={this.props.disabled}
//           updateDisabled={this.props.updateDisabled}
//           field={this.props.field}
//           placeholder={this.props.placeholder}
//         />
//         {/* {console.log(this.props.disabled)} */}
//         {this.state.showPopup && (
//           <div className="__date_picker_popup">
//             <div className="df acsa jcsb  __date_picker_hdr">
//               <div
//                 className="btn prev"
//                 onClick={() => this.moveDate("prev")}
//               ></div>
//               <p className="tac ffqsb fs19">
//                 {moment(this.state.currentMonthDay).format("MMMM YYYY")}
//               </p>
//               <div
//                 className="btn next"
//                 onClick={() => this.moveDate("next")}
//               ></div>
//             </div>
//             <div className="df weekdays_wrpr">
//               <div className="day_wrpr ffmb fs12">S</div>
//               <div className="day_wrpr ffmb fs12">M</div>
//               <div className="day_wrpr ffmb fs12">T</div>
//               <div className="day_wrpr ffmb fs12">W</div>
//               <div className="day_wrpr ffmb fs12">T</div>
//               <div className="day_wrpr ffmb fs12">F</div>
//               <div className="day_wrpr ffmb fs12">S</div>
//             </div>
//             {this.state.month &&
//               this.state.month.length &&
//               this.state.month.map((week, i) => (
//                 <div className="df week_wrpr" key={i}>
//                   {week.map((day, idx) => (
//                     <div
//                       key={idx}
//                       className={`day_wrpr ffmr fs12 ${
//                         this.isSelected(day) ? "selected" : ""
//                       }  ${
//                         moment(day)
//                           .startOf("day")
//                           .diff(moment(new Date()).startOf("day"), "day") === 0
//                           ? "today"
//                           : ""
//                       } ${
//                         moment(day)
//                           .startOf("day")
//                           .diff(
//                             moment(this.props.earliestDate).startOf("day"),
//                             "day"
//                           ) < 0
//                           ? "disabled"
//                           : ""
//                       }`}
//                       onClick={async () => {
//                         await this.props.updateDate(day);
//                         this.togglePopup();
//                       }}
//                     >
//                       {moment(day).format("D")}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//           </div>
//         )}
//       </div>
//     );
//   }
// }

// export default DatePicker;
