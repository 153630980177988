export const StarSVG = (props) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3327 6.69935L11.341 6.18268L8.99935 0.666016L6.65768 6.19102L0.666016 6.69935L5.21602 10.641L3.84935 16.4993L8.99935 13.391L14.1493 16.4993L12.791 10.641L17.3327 6.69935ZM8.99935 11.8327L5.86602 13.7243L6.69935 10.1577L3.93268 7.75768L7.58268 7.44102L8.99935 4.08268L10.4243 7.44935L14.0743 7.76602L11.3077 10.166L12.141 13.7327L8.99935 11.8327Z"
      fill="#1F2041"
      fillOpacity="0.25"
    />
  </svg>
);
