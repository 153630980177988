const ReportsHubSVG = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.47254 9.98047C1.39684 9.99391 1.32239 10 1.24915 10C0.65588 10 0.129796 9.5752 0.0199156 8.97219C-0.102152 8.29227 0.349519 7.6416 1.02943 7.51953C4.08968 6.96777 7.37696 5.4077 10.8083 2.9016L9.36544 1.15113L16.2501 0L13.805 6.53809L12.4 4.83398C8.57932 7.64895 4.99541 9.3457 1.47254 9.98047ZM3.75031 13.75H2.50034C1.81066 13.75 1.25036 14.3091 1.25036 15V18.75C1.25036 19.4409 1.81066 20 2.50034 20H3.75031C4.44124 20 5.00029 19.4409 5.00029 18.75V15C5.00029 14.3091 4.44124 13.75 3.75031 13.75ZM13.7501 8.75H12.5001C11.8092 8.75 11.2502 9.3091 11.2502 10V18.75C11.2502 19.4409 11.8093 20 12.5001 20H13.7501C14.441 20 15.0001 19.4409 15.0001 18.75V10C15.0001 9.3091 14.441 8.75 13.7501 8.75ZM18.75 6.25H17.5C16.8091 6.25 16.2501 6.8091 16.2501 7.5V18.75C16.2501 19.4409 16.8092 20 17.5 20H18.75C19.4409 20 20 19.4409 20 18.75V7.5C20 6.8091 19.4409 6.25 18.75 6.25ZM8.75022 11.25H7.50024C6.81057 11.25 6.25026 11.8091 6.25026 12.5V18.75C6.25026 19.4409 6.81057 20 7.50024 20H8.75022C9.44114 20 10.0002 19.4409 10.0002 18.75V12.5C10.0002 11.8091 9.44114 11.25 8.75022 11.25Z"
      fill={props.fill | "white"}
    />
  </svg>
);

export default ReportsHubSVG;
