import { useState } from "react";
import axios from "axios";
import Portal from "../../../components/portal/Portal";
import { NonFormikInput } from "../../../components/inputs/NonFormikInput";
import AddressAutoComplete from "../../tenants/add-tenant/add-user/address-autocomplete/AddressAutoComplete";

import styles from "./AddLocation.module.scss";
import { useSelector } from "react-redux";

const AddLocation = ({ close }) => {
  const { user } = useSelector(({ user }) => ({ user }));

  const [name, setName] = useState("");
  const [deliveryLine1, setDeliveryLine1] = useState("");
  const [lastLine, setLastLine] = useState("");

  const saveLocation = async () => {
    console.log(user?.account);

    await axios.post(
      "/api/location",
      {
        name,
        deliveryLine1: deliveryLine1,
        lastLine: lastLine,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
  };

  const updateAddress = (e) => {
    Object.keys(e).forEach((key) => {
      if (key === "deliveryLine1") {
        setDeliveryLine1(e[key]);
      } else if (key === "lastLine") {
        setLastLine(e[key]);
      }
    });
  };

  return (
    <Portal
      title="Add Location"
      height="auto"
      width="800px"
      zIndex="1"
      opacity="0.2"
      close={close}
    >
      {/* {deliveryLine1}
      {lastLine} */}
      <div className={styles.content}>
        <NonFormikInput id="name" label="Name" value={name} change={setName} />

        <AddressAutoComplete
          onUpdate={(e) => {
            updateAddress(e);
            // console.log("Updating...");
            // setDeliveryLine1(e.deliveryLine1);
            // setLastLine(e.lastLine);
          }}
        />
      </div>
      <button onClick={saveLocation} className={`w100`}>
        Save Location
      </button>
    </Portal>
  );
};

export default AddLocation;

// name: String,
// address: { ref: "webAddress", type: Types.ObjectId },
// address_line_1: String,
// address_line_2: String,
// city: String,
// state: String,
// zip: String,
// status: String,

// locationPhoneNumber: String,
// account: { type: ObjectId, ref: "portalAccount" },
// defaultWebsiteProfile: { type: ObjectId, ref: "websiteProfile" },
// createdBy: ObjectId,
// securityDeposit: Currency,
// currency: String,
// adminFee: Currency,
// unitTaxRate: Number,
