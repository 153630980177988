import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import * as creditCards from "../../../../../../assets/images/credit-cards";
import { formatFromCurrencyToMoney } from "../../../../../../helpers";

import CardsPopup from "../../../payments-popup/cards-popup/CardsPopup";
import styles from "./PayBalance.module.scss";

const PayBalance = ({
  balance,
  credits,
  userId,
  stepBack,
  reservationId,
  close,
  refreshAction,
}) => {
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));
  const [showCardPopup, setShowCardPopup] = useState(false);
  const [paymentType, setPaymentType] = useState(undefined);
  const [paymentMethod, setPaymentMethod] = useState(undefined);
  const [payMethodDetails, setPayMethodDetails] = useState(undefined);

  const getPayMethodDetails = async () => {
    let locatSelectedPayMethodDetails = await axios.get(
      `/api/payment-method/${paymentMethod}`
    );
    setPayMethodDetails(locatSelectedPayMethodDetails.data.data);
  };

  useEffect(() => {
    if (!paymentMethod) return;
    if (paymentMethod === "cash") {
      console.log("Cash");
    } else if (paymentMethod === "check") {
      console.log("Check");
    } else {
      console.log("CC");
      getPayMethodDetails();
    }
    // eslint-disable-next-line
  }, [paymentMethod]);

  const getIcon = ({ xCardType }) => {
    // console.log(")IU7nyUNY", selectedPayMethodDetails);
    // console.log(selectedPayMethodDetails);
    if (!xCardType) return;
    if (xCardType === "check") return "BA";
    let CardLogo = creditCards[xCardType];
    return <CardLogo />;
  };

  const processPayment = async () => {
    // axios.post(`/api/move-out/payment?applycredits=${true}`, {
    //   paymentMethod,
    //   balance,
    //   credits,
    //   userId,
    //   reservationId,
    // });

    await axios.post(
      `/api/portal-payment/?reservationid=${reservationId}&applycredit=true`,
      {
        amount: balance,
        payMethod: paymentMethod,
        userId: userId,
        location: reduxUser.selectedLocation._id,
        account: user.account,
      }
    );

    completeMoveOut();
  };

  const completeMoveOut = async () => {
    await axios.put(
      `/api/reservation/${reservationId}`,
      { moveOutDate: Number(new Date()) },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    refreshAction();
    close();
  };
  return (
    <>
      <div className={`p-t-16 p-r-16 p-l-28 p-b-28 ${styles.payBalance}`}>
        <h4 className={`ffnsb fs14 m-b-20`}>Payments</h4>
        <p className={`ffnsb fs14 m-b-15`}>
          Payment Due{" "}
          <span className={styles.amount}>
            {formatFromCurrencyToMoney(balance - credits)}
          </span>
        </p>

        {!payMethodDetails ? (
          <div className={`ffnr fs14 ${styles.methodsWrpr}`}>
            <div
              className={`df acsa ${styles.line}`}
              onClick={() => {
                setShowCardPopup(true);
                setPaymentType("cc");
              }}
            >
              <div
                className={`df acc ${styles.radio} ${
                  paymentType === "cc" ? styles.selected : undefined
                }`}
              ></div>
              <p className={`p-l-10`}>Card</p>
            </div>
            <div className={`df acsa ${styles.line}`}>
              <div className={`df acc ${styles.radio}`}></div>
              <p className={`p-l-10`}>Cash</p>
            </div>
            <div className={`df acsa ${styles.line}`}>
              <div className={`df acc ${styles.radio}`}></div>
              <p className={`p-l-10`}>Check</p>
            </div>
          </div>
        ) : (
          <div
            className={`df acsa ffnr fs16 m-t-25 m-b-50 ${styles.selectedWrpr}`}
          >
            <div className={styles.cardIconWrpr}>
              {getIcon(payMethodDetails)}
            </div>{" "}
            {payMethodDetails.xCardType} ****
            {payMethodDetails.xMaskedCardNumber.substring(
              payMethodDetails.xMaskedCardNumber.length - 4,
              payMethodDetails.xMaskedCardNumber.length
            )}
            <button
              onClick={() => {
                setPaymentType(undefined);
                setPayMethodDetails(undefined);
              }}
              className={`secondary small m-l-20 p-l-20 p-r-20`}
            >
              Change Payment Method
            </button>
          </div>
        )}

        {showCardPopup ? (
          <CardsPopup
            close={() => setShowCardPopup(false)}
            userId={userId}
            type="cc"
            setCardType={(e) => setPaymentMethod(e)}
          />
        ) : undefined}
      </div>

      <div className={`${styles.moveOutFtr} df p-t-20 p-b-20 p-l-20 p-r-20 `}>
        <div className={"mla"}>
          <button
            //   disabled={step === 0}
            className={`${styles.moveOutBtn} secondary ttuc m-r-15 p-l-25 p-r-25`}
            onClick={stepBack}
          >
            Back
          </button>

          <button
            //   disabled={step === 0}
            className={`${styles.moveOutBtn} secondary ttuc m-r-15 p-l-25 p-r-25`}
            onClick={completeMoveOut}
          >
            Moveout anyway
          </button>

          <button
            onClick={processPayment}
            disabled={!payMethodDetails}
            className={`primary ttuc p-l-25 p-r-25`}
          >
            Process payment
          </button>
        </div>
      </div>
    </>
  );
};

export default PayBalance;
