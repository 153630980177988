import React from "react";

const Arrow = (props) => {
  return (
    <svg
      width={props.width || "10"}
      height={props.height || "6"}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.55469 0.433594L0.5 1.48828L5 5.98828L9.5 1.48828L8.44531 0.433594L5 3.87891L1.55469 0.433594Z"
        fill={props.fill || "#F2F2F2"}
        fillOpacity={props.fillOpacity || "0.5"}
      />
    </svg>
  );
};

export default Arrow;
