// import openSocket from "socket.io-client";
// eslint-disable-next-line
import axios from "axios";
// eslint-disable-next-line
import { useEffect, useState } from "react";
// eslint-disable-next-line
import { Device } from "twilio-client";
import Lottie from "react-lottie";
import ExpiringCards from "./widgets/expiring-cards/ExpiringCards";
// eslint-disable-next-line
// const socket = openSocket("/");

import styles from "./Dashboard.module.scss";
import PastDues from "./widgets/past-dues/PastDues";

import loader from "../../assets/lotties/loader.json";

const Dashboard = () => {
  // eslint-disable-next-line
  // const [calls, setCalls] = useState([]);

  // useEffect(() => {
  //   socket.on("callComing", (data) => {
  //     console.log(data);
  //     setCalls([...calls, data.data]);
  //   });

  //   axios
  //     .get("/api/communications/token")
  //     .then((res) => Device.setup(res.data));

  //   Device.on("incoming", (conn) => {
  //     console.log("Incoming connection from ", conn.parameters.From);
  //     conn.accept();
  //   });
  //   // eslint-disable-next-line
  // }, []);

  // const answer = (call) => {
  //   axios
  //     .post("/api/communications/answer", { id: call.CallSid })
  //     .then((res, err) => {
  //       console.log(res.err);
  //     });
  // };

  // /api/communications/token

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.dashboard}>
      <Lottie options={defaultOptions} height={60} width={60} />
      <div className={"df aic"}>
        <PastDues />
      </div>
      <div className={"df aic"}>
        <ExpiringCards />
      </div>

      {/* <div>
        {calls.map((call) => (
          <p onClick={() => answer(call)} key={call.CallSid}>
            {call.From}
          </p>
        ))}
      </div> */}
    </div>
  );
};

export default Dashboard;
