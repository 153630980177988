import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import Portal from "../../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";

import checkMark from "../../../../../assets/images/checkmark.svg";

import styles from "./AddPopup.module.scss";
import NonFormikSelect from "../../../../../components/inputs/drop-down/non-formik/NonFormikSelect";
import CurrencyInput from "../../../../../components/inputs/CurrencyInput";

const feeTypes = [
  { label: "Admin fee", value: "adminFee" },
  { label: "Late fee", value: "lateFee" },
  { label: "Lien fee", value: "lienFee" },
  { label: "Auction fee", value: "auctionFee" },
];
// lateFee || adminFee || lienFee || auctionFee

const AddPopup = ({ close, feeAdded }) => {
  const { user, reduxUser } = useSelector(({ user, reduxUser }) => ({
    user,
    reduxUser,
  }));

  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState(true);

  const save = async () => {
    // console.log(user.account, reduxUser.selectedLocation._id, name, active);
    let localFee = await axios.post("/api/fee", {
      label,
      value,
      type: type?.value,
      active,
      accountId: user.account,
      locationId: reduxUser.selectedLocation._id,
    });

    feeAdded(localFee?.data?.data);

    close();
  };

  return (
    <Portal
      zIndex="1"
      opacity="0.3"
      close={close}
      height="auto"
      width="400px"
      title="Add Fee"
    >
      <div className={`p-l-20 p-r-20 p-t-10 ${styles.addInsProvider}`}>
        <NonFormikInput
          label="Fee name"
          value={label}
          change={(val) => setLabel(val)}
          id="label"
        />
        {/* <NonFormikInput
          label="Fee amount"
          value={value}
          change={(val) => setValue(val)}
          id="label"
        /> */}

        <CurrencyInput
          id="reAmount"
          value={value}
          label="amount"
          onChange={(val) => setValue(val.fullNumber)}
          // onChange={(val) => console.log(val.fullNumber)}
        />
        <NonFormikSelect
          options={feeTypes}
          menuPortalTarget={document.body}
          label="Fee type"
          value={type}
          onChange={(val) => {
            setType(val);
          }}
        />
        <div
          className={`df acsa ${styles.activeSwitch} ${
            active ? styles.active : ""
          }`}
          onClick={() => setActive(!active)}
        >
          <div className={`df acc ${styles.activeCheckbox}`}>
            {active ? (
              <img className={`m-t-2`} src={checkMark} alt="" />
            ) : undefined}
          </div>
          <p className={`ffnsb fs14`}>Active</p>
        </div>
        <div className={`footer p-b-30`}>
          <button onClick={save} className="button primary w100 m-t-20 ttuc">
            Save
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default AddPopup;
