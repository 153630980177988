import Portal from "../portal/Portal";
import Slider from "@reach/slider";
import "@reach/slider/styles.css";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./utils";
// import "react-image-crop/dist/ReactCrop.css";

import styles from "./ImageUploadPopup.module.scss";
import ClosePopup from "../../assets/js-svgs/close-popup.svg";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ImageUploadPopup = (props) => {
  const [imageSrc, setImageSrc] = useState(props.imageUrl || null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.imageUrl) setImageSrc(props.imageUrl);
  }, [props.imageUrl]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      // setCroppedImage(croppedImage);
      props.setImageBlob(croppedImage.blob);

      props.setBase64Image(croppedImage.base64);
      setLoading(false);
      props.close(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
    // eslint-disable-next-line
  }, [imageSrc, croppedAreaPixels]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      props.setImageFileName(file.name);
    }
  };

  return (
    <Portal customHeader="true" height="auto" opacity="0" zIndex="999">
      <div className={styles.uploadPopup}>
        <div
          className={`df acc ${styles.closeWrpr}`}
          onClick={() => props.close(false)}
        >
          <ClosePopup />
        </div>
        {imageSrc || props.imageUrl ? (
          <>
            <div className={styles.cropSection}>
              <div className={styles.cropWrapper}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  aspect={props.aspect || 3.5 / 4}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  zoom={zoom}
                />
              </div>
              <div className={`df acsa ${styles.sliderWrapper}`}>
                <div className={styles.innerSliderWrapper}>
                  <Slider
                    min={1}
                    max={4}
                    step={0}
                    onChange={(range) => setZoom(range)}
                  />
                </div>
              </div>
            </div>
            <div className={`df acsa ${styles.ftr}`}>
              <div className="df mla">
                <label
                  className={`df button secondary w100 ttuc df acc ffnr p-l-20 p-r-20`}
                  htmlFor="file_input"
                >
                  Change image
                </label>

                <p
                  className={`df acc ffsm fs14 button primary w100 ttuc df acc ffnr p-l-20 p-r-20 m-l-10 ${
                    styles.setBtn
                  } ${loading ? styles.disabled : ""}`}
                  onClick={() => !loading && showCroppedImage()}
                >
                  Set image
                </p>
              </div>
            </div>
          </>
        ) : (
          // setBtn
          <>
            <label
              className={`df acc ffc ffsr fs14 ${styles.selectBtn}`}
              htmlFor="file_input"
            >
              <span className={`ffnsb fs18`}>Click to upload images</span>
            </label>
          </>
        )}

        <input
          id="file_input"
          style={{ display: "none" }}
          type="file"
          onChange={onFileChange}
          accept="image/*"
        />
      </div>
    </Portal>
  );
};

export default ImageUploadPopup;
