import axios from "axios";
import { useState } from "react";
import { NonFormikInput } from "../../../../../../components/inputs/NonFormikInput";
import Portal from "../../../../../../components/portal/Portal";

import styles from "./AddCheck.module.scss";

const AddCheck = ({ userId, getAccounts, close }) => {
  const [xName, setXName] = useState("");
  const [xRouting, setXRouting] = useState("");
  const [xAccount, setXAccount] = useState("");
  const [xAccountCopy, setXAccountCopy] = useState();

  const addPaymentMethod = async () => {
    console.log("O:K");

    // let saved =
    await axios.post("/api/payment-method", {
      userId: userId,
      xName,
      xRouting,
      xAccount,
      type: "check",
    });

    // setAccount(saved?.data?.data?._id);
    getAccounts();
    close();

    // return "OK";
  };

  return (
    <Portal
      title="Add check"
      zIndex="107"
      width="330px"
      height="auto"
      minHeight="auto"
      opacity="0.1"
      close={close}
    >
      <div className={`p-t-0 p-b-20 p-l-20 p-r-20`}>
        <div className={styles.innerCntntWrpr}>
          <NonFormikInput
            change={setXName}
            label="Name on account"
            size="small"
            value={xName}
            id="nameOnAccount"
          />

          <NonFormikInput
            change={setXRouting}
            label="Account Routing Number"
            size="small"
            value={xRouting}
            id="accountRouting"
          />

          <NonFormikInput
            change={setXAccount}
            label="Account Number"
            size="small"
            value={xAccount}
            id="accountNumber"
          />

          <NonFormikInput
            change={setXAccountCopy}
            label="Confirm Account Number"
            size="small"
            value={xAccountCopy}
            id="confirmAccountNumber"
          />
        </div>
        <div className={`m-t-20 ${styles.ftr}`}>
          <button
            disabled={
              !xName ||
              !xRouting ||
              !xAccount ||
              !xAccountCopy ||
              xAccount !== xAccountCopy
            }
            className={`button secondary small w100`}
            onClick={() => {
              addPaymentMethod();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default AddCheck;
