import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";

import styles from "./Adjust.module.scss";
import { useEffect, useState } from "react";
import AdjustLine from "./adjust-line/AdjustLine";
import DatePicker from "../../../components/inputs/datepicker/DatePicker";
import { CheckInCircle } from "../../../assets/js-svgs/check-in-circle.svg";

const Adjust = () => {
  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));
  const [units, setUnits] = useState(undefined);
  const [selected, setSelected] = useState([]);
  const [showAdjust, setShowAdjust] = useState(true);
  const [type, setType] = useState("dollar");
  const [amount, setAmount] = useState("");
  const [activeDate, setActiveDate] = useState(
    Number(dayjs(new Date()).startOf("day").add(1, "day"))
  );
  const [confirmAdjust, setConfirmAdjust] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const getUnits = async () => {
    let localUnits = await axios.get(
      `/api/price/units?locations=${[reduxUser.selectedLocation._id]}`
    );

    setUnits(localUnits.data);
  };

  const toggleCheckbox = (id) => {
    let index = selected.findIndex((el) => {
      return id.reservationId === el.reservationId;
    });

    if (index >= 0) {
      let copy = cloneDeep(selected);
      copy.splice(index, 1);
      setSelected(copy);
    } else {
      setSelected([...selected, id]);
    }
  };

  const selectAllTenant = (userId) => {
    console.log("SELECTING");
    let userIndex = units.findIndex((elem) => userId === elem.user._id);
    const copy = cloneDeep(selected);
    units[userIndex].reservations.map((reservation) => {
      let index = selected.findIndex((el) => {
        return reservation._id === el.reservationId;
      });
      if (index >= 0) {
        return true;
      } else {
        return copy.push({
          unitId: reservation?.unit?._id,
          reservationId: reservation?._id,
          currentPrice: reservation?.currentRate?.price,
        });
      }
    });
    return setSelected(copy);
  };

  const deSelectAllTenant = async (userId) => {
    console.log("DESELECTING");
    let userIndex = units.findIndex((elem) => userId === elem.user._id);
    const copy = cloneDeep(selected);

    let updated = await copy.filter(
      (reservation) =>
        !units[userIndex].reservations
          .map((res) => res._id)
          .includes(reservation.reservationId)
    );

    return setSelected(updated);
  };

  // unitId: reservation?.unit?._id,
  // reservationId: reservation?._id,
  // currentPrice: reservation?.currentRate?.price,

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line
  }, []);

  const adjust = async () => {
    await axios.post(`/api/price/adjust`, {
      reservations: selected,
      amount: amount,
      activeDate: activeDate,
      type: type,
    });

    setConfirmAdjust(false);
    setShowSuccess(true);
  };

  // unitId: val._id,
  // reservationId: val?.activeReservations?._id,
  // currentPrice: val?.activeReservations?.reservationPrice?.price,

  const hasValue = (prop) => prop?.toString()?.length;

  useEffect(() => {
    if (showSuccess) {
      getUnits();
      setSelected([]);
      setAmount("");
      setActiveDate(Number(dayjs(new Date()).startOf("day").add(1, "day")));
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [showSuccess]);

  return (
    <div className={styles.adjustPage}>
      <div className={`top_bar ${styles.header}`}>
        <div className="df acsa header">
          <div className="ffnsb fs16">Tenants</div>
          <NavLink
            exact
            className={`ffnsb fs14 ${styles.navSec}`}
            activeClassName={styles.selected}
            to="/tenants"
          >
            List
          </NavLink>
          <NavLink
            exact
            className={`ffnsb fs14 ${styles.navSec}`}
            activeClassName={styles.selected}
            to="/tenants/adjust"
          >
            Adjust Rate
          </NavLink>
          <div className="df accsa mla actions_wrpr">
            <p
              className="df ffnb fs14 rwt main_action"
              onClick={() => {
                setShowAdjust(!showAdjust);
                // this.props.setBgBlur(true);
                // this.setState({ showAddTenant: true });
              }}
            >
              {showAdjust ? "Cancel" : "Adjust"}
            </p>
          </div>
        </div>
        <div className={`df acsa ${styles.filtersWrpr}`}>
          <p className={`ffnl fs14`}>Filter by:</p>
          {/* <div className={styles.filterWrpr}>
            <div
              className={`m-l-10 df acsa ffnl fs14 ${styles.filterTypeWrpr}`}
              onClick={() => setShowFilter(true)}
            >
              <p>Unit Type</p>{" "}
              {filters?.types?.length ? (
                <p className={`df acc ffnb fs10 m-l-5 ${styles.countPill}`}>
                  {filters?.types?.length}
                </p>
              ) : undefined}
            </div>

            {showFilter ? (
              <Portal
                zIndex="9"
                opacity="0.2"
                close={() => setShowFilter(false)}
                height="auto"
                title="Select Unit Type"
                width="300px"
                customHeader={true}
              >
                <UnitTypeFilter
                  selected={filters?.types}
                  close={() => setShowFilter(false)}
                  updateFilter={(vals) => {
                    updateFilters("types", vals);
                  }}
                />
              </Portal>
            ) : undefined}
          </div>
          <div className="mla">
            <div className={`${styles.searchInputWrpr}`}>
              <div className={`${styles.searchIcon}`}>
                <img src={searchIcon} alt="" />
              </div>
              <input
                placeholder="Search tenants"
                type="text"
                className={`${styles.searchInput} ffnr fs14`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div> */}
        </div>
      </div>

      {units?.length ? (
        <div
          className={`p-t-20 ${styles.listWrpr} ${
            showAdjust ? styles.footerOpen : ""
          }`}
        >
          {units.map((user) => (
            <AdjustLine
              key={user._id}
              user={user}
              toggleCheckbox={toggleCheckbox}
              selected={selected}
              type={type}
              amount={amount}
              selectAllTenant={selectAllTenant}
              deSelectAllTenant={deSelectAllTenant}
            />
          ))}
        </div>
      ) : undefined}

      {showAdjust ? (
        <div className={`df acsa p-l-20 p-r-20 ${styles.adjustFooter}`}>
          <div className={`df aic ffnr fs14 ${styles.typeToggle}`}>
            <div
              onClick={() => setType("dollar")}
              className={`df acc ${styles.sec} ${
                type === "dollar" ? styles.selected : ""
              }`}
            >
              <span className={`m-r-5`}>$</span>Dollar Amount
            </div>
            <div
              onClick={() => setType("percentage")}
              className={`df acc ${styles.sec} ${
                type === "percentage" ? styles.selected : ""
              }`}
            >
              <span className={`m-r-5`}>%</span> Percentage
            </div>
          </div>
          <div
            className={`m-l-20 ${styles.inputWrpr} ${
              hasValue(amount) ? styles.dirty : ""
            }`}
          >
            <label className={`ffnr`} htmlFor="amount">
              Amount
            </label>
            <input
              value={amount}
              id="amount"
              type="text"
              onChange={(e) => setAmount(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className={`m-l-20`} style={{ width: "150px" }}>
            <DatePicker
              label="Effective date"
              size="medium"
              field=""
              onChange={(a, b) => setActiveDate(b)}
              value={activeDate}
              defaultDate={activeDate}
              minDate={Number(dayjs(new Date()).startOf("day").add(1, "day"))}
            ></DatePicker>
          </div>
          <div className={`mla`}>
            <button
              disabled={!amount || !activeDate || !selected?.length}
              className="p-l-35 p-r-35 ffnb fs14 button primary process_btn ttuc"
              onClick={() => {
                setConfirmAdjust(true);
                // adjust();
                // setShowAdjust(!showAdjust);
                // this.props.setBgBlur(true);
                // this.setState({ showAddTenant: true });
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      ) : undefined}

      {confirmAdjust ? (
        <div className={`df acc ${styles.confirmAdjust}`}>
          <div className={`p-t-20 p-b-20 p-l-20 p-r-20 ${styles.confirmPopup}`}>
            <p className={`ffnr fs14 tac p-t-40 m-b-35`}>
              Do you want to confirm the changes?
            </p>
            <button
              className="w100 ffnb fs14 button primary process_btn ttuc m-b-10"
              onClick={adjust}
            >
              Confirm
            </button>
            <button
              className="w100 ffnb fs14 button secondary process_btn ttuc"
              onClick={() => setConfirmAdjust(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : undefined}

      {showSuccess ? (
        <div className={`df acc ${styles.confirmAdjust}`}>
          <div
            className={`df acc ffc p-t-50 p-b-50 p-l-20 p-r-20 ${styles.confirmPopup}`}
          >
            <CheckInCircle height="40" width="40" />
            <p className={`ffnr fs20 m-t-40`}>Successfully Adjusted</p>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default Adjust;
