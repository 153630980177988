import React from "react";

export const Citi = (props) => {
  return (
    <svg
      width={props.width || "496"}
      height={props.height || "321"}
      viewBox="0 0 496 321"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M240 120H200V320H240V120Z" fill="#3042C6" />
      <path
        d="M272 120.001V152.001H312V267.201C312 282.401 314.4 295.201 323.2 304.001C331.2 313.601 341.6 318.401 355.2 318.401C368.8 318.401 380.8 316.001 391.2 309.601H392V308.801L400.8 271.201L398.4 272.801C387.2 279.201 379.2 282.401 369.6 282.401C356.8 282.401 352 275.201 352 260.001V152.001H392V120.001H352V56.8008L312 78.4008V120.001H272Z"
        fill="#32357F"
      />
      <path d="M472 120H432V320H472V120Z" fill="#32357F" />
      <path
        d="M174.4 149.601L173.6 148.801C155.2 126.401 132 115.201 104 115.201C74.4 115.201 50.4 124.001 31.2 141.601C10.4 160.801 0 185.601 0 217.601C0 249.601 10.4 274.401 31.2 293.601C50.4 311.201 74.4 320.001 104 320.001C132 320.001 155.2 308.801 173.6 286.401L174.4 285.601L151.2 257.601L150.4 258.401C135.2 272.801 119.2 280.801 102.4 280.801C84.8 280.801 69.6 274.401 58.4 262.401C46.4 250.401 40.8 235.201 40.8 216.801C40.8 198.401 46.4 183.201 58.4 171.201C70.4 159.201 84.8 153.601 102.4 153.601C119.2 153.601 135.2 161.601 150.4 176.001L151.2 176.801L174.4 149.601Z"
        fill="#32357F"
      />
      <path
        d="M217.6 86.4C233.6 70.4 251.2 57.6 272 48.8C291.2 40 312 36 333.6 36C355.2 36 376 40 396 48.8C416 57.6 433.6 70.4 449.6 87.2L450.4 88.8H496L494.4 85.6C476 60 452 38.4 423.2 24C394.4 8 364.8 0 333.6 0C302.4 0 272 7.2 243.2 22.4C214.4 37.6 190.4 58.4 172 84.8L170.4 88H216.8L217.6 86.4Z"
        fill="#EA2230"
      />
      <path d="M240 135.2L200 126.4V320H240V126.4V135.2Z" fill="#32357F" />
      <path
        d="M272 120.001V152.001H312V267.201C312 282.401 314.4 295.201 323.2 304.001C331.2 313.601 341.6 318.401 355.2 318.401C368.8 318.401 380.8 316.001 391.2 309.601H392V308.801L400.8 271.201L398.4 272.801C387.2 279.201 379.2 282.401 369.6 282.401C356.8 282.401 352 275.201 352 260.001V152.001H392V120.001H352V56.8008L312 78.4008V120.001H272Z"
        fill="#3042C6"
      />
      <path
        d="M272 120V152H312V267.2C312 282.4 314.4 295.2 323.2 304C331.2 313.6 341.6 318.4 355.2 318.4C368.8 318.4 380.8 316 391.2 309.6H392V308.8L400.8 271.2L398.4 272.8C387.2 279.2 379.2 282.4 369.6 282.4C356.8 282.4 352 275.2 352 260V152H392V120H352V96.0004L312 78.4004V120H272Z"
        fill="#32357F"
      />
      <path d="M472 120H432V320H472V120Z" fill="#3042C6" />
      <path d="M472 120L432 133.6V320H472V120Z" fill="#32357F" />
      <path
        d="M173.6 150.399V148.799C155.2 126.399 132 115.199 104 115.199C74.4 115.199 50.4 123.999 31.2 141.599C10.4 160.799 0 185.599 0 217.599C0 249.599 10.4 274.399 31.2 293.599C50.4 311.199 74.4 319.999 104 319.999C132 319.999 155.2 308.799 173.6 286.399L174.4 285.599L151.2 257.599L150.4 258.399C135.2 272.799 119.2 280.799 102.4 280.799C84.8 280.799 69.6 274.399 58.4 262.399C46.4 250.399 40.8 235.199 40.8 216.799C40.8 198.399 46.4 183.199 58.4 171.199C70.4 159.199 84.8 153.599 102.4 153.599C119.2 153.599 135.2 161.599 150.4 175.999L151.2 176.799L174.4 148.799L173.6 150.399Z"
        fill="#3042C6"
      />
      <path
        d="M31.2 141.6C10.4 160.8 0 185.6 0 217.6C0 249.6 10.4 274.4 31.2 293.6C50.4 311.2 74.4 320 104 320C132 320 155.2 308.8 173.6 286.4L174.4 285.6L151.2 257.6L150.4 258.4C135.2 272.8 119.2 280.8 102.4 280.8C84.8 280.8 69.6 274.4 58.4 262.4C46.4 250.4 40.8 235.2 40.8 216.8C40.8 198.4 46.4 183.2 58.4 171.2C70.4 159.2 84.8 153.6 102.4 153.6C119.2 153.6 135.2 161.6 150.4 176L151.2 176.8L172.8 150.4V148.8"
        fill="#32357F"
      />
      <path
        d="M217.6 86.4C233.6 70.4 251.2 57.6 272 48.8C291.2 40 312 36 333.6 36C355.2 36 376 40 396 48.8C416 57.6 433.6 70.4 449.6 87.2L450.4 88.8H496L494.4 85.6C476 60 452 38.4 423.2 24C394.4 8 364.8 0 333.6 0C302.4 0 272 7.2 243.2 22.4C214.4 37.6 190.4 58.4 172 84.8L170.4 88H216.8L217.6 86.4Z"
        fill="#E92F30"
      />
      <path
        d="M217.6 86.4C233.6 70.4 251.2 57.6 272 48.8C291.2 40 312 36 333.6 36C355.2 36 376 40 396 48.8C416 57.6 433.6 70.4 449.6 87.2L450.4 88.8H496L494.4 85.6C476 60 452 38.4 423.2 24C394.4 8 364.8 0 333.6 0C302.4 0 272 7.2 243.2 22.4C214.4 37.6 190.4 58.4 172 84.8L170.4 88H216.8L217.6 86.4Z"
        fill="#E92F30"
      />
      <path
        d="M217.6 86.4C233.6 70.4 251.2 57.6 272 48.8C291.2 40 312 36 333.6 36C355.2 36 376 40 396 48.8C416 57.6 433.6 70.4 449.6 87.2L450.4 88.8H496L494.4 85.6C476 60 452 38.4 423.2 24C394.4 8 364.8 0 333.6 0C302.4 0 272 7.2 243.2 22.4C214.4 37.6 190.4 58.4 172 84.8L170.4 88H216.8L217.6 86.4Z"
        fill="#FF3E48"
      />
      <path
        d="M423.199 35.9996C394.399 20.7996 364.799 13.5996 332.799 13.5996C301.599 13.5996 271.199 21.5996 242.399 35.9996C217.599 48.7996 196.799 63.9996 179.199 87.9996H215.999V86.3996C231.999 70.3996 249.599 57.5996 270.399 48.7996C289.599 39.9996 310.399 35.9996 331.999 35.9996C353.599 35.9996 374.399 39.9996 394.399 48.7996C414.399 57.5996 431.999 70.3996 447.999 87.1996L448.799 88.7996H485.599C469.599 63.9996 447.999 48.7996 423.199 35.9996Z"
        fill="#E92F30"
      />
      <path
        d="M216.8 87.9992C232.8 71.9992 251.2 57.5992 271.2 48.7992C290.4 39.9992 311.2 35.9992 332.8 35.9992C354.4 35.9992 375.2 39.9992 395.2 48.7992C415.2 57.5992 432.8 70.3992 448.8 87.1992L449.6 88.7992H475.2C460 72.7992 443.2 59.9992 424 49.5992C395.2 34.3992 365.6 27.1992 333.6 27.1992C302.4 27.1992 272 35.1992 243.2 49.5992C224 59.9992 206.4 72.7992 192 88.7992H216.8V87.9992Z"
        fill="#D3000B"
      />
      <path
        d="M432 201.6V320H472V229.6C456 218.4 448 209.6 432 201.6Z"
        fill="#0A0E49"
      />
      <path
        d="M328.8 174.4C323.2 174.4 312 174.4 312 175.2V267.2C312 282.4 314.4 295.2 323.2 304C331.2 313.6 341.6 318.4 355.2 318.4C368.8 318.4 380.8 316 391.2 309.6H392V308.8L400.8 271.2L398.4 272.8C387.2 279.2 379.2 282.4 369.6 282.4C356.8 282.4 352 275.2 352 260V175.2C344 174.4 336.8 174.4 328.8 174.4Z"
        fill="#0A0E49"
      />
      <path
        d="M200 216.8V320H240V193.6C224 200 208 208 200 216.8Z"
        fill="#0A0E49"
      />
      <path
        d="M108.801 319.2C134.401 317.6 156.001 306.4 172.801 285.6L173.601 284.8L152.001 258.4C136.001 276.8 121.601 296.8 108.801 319.2Z"
        fill="#0A0E49"
      />
    </svg>
  );
};
