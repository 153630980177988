import { ADD_UNIT, GET_UNITS } from "../actions/types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_UNIT:
      return action.payload;
    case GET_UNITS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
