import axiosDefaults from "axios/lib/defaults";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { getUser, updateReduxUser } from "./store/actions";
import LoginPage from "./containers/login/Login";
import Home from "./containers/home/Home";
import "./global.scss";
import "./topBar.scss";
import V from "./metadata.json";

// import Tracker from "@openreplay/tracker";
// import ReporterTool from "./reporter-tool/ReporterTool";

// const tracker = new Tracker({
//   projectKey: "4tHOure2Vn8aXPhcR2dA",
//   __DISABLE_SECURE_MODE: true,
// });
// tracker.start();

// tracker.event("product_added", "shoes");
if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  axiosDefaults.baseURL = "https://api.slfstrg.com/";
}

class App extends Component {
  state = { loading: true };
  async componentDidMount() {
    console.log(
      `Running version ${V.buildMajor}.${V.buildMinor}.${V.buildRevision} - ${V.buildTag}`
    );
    await this.props.getUser();
    this.setState({ loading: false });
  }
  render() {
    return (
      <>
        {!this.state.loading ? (
          // <div className="App" style={{filter:`blur(${this.props.user.blurBG ? '3px': '0px'})`}>
          <div
            className="App"
            style={{
              transform: "translateZ(0)" /*for older browsers*/,
              willChange: "transform",
              filter: `blur(${
                this.props.user && this.props.user.blurBG ? "1.5px" : ""
              })`,
              transition: "filter .1s",
            }}
          >
            <BrowserRouter>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/" component={Home} />
              </Switch>
            </BrowserRouter>
          </div>
        ) : (
          "Loading"
        )}
        {/* <ReporterTool tracker={tracker} /> */}
      </>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { getUser, updateReduxUser })(App);
