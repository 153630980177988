import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import CurrencyInput from "../../../../../components/inputs/CurrencyInput";
import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";
import Portal from "../../../../../components/portal/Portal";

const AddUnitSizePopup = ({ size, close, saved, updated }) => {
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [label, setLabel] = useState(size?.label || "");
  const [price, setPrice] = useState(size?.price || 0);
  const [length, setLength] = useState(size?.length || "");
  const [width, setWidth] = useState(size?.width || "");
  const [height, setHeight] = useState(size?.height || "");

  const saveUnitSize = async () => {
    // return console.log({
    //   label,
    //   length,
    //   width,
    //   height,
    //   price,
    //   currency: reduxUser.selectedLocation.currency,
    //   accountId: user.account,
    //   locationId: reduxUser.selectedLocation._id,
    // });
    if (size?._id) {
      let localUnitSize = await axios.put(`/api/unit-size/${size._id}`, {
        label,
        length,
        width,
        height,
        price,
        currency: reduxUser.selectedLocation.currency,
        accountId: user.account,
        locationId: reduxUser.selectedLocation._id,
      });
      updated(localUnitSize.data.unitSize);
    } else {
      let localUnitSize = await axios.post(
        `/api/unit-size`,
        {
          label,
          length,
          width,
          height,
          price,
          currency: reduxUser.selectedLocation.currency,
          accountId: user.account,
          locationId: reduxUser.selectedLocation._id,
        },
        { headers: { "x-auth": localStorage.getItem("x-auth") } }
      );
      saved(localUnitSize.data.unitSize);
    }
    close();
  };

  //   /api/unit-size

  //   label: String,
  //   length: Number,
  //   width: Number,
  //   height: Number,
  //   price: Currency,
  //   currency: String,
  //   accountId: { type: ObjectId, ref: "portalAccount" },
  //   locationId: { type: ObjectId, ref: "portalLocation" },

  return (
    <Portal
      close={close}
      zIndex={1}
      title={`${size?._id ? "Edit" : "Add"} unit size`}
      height="auto"
      width="374px"
      opacity="0.2"
    >
      <div className={`p-l-20 p-r-20`}>
        <div className={`df m-b-10`}>
          <NonFormikInput
            value={label}
            change={setLabel}
            label="Label"
            id="label"
          />
        </div>
        <div className={`df m-b-10`}>
          <CurrencyInput
            value={price}
            label="Price"
            id="price"
            onChange={(val) => setPrice(val.fullNumber)}
          />
        </div>
        <div className={`df m-b-10`}>
          <div className={`pw33 m-r-10`}>
            <NonFormikInput
              value={length}
              change={setLength}
              label="Length"
              id="length"
            />
          </div>
          <div className={`pw33`}>
            <NonFormikInput
              value={width}
              change={setWidth}
              label="Width"
              id="width"
            />
          </div>
          <div className={`pw33 m-l-10`}>
            <NonFormikInput
              value={height}
              change={setHeight}
              label="Height"
              id="height"
            />
          </div>
        </div>

        <button
          onClick={saveUnitSize}
          className={`button primary w100 m-t-15 ttuc m-b-25`}
        >{`${size?._id ? "Update" : "Save"} unit size`}</button>
      </div>
    </Portal>
  );
};

export default AddUnitSizePopup;
