import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";
import { formatFromCurrencyToMoney } from "../../../../../helpers";
import Portal from "../../../../../components/portal/Portal";
import styles from "./InsurancePopup.module.scss";

const NewInsurancePopup = (props) => {
  const history = useHistory();
  const params = useParams();

  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [insuranceBrackets, setInsuranceBrackets] = useState([]);
  const [insuranceBracket, setInsuranceBracket] = useState(undefined);
  const [outsideInsurancePolicyNumber, setOutsideInsurancePolicyNumber] =
    useState("");

  const getInsuranceBrackets = async () => {
    let insCompany = await axios.get(
      `/api/insurance-company/${reduxUser.selectedLocation._id}`
    );
    let companyId = insCompany.data.data[0]._id;
    let brs = await axios.get(`/api/insurance-brackets/${companyId}`);

    setInsuranceBrackets(brs.data.data);
  };

  const save = async () => {
    await axios.put(
      `/api/reservation/${params.reservationId}`,
      {
        insuranceBracket,
        outsideInsurancePolicyNumber,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );

    history.push(`/tenants/add/finalize/${params.reservationId}`);
  };

  useEffect(() => {
    getInsuranceBrackets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOutsideInsurancePolicyNumber("");
  }, [insuranceBracket]);

  const isDisabled = () => insuranceBracket === undefined;

  return (
    <Portal
      minHeight="auto"
      // maxHeight="632px"
      width="354px"
      height="auto"
      zIndex="100"
      opacity="0.45"
      title="Tenant protection"
      close={() => history.push("/tenants")}
      noCustomScrollbar={true}
    >
      <div className={`p-l-20 p-r-20 p-b-30 ${styles.insurance}`}>
        <div className="p-t-20">
          <p className="df acsa ffqsb fs14 ttuc m-b-20">Tenant protection</p>
        </div>
        <div>
          {insuranceBrackets.map((bracket) => (
            <div
              key={bracket._id}
              className={`df ffnsb fs14 ${styles.bracketWrapper} ${
                insuranceBracket === bracket._id ? styles.selected : ""
              }`}
              onClick={() => setInsuranceBracket(bracket._id)}
            >
              <div>
                <p className="fs12 m-b-4">Coverage</p>
                <p>{formatFromCurrencyToMoney(bracket.coverageAmount)}</p>
              </div>
              <div className="mla tar">
                <p className="fs12 m-b-4">Premium</p>
                <p>{formatFromCurrencyToMoney(bracket.premium)} / mo</p>
              </div>
            </div>
          ))}

          <div
            className={`df ffnsb fs14 p-t-18 p-b-18 ${styles.bracketWrapper} ${
              insuranceBracket === null ? styles.selected : ""
            }`}
            onClick={() => setInsuranceBracket(null)}
          >
            <p>Decline protection</p>
          </div>
          {insuranceBracket === null ? (
            <NonFormikInput
              id="opn"
              size="medium"
              label="Insurance policy number"
              type="text"
              value={outsideInsurancePolicyNumber}
              change={setOutsideInsurancePolicyNumber}
            ></NonFormikInput>
          ) : undefined}
        </div>
      </div>
      <div className={`p-l-20 p-r-20 p-b-30`}>
        <button
          className={`ffnb w100 fs14 ttuc sbmt_btn primary m-t-10`}
          onClick={save}
          disabled={isDisabled()}
        >
          Next
        </button>
        {/* <button
          className={`ffnb w100 fs14 ttuc sbmt_btn secondary m-t-10`}
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Portal>
  );
};

export default NewInsurancePopup;
