import React from "react";

const CartAddSvg = (props) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6342 6.47619H9.01516V4.04762H6.58659V2.42857H9.01516V0H10.6342V2.42857H13.0628V4.04762H10.6342V6.47619ZM4.16612 15.381C4.16612 14.4905 4.8866 13.7619 5.77707 13.7619C6.66755 13.7619 7.39612 14.4905 7.39612 15.381C7.39612 16.2714 6.66755 17 5.77707 17C4.8866 17 4.16612 16.2714 4.16612 15.381ZM13.8723 13.7619C12.9818 13.7619 12.2614 14.4905 12.2614 15.381C12.2614 16.2714 12.9818 17 13.8723 17C14.7628 17 15.4914 16.2714 15.4914 15.381C15.4914 14.4905 14.7628 13.7619 13.8723 13.7619ZM12.6985 9.71429H6.66754L5.77706 11.3333H15.4914V12.9524H5.77706C4.54659 12.9524 3.76945 11.6329 4.3604 10.5481L5.45326 8.57286L2.53897 2.42857H0.919922V0.809524H3.56706L7.01564 8.09524H12.6985L15.8314 2.42857L17.2399 3.20571L14.1152 8.88048C13.8399 9.38238 13.3056 9.71429 12.6985 9.71429Z"
      fill={props.fill || ""}
      fillOpacity={props.fillOpacity || ""}
    />
  </svg>
);

export default CartAddSvg;
