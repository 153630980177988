import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";

//import scss
import "./Portal.scss";
import ClosePopup from "../../assets/js-svgs/close-popup.svg";
import Scrollbars from "react-custom-scrollbars";

class Portal extends Component {
  constructor(props) {
    super(props);
    this.rootSelector = document.getElementById("root");
    this.container = document.createElement("div");
    this.container.classList.add("popup");
    this.popupRef = createRef(null);
  }
  componentDidMount() {
    this.rootSelector.appendChild(this.container);
  }

  componentWillUnmount() {
    this.rootSelector.removeChild(this.container);
  }

  render() {
    return ReactDOM.createPortal(
      <>
        <div
          className={`__popup df ffc ${this.props.customClass}`}
          ref={this.popupRef}
          style={{
            height: this.props.height || "400px",
            width: this.props.width,
            zIndex: this.props.zIndex,
            minHeight: this.props.minHeight,
            maxHeight: this.props.maxHeight,
          }}
        >
          {!this.props.customHeader ? (
            <div className="df acsa __popup_hdr">
              <p className="ffnsb fs16 __popup_title">{this.props.title}</p>
              <div
                className="df acc mla __popup_close cursor_pointer"
                onClick={() => this.props.close()}
              >
                <ClosePopup></ClosePopup>
                {/* {console.log(this.props.close)} */}
              </div>
            </div>
          ) : undefined}

          <div
            className="__popup_cntnt_wrpr"
            style={{
              overflowX: `${this.props.noCustomScrollbar ? "auto" : "initial"}`,
            }}
          >
            {this.props.noCustomScrollbar ? (
              <>{this.props.children}</>
            ) : (
              <>
                {false ? (
                  <Scrollbars
                    className=" __scrollbar"
                    style={{ height: "100%" }}
                    renderTrackVertical={this.renderTrackVertical}
                    renderTrackHorizontal={this.renderTrackHorizontal}
                  >
                    {this.props.children}
                  </Scrollbars>
                ) : (
                  <>{this.props.children}</>
                )}
              </>
            )}
          </div>

          {/* <div className="__popup_cntnt"> {this.props.children}</div> */}
        </div>
        <div
          // onKeyUp={() => console.log("LOIHJUI YY&YGUYGB")}
          onClick={
            (e) => {
              // console.log("JUJN");
              e.stopPropagation();

              if (this.props.overlayClick) this.props.overlayClick();
            }
            // ( && ) || null
          }
          className="__overlay"
          style={{
            zIndex: this.props.zIndex - 1,
            opacity: this.props.opacity || 0,
          }}
        ></div>
      </>,
      this.container
    );
  }
}

export default Portal;
