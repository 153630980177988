import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";

import NonFormikSelect from "../../../components/inputs/drop-down/non-formik/NonFormikSelect";
import { NonFormikInput } from "../../../components/inputs/NonFormikInput";
import MultiSelect from "../../../components/inputs/drop-down/multi-select/MultiSelect";

import { formatFromCurrencyToMoney } from "../../../helpers";

import {
  GET_UNIT_FEATURES,
  GET_UNIT_SIZES,
  GET_UNIT_TYPES,
} from "../../../store/actions/types";
import styles from "./AddUnit.module.scss";

// import React from "react";
// import { Formik, Field } from "formik";
// import { DefaultInput } from "../../../components/inputs/DefaultInput";
// import { connect } from "react-redux";
// import {
//   getUnitTypes,
//   getUnitStatuses,
//   getUnitSizes,
//   getUnitFeatures,
//   addUnit as addUnitAction,
// } from "../../../store/actions";

// import * as Yup from "yup";

// //import scss
// import "./AddUnit.scss";
// import UnitTypesSelect from "../../../components/inputs/drop-down/unit-types/UnitTypes";
// // import SwitchInput from "../../../components/inputs/switch/Switch";
// import { withRouter } from "react-router-dom";

// const UnitSchema = Yup.object().shape({
//   unitId: Yup.string()
//     .min(1, "Too Short!")
//     .max(50, "Too Long!")
//     .required("Required"),
//   unitType: Yup.string().required("Select type"),
//   unitSize: Yup.string().required("Select size"),
//   rentable: Yup.string().required("Select rentable"),
//   unitFeatures: Yup.string().required("Select features"),
//   // available: Yup.boolean().required("Setect availability"),
// });

// // \ unitId: "",
// // \ unitType: "",
// // \ unitSize: "",
// // \ unitStatus: "",
// // \ unitFeatures: [],
// // available: true
// class AddUnit extends React.Component {
//   state = {
//     selectedUnit: {},
//     unit: {},
//     loading: true,
//   };

//   getCurrentUnit = async (id) => {
//     const unit = await Axios.get(`/api/unit-by-id/${id}`);
//     console.log("Done getting unit");
//     this.setState({ unit: unit.data.data, loading: false });
//   };

//   componentDidMount() {
//     this.props.getUnitTypes();
//     this.props.getUnitStatuses();
//     this.props.getUnitSizes(this.props.reduxUser.selectedLocation._id);
//     this.props.getUnitFeatures();

//     if (this.props.match.params.id)
//       return this.getCurrentUnit(this.props.match.params.id);
//     this.setState({ loading: false });
//   }
//   render() {
//     const { unit } = this.state;
//     return (
//       <>
//         {!this.state.loading ||
//         !this.props.unitSetupDetails?.unitTypes ||
//         !this.props.unitSetupDetails?.unitSizes ||
//         !this.props.unitSetupDetails?.unitStatuses ||
//         !this.props.unitSetupDetails?.unitFeatures ? (
//           <div className="add_unit_popup">
//             <Formik
//               initialValues={{
//                 unitId: unit?.unitId || "",
//                 unitType: unit?.unitType
//                   ? this.props.unitSetupDetails?.unitTypes?.find(
//                       (thisUnit) => thisUnit._id === unit.unitType
//                     )
//                   : "",
//                 unitSize: unit?.unitSize
//                   ? this.props.unitSetupDetails?.unitSizes?.find(
//                       (thisUnit) => thisUnit._id === unit.unitSize
//                     )
//                   : "",
//                 unitStatus: unit?.unitStatus
//                   ? this.props.unitSetupDetails?.unitStatuses?.find(
//                       (thisUnit) => thisUnit._id === unit.unitStatus
//                     )
//                   : "",
//                 unitFeatures: unit?.unitFeatures
//                   ? this.props.unitSetupDetails?.unitFeatures?.filter(
//                       (thisUnit) => unit?.unitFeatures.includes(thisUnit._id)
//                     )
//                   : [],
//                 rentable:
//                   unit?.rentable !== undefined
//                     ? unit?.rentable.toString()
//                     : "true",
//               }}
//               validationSchema={UnitSchema}
//               onSubmit={(values) => {
//                 // let unitFeatures = values.unitFeatures.map(ftr => ftr.value);
//                 // values.unitFeatures = unitFeatures;

//                 console.log(values);

//                 let updatedValues = { ...values };
//                 let updatedUnitFeatures = [
//                   ...updatedValues.unitFeatures.map((ftr) => ftr._id),
//                 ];

//                 updatedValues.unitSize = updatedValues.unitSize._id;
//                 updatedValues.unitType = updatedValues.unitType._id;
//                 updatedValues.unitStatus = updatedValues.unitStatus._id;

//                 updatedValues.unitFeatures = updatedUnitFeatures;
//                 // console.log(updatedValues);

//                 this.props.addUnitAction(updatedValues, unit?._id);

//                 this.props.history.push("/units");
//               }}
//             >
//               {(props) => (
//                 <form onSubmit={props.handleSubmit}>
//                   <div className="df inpt_line single">
//                     <div className="inpt_wrpr">
//                       <Field
//                         name="unitId"
//                         label="Unit ID"
//                         id="unitid"
//                         component={DefaultInput}
//                         disabled={unit._id}
//                       />
//                     </div>
//                   </div>

//                   <div className="df inpt_line single">
//                     <div className="inpt_wrpr">
//                       <UnitTypesSelect
//                         value={props.values.unitType}
//                         onChange={props.setFieldValue}
//                         onBlur={props.setFieldTouched}
//                         error={props.errors.unitType}
//                         touched={props.touched.unitType}
//                         options={this.props.unitSetupDetails.unitTypes}
//                         name="unitType"
//                         isMulti={false}
//                         label="Unit type"
//                         menuPortalTarget={document.body}
//                       />
//                     </div>
//                   </div>
//                   <div className="df inpt_line single">
//                     <div className="inpt_wrpr">
//                       <UnitSizesSelect
//                         value={props.values.unitSize}
//                         onChange={props.setFieldValue}
//                         onBlur={props.setFieldTouched}
//                         error={props.errors.unitSize}
//                         touched={props.touched.unitSize}
//                         options={this.props.unitSetupDetails.unitSizes}
//                         name="unitSize"
//                         isMulti={false}
//                         label="Unit size"
//                         menuPortalTarget={document.body}
//                       />
//                     </div>
//                   </div>

//                   <div className="df inpt_line single">
//                     {/* <div className="inpt_wrpr">
//                       <UnitStatusSelect
//                         value={props.values.unitStatus}
//                         onChange={props.setFieldValue}
//                         onBlur={props.setFieldTouched}
//                         error={props.errors.unitStatus}
//                         touched={props.touched.unitStatus}
//                         options={this.props.unitSetupDetails.unitStatuses}
//                         name="unitStatus"
//                         isMulti={false}
//                         label="Unit status"
//                         menuPortalTarget={document.body}
//                       />
//                     </div> */}
//                     <div className="rentableToggle">
//                       <p className="label">Rentable</p>
//                       <div className="df ffnsb fs14 options">
//                         <Field
//                           type="radio"
//                           name="rentable"
//                           className="dn"
//                           id="rentable"
//                           value={"true"}
//                           checked={props.values.rentable === "true"}
//                         />
//                         <label
//                           htmlFor="rentable"
//                           className={`df acc option ${
//                             props.values.rentable === "true" ? "active" : ""
//                           }`}
//                         >
//                           Rentable
//                         </label>
//                         <Field
//                           type="radio"
//                           name="rentable"
//                           className="dn"
//                           id="non-rentable"
//                           value={"false"}
//                           checked={props.values.rentable === "false"}
//                         />
//                         <label
//                           htmlFor="non-rentable"
//                           className={`df acc option ${
//                             props.values.rentable === "false" ? "active" : ""
//                           }`}
//                         >
//                           Non Rentable
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="inpt_line">
//                     <MultiSelect
//                       value={props.values.unitFeatures}
//                       onChange={props.setFieldValue}
//                       onBlur={props.setFieldTouched}
//                       error={props.errors.unitFeatures}
//                       touched={props.touched.unitFeatures}
//                       // options={[
//                       //   { label: "Ground level", value: "gl" },
//                       //   { label: "First floor", value: "ff" },
//                       //   { label: "First 1", value: "ff5" },
//                       //   { label: "First 2", value: "ff2" },
//                       //   { label: "First 3", value: "ff3" },
//                       //   { label: "First 4", value: "f5f" },
//                       //   { label: "First 5", value: "4ff" }
//                       // ]}
//                       options={this.props.unitSetupDetails.unitFeatures}
//                       className="hello-world"
//                       name="unitFeatures"
//                       isMulti={true}
//                       label="Unit features"
//                       menuPortalTarget={document.body}
//                     />
//                   </div>
//                   {/* <div className="df inpt_line">
//                     <Field
//                       component={SwitchInput}
//                       name="available"
//                       id="available"
//                       label="available"
//                     />
//                   </div> */}
//                   <div className="df jcfe footer">
//                     {/* <button
//                       className="ffmb fs14 ttuc __btn secondary edgy cancel"
//                       type="button"
//                     >
//                       Cancel
//                     </button> */}
//                     <button
//                       className="ffmb fs14 w100 ttuc __btn primary edgy"
//                       type="submit"
//                     >
//                       Create unit
//                     </button>
//                   </div>
//                 </form>
//               )}
//             </Formik>
//           </div>
//         ) : undefined}
//       </>
//     );
//   }
// }

// const mapStateToProps = ({ unitSetupDetails, reduxUser }) => ({
//   unitSetupDetails,
//   reduxUser,
// });

// export default withRouter(
//   connect(mapStateToProps, {
//     getUnitTypes,
//     getUnitStatuses,
//     getUnitSizes,
//     getUnitFeatures,
//     addUnitAction,
//   })(AddUnit)
// );

const AddUnit = ({
  lUnitSize,
  lUnitId,
  lUnitType,
  lRentable,
  lUnitFeatures,
}) => {
  let token = localStorage.getItem("x-auth");

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { unitSetupDetails, reduxUser } = useSelector(
    ({ unitSetupDetails, reduxUser }) => ({
      unitSetupDetails,
      reduxUser,
    })
  );

  const [unitSize, setUnitSize] = useState("");
  const [unitId, setUnitId] = useState("");
  const [unitType, setUnitType] = useState("");
  const [rentable, setRentable] = useState(true);
  const [unitFeatures, setUnitFeatures] = useState([]);

  //get unit types
  const getUnitTypes = async () => {
    console.log(":{}PK{OP");
    let unitTypes = await Axios.get("/api/unit-types");
    console.log(unitTypes);
    dispatch({ type: GET_UNIT_TYPES, payload: unitTypes.data.data });
  };

  const getUnitSizes = async () => {
    let unitStatuses = await Axios.get(
      `/api/unit-size${
        reduxUser?.selectedLocation?._id
          ? "?locationId=" + reduxUser?.selectedLocation?._id
          : ""
      }`
    );
    dispatch({ type: GET_UNIT_SIZES, payload: unitStatuses.data.data });
  };

  const getUnitFeatures = async () => {
    let unitFeatures = await Axios.get(`/api/unit-feature`);
    dispatch({ type: GET_UNIT_FEATURES, payload: unitFeatures.data.data });
  };

  const getUnit = async () => {
    console.log("params", params);

    let u = await Axios.get(`/api/unit-by-id/${params.id}`);

    let { data } = u;

    setUnitId(data?.data?.unitId);
    setUnitFeatures(data?.data?.unitFeatures);
    setUnitSize(data?.data?.unitSize);
    setUnitType(data?.data?.unitType);
    setRentable(data?.data?.rentable || false);
  };

  useEffect(() => {
    console.log("Mounted");
    getUnitTypes();
    getUnitSizes();
    getUnitFeatures();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!params?.id) return;
    console.log("Loading unit");
    getUnit();
    // eslint-disable-next-line
  }, [params?.id]);

  const save = async () => {
    console.log(
      unitId,
      unitSize,
      unitFeatures,
      unitType,
      rentable,
      reduxUser.selectedLocation._id
    );
    // unitLocation = reduxUser.selectedLocation._id

    if (!params?.id) {
      await Axios.post(
        `/api/unit`,
        {
          unitId,
          unitSize: unitSize.value,
          unitFeatures: unitFeatures.map((fe) => fe._id),
          unitType: unitType.value,
          rentable,
          unitLocation: reduxUser.selectedLocation._id,
        },
        {
          headers: { "x-auth": token },
        }
      );
    } else {
      await Axios.post(
        `/api/unit/${params?.id}`,
        {
          unitId,
          unitSize: unitSize.value,
          unitFeatures: unitFeatures.map((fe) => fe._id),
          unitType: unitType.value,
          rentable,
          unitLocation: reduxUser.selectedLocation._id,
        },
        {
          headers: { "x-auth": token },
        }
      );
    }
    history.push("/units");
  };
  return (
    <div className={styles.addUnitPopup}>
      <div className={`m-b-10 ${styles.inptLine} ${styles.single}`}>
        <div className={styles.inptWrpr}>
          <NonFormikInput
            label="Unit ID"
            value={unitId}
            id="unitId"
            type="text"
            change={setUnitId}
          ></NonFormikInput>
        </div>
      </div>
      <div className={`m-b-10 ${styles.inptLine} ${styles.single}`}>
        <div className={styles.inptWrpr}>
          <NonFormikSelect
            displayBlock={true}
            menuPortalTarget={document.body}
            label="Unit type"
            value={unitType}
            options={unitSetupDetails?.unitTypes?.map((size) => ({
              label: size.label,
              value: size._id,
            }))}
            onChange={setUnitType}
          />
        </div>
      </div>

      <div className={`m-b-10 ${styles.inptLine} ${styles.single}`}>
        <div className={styles.inptWrpr}>
          <NonFormikSelect
            displayBlock={true}
            menuPortalTarget={document.body}
            label="Unit size"
            value={unitSize}
            options={unitSetupDetails?.unitSizes?.map((size) => ({
              label: `${size.label} (${size.length} x ${
                size.width
              }) ${formatFromCurrencyToMoney(size.price)}`,
              value: size._id,
            }))}
            onChange={setUnitSize}
          />
        </div>
      </div>

      <div className={styles.rentableToggle}>
        <p className={styles.label}>Rentable</p>
        <div className={`df ffnsb fs14 ${styles.options}`}>
          <input
            type="radio"
            name="rentable"
            className="dn"
            id="rentable"
            // value={true}
            checked={rentable === true}
            onChange={(e) => setRentable(true)}
          />
          <label
            htmlFor="rentable"
            className={`df acc ${styles.option} ${
              rentable === true ? styles.active : ""
            }`}
          >
            Rentable
          </label>
          <input
            type="radio"
            name="rentable"
            className="dn"
            id="non-rentable"
            // value={false}
            checked={rentable === false}
            onChange={(e) => setRentable(false)}
          />
          <label
            htmlFor="non-rentable"
            className={`df acc ${styles.option} ${
              rentable === false ? styles.active : ""
            }`}
          >
            Non Rentable
          </label>
        </div>
      </div>

      <div className={`m-b-0`}>
        <MultiSelect
          value={unitFeatures}
          onChange={setUnitFeatures}
          onBlur={console.log}
          error={undefined}
          touched={true}
          // options={[
          //   { label: "Ground level", value: "gl" },
          //   { label: "First floor", value: "ff" },
          //   { label: "First 1", value: "ff5" },
          //   { label: "First 2", value: "ff2" },
          //   { label: "First 3", value: "ff3" },
          //   { label: "First 4", value: "f5f" },
          //   { label: "First 5", value: "4ff" }
          // ]}
          options={unitSetupDetails?.unitFeatures}
          className="hello-world"
          name="unitFeatures"
          isMulti={true}
          label="Unit features"
          menuPortalTarget={document.body}
        />
      </div>

      <div className={`df jcfe ${styles.footer}`}>
        {/* <button
                       className="ffmb fs14 ttuc __btn secondary edgy cancel"
                       type="button"
                     >
                       Cancel
                     </button> */}
        <button
          className="ffmb fs14 w100 ttuc __btn primary edgy"
          onClick={save}
        >
          Create unit
        </button>
      </div>
    </div>
  );
};

export default AddUnit;
