import { GET_ACTIVE_RESERVATION } from "../actions/types";

const reducer = (state = null, action) => {
  switch (action.type) {
    case GET_ACTIVE_RESERVATION:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
