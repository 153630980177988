const NoBalanceSVG = (props) => (
  <svg
    width={props.width || "150"}
    height={props.height || "147"}
    viewBox="0 0 532 532"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M253.247 0V148.001C207.726 151.066 167.51 175.144 144.085 214.552L143.197 221.991L253.693 528.492H270.487L380.983 221.991L380.095 214.552C356.671 175.144 316.457 151.062 270.933 148.436V0.00400299L253.247 0ZM262.086 165.516C299.211 165.516 333.239 182.15 355.779 210.173H168.396C190.936 182.15 224.964 165.516 262.088 165.516H262.086ZM163.975 227.687H359.762L262.087 499.6L163.975 227.687ZM522.202 430.644L507.483 440.359L517.289 454.929L532 445.215L522.202 430.644ZM9.72713 435.671L0 450.124L14.5883 460.191L19.4499 452.748L23.8695 445.3L9.72713 435.671ZM478.208 458.881C473.346 461.946 468.043 464.565 463.181 467.193L471.578 482.517C476.882 479.89 482.186 476.825 487.489 473.76L478.208 458.881ZM53.9234 463.26L45.5261 478.584C50.8297 481.649 56.1332 484.269 61.4368 486.896L69.3921 471.137C64.0886 468.948 58.785 465.886 53.9239 463.259L53.9234 463.26ZM431.799 482.084L415.893 488.652L422.072 504.856C427.818 502.667 433.125 500.478 438.871 498.288L431.799 482.084ZM100.766 485.587L94.137 501.788C99.4406 503.978 105.19 506.167 110.936 508.356L117.115 491.722C111.37 489.532 106.07 487.777 100.766 485.588L100.766 485.587ZM383.178 499.598C377.433 500.912 372.129 502.663 366.384 503.977L370.803 521.053C376.549 519.739 382.294 517.988 388.039 516.237L383.178 499.598ZM149.822 502.222L145.402 519.302C151.148 520.177 156.893 521.929 162.638 523.242L166.616 506.166C160.87 504.853 155.125 503.535 149.821 502.222L149.822 502.222ZM332.355 510.979L315.119 513.614L317.329 531.128C323.074 530.252 329.261 529.369 335.007 528.493L332.355 510.979ZM200.645 512.296L197.993 529.811C203.739 530.686 209.926 531.562 215.672 532L217.44 514.486C212.136 514.048 206.39 513.172 200.645 512.296Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default NoBalanceSVG;
