import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import Portal from "../../../../components/portal/Portal";
import AddressAutoComplete from "./address-autocomplete/AddressAutoComplete";
import Cleave from "cleave.js/react";
import { useHistory, useLocation } from "react-router-dom";
import TextMessage from "../../../../assets/js-svgs/text-message.svg";

import styles from "./AddUser.module.scss";
import axios from "axios";

const AddUser = (props) => {
  const history = useHistory();
  const location = useLocation();

  // const location = useLocation

  const { user } = useSelector(({ user }) => ({ user }));

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [canText, setcanText] = useState(false);
  const [address, setAddress] = useState({});

  const updateField = (value) => {
    setAddress({ ...address, ...value });
  };

  const save = async (next) => {
    const search = new URLSearchParams(location.search);
    const unit = search.get("unit");

    let query = "";

    if (unit) {
      query = `?unit=${unit}`;
    }

    let selectedLocation = JSON.parse(localStorage.getItem("selectedLocation"));

    let re = await axios.post("/api/create-user-from-portal", {
      fullName,
      email,
      number: `+1${number}`,
      canText,
      address,
      webProfileId: selectedLocation.selectedLocation.defaultWebsiteProfile,
      accountId: user.account,
      locations: [selectedLocation.selectedLocation._id],
    });

    if (next) {
      history.push(
        `/tenants/add/all-units/${re.data.data.payload._id}/${query}`
      );
      // `/tenants/add/all-units/${user.data.data.payload._id}`
    } else {
      history.push("/tenants");
    }
  };

  const isDisabled = () => {
    return (
      !fullName.length ||
      !email.length ||
      !number.length ||
      !address?.deliveryLine1?.length ||
      !address?.lastLine?.length
    );
  };
  return (
    <Portal
      width="354px"
      height="auto"
      zIndex="100"
      opacity="0.45"
      title="Add Tenant"
      close={() => history.push("/tenants")}
    >
      {/* {fullName} */}
      <div className={`p-l-20 p-r-20 p-b-30`}>
        <NonFormikInput
          label="Fu&zwj;ll na&zwj;me"
          value={fullName}
          id="search"
          type="text"
          change={setFullName}
          autoComplete="off"
        ></NonFormikInput>

        <NonFormikInput
          label="Email"
          value={email}
          id="email"
          type="text"
          change={setEmail}
          autoComplete="off"
        ></NonFormikInput>

        {/* <NonFormikInput
          label="Phone number"
          value={phone}
          id="search"
          type="text"
          change={setPhone}
          autoComplete="off"
        ></NonFormikInput> */}
        <div className={`df acsa`}>
          <div className="__inpt_wrpr" style={{ width: "82%" }}>
            <Cleave
              className={`ffnr fs14`}
              type="text"
              id="pn"
              // placeholder="Please enter phone number"
              options={{
                blocks: [0, 3, 3, 4],
                delimiters: ["(", ") ", "-"],

                numericOnly: true,
                // prefix: "+1",
              }}
              value={number}
              onChange={(event) => {
                // console.log(event);
                setNumber(event.target.rawValue);
              }}
            />
            <label
              htmlFor="pn"
              className={`__inpt_lbl ffnr fs14 ${number?.length ? "move" : ""}`}
            >
              Phone number
            </label>
          </div>
          <div
            className={`mla df acc m-t-8 ${styles.canText} ${
              canText ? styles.active : ""
            }`}
            onClick={() => setcanText(!canText)}
          >
            <TextMessage
              fill={canText ? "#FFF" : "rgba(50, 50, 50, 0.251)"}
            ></TextMessage>
          </div>
        </div>

        <AddressAutoComplete onUpdate={updateField} />
        <div className={`df ffc`}>
          <button
            disabled={isDisabled()}
            className={`ffnb fs14 ttuc sbmt_btn primary w100 m-t-15 ${
              isDisabled() ? "disabled" : ""
            }`}
            type="submit"
            onClick={() => save(true)}
          >
            Next
          </button>
          <button
            disabled={isDisabled()}
            className={`ffnb fs14 ttuc sbmt_btn secondary w100 m-t-10  ${
              isDisabled() ? "disabled" : ""
            }`}
            type="submit"
            onClick={() => save(false)}
          >
            Save and close
          </button>
        </div>
      </div>
    </Portal>
  );
};

// const matStateToProps = ({ user, tenants }) => ({ user, tenants });

export default AddUser;
