import React, { useEffect, useState } from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import { connect, useDispatch, useSelector } from "react-redux";

// import scss
import "./Home.scss";

// import components
import Locations from "../locations/Locations";
import MainNav from "../main-nav/MainNav";
import UnitsPage from "../units-page/UnitsPage";
import Tenants from "../tenants/Tenants";
import Users from "../users/Users";
import SettingsRoutes from "../settings-page/SettingsRoutes";
import ReportsHub from "../reports-hub/ReportsHub";
import Tasks from "../tasks/Tasks";
import axios from "axios";
import { GET_TASKS } from "../../store/actions/types";
import Adjust from "../tenants/adjust/Adjust";

const Home = (props) => {
  const token = localStorage.getItem("x-auth");
  let history = useHistory();
  const dispatch = useDispatch();

  const { tasks } = useSelector(({ tasks }) => ({ tasks }));

  const [loading, setLoading] = useState(true);

  const getTasks = async () => {
    let tasks = await axios.get("/api/task", { headers: { "x-auth": token } });
    // setAllTasks(tasks.data.data);
    dispatch({ type: GET_TASKS, payload: { tasks: tasks.data.data } });
  };

  useEffect(() => {
    setLoading(true);
    getTasks();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, [props.reduxUser.selectedLocation]);

  if (!props.user) history.push("/login");

  return (
    <>
      {!loading ? (
        <div className="df pages_nav_wrpr">
          <MainNav />
          {tasks?.showTasks ? <Tasks /> : undefined}
          <div className="routes_wrpr">
            {/* <TopBar /> */}
            <Switch>
              <Route path="/dashboard" component={Dashboard}></Route>{" "}
              <Route path="/units" component={UnitsPage}></Route>
              <Route path="/locations" component={Locations}></Route>
              <Route path="/tenants/adjust" render={() => <Adjust />}></Route>
              <Route path="/tenants" component={Tenants}></Route>
              <Route path="/users" component={Users}></Route>
              <Route path="/settings" component={SettingsRoutes}></Route>
              <Route path="/reports-hub" component={ReportsHub}></Route>
              <Route
                path={"/payments"}
                component={() => <div>Payments page coming soon!</div>}
              ></Route>
              <Route
                path="/communications"
                component={() => <div>Communications page coming soon!</div>}
              ></Route>
              <Route path="/" component={Dashboard}></Route>
            </Switch>
          </div>
        </div>
      ) : (
        "Loading"
      )}
    </>
  );
};

const mapStateToProps = ({ user, reduxUser }) => ({ user, reduxUser });

export default connect(mapStateToProps, null)(Home);
