import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./UnitSizes.scss";

const UnitSizes = (props) => {
  const [src, setSrc] = useState();
  // const [file, setFile] = useState(null);

  // const [fileConvertError, setFileConvertError] = useState(null);
  // const [fileName, setFileName] = useState(null);
  const [sizesList, setSizesList] = useState([]);

  const params = useParams();
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const onSelectFile = (e, drop) => {
    let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    let reader = new FileReader();

    // setFile(file);
    // setFileConvertError(null);
    // setFileName(file.name);

    reader.readAsDataURL(file);
    reader.onload = (res) => setSrc(res.target.result);
  };

  const uploadFile = () => {
    const i = src.indexOf("base64,");
    const buffer = Buffer.from(src.slice(i + 7), "base64");
    const file = new File([buffer], "name", {
      type: "application/vnd.ms-excel",
    });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("locationId", reduxUser.selectedLocation._id);
    fd.append("accountId", user.account);
    fd.append("jobId", params.id);

    Axios.post("/api/import/unit-sizes", fd);
  };

  const getSizesList = async () => {
    const list = await Axios.get(`/api/import/unit-sizes/${params.id}`);

    setSizesList(list.data);
  };
  useEffect(() => {
    getSizesList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="unit_sizes_page">
      <h1>Import Unit Sizes</h1>
      <div>
        <input
          type="file"
          accept=".csv, .txt"
          id="upld_file"
          onChange={onSelectFile}
        />
      </div>
      <button onClick={uploadFile}>Upload</button>

      <div>
        {sizesList.map((size) => {
          return (
            <div className="df acsa line">
              <div className="sec" style={{ width: "70px" }}>
                <p>Label</p>
                <p>{size?.label}</p>
              </div>
              <div className="sec" style={{ width: "350px" }}>
                <p>Imported name</p>
                <p>{size?.importedObject["Imported name"]}</p>
              </div>
              <div className="sec" style={{ width: "200px" }}>
                <p>_id</p>
                <p>{size._id}</p>
              </div>
              <div className="sec">
                <p>Width</p>
                <p>{size.width}</p>
              </div>

              <div className="sec">
                <p>Length</p>
                <p>{size.length}</p>
              </div>

              <div className="sec">
                <p>Height</p>
                <p>{size.height}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UnitSizes;
