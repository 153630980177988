import React from "react";

const TextMessage = (props) => {
	return (
		<svg
			width={props.width || 16}
			height={props.height || 15}
			viewBox="0 0 16 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity={props.opacity || 1}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 0H2C1.175 0 0.5 0.675 0.5 1.5V15L3.5 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0ZM3.5 10.5L2 12V1.5H14V10.5H3.5ZM5.75 5.25H4.25V6.75H5.75V5.25ZM7.25 5.25H8.75V6.75H7.25V5.25ZM11.75 5.25H10.25V6.75H11.75V5.25Z"
				fill={props.fill}
			/>
		</svg>
	);
};

export default TextMessage;
