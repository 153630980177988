import React from "react";
// import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
// import scss
import "./DefaultInput.scss";

// eslint-disable-next-line
String.prototype.splice = function (idx, rem, str) {
  // console.log(rem);
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export const DefaultInput = ({
  field,
  form: { touched, errors, values, dirty, isValid, status },
  ...props
}) => {
  // const [formattedValue, updatePhoneNumber] = useState("");

  return (
    <>
      {props.multilevels ? (
        <>
          <div
            className={`__inpt_wrpr ${errors[field.name] ? "error" : ""} ${
              props.size
            }`}
          >
            {field.name.toLowerCase().includes("phone") ? (
              <>
                {/* <input
                  ref={ref}
                  className="ffnsb fs14"
                  type="text"
                  {...field}
                  {...props}
                  value={formattedValue}
                  autoComplete={props.autocomplete || field.name}
                  onChange={(e) => {
                    let clean = e.target.value
                      .replace("(", "")
                      .replace(")", "")
                      .replace("-", "")
                      .replace(" ", "")
                      .replace("  ", "");
                    let areaCode = clean.slice(0, 3);
                    let leading = clean.slice(3, 6);
                    let trailing = clean.slice(6, 10);
                    props.onChange(clean);
                    updatePhoneNumber(
                      `${
                        areaCode.length >= 3 ? "(" + areaCode + ")" : areaCode
                      }${
                        trailing.length > 0 ? leading + "-" : leading
                      }${trailing}`
                    );
                  }}
                /> */}
                <Input
                  country="US"
                  className="ffnsb fs14"
                  type="text"
                  {...field}
                  {...props}
                  // onChange={(e) => console.log(e)}
                />
                {/* <label
                  htmlFor={props.id}
                  contenteditable="true"
                  className="ffnsb fs14 df acsa input formatted_phone_number"
                  onChange={(e) => console.log("====", e)}
                >
                  {formattedValue}
                </label> */}
              </>
            ) : (
              <input
                className="ffnsb fs14"
                type="text"
                {...field}
                {...props}
                id={props.id}
                autoComplete={props.autocomplete || field.name}
              />
            )}

            <label
              htmlFor={props.id}
              className={` __inpt_lbl ${
                values[props.arrayname][props.i][props.fieldname] &&
                values[props.arrayname][props.i][props.fieldname].length
                  ? "move ffnsb fs11"
                  : "ffnr fs14"
              }`}
            >
              {props.label}
            </label>
          </div>
        </>
      ) : (
        <div
          className={`__inpt_wrpr ${errors[field.name] ? "error" : ""}  ${
            props.size
          }`}
        >
          {field.name.toLowerCase().includes("phone") ? (
            <>
              {/* <input
                className="ffnsb fs14"
                type="text"
                {...field}
                {...props}
                autoComplete={props.autocomplete || field.name}
                onChange={(e) => {
                  console.log(":::::", e);
                  props.onChange(e);
                }}
              /> */}

              <Input
                country="US"
                className="ffnsb fs14"
                type="text"
                {...field}
                {...props}
                // onChange={(e) => console.log(e)}
              />
              {/* <p>{formattedValue}</p> */}
            </>
          ) : (
            <input
              className="ffnsb fs14"
              type="text"
              {...field}
              {...props}
              autoComplete={props.autocomplete || field.name}
            />
          )}

          {/* <Input
            className="ffnsb fs14"
            country="US"
            type="text"
            {...field}
            {...props}
            value={phoneNumber}
            autoComplete={props.autocomplete || field.name}
          /> */}

          <label
            htmlFor={props.id}
            className={` __inpt_lbl ${
              values[field.name] && values[field.name].length
                ? "move ffnsb fs11"
                : "ffnr fs14"
            }`}
          >
            {props.label}
          </label>
          {errors[field.name] && (
            <p className="fs10 ffnsb inpt_error_lbl">{errors[field.name]}</p>
          )}
        </div>
      )}
    </>
  );
};
