import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatPhoneNumber } from "react-phone-number-input";
import update from "immutability-helper";

// import Portal from "../../../../../components/portal/Portal";
// import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";

import importStyles from "../Import.module.scss";
import styles from "./CustomersList.module.scss";
import AlertSVG from "../../../../../assets/js-svgs/alert.svg";
import FixPopup from "./fix-popup/FixPopup";
import WarningSVG from "../../../../../assets/js-svgs/warning.svg";

const CustomersList = ({ createReservations }) => {
  const params = useParams();

  const [list, setList] = useState([]);
  const [fixCustomer, setFixCustomer] = useState(undefined);

  const getCustomers = async () => {
    console.log("GETTING CUSTOMERS");
    let res = await axios.get(`/api/new-import/tenants/${params.id}`);
    setList(res.data);
  };

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAddress = async (address) => {
    console.log("updateAddress", address);
    const index = list.findIndex((c) => c.defaultAddress._id === address._id);
    console.log("index", index);
    let updated = update(list, {
      [index]: {
        defaultAddress: {
          $set: address,
        },
      },
    });

    console.log("updated", updated[1]);
    setList(updated);
  };

  return (
    <div className={`${styles.customerList} ${importStyles.card}`}>
      <div className={`df acsa ${importStyles.cardHdr}`}>
        <div>
          <p className={`ffnsb fs12 ttuc`}>
            Easy Storage Import | Review tenants
          </p>
          <p className={`ffnr fs12 m-t-10`}>Review your tenants.</p>
        </div>
      </div>

      <div className={`df aic ffc ${importStyles.contentWrpr}`}>
        {list.map((customer, idx) => {
          return (
            <div className={`df acsa ffnr fs14  ${importStyles.line}`}>
              <p className={styles.nameSec}>{customer.fullName}</p>
              <p className={styles.emailSec}>{customer.email}</p>
              <p className={styles.phoneSec}>
                {formatPhoneNumber(customer?.defaultPhoneNumber?.number)}
              </p>
              <p className={styles.addressSec}>
                <>
                  {customer.defaultAddress.deliveryLine1 ? (
                    <>
                      {customer.defaultAddress.deliveryLine1}{" "}
                      {customer.defaultAddress.lastLine}
                    </>
                  ) : customer.defaultAddress.missingAddress ? (
                    <div
                      // onClick={() => {
                      //   setFixCustomer(customer);
                      // }}
                      className={`df acsa ${styles.addressWarning}`}
                      title="The address is missing for this tenant. Please contact the tenant to update their address."
                    >
                      <WarningSVG fill="#fe8e2b" />

                      <p className={`m-l-8`}>
                        Missing address, please contact tenant.
                      </p>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setFixCustomer(customer);
                      }}
                      className={`df acsa ${styles.addressError}`}
                      title="We were unable to verify this address please fix before continuing."
                    >
                      <AlertSVG fill="#ec407a" />

                      <p className={`m-l-8`}>
                        {customer.defaultAddress.importedString}
                      </p>
                    </div>
                  )}
                </>
              </p>
            </div>
          );
        })}
        <button
          disabled={list.some(
            (c) =>
              !c.defaultAddress.deliveryLine1 &&
              !c.defaultAddress.missingAddress
          )}
          className={`button primary w100 m-t-20 ttuc`}
          onClick={createReservations}
        >
          Create reservations
        </button>
      </div>

      {fixCustomer ? (
        <FixPopup
          fixCustomer={fixCustomer}
          close={() => setFixCustomer(undefined)}
          updateAddress={updateAddress}
        />
      ) : undefined}
    </div>
  );
};

export default CustomersList;
