import Cleave from "cleave.js/react";

const CurrencyInput = ({
  size,
  disabled,
  id,
  label,
  value,
  onChange,
  autocomplete,
  height,
}) => {
  return (
    <div className={`__inpt_wrpr ${false ? "error" : ""}  ${size}`}>
      <Cleave
        style={{ height: height }}
        className={`ffnsb fs14`}
        disabled={disabled}
        id={id}
        value={value ? value / 100 : ""}
        onChange={(e) => {
          onChange({ fullNumber: e.target.rawValue * 100 });
        }}
        options={{
          prefix: `${value?.toString().length > 0 ? "$" : ""}`,
          numeral: true,
          // numeralThousandsGroupStyle: "lakh",
          rawValueTrimPrefix: true,
        }}
      />
      <label
        htmlFor={id}
        className={`ffnsb fs14 ${value !== "" ? "move" : ""}`}
      >
        {label}
      </label>
    </div>
  );
};

export default CurrencyInput;
