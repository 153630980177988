export const StarFilledSVG = (props) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 12.0553L12.135 15L10.905 9.45L15 5.71579L9.6075 5.23421L7.5 0L5.3925 5.23421L0 5.71579L4.095 9.45L2.865 15L7.5 12.0553Z"
        fill={props.fill || "#F8B449"}
      />
    </svg>
  );
};
