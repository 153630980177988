import Axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const DelinquenciesImport = (props) => {
  const params = useParams();
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [src, setSrc] = useState(null);

  const onSelectFile = (e, drop) => {
    let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (res) => setSrc(res.target.result);
  };

  const uploadFile = () => {
    const i = src.indexOf("base64,");
    const buffer = Buffer.from(src.slice(i + 7), "base64");
    const file = new File([buffer], "name", {
      type: "application/vnd.ms-excel",
    });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("locationId", reduxUser.selectedLocation._id);
    fd.append("accountId", user.account);
    fd.append("jobId", params.id);

    Axios.post("/api/import/delinquencies", fd);
  };

  return (
    <div>
      <h1>Import Delinquencies</h1>
      <div>
        <input
          type="file"
          accept=".csv, .txt"
          id="upld_file"
          onChange={onSelectFile}
        />
      </div>
      <button onClick={uploadFile}>Upload</button>
    </div>
  );
};

export default DelinquenciesImport;
