import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";

//import scss
import "./UnitsPage.scss";
import UnitsList from "./units-list/UnitsList";
import GroupedUnits from "./grouped-units/GroupedUnits";
import UnitsMap from "./units-map/UnitsMap";

class UnitsPage extends Component {
	render() {
		return (
			<Switch>
				<Route path="/units/list" component={UnitsList}></Route>
				<Route path="/units/grouped" component={GroupedUnits}></Route>
				<Route path="/units/map" component={UnitsMap}></Route>
				<Route path="/units" component={UnitsList}></Route>
			</Switch>
		);
	}
}

export default UnitsPage;
