import dayjs from "dayjs";
import { useState } from "react";
import { createPortal } from "react-dom";
import Arrow from "../../../../../../assets/js-svgs/arrow.svg";
import { CheckInCircleFull } from "../../../../../../assets/js-svgs/check-in-circle-full.svg";
import { PartialCheckedInCircle } from "../../../../../../assets/js-svgs/partial-checked-in-circle.svg";
// PartialCheckedInCircle
import styles from "./DelinquencyStage.module.scss";
import popupStyles from "../../../TenantDetails.module.scss";

const DelinquencyStage = ({ stage, selectedPastDue }) => {
  const [showActions, setShowActions] = useState(false);
  const [showAuctionDetails, setShowAuctionDetails] = useState(false);

  const competedActionsCount = (stage) =>
    stage?.data?.filter((action) => action?.actions.completedAt)?.length;

  return (
    <div className={`ffnr fs14  ${styles.delinquencyLine}`}>
      <div
        onClick={() => setShowActions(!showActions)}
        className={`df acsa p-t-10 p-b-10 cursor_pointer ${styles.stageHdr}  ${
          dayjs(new Date()).isSame(dayjs(stage.scheduledDate), "day")
            ? styles.active
            : dayjs().isAfter(dayjs(stage.scheduledDate), "day") &&
              competedActionsCount(stage) === stage?.data?.length
            ? styles.over
            : dayjs().isAfter(dayjs(stage.scheduledDate), "day") &&
              competedActionsCount(stage) < stage?.data?.length
            ? styles.past
            : ""
        }`}
      >
        <div>
          {!stage.dependsOnRule ? (
            <div className={`fs11 df acc ffc ffqsb ${styles.dayWrpr}`}>
              <p className="m-b-2">DAY</p>
              <p style={{ marginBottom: "-2px" }}>
                {dayjs(stage.scheduledDate)
                  .startOf("day")
                  .diff(
                    Number(dayjs(selectedPastDue?.startDate).startOf("day")),
                    "d"
                  )}
              </p>
            </div>
          ) : undefined}
          <p className={`ffml ${styles.label}`}>
            {stage.title}{" "}
            <span className={`${styles.sub}`}>
              ({stage?.data?.length} action
              {stage?.data?.length === 1 ? "" : "s"})
            </span>{" "}
          </p>
          <p className={`ffmr fs12 m-t-8 ${styles.date}`}>
            {stage.scheduled ? "Scheduled for" : "Expected"}{" "}
            {dayjs(stage.scheduledDate).format("MM/DD/YYYY")}
          </p>
        </div>
        <div className="df acsa mla">
          <div style={{ marginBottom: "-3px" }} className={`m-r-10`}>
            {competedActionsCount(stage) > 0 &&
            competedActionsCount(stage) === stage?.data?.length ? (
              <CheckInCircleFull height="18" width="18" />
            ) : competedActionsCount(stage) > 0 ? (
              <PartialCheckedInCircle
                fill="rgba(31, 32, 65, 0.5)"
                height="18"
                width="18"
              />
            ) : undefined}
          </div>
          <div
            className={`${styles.opener} ${showActions ? styles.active : ""}`}
          >
            <Arrow fill="#30be76" fillOpacity="0.8" height="6" width="14" />
          </div>
        </div>
      </div>

      {showActions ? (
        <div className={`${styles.stageActions}`}>
          {stage?.data?.map((action, idx) => (
            <div
              className={`df acsa ${styles.actionLine}`}
              key={idx + action?.actions?._id}
            >
              <p>{action?.actions?.v?.label}</p>{" "}
              {action?.auction ? (
                <>
                  <p
                    className={`m-l-8 fsti tdu cursor_pointer`}
                    style={{ opacity: 0.7 }}
                    onClick={() => setShowAuctionDetails(!showAuctionDetails)}
                  >
                    view auction details
                  </p>
                  <>
                    {showAuctionDetails ? (
                      <>
                        {createPortal(
                          <div
                            className={`${popupStyles.auctionWrpr}`}
                            onClick={() => setShowAuctionDetails(false)}
                          >
                            <div
                              className={`df ffc ${popupStyles.innerWrpr}`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <p className={`ffnsb fs16`}>Auction Details</p>
                              <div className={`m-t-25 df aic `}>
                                <div className={popupStyles.fieldWrpr}>
                                  <label className={`ffnsb fs14`}>
                                    Serial Number
                                  </label>
                                  <p
                                    className={`ffnr fs14 m-t-5 ${popupStyles.value}`}
                                  >
                                    {action?.auction?.fields?.serialNumber}
                                  </p>
                                </div>
                                <div className={`mla ${popupStyles.fieldWrpr}`}>
                                  <label className={`ffnsb fs14`}>
                                    Scheduled For
                                  </label>
                                  <p
                                    className={`ffnr fs14 m-t-5 tar ${popupStyles.value}`}
                                  >
                                    {dayjs(
                                      new Date(action?.auction?.scheduledAt)
                                    ).format("MMM/DD/YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className={`${popupStyles.imagesWrpr}`}>
                                {action?.auction?.attachments?.map(
                                  (attachment, idx) => (
                                    <div className={`${popupStyles.imageWrpr}`}>
                                      <img
                                        width={"100%"}
                                        key={idx}
                                        src={attachment.imgUrl}
                                        alt=""
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                              <p className={`ffnsb fs14`}>Description</p>
                              <p
                                className={`ffnr fs14 m-t-5 m-b-25 ${popupStyles.value}`}
                              >
                                {action.auction.fields.itemsDescription}
                              </p>
                              <div className={`mta ${popupStyles.actionsWrpr}`}>
                                <button
                                  className={`w100 ttuc ffnb  ${popupStyles.pauseButton}`}
                                >
                                  Pause Auction
                                </button>
                                {/* <button>Actions</button> */}
                              </div>
                            </div>
                          </div>,
                          document.getElementById("tenant_details_popup")
                        )}
                      </>
                    ) : undefined}
                  </>
                </>
              ) : undefined}
              {action?.actions?.v?.value === "printCertifiedMail" ? (
                <div className={`m-l-6`}>
                  <p className={`flcap fsti tdu cursor_pointer`}>
                    {action?.actions?.trackingStatus}
                  </p>
                </div>
              ) : undefined}
              <p className={`mla ${styles.completedWrpr}`}>
                {action?.actions.completedAt
                  ? `completed at ${dayjs(action?.actions.completedAt).format(
                      "MM/DD/YYYY hh:mm A"
                    )}`
                  : ""}{" "}
              </p>
            </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default DelinquencyStage;
