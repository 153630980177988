import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./TenantLine.module.scss";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { formatPhoneNumber } from "react-phone-number-input";

dayjs.extend(advancedFormat);
dayjs().format("Q Do k kk X x");

const TenantLine = ({ tenant, idx, sd }) => {
  // eslint-disable-next-line
  const [showDetails, setShowDetails] = useState(sd);

  return (
    <>
      <Link
        onDragStart={(e) => e.preventDefault()}
        // onDrag={(e) => e.preventDefault()}
        className={`df acsa ${styles.line} ${showDetails ? styles.open : ""}`}
        title={idx}
        key={idx}
        to={`/tenants/${tenant._id}`}
      >
        <div className={`df ${styles.sec} ${styles.cntct}`}>
          <p className={`ffnr fs16`} title={tenant.fullName}>
            {tenant.fullName}
          </p>
          {tenant.hasPastDue ? <span className="star">*</span> : undefined}
        </div>
        <div className={`${styles.sec} ${styles.stts} ffnr fs16`}>{"N/A"}</div>
        <div className={`${styles.sec} ${styles.unit} ffnr fs16`}>
          {tenant.reservations.length ? tenant.reservations.length : ""}
        </div>
        <div className={`${styles.sec} ${styles.mv_in}  ffnr fs14`}>
          {tenant.createdAt
            ? dayjs(tenant.createdAt).format("MMM Do YYYY")
            : "N/A"}
        </div>
        {/* <div className="sec dt_crtd ffnr fs14">{"N/A"}</div> */}
        {/* <div className="sec src ffnr fs16">{"N/A"}</div> */}
        <div className={`df acc ${styles.sec} ${styles.cntrls}`}>
          {/* {tenant?.reservations?.length ? ( */}
          {/* <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              toggleShowDetails();
            }}
            style={{ transform: `rotate(${showDetails ? 180 : 0}deg)` }}
          >
            <DownArrow />
          </div> */}
        </div>
      </Link>
      {showDetails ? (
        <div
          className={`p-t-10 m-l-12 m-r-12 p-l-15 p-r-15 df ${styles.detailsWrpr}`}
        >
          <div className={`p-r-20 ${styles.contactDetails}`}>
            <div className={`ffnr fs14 ${styles.contactLine}`}>
              <p className={`${styles.label}`}>Email</p>
              <p className={`mla ${styles.val}`}>{tenant?.email}</p>
            </div>
            <div className={`ffnr fs14 ${styles.contactLine}`}>
              <p className={`${styles.label}`}>Primary Phone Number</p>
              <p className={`mla ${styles.val}`}>
                {formatPhoneNumber(tenant?.phoneNumber?.number)}
              </p>
            </div>
            <div className={`ffnr fs14 ${styles.contactLine}`}>
              <p className={`${styles.label}`}>Address</p>
              <p className={`mla  ${styles.val}`}>
                <p>{tenant?.address.deliveryLine1}</p>
                <p>{tenant?.address.lastLine}</p>
              </p>
            </div>
          </div>
          <div className={styles.unitDetails}>
            <div className={`df aic ${styles.rentalsListWrpr}`}>
              {tenant.reservations.map((reservation) => (
                <div
                  className={`df ffc m-r-10 p-l-20 p-r-20 p-t-20 p-b-20 ${styles.unitWrpr}`}
                >
                  <p
                    className={`ffnb fs12 p-l-20 p-r-20 p-t-5 p-b-5 ${
                      styles.unitId
                    } ${
                      reservation.expectedMoveInDate && !reservation.moveInDate
                        ? styles.notMovedIn
                        : ""
                    }`}
                  >
                    {/* rgba(228, 159, 0, 0.15) */}
                    {reservation.unit.unitId}{" "}
                    <span className={`ffnr`}>
                      ({reservation?.unit?.unitSize.length} X{" "}
                      {reservation?.unit?.unitSize.width})
                    </span>
                  </p>
                  {/* expectedMoveInDate */}
                  <div
                    className={`ffnr fs14 m-t-15 ${styles.rentalDetailLine}`}
                  >
                    <p className={`m-b-3 ${styles.label}`}>
                      {reservation.moveInDate ? "" : "Expected"} Move In Date
                    </p>
                    <p className={`${styles.val}`}>
                      {reservation.moveInDate
                        ? dayjs(reservation.moveInDate).format("MM/DD/YYYY")
                        : dayjs(reservation.expectedMoveInDate).format(
                            "MM/DD/YYYY"
                          )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default TenantLine;
