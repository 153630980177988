import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Portal from "../../../components/portal/Portal";
import SettingsNav from "../settings-nav/SettingNav";

import pageStyles from "../SettingsHome.module.scss";
import styles from "./Users.module.scss";
import AddUserPopup from "./add/AddUser";

const Users = (props) => {
  // /api/user

  const { user } = useSelector(({ user }) => ({ user }));

  const [users, setUsers] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  // const saveUser = (async) => {
  //   axios.post("/api/user", {
  //     email: "benbreuer845+6@gmail.com",
  //     firstName: "Ben",
  //     lastName: "Breuer",
  //     type: "accountAdmin",
  //     account: user.account,
  //     locations: user.locations.map((lo) => lo._id),
  //   });
  // };

  const getUsers = async () => {
    let list = await axios.get(`/api/user/${user.account}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setUsers(list.data);
  };

  useEffect(
    () => {
      getUsers();
    },
    // eslint-disable-next-line
    []
  );

  const toggleShowAdd = () => setShowAdd(!showAdd);

  return (
    <>
      <SettingsNav actions={[{ label: "Add user", onClick: toggleShowAdd }]} />
      <div className={pageStyles.page}>
        <div className={styles.userSettings}>
          <div className={styles.card}>
            {users.map((user) => (
              <div className={`df acsa ffnr fs16 p-l-10 p-r-10 ${styles.line}`}>
                <div
                  className={`df acc m-r-20 ${styles.avatar}`}
                  style={{ background: user.color }}
                >
                  {user.firstName?.substring(0, 1)}
                </div>
                <p className={styles.name}>
                  {user.firstName} {user.lastName}
                </p>
                <p className={styles.email}>{user.email}</p>
              </div>
            ))}
          </div>

          {showAdd ? (
            <Portal
              close={toggleShowAdd}
              title="Add user"
              height="auto"
              minHeight="auto"
              opacity="0.4"
              zIndex="2"
            >
              <AddUserPopup />
            </Portal>
          ) : undefined}
          {/* <button onClick={saveUser}>Add user</button> */}
        </div>
      </div>
    </>
  );
};

export default Users;
