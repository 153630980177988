import { useEffect, useState } from "react";
import axios from "axios";
import TopBar from "../../top-bar/TopBar";
import styles from "./OccupancyReports.module.scss";
// import currency from "currency.js";
import { formatFromCurrencyToMoney } from "../../../helpers";
// const formatFromCurrencyToMoney = (value) =>
//   currency(value, { symbol: "$", precision: 2 });

const OccupancyReport = (props) => {
  const [data, setData] = useState();

  const getReportData = async () => {
    const res = await axios.get(`/api/reports/occupancy-statistics`);
    console.log(res.data);
    setData(res.data);
  };

  useEffect(() => {
    getReportData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`df ffc ${styles.occupancyReport}`}>
      <TopBar title="Occupancy Report" />
      <div className={styles.reportBody}>
        {data &&
          data.map((location) => (
            <div className={styles.locationWrpr}>
              <div className={`df acsa ${styles.hdr}`}>
                <p>
                  Unit Location: <span>{location._id.name || "N/A"}</span>
                </p>
              </div>

              {location.types.map((type) => {
                return (
                  <div className={styles.typeWrpr}>
                    <p>
                      {type?._id?.unitType?.label || "N/A"} - {type.occupied}
                    </p>
                    <div className={styles.tableBody}>
                      <div className={`${styles.line} ${styles.hdrLine}`}>
                        <p className={`ffnr fs12 tac`}>Size</p>
                        <p className={`ffnr fs12 tac`}>Area</p>
                        <p className={`ffnr fs12 tac`}>Total Area</p>
                        <p className={`ffnr fs12 tac`}>Occupied</p>
                        <p className={`ffnr fs12 tac`}>Vacent</p>
                        <p className={`ffnr fs12 tac`}>Unrentable</p>
                        <p className={`ffnr fs12 tac`}>Total Units</p>
                        <p className={`ffnr fs12 tac`}>Standard Rate</p>
                        <p className={`ffnr fs12 tac`}>Gross Potential</p>
                        <p className={`ffnr fs12 tac`}>Gross Occupied</p>
                        <p className={`ffnr fs12 tac`}>Actual Occupied</p>
                        <p className={`ffnr fs12 tac`}>VACC</p>
                        <p className={`ffnr fs12 tac`}>VARC</p>
                        {/* <p className={`ffnr fs12 tac`}>VCOMP</p> */}
                        <p className={`ffnr fs12 tac`}>Income</p>
                        <p className={`ffnr fs12 tac`}>Units</p>
                        <p className={`ffnr fs12 tac`}>Area</p>
                        {/* <p className={`ffnr fs12 tac`}>Economic Occupancy</p> */}
                      </div>
                      {type.sizes.map((size) => {
                        return (
                          <div className={`ffnr fs14 ${styles.line}`}>
                            <p>
                              {size?._id?.unitSize?.length}x
                              {size?._id?.unitSize?.width}
                            </p>
                            <p>
                              {size?._id?.unitSize?.length *
                                size?._id?.unitSize?.width}
                            </p>
                            <p>
                              {" "}
                              {size?._id?.unitSize?.length *
                                size?._id?.unitSize?.width *
                                size.count}
                            </p>
                            <p>{size.occupied}</p>
                            <p>{size.vacent}</p>
                            <p>{size.unrentable}</p>
                            <p>{size.count}</p>
                            <p className={`tar`}>
                              {formatFromCurrencyToMoney(
                                size?._id?.unitSize?.price
                              )}
                            </p>
                            <p className={`tar`}>
                              {formatFromCurrencyToMoney(size?.grossPotential)}
                            </p>
                            <p className={`tar`}>
                              {formatFromCurrencyToMoney(size.grossOccupied)}
                            </p>
                            <p className={`tar`}>
                              {formatFromCurrencyToMoney(size.actualOccupied)}
                            </p>
                            <p className={`tar`}>
                              {formatFromCurrencyToMoney(
                                size.vacent * size?._id?.unitSize?.price
                              )}
                              {/* {formatFromCurrencyToMoney(size.vacent * size?._id?.unitSize?.price)} */}
                            </p>
                            <p className={`tar`}>
                              {formatFromCurrencyToMoney(size?.VARC)}
                            </p>
                            {/* <p>N/A</p> */}
                            <p className={`tar`}>
                              {size.AAAAAAA
                                ? `${size.AAAAAAA.toFixed(1)}%`
                                : "0%"}
                            </p>
                            <p className={`tar`}>
                              {((size.occupied / size.count) * 100).toFixed(1)}%
                            </p>
                            <p className={`tar`}>
                              {(
                                ((size?._id?.unitSize?.length *
                                  size?._id?.unitSize?.width *
                                  size.occupied) /
                                  (size?._id?.unitSize?.length *
                                    size?._id?.unitSize?.width *
                                    size.count)) *
                                100
                              ).toFixed(1)}
                              %
                            </p>
                            {/* <p>N/A</p> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

export default OccupancyReport;
