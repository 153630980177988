import { useState } from "react";
import Portal from "../../../../../components/portal/Portal";
import styles from "./InvoiceSetup.module.scss";
import BackArrow from "../../../../../assets/js-svgs/back-arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";
import axios from "axios";
const InvoiceSetup = (props) => {
  let token = localStorage.getItem("x-auth");
  //   return <div className={styles.invoiceSetup}>Hello</div>;

  const [invoiceScheduleType, setInvoiceScheduleType] = useState(
    props.invoiceScheduleType
  );

  const [step, setStep] = useState(1);
  const [selectedDay, setSelectedDay] = useState(props.selectedDay);

  const save = async () => {
    // /api/reservation/:reservationid

    await axios.put(
      `/api/reservation/${props.reservationId}`,
      {
        invoiceSchedule: invoiceScheduleType,
        invoiceDay: selectedDay,
      },
      { headers: { "x-auth": token } }
    );

    props.close();
  };
  return (
    <Portal
      title="Set Up Billing Cycle"
      height={`auto`}
      width="348px"
      opacity="0.45"
      zIndex="101"
      close={props.close}
    >
      <div className={styles.invoiceSetup}>
        {step === 1 ? (
          <div>
            <div className={styles.listWrpr}>
              {/* // monthly || quarterly || biannual || annually */}
              <div
                className={`df acsa ${styles.line}`}
                onClick={() => setInvoiceScheduleType("monthly")}
              >
                <div
                  className={`${styles.radioIcon} ${
                    invoiceScheduleType === "monthly" ? styles.selected : ""
                  }`}
                ></div>
                <p className={`ffnr fs14`}>Monthly</p>
              </div>
              <div
                className={`df acsa ${styles.line}`}
                onClick={() => setInvoiceScheduleType("quarterly")}
              >
                <div
                  className={`${styles.radioIcon} ${
                    invoiceScheduleType === "quarterly" ? styles.selected : ""
                  }`}
                ></div>
                <p className={`ffnr fs14`}>Quarterly</p>
              </div>
              <div
                className={`df acsa ${styles.line}`}
                onClick={() => setInvoiceScheduleType("biannual")}
              >
                <div
                  className={`${styles.radioIcon} ${
                    invoiceScheduleType === "biannual" ? styles.selected : ""
                  }`}
                ></div>
                <p className={`ffnr fs14`}>Biannual</p>
              </div>
              <div
                className={`df acsa ${styles.line}`}
                onClick={() => setInvoiceScheduleType("annually")}
              >
                <div
                  className={`${styles.radioIcon} ${
                    invoiceScheduleType === "annually" ? styles.selected : ""
                  }`}
                ></div>
                <p className={`ffnr fs14`}>Annually</p>
              </div>
            </div>
            <button className={`primary w100 ttuc`} onClick={() => setStep(2)}>
              Next
            </button>
          </div>
        ) : undefined}
        {step === 2 ? (
          <div>
            <div
              className={`df acsa m-b-20 ffmr fs12 `}
              onClick={() => setStep(1)}
            >
              <BackArrow />
              <p className={`m-l-8`} style={{ opacity: 0.5 }}>
                Back
              </p>
            </div>
            <Swiper
              centeredSlidesBounds
              slidesPerView={"auto"}
              spaceBetween={0}
              //   navigation
              pagination={{ clickable: true }}
              className={`customized ${styles.daysWrpr}`}
              // initialSlide={props.initialSlide}
            >
              {[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
              ].map((day) => (
                <SwiperSlide
                  key={day}
                  // className={`tabWrpr ffnr fs14 tab ttuc ${
                  //   props.selectedRental === tab._id ? "selected" : ""
                  // } ${tab.hasPastDue ? "hasPastDue" : ""}`}
                  // key={tab.unit?._id + i}
                  className={`df acc ffmr fs12 ${styles.day} ${
                    selectedDay === day ? styles.selected : ""
                  }`}
                  onClick={() => setSelectedDay(day)}
                >
                  {day}
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              className={`primary w100 ttuc m-t-30`}
              onClick={() => save()}
            >
              Save
            </button>
          </div>
        ) : undefined}
      </div>
    </Portal>
  );

  //   minHeight="488px"
  //   width="354px"
  //   height="816px"
  //   zIndex="100"
  //   opacity="0.45"
  //   title="Add Tenant"
  //   close={() => this.props.history.push("/tenants")}
};
export default InvoiceSetup;
