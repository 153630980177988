export const ImageUploadSVG = (props) => (
  <svg
    width={props.width || "16"}
    height={props.height || "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.1019 0C7.76459 0 7.49121 0.273375 7.49121 0.610687V10.3003C7.49121 10.6376 7.76459 10.9109 8.1019 10.9109C8.43921 10.9109 8.71259 10.6376 8.71259 10.3003V0.610687C8.71259 0.273375 8.43921 0 8.1019 0Z"
      fill="#222F33"
      fillOpacity="0.5"
    />
    <path
      d="M11.8982 3.31974L8.51904 0.164518C8.28432 -0.0545137 7.92017 -0.0545137 7.68545 0.164518L4.3063 3.31974C4.0598 3.54995 4.04636 3.93633 4.27658 4.18283C4.39667 4.31167 4.55974 4.37683 4.72299 4.37683C4.87239 4.37683 5.02202 4.32249 5.13967 4.21255L8.10211 1.44633L11.0645 4.21255C11.3114 4.44277 11.6976 4.42933 11.9276 4.18283C12.1577 3.93633 12.1447 3.54995 11.8982 3.31974Z"
      fill="#222F33"
      fillOpacity="0.5"
    />
    <path
      d="M14.2087 5.21118H10.9517C10.6144 5.21118 10.341 5.48456 10.341 5.82187C10.341 6.15918 10.6144 6.43256 10.9517 6.43256H13.598V14.7786H2.40204V6.43256H5.04835C5.38566 6.43256 5.65904 6.15918 5.65904 5.82187C5.65904 5.48456 5.38566 5.21118 5.04835 5.21118H1.79135C1.45404 5.21118 1.18066 5.48456 1.18066 5.82187V15.3893C1.18066 15.7266 1.45404 16 1.79135 16H14.2087C14.546 16 14.8194 15.7266 14.8194 15.3893V5.82187C14.8194 5.48459 14.546 5.21118 14.2087 5.21118Z"
      fill="#222F33"
      fillOpacity="0.5"
    />
  </svg>
);
