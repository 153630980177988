import React, { useState, useEffect } from "react";
import Portal from "../../../../components/portal/Portal";
import _ from "lodash";

// import UploadIcon from "../../../../assets/js-svgs/upload-icon.svg";

import "./Ids.scss";
// import {Formik, Field} from "formik";
// import {DefaultInput} from "../../../../components/inputs/DefaultInput";
import Axios from "axios";
import DL from "./DL";
import SID from "./SID";
import Other from "./Other";

const types = [
  { label: "DMV ID", value: "dmv" },
  { label: "Student ID", value: "student" },
  { label: "Other", value: "other" },
];

const Ids = (props) => {
  const [selectedType, updateSelectedType] = useState("dmv");
  const [fileB, updateFile] = useState(null);
  const [filename, updateFileName] = useState(null);
  // const [ids, updateIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idList, setIdList] = useState([]);

  const selectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      updateFileName(event.target.files[0].name);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        updateFile(reader.result);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (!fileB) return;
    postFile();
    // eslint-disable-next-line
  }, [fileB]);

  const postFile = async () => {
    setLoading(true);
    const i = fileB.indexOf("base64,");
    const buffer = Buffer.from(fileB.slice(i + 7), "base64");

    // const imageType = fileB.substring(
    // 	fileB.indexOf("/") + 1,
    // 	fileB.indexOf(";base64")
    // );

    const file = new File([buffer], filename, { type: "image/png" });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("filename", file.name);

    let currentID = _.find(idList, ["type", selectedType]);

    let id = currentID ? `&id=${currentID._id}` : "";

    let list = await Axios.post(
      `/api/portal-reservation-id-upload/${props.match.params.reservationId}/?type=${selectedType}${id}`,
      fd,
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );

    let localList = {};
    await types.map((type) => {
      return (localList[type.value] = _.find(list.data.data, [
        "type",
        type.value,
      ]));
    });

    setIdList(localList);
    setLoading(false);
  };

  const getIdList = async () => {
    setLoading(true);
    let list = await Axios.get(
      `/api/reservation-id-cards/${props.match.params.reservationId}`
    );
    let localList = {};
    await types.map((type) => {
      return (localList[type.value] = _.find(list.data.data, [
        "type",
        type.value,
      ]));
    });

    setIdList(localList);
    setLoading(false);
  };

  useEffect(() => {
    getIdList();
    // eslint-disable-next-line
  }, []);

  // const test = () => {
  // 	let id1 = _.find(idList, ["type", selectedType]);
  // };

  // const submitBtn = useRef(null);

  return (
    <Portal
      minHeight="488px"
      width="354px"
      noCustomScrollbar
      height={`auto`}
      zIndex="100"
      opacity="0.45"
      title="ID Verification"
      close={() => props.history.push("/tenants")}
      //   overlayClick={() => (showPopup ? setPopup(undefined) : null)}
    >
      {!loading && (
        <div className="id_page">
          <div className="df acsa ffnr fs14 c_tabs_wrpr">
            {types.map((type, idx) => (
              <div
                key={idx}
                className={`df acc c_tab ${
                  selectedType === type.value ? "selected" : ""
                }`}
                onClick={() => {
                  updateSelectedType(type.value);
                }}
              >
                {type.label}
              </div>
            ))}
          </div>

          <div className="tabs_content">
            {selectedType === "dmv" ? (
              <DL
                idList={idList.dmv}
                selectedType={selectedType}
                selectFile={selectFile}
                match={props.match}
              ></DL>
            ) : undefined}

            {selectedType === "student" ? (
              <SID
                idList={idList.student}
                selectedType={selectedType}
                selectFile={selectFile}
                match={props.match}
              ></SID>
            ) : undefined}

            {selectedType === "other" ? (
              <Other
                idList={idList.other}
                selectedType={selectedType}
                selectFile={selectFile}
                match={props.match}
              ></Other>
            ) : undefined}
          </div>
        </div>
      )}
    </Portal>
  );
};

export default Ids;
