import { Route, Switch } from "react-router-dom";
import ReportsNav from "./nav/Nav";
import RentRoll from "./rent-roll/RentRoll";
import OccupancyReport from "./occupancy-reports/OccupancyReports";
import styles from "./ReportsHub.module.scss";

const ReportsHub = (props) => {
  return (
    <div className={styles.ReportsHub}>
      <Switch>
        <Route
          path="/reports-hub/occupancy"
          component={OccupancyReport}
        ></Route>
        <Route path="/reports-hub/rent-roll" component={RentRoll}></Route>
        <Route path="/reports-hub" component={ReportsNav}></Route>
      </Switch>
    </div>
  );
};

export default ReportsHub;
