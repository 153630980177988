// const UnitFeatures = (props) => {
//   return <div>Hello unit features</div>;
// };

// export default UnitFeatures;

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styles from "./UnitFeatures.module.scss";

const UnitFeatures = (props) => {
  const params = useParams();
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [src, setSrc] = useState();
  const [featuresList, setFeaturesList] = useState([]);

  const onSelectFile = (e, drop) => {
    let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (res) => setSrc(res.target.result);
  };

  const uploadFile = () => {
    const i = src.indexOf("base64,");
    const buffer = Buffer.from(src.slice(i + 7), "base64");
    const file = new File([buffer], "name", {
      type: "application/vnd.ms-excel",
    });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("locationId", reduxUser.selectedLocation._id);
    fd.append("accountId", user.account);
    fd.append("jobId", params.id);

    axios.post("/api/import/unit-features", fd);
  };

  const getUnitFeatures = async () => {
    let features = await axios.get(`/api/import/unit-features/${params.id}`);

    setFeaturesList(features.data);
  };

  useEffect(() => {
    getUnitFeatures();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${styles.unitFeaturesPage}`}>
      <h1>Import Unit Features</h1>
      <div>
        <input
          type="file"
          accept=".csv, .txt"
          id="upld_file"
          onChange={onSelectFile}
        />
      </div>
      <button onClick={uploadFile}>Upload</button>

      <div>
        {featuresList.map((size) => {
          return (
            <div className={`df acsa ${styles.line}`}>
              <div className={`${styles.sec}`} style={{ width: "120px" }}>
                <p>Label</p>
                <p>{size?.label}</p>
              </div>
              <div className={`${styles.sec}`} style={{ width: "200px" }}>
                <p>_id</p>
                <p>{size._id}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UnitFeatures;
