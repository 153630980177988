import React from "react";

const BellSvg = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90889 14.2922L3.82217 10.7247V5.97062C3.82217 3.34389 6.10172 1.2165 8.91631 1.2165C11.7309 1.2165 14.0144 3.34389 14.0144 5.97062V10.7247L15.9237 14.2922H1.90889ZM8.91412 16.6687C8.08368 16.6687 7.38381 16.1723 7.11947 15.482H10.7088C10.4444 16.1723 9.74457 16.6687 8.91412 16.6687ZM15.2864 10.7235V5.96941C15.2864 2.68656 12.4344 0.0249023 8.91673 0.0249023C5.39908 0.0249023 2.54707 2.68656 2.54707 5.96941V10.7235L0 15.4814H5.79719C6.09258 16.8367 7.37766 17.8584 8.91673 17.8584C10.4598 17.8584 11.7441 16.8367 12.0395 15.4814H17.8335L15.2864 10.7235Z"
      fill={props.fill || ""}
      fillOpacity={props.fillOpacity || ""}
    />
    {/* </g> */}
  </svg>
);

export default BellSvg;
