import React from "react";

const BackArrow = (props) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4.375H2.39375L5.8875 0.88125L5 0L0 5L5 10L5.88125 9.11875L2.39375 5.625H10V4.375Z"
      fill={props.fill || "black"}
      fillOpacity={props.opacity || "0.3"}
    />
  </svg>
);

export default BackArrow;
