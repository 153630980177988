import { useEffect, useState } from "react";
import axios from "axios";
import _, { cloneDeep } from "lodash";
import SettingsNav from "../settings-nav/SettingNav";

import pageStyles from "../SettingsHome.module.scss";
import styles from "./Website.module.scss";
import ImageUploadPopup from "../../../components/image-upload/ImageUploadPopup";
import { NonFormikInput } from "../../../components/inputs/NonFormikInput";
import { ImageUploadSVG } from "../../../assets/js-svgs/image-upload.svg";
import { useSelector } from "react-redux";
import NonFormikSelect from "../../../components/inputs/drop-down/non-formik/NonFormikSelect";

const Website = () => {
  const { user } = useSelector(({ user }) => ({ user }));

  const [websiteProfiles, setWebsiteProfiles] = useState([]);
  const [websiteProfile, setWebsiteProfile] = useState({});
  const [showLogoUpload, setShowLogoUpload] = useState(false);
  const [showDarkFaviconUpload, setShowDarkFaviconUpload] = useState(false);
  const [showLandingUpload, setShowLandingUpload] = useState(false);
  // const [base64Image, setBase64Image] = useState("");
  // const [imageBlob, setImageBlob] = useState(null);
  // const [imageFileName, setImageFileName] = useState("");

  const [templates, setTemplates] = useState([]);
  const [fees, setFees] = useState([]);

  const getTemplates = async () => {
    let re = await axios.get(`/api/template-list/${user.account}`);
    setTemplates(re.data);
  };

  const getFees = async () => {
    let re = await axios.get(`/api/fee/?accountId${user.account}`);
    setFees(re?.data?.data);
  };

  const setShowLogoPopup = () => {
    setShowLogoUpload(!showLogoUpload);
  };

  const setShowDarkFaviconUploadPopup = () => {
    setShowDarkFaviconUpload(!showDarkFaviconUpload);
  };

  const setShowLandingUploadPopup = () => {
    setShowLandingUpload(!showLandingUpload);
  };

  // useEffect(() => {
  //   setImageBlob(websiteProfile?.theme?.imgUrl);
  // }, [websiteProfile?.theme?.imgUrl]);

  // const setImageBlobAction = (d) => {
  //   setImageBlob(d);
  // };

  const updateHour = (value, idx, field) => {
    console.log(`hours[${idx}].${field}`);
    let copy = cloneDeep(websiteProfile);
    _.set(copy, `hours[${idx}].${field}`, value);
    setWebsiteProfile(copy);
  };

  const saveImage = async (blob, field) => {
    // console.log(blob, field);
    if (!blob || !field) return;
    const i = blob.indexOf("base64,");
    const buffer = Buffer.from(blob.slice(i + 7), "base64");
    const file = new File(
      [buffer],
      `${(Math.random() + 1).toString(36).substring(7)}.png`
    );
    const fd = new FormData();
    fd.append("file", file);
    let re = await axios.post("/api/file/upload", fd);
    let copy = cloneDeep(websiteProfile);
    _.set(copy, field, re.data.data.url);
    setWebsiteProfile(copy);
  };

  const saveProfile = async () => {
    if (websiteProfile._id) {
      axios.put(
        `/api/website-profile/${websiteProfile._id}`,
        {
          ...websiteProfile,
        },
        { headers: { "x-auth": localStorage.getItem("x-auth") } }
      );
    } else {
      axios.post(
        "/api/website-profile",
        {
          ...websiteProfile,
          account: user.account,
        },
        { headers: { "x-auth": localStorage.getItem("x-auth") } }
      );
    }
  };

  const getAccountWebProfiles = async () => {
    let res = await axios.get(
      `/api/website-profile/${user.account}/?type=list`,
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );
    setWebsiteProfiles(res.data.data);
  };

  const getProfile = async () => {
    let res = await axios.get(
      `/api/website-profile/${websiteProfiles[0]._id}`,
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );
    setWebsiteProfile(res.data.data);
  };

  useEffect(() => {
    getAccountWebProfiles();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!websiteProfiles?.length) return;
    getProfile();
    // eslint-disable-next-line
  }, [websiteProfiles]);

  useEffect(() => {
    if (!user?.account) return;
    getTemplates();
    getFees();
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <SettingsNav />
      <div className={`${styles.websiteSettings} ${pageStyles.page}`}>
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>Website profile</p>
              <p className={`ffnr fs12 m-t-10`}>Set up your website profile</p>
            </div>
            <button
              className={`mla ffnr s14 ${styles.addBtn}`}
              onClick={saveProfile}
            >
              Save Profile
            </button>
          </div>
          <div className={`df aic ${styles.settingLine}  ${styles.hasInput}`}>
            <div className={`${styles.inputSec}`}>
              <input
                style={{ width: "100%" }}
                value={websiteProfile?.name}
                onChange={(e) =>
                  setWebsiteProfile({
                    ...websiteProfile,
                    name: e.target.value,
                  })
                }
                placeholder="Website name"
                label="Website name"
                id="wName"
              />
            </div>
            <div className={`m-l-20 m-r-20 ${styles.inputSec}`}>
              <input
                style={{ width: "100%", display: "block" }}
                value={websiteProfile?.websiteBaseURL}
                onChange={(e) =>
                  setWebsiteProfile({
                    ...websiteProfile,
                    websiteBaseURL: e.target.value,
                  })
                }
                placeholder="Website URL"
                label="Website URL"
                id="wURL"
              />
            </div>
          </div>
          <div className={`df aic ${styles.settingLine}  ${styles.hasInput}`}>
            <div className={`${styles.inputSec}`}>
              <input
                style={{ width: "100%" }}
                value={websiteProfile?.profilePhoneNumber}
                onChange={(e) =>
                  setWebsiteProfile({
                    ...websiteProfile,
                    profilePhoneNumber: e.target.value,
                  })
                }
                placeholder="Website phone number"
                label="Website phone number"
                id="wPhoneNum"
              />
            </div>
            <div className={`m-l-20 m-r-20 ${styles.inputSec}`}>
              <input
                style={{ width: "100%", display: "block" }}
                value={websiteProfile?.email}
                onChange={(e) =>
                  setWebsiteProfile({
                    ...websiteProfile,
                    email: e.target.value,
                  })
                }
                placeholder="Website email"
                label="Website email"
                id="wEmail"
              />
            </div>
          </div>

          {/* <p>Address</p> */}
          <p>Primary Color</p>
          <input
            type="color"
            value={websiteProfile?.theme?.primaryColor}
            onChange={(e) =>
              setWebsiteProfile({
                ...websiteProfile,
                theme: {
                  ...websiteProfile.theme,
                  primaryColor: e.target.value,
                },
              })
            }
          />
          <p>Primary Text Color</p>
          <input
            type="color"
            value={websiteProfile?.theme?.primaryTextColor}
            onChange={(e) =>
              setWebsiteProfile({
                ...websiteProfile,
                theme: {
                  ...websiteProfile.theme,
                  primaryTextColor: e.target.value,
                },
              })
            }
          />
          <p onClick={setShowLogoPopup}>Upload logo</p>
          {websiteProfile?.theme?.logoURL ? (
            <img src={websiteProfile?.theme?.logoURL} alt="img" />
          ) : (
            <ImageUploadSVG />
          )}
          {showLogoUpload ? (
            <ImageUploadPopup
              aspect={4.86 / 1}
              close={setShowLogoPopup}
              setBase64Image={(e) => saveImage(e, "theme.logoURL")}
              setImageBlob={(e) => console.log(e)}
              imageUrl={websiteProfile?.theme?.logoURL}
              setImageFileName={() => console.log()}
            />
          ) : undefined}
          <p onClick={setShowDarkFaviconUploadPopup}>Upload favicon</p>
          {websiteProfile?.theme?.faveiconOnDarkUrl ? (
            <img src={websiteProfile?.theme?.faveiconOnDarkUrl} alt="img" />
          ) : (
            <ImageUploadSVG />
          )}
          {showDarkFaviconUpload ? (
            <ImageUploadPopup
              aspect={1 / 1}
              close={setShowDarkFaviconUploadPopup}
              setBase64Image={(e) => saveImage(e, "theme.faveiconOnDarkUrl")}
              setImageBlob={(e) => console.log(e)}
              imageUrl={websiteProfile?.theme?.faveiconOnDarkUrl}
              setImageFileName={() => console.log()}
            />
          ) : undefined}
          <p onClick={setShowLandingUploadPopup}>Upload landing image</p>
          {websiteProfile?.theme?.landingImageURL ? (
            <img src={websiteProfile?.theme?.landingImageURL} alt="img" />
          ) : (
            <ImageUploadSVG />
          )}
          {showLandingUpload ? (
            <ImageUploadPopup
              aspect={2.11 / 1}
              close={setShowLandingUploadPopup}
              setBase64Image={(e) => saveImage(e, "theme.landingImageURL")}
              setImageBlob={(e) => console.log(e)}
              imageUrl={websiteProfile?.theme?.landingImageURL}
              setImageFileName={() => console.log()}
            />
          ) : undefined}
          {websiteProfile?.hours?.map((hr, idx) => {
            return (
              <div className={`df aic`}>
                <div>
                  <NonFormikInput
                    label="Label"
                    value={hr.label}
                    change={(e) => updateHour(e, idx, "label")}
                  />
                </div>
                <div>
                  <NonFormikInput
                    label="Start Time"
                    value={hr.startTime}
                    change={(e) => updateHour(e, idx, "startTime")}
                  />
                </div>
                <div>
                  <NonFormikInput
                    label="End Time"
                    value={hr.endTime}
                    change={(e) => updateHour(e, idx, "endTime")}
                  />
                </div>
              </div>
            );
          })}
          <p
            onClick={() =>
              setWebsiteProfile({
                ...websiteProfile,
                hours: websiteProfile?.hours?.length
                  ? [
                      ...websiteProfile.hours,
                      {
                        label: "",
                        startTime: "",
                        endTime: "",
                      },
                    ]
                  : [
                      {
                        label: "",
                        startTime: "",
                        endTime: "",
                      },
                    ],
              })
            }
          >
            Add Schedule
          </p>
          <p>Select locations</p>
          {user?.locations?.map((loc, idx) => {
            return (
              <p
                onClick={() => {
                  let copy = websiteProfile?.locations?.length
                    ? [...websiteProfile.locations]
                    : [];

                  let indexOf = copy?.find((el) => el._id === loc._id);

                  console.log(indexOf);
                  if (indexOf === -1 || indexOf === undefined) {
                    copy.push({ _id: loc._id });
                  } else {
                    copy.splice(indexOf, 1);
                  }
                  setWebsiteProfile({
                    ...websiteProfile,
                    locations: copy,
                  });
                }}
                style={{
                  backgroundColor: websiteProfile?.locations?.find(
                    (el) => el._id === loc._id
                  )
                    ? "green"
                    : "",
                }}
              >
                {loc.name}
              </p>
            );
          })}

          <p>Landing page</p>
          <NonFormikInput
            label="Landing page square one text"
            value={websiteProfile?.landingPage?.squareOneText}
            change={(e) =>
              setWebsiteProfile({
                ...websiteProfile,
                landingPage: {
                  ...websiteProfile.landingPage,
                  squareOneText: e,
                },
              })
            }
          />
          <NonFormikInput
            label="Landing page square two text"
            value={websiteProfile?.landingPage?.squareTwoText}
            change={(e) =>
              setWebsiteProfile({
                ...websiteProfile,
                landingPage: {
                  ...websiteProfile.landingPage,
                  squareTwoText: e,
                },
              })
            }
          />

          <NonFormikInput
            id="minimumMonths"
            label="Minimum months"
            value={websiteProfile?.minimumMonths}
            change={(e) => {
              setWebsiteProfile({
                ...websiteProfile,
                minimumMonths: e,
              });
            }}
          />

          <NonFormikInput
            id="minimumDaysInMonth"
            label="Minimum days in month"
            value={websiteProfile?.minimumDaysInMonth}
            change={(e) => {
              setWebsiteProfile({
                ...websiteProfile,
                minimumDaysInMonth: e,
              });
            }}
          />

          <NonFormikInput
            id="salesTax"
            label="Sales tax rate"
            value={websiteProfile?.salesTaxRate}
            change={(e) => {
              setWebsiteProfile({
                ...websiteProfile,
                salesTaxRate: e,
              });
            }}
          />

          <p>Add social</p>
          <NonFormikInput
            id="twitter"
            label="Twitter handle"
            value={websiteProfile?.social?.twitter}
            change={(e) => {
              let copy = websiteProfile?.social
                ? cloneDeep(websiteProfile?.social)
                : {};

              copy = { ...copy, twitter: e };
              setWebsiteProfile({
                ...websiteProfile,
                social: copy,
              });
            }}
          />

          <NonFormikInput
            id="facebook"
            label="Facebook handle"
            value={websiteProfile?.social?.facebook}
            change={(e) => {
              let copy = websiteProfile?.social
                ? cloneDeep(websiteProfile?.social)
                : {};

              copy = { ...copy, facebook: e };
              setWebsiteProfile({
                ...websiteProfile,
                social: copy,
              });
            }}
          />

          <NonFormikInput
            id="instagram"
            label="Instagram handle"
            value={websiteProfile?.social?.instagram}
            change={(e) => {
              let copy = websiteProfile?.social
                ? cloneDeep(websiteProfile?.social)
                : {};

              copy = { ...copy, instagram: e };
              setWebsiteProfile({
                ...websiteProfile,
                social: copy,
              });
            }}
          />

          <NonFormikSelect
            label="Select onboarding template"
            value={{
              label: websiteProfile?.onBoardingEmailTemplate?.name,
              value: websiteProfile?.onBoardingEmailTemplate?._id,
            }}
            options={templates.map((template) => ({
              label: template.name,
              value: template._id,
            }))}
            onChange={(value) => {
              setWebsiteProfile({
                ...websiteProfile,
                onBoardingEmailTemplate: value.value,
              });
            }}
          />
          <NonFormikSelect
            label="Select invoice email template"
            value={{
              label: websiteProfile?.invoiceEmailTemplate?.name,
              value: websiteProfile?.invoiceEmailTemplate?._id,
            }}
            options={templates
              .filter((el) => el.type === "email")
              .map((template) => ({
                label: template.name,
                value: template._id,
              }))}
            onChange={(value) => {
              setWebsiteProfile({
                ...websiteProfile,
                invoiceEmailTemplate: value.value,
              });
            }}
          />

          <NonFormikSelect
            label="Select invoice email template"
            value={{
              label: websiteProfile?.contractTemplate?.name,
              value: websiteProfile?.contractTemplate?._id,
            }}
            options={templates
              .filter((el) => el.type === "pdf")
              .map((template) => ({
                label: template.name,
                value: template._id,
              }))}
            onChange={(value) => {
              setWebsiteProfile({
                ...websiteProfile,
                contractTemplate: value.value,
              });
            }}
          />

          <NonFormikSelect
            label="Select move in fee"
            value={{
              label: websiteProfile?.moveInAdminFee?.label,
              value: websiteProfile?.moveInAdminFee?._id,
            }}
            options={fees.map((template) => ({
              label: template.label,
              value: template._id,
            }))}
            onChange={(value) => {
              setWebsiteProfile({
                ...websiteProfile,
                moveInAdminFee: value.value,
              });
            }}
          />
        </div>
        <div style={{ height: "300px" }}></div>"
      </div>
    </>
  );
};

export default Website;

// landingPage: {
//     squareOneText: String,
//     squareOneImageURL: String,
//     squareTwoText: String,
//     squareTwoImageURL: String,
//   },
