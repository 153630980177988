import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import { updateReduxUser } from "../../store/actions";
import { SET_REDUX_USER, SET_TASKS_VISIBLE } from "../../store/actions/types";

// import scss
import "./MainNav.scss";
import { useDispatch, useSelector } from "react-redux";
import UnitsSvg from "../../assets/js-svgs/units.svg";
import TenantsSvg from "../../assets/js-svgs/tenants.svg";
import DashboardSvg from "../../assets/js-svgs/dashboard.svg";
import CommunicationsSvg from "../../assets/js-svgs/communications.svg";
import PaymentSvg from "../../assets/js-svgs/payment.svg";
import BellSvg from "../../assets/js-svgs/bell.svg";
import ChatSvg from "../../assets/js-svgs/chat.svg";
import ShowMoreSvg from "../../assets/js-svgs/show-more.svg";
import CartAddSvg from "../../assets/js-svgs/cart-add.svg";
import TransferSvg from "../../assets/js-svgs/transfer.svg";
import MoveInSvg from "../../assets/js-svgs/move-in.svg";
import AddUserSvg from "../../assets/js-svgs/add-user.svg";

// import avatar from "../../assets/images/me.png";
import MapPin from "../../assets/js-svgs/map-pin.svg";
import Arrow from "../../assets/js-svgs/arrow.svg";
import Plus from "../../assets/js-svgs/plus.svg";
import CompanyLogoSVG from "../../assets/js-svgs/company-logo.svg";
import ReportsHubSVG from "../../assets/js-svgs/reports-hub.svg";
import SettingsNavSVG from "../../assets/js-svgs/settings-nav-icon.svg";
import AddLocation from "./add-location/AddLocation";
// import UsersNavSVG from "../../assets/js-svgs/users-nav-icon.svg";

const MainNav = (props) => {
  const dispatch = useDispatch();
  const { user, reduxUser, tasks } = useSelector(
    ({ user, reduxUser, tasks }) => ({
      user,
      reduxUser,
      tasks,
    })
  );

  const [showLocationSelection, setShowLocationSelection] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);

  const navs = [
    {
      label: "dashboard",
      url: "/dashboard",
      class: "dashboard",
      showSubs: false,
      icon: <DashboardSvg />,
      iconsHeight: "17px",
      subs: [
        // { label: "sales", url: "/dashboard/sales" },
        // { label: "tenants", url: "/dashboard/tenants" },
        // { label: "payments", url: "/dashboard/payments" },
      ],
    },
    {
      label: "tenants",
      url: "/tenants",
      class: "tenants",
      showSubs: false,
      icon: <TenantsSvg />,
      iconsHeight: "20px",
      subs: [],
    },
    // {
    //   label: "locations",
    //   url: "/locations",
    //   class: "locations",
    //   showSubs: false,
    //   icon: <UnitsSvg />,
    //   SelectedIcon: <UnitsSvg />,
    //   subs: [],
    // },
    // {
    //   label: "users",
    //   url: "/users",
    //   class: "users",
    //   showSubs: false,
    //   subs: [],
    // },

    {
      label: "units",
      url: "/units",
      class: "units",
      icon: <UnitsSvg></UnitsSvg>,
      iconsHeight: "16px",
      showSubs: false,
      subs: [
        // { label: "list", url: "/units/list" },
        // { label: "grouped", url: "/units/grouped" },
      ],
    },

    {
      label: "communications",
      url: "/communications",
      class: "communications",
      icon: <CommunicationsSvg></CommunicationsSvg>,
      iconsHeight: "16px",
      showSubs: false,
      subs: [
        // { label: "list", url: "/units/list" },
        // { label: "grouped", url: "/units/grouped" },
      ],
    },

    // {
    //   label: "payments",
    //   url: "/payments",
    //   class: "payments",
    //   icon: <PaymentSvg />,
    //   iconsHeight: "16px",
    //   showSubs: false,
    //   subs: [
    //     // { label: "list", url: "/units/list" },
    //     // { label: "grouped", url: "/units/grouped" },
    //   ],
    // },

    {
      label: "settings",
      url: "/settings",
      class: "settings",
      icon: <SettingsNavSVG />,
      iconsHeight: "20px",
      showSubs: false,
      subs: [
        // { label: "list", url: "/units/list" },
        // { label: "grouped", url: "/units/grouped" },
      ],
    },
    // {
    //   label: "users",
    //   url: "/users",
    //   class: "users",
    //   icon: <UsersNavSVG />,
    //   iconsHeight: "20px",
    //   showSubs: false,
    //   subs: [
    //     // { label: "list", url: "/units/list" },
    //     // { label: "grouped", url: "/units/grouped" },
    //   ],
    // },

    {
      label: "reports hub",
      url: "/reports-hub",
      class: "reports-hub",
      icon: <ReportsHubSVG />,
      iconsHeight: "20px",
      showSubs: false,
      subs: [
        // { label: "list", url: "/units/list" },
        // { label: "grouped", url: "/units/grouped" },
      ],
    },
  ];

  // const toggleSubs = (index) =>
  //   this.setState((prevState) => {
  //     let newNavs = [...prevState.navs];
  //     newNavs[index].showSubs = !newNavs[index].showSubs;

  //     return { navs: newNavs };
  //   });

  const toggleLocationSelect = () =>
    setShowLocationSelection(!showLocationSelection);

  const updateReduxUser = (obj) => {
    localStorage.setItem("selectedLocation", JSON.stringify(obj));

    dispatch({
      type: SET_REDUX_USER,
      payload: { ...reduxUser, ...obj },
    });
  };

  const toggleTasks = () => {
    dispatch({ type: SET_TASKS_VISIBLE });
  };

  return (
    <div className="df ffc main_nav">
      <div className="df acsa nav_hdr">
        <div className="logo_wrpr ffmr ttuc fs20" style={{ color: "white" }}>
          <CompanyLogoSVG></CompanyLogoSVG>
        </div>

        <div className="df mla">
          <div
            className="df acc sec notifs_wrpr cursor_pointer"
            onClick={toggleTasks}
          >
            <BellSvg fill="#f9f9f9" fillOpacity="0.5" />
            {tasks?.tasks?.filter((task) => task.completed !== true)?.length ? (
              <div className="df acc ffnb fs9 badge">
                {tasks.tasks?.filter((task) => task.completed !== true).length}
              </div>
            ) : undefined}
          </div>

          <div className="df acc sec notes_wrpr cursor_pointer">
            <ChatSvg fill="#f9f9f9" fillOpacity="0.5" />{" "}
            <div className="df acc ffnb fs9 badge">99+</div>
          </div>
        </div>
      </div>

      <div className="df acc quick_actns_wrpr">
        <div className="df acsa cntnt">
          <div className="df acc sec">
            <AddUserSvg></AddUserSvg>
          </div>
          <div className="df acc sec">
            <PaymentSvg></PaymentSvg>
          </div>
          <div className="df acc sec">
            <MoveInSvg></MoveInSvg>
          </div>
          {/* class for selected tab selected */}
          <div className="df acc sec">
            <TransferSvg></TransferSvg>
          </div>
          <div className="df acc sec">
            <CartAddSvg></CartAddSvg>
          </div>
        </div>
      </div>
      <div className="navs_wrpr">
        <h5 className="ffmb fs10 ttuc ttnb sec_hdr">Manage</h5>
        {navs.map((nav, idx) => (
          <div className="df acc nav_wrpr" key={idx}>
            <NavLink
              to={nav.url}
              activeClassName="active"
              className={`df ffnb fs15 ttcaps nav main ${nav.class}`}
            >
              <div className="icon" style={{ height: nav.iconsHeight }}>
                {nav.icon}
              </div>
              {nav.label}
              {nav.subs.length > 0 && (
                <div
                  className="df acc subs_opnr"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    // toggleSubs(idx);
                  }}
                ></div>
              )}
            </NavLink>
            {nav.showSubs && (
              <div className="sub_navs">
                {nav.subs.map((sub, i) => (
                  <NavLink
                    key={i}
                    to={sub.url}
                    activeClassName="active"
                    className={`df ffmm fs14 ttuc nav sub`}
                  >
                    <div className="icon"></div>
                    {sub.label}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        ))}
        <div className="df acc show_more_wrpr">
          <div className="show_more df acsa ffnb fs15 ttcaps ">
            <div className="df acc icon">
              <ShowMoreSvg fill="#F2F2F2" fillOpacity="0.5"></ShowMoreSvg>
            </div>
            Show More
          </div>
        </div>
      </div>

      {/* <div className="users_wrpr">
        <h5 className="ffmb fs10 ttuc ttnb sec_hdr">Users</h5>

        <div className="df acsa user_wrpr">
          <div
            className="avatar"
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          ></div>
          <p className="ffnb fs14">Shmili Breuer</p>
        </div>
        <div className="df acsa user_wrpr">
          <div
            className="avatar"
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          ></div>
          <p className="ffnb fs14">Yanky Green</p>
        </div>
        <div className="df acsa user_wrpr">
          <div
            className="avatar"
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          ></div>
          <p className="ffnb fs14">Leebi Breuer</p>
        </div>
        <div className="df acsa user_wrpr">
          <div
            className="avatar"
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          ></div>
          <p className="ffnb fs14">Firstname Lastname</p>
        </div>

        <div className="df acc show_more_wrpr">
          <div className="show_more df acsa ffnb fs15 ttcaps ">
            <div className="df acc icon">
              <ShowMoreSvg fill="#F2F2F2" fillOpacity="0.5"></ShowMoreSvg>
            </div>
            Show More
          </div>
        </div>
      </div> */}

      <div className="mta user_wrpr">
        <div className="location_wrpr">
          <h5 className="ffmb fs10 ttuc ttnb sec_hdr">Location</h5>

          <div className="wt dropdown_wrpr">
            <div
              className="df acsa ffnsb fs14 selected_location"
              onClick={toggleLocationSelect}
            >
              <MapPin></MapPin>
              <p>
                {reduxUser.selectedLocation && reduxUser.selectedLocation.name}
              </p>
              <div className="mla">
                <Arrow></Arrow>
              </div>
            </div>
            {showLocationSelection && (
              <div className="popup">
                <div className="list_wrpr">
                  {user.locations.map((location) => (
                    <p
                      className={`fs14 ffnr df acsa line ${
                        reduxUser.selectedLocation &&
                        reduxUser.selectedLocation.name === location.name
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        updateReduxUser({
                          selectedLocation: location,
                        });
                        // setShowDropdown(false);
                      }}
                    >
                      {location.name}
                    </p>
                  ))}

                  {/* <p className="fs14 ffnr df acsa line selected">Hello 123</p>
                  <p className="fs14 ffnr df acsa line">Hello 123</p>
                  <p className="fs14 ffnr df acsa line">Hello 123</p> */}
                </div>
                <div className="footer">
                  <div
                    className="df acc ffnr fs14 btn"
                    onClick={() => setShowAddLocation(true)}
                  >
                    <div className="icon">
                      <Plus></Plus>
                    </div>
                    New Location
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {showAddLocation ? (
          <AddLocation close={() => setShowAddLocation(false)} />
        ) : undefined}

        <div className="df acsa ffmr fs14 user_name">
          <div className="text_wrpr">
            <p className="fsnsb fs14 wt">
              {user.firstName} {user.lastName}
            </p>
            <p className="fsnsb fs14 wt_op50">{user.email}</p>
          </div>

          <p
            className="df acc fs12 user_profile_img"
            style={{ backgroundColor: user.color }}
          >
            {user.firstName && user.firstName.substring(0, 1)}
          </p>

          {/* <div className="df acc mla icon"></div> */}
        </div>
      </div>
    </div>
  );
};

export default MainNav;
