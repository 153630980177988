import Portal from "../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import { useState } from "react";

import styles from "./Add.module.scss";
import axios from "axios";
import { useSelector } from "react-redux";

const AddMerchantAccount = (props) => {
  const { user } = useSelector(({ user }) => ({
    user,
  }));

  const [xSoftwareName, setXSoftwareName] = useState(
    props.data?.xSoftwareName || ""
  );
  const [xSoftwareVersion, setXSoftwareVersion] = useState(
    props.data?.xSoftwareVersion || ""
  );
  const [xVersion, setXVersion] = useState(props.data?.xVersion || "");
  const [publicKey, setPublicKey] = useState(props.data?.publicKey || "");
  const [API_URL, setAPIURL] = useState(props.data?.API_URL || "");
  const [xKey, setXKey] = useState(props.data?.xKey || "");

  const submit = () => {
    if (props.data._id) {
    }
    axios.put(
      `/api/account/merchant-account/${props.data._id}`,
      {
        accountId: user.account,
        xSoftwareName,
        xSoftwareVersion,
        xVersion,
        publicKey,
        API_URL,
        xKey,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    axios.post(
      "/api/account/merchant-account",
      {
        accountId: user.account,
        xSoftwareName,
        xSoftwareVersion,
        xVersion,
        publicKey,
        API_URL,
        xKey,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
  };
  return (
    <Portal
      zIndex="101"
      title="Add Merchant Account"
      width="310px"
      height="auto"
      noCustomScrollbar
      close={props.close}
    >
      {/* // xKey: String,
// xSoftwareName: String,
// xSoftwareVersion: String,
// xVersion: String,
// publicKey: String,
// API_URL: String, */}
      <div className={styles.addMerchPopup}>
        <NonFormikInput
          label="API URL"
          value={API_URL}
          id="apiurl"
          type="text"
          change={setAPIURL}
        ></NonFormikInput>

        <NonFormikInput
          label="publicKey"
          value={publicKey}
          id="publicKey"
          type="text"
          change={setPublicKey}
        ></NonFormikInput>

        <NonFormikInput
          label="xKey"
          value={xKey}
          id="xKey"
          type="text"
          change={setXKey}
        ></NonFormikInput>

        <NonFormikInput
          label="xSoftwareName"
          value={xSoftwareName}
          id="xSoftwareName"
          type="text"
          change={setXSoftwareName}
        ></NonFormikInput>

        <NonFormikInput
          label="xSoftwareVersion"
          value={xSoftwareVersion}
          id="xSoftwareVersion"
          type="text"
          change={setXSoftwareVersion}
        ></NonFormikInput>

        <NonFormikInput
          label="xVersion"
          value={xVersion}
          id="xVersion"
          type="text"
          change={setXVersion}
        ></NonFormikInput>

        <div className={styles.ftr}>
          <button onClick={submit}>Save</button>
        </div>
      </div>

      {/* <NonFormikInput
          id="invoice_day"
          label="Invoice day"
          type="text"
          value={invoiceDay}
          change={setInvoiceDay}
        ></NonFormikInput> */}
    </Portal>
  );
};

export default AddMerchantAccount;
