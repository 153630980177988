import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./ReserveUnit.module.scss";

const ReserveUnitFromList = ({ unitId }) => {
  const reduxUser = useSelector(({ reduxUser }) => ({ reduxUser }));
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(false);

  console.log(reduxUser.reduxUser.selectedLocation._id);

  const getCustomers = async () => {
    let token = await localStorage.getItem("x-auth");
    let response = await axios.post(
      "/api/tenants-by-locations",
      [reduxUser.reduxUser.selectedLocation._id],
      {
        headers: { "x-auth": token },
      }
    );
    setCustomers(response.data.data);
  };

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, []);

  const formatPhoneNumber = (number) => {
    //Filter only numbers from the input
    let cleaned = ("" + number).replace(/\D/g, "");

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      let intlCode = match[1] ? "" : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  return (
    <div className={styles.reservePopup}>
      <div className={styles.inputWrpr}>
        <label className={`ffnsb fs14 m-b-8 ${styles.label}`}>
          Existing customer
        </label>
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className={`ffnsb fs14 ${showList ? styles.active : ""}`}
          type="text"
          placeholder="Search customer"
          onFocus={() => setShowList(true)}
        />
        {showList && customers.length > 0 ? (
          <div className={styles.customerList}>
            {customers
              .filter(
                (el) =>
                  el?.fullName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  el.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
                  el.defaultPhoneNumber?.number
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase())
              )
              .map((cust, i) => (
                <div
                  onClick={() => {
                    setCustomer(cust);
                    setSearchValue(cust.fullName);
                    setShowList(false);
                  }}
                  key={cust._id}
                  className={`ffnr fs16 ${styles.customer} ${
                    customer && customer._id === cust._id ? styles.active : ""
                  }`}
                >
                  <div className={styles.customerName}>{cust.fullName}</div>
                  <div className={`fs11 ${styles.customerPhoneEmail}`}>
                    {cust.email} /{" "}
                    {formatPhoneNumber(cust?.defaultPhoneNumber?.number)}
                  </div>
                </div>
              ))}
          </div>
        ) : undefined}
      </div>
      <div className={`df acsa p-b-20 p-t-10 ${styles.ftr}`}>
        <div className={`mla ${styles.btnsWrpr}`}>
          <Link
            to={`/tenants/add/user/?unit=${unitId}`}
            className={`ffnsb fs14 button ${styles.btn} ${styles.text}`}
          >
            New customer
          </Link>
          <Link
            onClick={(e) => {
              if (!customer) {
                e.preventDefault();
                return;
              }
            }}
            to={`/tenants/add/all-units/${customer?._id}/?unit=${unitId}`}
            className={`ffnsb fs14 button ${styles.btn} ${
              !customer ? styles.disabled : ""
            }`}
          >
            Reserve
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReserveUnitFromList;
