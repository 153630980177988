import { cloneDeep } from "lodash";
import {
  GET_TASKS,
  SET_TASKS_VISIBLE,
  SET_TASK_COMPLETE,
  SET_TASK_STARRED,
} from "../actions/types";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload.tasks,
        count: action.payload.count,
      };
    case SET_TASKS_VISIBLE:
      return {
        ...state,
        showTasks: !state.showTasks,
      };
    case SET_TASK_COMPLETE:
      const clonedComplete = cloneDeep(state.tasks);
      let currentTaskIndexComplete = clonedComplete.findIndex(
        (elem) => elem._id === action.payload.data._id
      );
      clonedComplete[currentTaskIndexComplete].completed =
        action.payload.data.completed;
      return {
        ...state,
        tasks: clonedComplete,
      };
    case SET_TASK_STARRED:
      const cloned = cloneDeep(state.tasks);
      let currentTaskIndex = cloned.findIndex(
        (elem) => elem._id === action.payload.data._id
      );
      console.log(currentTaskIndex);
      cloned[currentTaskIndex].starredBy = action.payload.data.starredBy;
      return {
        ...state,
        tasks: cloned,
      };
    default:
      return state;
  }
};

export default reducer;
