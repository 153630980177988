const WarningSVG = (props) => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    viewBox="0 0 359 336"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 275.814C0.015625 291.759 6.3555 307.052 17.633 318.326C28.906 329.603 44.195 335.943 60.145 335.959H298.255C319.704 335.971 339.532 324.561 350.302 306.014C361.072 287.467 361.15 264.588 350.505 245.967L225.125 26.6169C218.918 15.7849 209.086 7.4959 197.359 3.2109C185.632 -1.0703 172.773 -1.0703 161.047 3.2109C149.32 7.4961 139.488 15.7849 133.281 26.6169L7.96112 245.967C2.76192 255.057 0.0197261 265.342 0.00802612 275.815L0 275.814ZM37.07 262.654L162.4 43.3039C164.658 39.3273 168.255 36.2805 172.548 34.7062C176.845 33.132 181.556 33.132 185.853 34.7062C190.146 36.2804 193.744 39.3273 196.001 43.3039L321.331 262.654C326.022 270.873 325.983 280.97 321.229 289.15C316.472 297.334 307.721 302.365 298.256 302.357H60.1464C50.6816 302.365 41.9314 297.334 37.1734 289.15C32.4195 280.97 32.3804 270.873 37.0719 262.654H37.07Z"
      fill={props.fill || "grey"}
    />
    <path
      d="M162.4 117.554H196.002V207.156H162.4V117.554Z"
      fill={props.fill || "grey"}
    />
    <path
      d="M196 251.954C196 261.231 188.477 268.755 179.199 268.755C169.921 268.755 162.398 261.231 162.398 251.954C162.398 242.676 169.921 235.153 179.199 235.153C188.477 235.153 196 242.676 196 251.954Z"
      fill={props.fill || "grey"}
    />
  </svg>
);

export default WarningSVG;
