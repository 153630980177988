import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./LocationSetup.module.scss";
import Portal from "../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import AddressAutoComplete from "../../../tenants/add-tenant/add-user/address-autocomplete/AddressAutoComplete";

const LocationSetup = () => {
  const { user } = useSelector(({ user }) => ({ user }));

  const params = useParams();
  const history = useHistory();

  console.log(params);
  const [location, setLocation] = useState();
  const [profiles, setProfiles] = useState([]);

  const getProfiles = async () => {
    let re = await axios.get(`/api/website-profile/${user.account}/?type=list`);

    setProfiles(re.data.data);
  };

  const getLocation = async () => {
    let re = await axios.get(`/api/location/${params.id}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setLocation(re?.data?.data);
  };

  useEffect(() => {
    getLocation();
    getProfiles();
    // eslint-disable-next-line
  }, []);

  const updateLocationProfile = async (id) => {
    let re = await axios.put(
      `/api/location/${params.id}`,
      { ...location, defaultWebsiteProfile: id },
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );

    setLocation(re?.data?.data);
  };

  useEffect(() => {
    console.log(location);
  }, [location]);

  const updateField = (data) => {
    setLocation({ ...location, ...data });
  };

  return (
    <Portal
      title="Location Setup"
      height="auto"
      width="800px"
      zIndex="1"
      opacity="0.2"
      close={() => {
        history.go(-1);
      }}
    >
      <div className={styles.locationSetup}>
        <div className={`df acca ${styles.inputLine}`}>
          <div className={styles.inputWrapper}>
            <NonFormikInput
              value={location?.name}
              change={(e) => {
                setLocation({ ...location, name: e });
              }}
            />
          </div>
        </div>

        <AddressAutoComplete onUpdate={updateField} />

        {profiles.map((profile) => (
          <div
            onClick={() => {
              updateLocationProfile(profile._id);
            }}
            className={`df acsa ${styles.profileLine} ${
              location?.defaultWebsiteProfile === profile._id
                ? styles.defaultProfile
                : ""
            }`}
          >
            {profile.name}
          </div>
        ))}
        {/* <div className={styles}>
          <p className={`ffmm m-t-20 m-b-10`}>Office hours</p>
        </div> */}
      </div>
    </Portal>
  );
};

export default LocationSetup;
