import axios from "axios";
import styles from "./PastDues.module.scss";
import { useEffect, useState } from "react";

const PastDues = () => {
  // eslint-disable-next-line
  const [tenants, setTenants] = useState([]);

  const getPastDueTenants = async () => {
    // eslint-disable-next-line
    let re = await axios.get("/api/widgets/past-due-tenants", {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });
  };

  useEffect(() => {
    getPastDueTenants();
  }, []);

  return (
    <div
      className={`p-l-16 p-r-16  p-t-20 p-b-20  ${styles.card} ${styles.pastDues}`}
    >
      <h4 className={`fs14 ffmsb m-b-10`}>Past Due Tenants</h4>
    </div>
  );
};

export default PastDues;
