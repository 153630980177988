import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./Units.module.scss";

const ImportUnits = (props) => {
  // const [file, setFile] = useState(null);
  // const [fileConvertError, setFileConvertError] = useState(null);
  // const [fileName, setFileName] = useState(null);
  const [src, setSrc] = useState(null);
  const [unitsList, setUnitsList] = useState([]);

  const params = useParams();
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const getUnits = useCallback(async () => {
    let units = await Axios.get(`/api/import/units/${params.id}`);
    setUnitsList(units.data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line
  }, []);

  const onSelectFile = (e, drop) => {
    let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    let reader = new FileReader();

    // setFile(file);
    // setFileConvertError(null);
    // setFileName(file.name);

    reader.readAsDataURL(file);
    reader.onload = (res) => setSrc(res.target.result);
  };

  const uploadFile = () => {
    const i = src.indexOf("base64,");
    const buffer = Buffer.from(src.slice(i + 7), "base64");
    const file = new File([buffer], "name", {
      type: "application/vnd.ms-excel",
    });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("locationId", reduxUser.selectedLocation._id);
    fd.append("accountId", user.account);
    fd.append("jobId", params.id);

    Axios.post("/api/import/units", fd);
  };

  return (
    <div className={`df ffc ${styles.importUnitsPage}`}>
      <h1>Import Units</h1>
      <div>
        <input
          type="file"
          accept=".csv, .txt"
          id="upld_file"
          onChange={onSelectFile}
        />
      </div>
      <button onClick={uploadFile}>Upload</button>
      <div className={styles.list}>
        {unitsList.map((unit) => (
          <div className={`df acsa ${styles.line}`}>
            Unit ID: {unit.unitId}{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportUnits;
