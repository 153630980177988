import Axios from "axios";
import { GET_USER, SET_REDUX_USER, SET_BLUR } from "./types";

export const getUser = () => async (dispatch) => {
  let token = await localStorage.getItem("x-auth");

  if (!token) {
    // console.log("Hello ");
    return dispatch({
      type: GET_USER,
      payload: null,
    });
  }

  let user = await Axios.get("/api/user", { headers: { "x-auth": token } });

  //   if (user?.data?.status === 401) {
  //     // console.log("Hello ");
  //     return dispatch({
  //       type: GET_USER,
  //       payload: null,
  //     });
  //   }

  if (user.data.email) {
    let selectedLocationLocalStorage = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );

    let selectedLocation;
    if (!user.data.locations.length) selectedLocation = undefined;
    if (
      selectedLocationLocalStorage &&
      selectedLocationLocalStorage.selectedLocation &&
      user.data.locations.length
    ) {
      selectedLocation = selectedLocationLocalStorage.selectedLocation;
    }

    if (
      ((selectedLocationLocalStorage &&
        !selectedLocationLocalStorage.selectedLocation) ||
        !selectedLocationLocalStorage) &&
      user.data.locations.length
    ) {
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify({ selectedLocation: user.data.locations[0] })
      );
      selectedLocation = user.data.locations[0];
    }

    dispatch(updateReduxUser({ selectedLocation }));
    dispatch({
      type: GET_USER,
      payload: user.data,
    });
  }
};

export const loginUser = (vals) => async (dispatch) => {
  let login = await Axios.post("/api/user/login", { ...vals }).catch((e) =>
    console.log(e)
  );

  if (login.data.data.email) {
    let selectedLocationLocalStorage = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    let selectedLocation;
    if (!login.data.data.locations.length) selectedLocation = undefined;
    if (
      selectedLocationLocalStorage &&
      selectedLocationLocalStorage.selectedLocation &&
      login.data.data.locations.length
    ) {
      selectedLocation = selectedLocationLocalStorage.selectedLocation;
    }

    if (
      ((selectedLocationLocalStorage &&
        !selectedLocationLocalStorage.selectedLocation) ||
        !selectedLocationLocalStorage) &&
      login.data.data.locations.length
    ) {
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify({ selectedLocation: login.data.data.locations[0] })
      );
      selectedLocation = login.data.data.locations[0];
    }

    dispatch(updateReduxUser({ selectedLocation }));
    localStorage.setItem("x-auth", login.headers["x-auth"]);
  }

  dispatch({
    type: GET_USER,
    payload: login.data.data,
  });
};

export const updateUser = (vals) => async (dispatch) => {
  let token = await localStorage.getItem("x-auth");

  let user = await Axios.put(
    "/api/user",
    { ...vals },
    { headers: { "x-auth": token } }
  );

  // console.log(user.data);

  dispatch({
    type: GET_USER,
    payload: user.data,
  });
};

export const updateReduxUser = (obj) => (dispatch, getState) => {
  // console.log(obj);

  localStorage.setItem("selectedLocation", JSON.stringify(obj));
  let { reduxUser } = getState();

  dispatch({
    type: SET_REDUX_USER,
    payload: { ...reduxUser, ...obj },
  });
};

export const setBgBlur = (val) => ({
  type: SET_BLUR,
  payload: val,
});
