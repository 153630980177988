import axios from "axios";
import ordinal from "ordinal";
import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import Portal from "../../../../../components/portal/Portal";
import CardsPopup from "../../../tenant-details/payments-popup/cards-popup/CardsPopup";
import * as creditCards from "../../../../../assets/images/credit-cards";
import WarningSVG from "../../../../../assets/js-svgs/warning.svg";

import styles from "./Payment.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { formatFromCurrencyToMoney } from "../../../../../helpers";
import NonFormikSelect from "../../../../../components/inputs/drop-down/non-formik/NonFormikSelect";
import { useSelector } from "react-redux";
import PayCheck from "../../../tenant-details/payments-popup/checks-popup/ChecksPopup";
import CurrencyInput from "../../../../../components/inputs/CurrencyInput";
import ChangeSVG from "../../../../../assets/js-svgs/change.svg";

const autoPayDays = (invoiceDay) => {
  console.log(invoiceDay);
  const limit = JSON.parse(localStorage.getItem("selectedLocation"))
    ?.selectedLocation?.settings?.daysAutopayPastDue;
  console.log(limit);

  let days = [];
  for (let i = 0; i < limit; i++) {
    days.push({
      label: `On the ${ordinal(invoiceDay + i)} of each month`,
      value: i,
    });
  }
  return days;
};

const ReservationPayment = (props) => {
  // const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [selectedPaymentType, setSelectedPaymentType] = useState(undefined);
  const [selectedPayMethod, setSelectedPayMethod] = useState();
  const [selectedPayMethodDetails, setSelectedPayMethodDetails] = useState();
  const [userId, setUserId] = useState();
  const [showCardSelection, setShowCardSelection] = useState(false);
  const [showPayCheck, setShowPayCheck] = useState(false);
  const [address, setAddress] = useState();
  // eslint-disable-next-line
  const [reservation, setReservation] = useState();
  const [submitting, setSubmitting] = useState();
  const [activeReservation, setActiveReservation] = useState(undefined);
  const [autoPay, setAutoPay] = useState(true);
  const [paymentError, setPaymentError] = useState(false);
  const [autoPayDay, setAutoPayDay] = useState({
    label: "On the 1st of each month",
    value: 0,
  });
  const [reservationCost, setReservationCost] = useState(undefined);
  const [cashTendered, setCashTendered] = useState(0);

  const getReservationCost = async () => {
    console.log("activeReservation ::: ", reduxUser);
    let theUnit = activeReservation?.unit;
    let url = `/api/unit-details/?size=${
      theUnit?.unitSize?._id
    }&features=${activeReservation?.unitFeatures
      ?.map((f) => f._id)
      .join(",")}&location=${activeReservation?.unit?.unitLocation}&date=${
      activeReservation?.expectedMoveInDate
    }&websiteProfileId=${
      reduxUser?.selectedLocation?.defaultWebsiteProfile
    }&insuranceId=${activeReservation?.insuranceBracket}`;
    console.log("This is my running task###");
    const cost = await axios.get(url);

    console.log("cost.data.cost", cost.data.cost);

    setReservationCost(cost.data.cost);
  };

  const getPayMethodDetails = async () => {
    let locatSelectedPayMethodDetails = await axios.get(
      `/api/payment-method/${selectedPayMethod}`
    );
    setSelectedPayMethodDetails(locatSelectedPayMethodDetails.data.data);
  };

  // const activeReservation = useSelector(
  //   ({ activeReservation }) => activeReservation
  // );

  const getReservationDetails = async () => {
    let d = await axios.get(
      `/api/reserve-unit/?unitid=${activeReservation?.unit?._id}&moveindate=${activeReservation?.expectedMoveInDate}&reservationid=${params.reservationId}`
    );
    setReservation(d?.data?.data);
  };

  const setAddCardAction = async (methodId) => {
    setSelectedPayMethod(methodId);
  };

  const setActiveReservationAction = async () => {
    let d = await axios.get(
      `/api/portal-reservation/${props.match.params.reservationId}`
    );
    setActiveReservation(d.data?.data || null);
  };

  //   /api/address/:addressid

  const getIcon = () => {
    if (!selectedPayMethodDetails) return;
    console.log(selectedPayMethodDetails);
    let CardLogo = creditCards[selectedPayMethodDetails.xCardType];
    if (!CardLogo) return <p>Check</p>;
    return <CardLogo />;
  };

  const getAddress = async (addressId) => {
    let address = await axios.get(` /api/address/${addressId}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setAddress(address.data.data);
  };

  useEffect(() => {
    if (!activeReservation) {
      return setActiveReservationAction();
    }
    getAddress(activeReservation.address);
    setUserId(activeReservation?.user._id);
    getReservationDetails();
    getReservationCost();
    // eslint-disable-next-line
  }, [activeReservation]);

  useEffect(() => {
    if (!selectedPayMethod) return;
    getPayMethodDetails();
    // eslint-disable-next-line
  }, [selectedPayMethod]);

  const updateReservation = async () => {
    await axios.put(
      `/api/reservation/${params.reservationId}`,
      {
        autoPay,
        autoPayDay: autoPayDay.value,
        autoPayMethod: autoPay ? selectedPayMethod : undefined,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
  };

  const processPayment = async () => {
    setSubmitting(true);

    let saved;
    if (selectedPayMethod === "cash") {
      saved = await axios.post("/api/payment-method", {
        userId: activeReservation?.user?._id,
        type: "cash",
      });
    }
    // return console.log("Payment Method Saved:::", saved?.data?.data?._id);

    let res = await axios.post(
      `/api/reservation-initial-payment/${params.reservationId}`,
      {
        payDetails: {
          payMethod: saved?.data?.data?._id || selectedPayMethod,
          expectedTotal: reservationCost?.totalDueToday,
        },
        reservationDetails: {
          user: activeReservation?.user?._id,
          unit: activeReservation.unit._id,
          locationId: activeReservation.unitLocation,
          webProfileId: activeReservation.user.webProfileId,
          accountId: activeReservation?.websiteProfile?.account,
          expectedMoveInDate: activeReservation?.expectedMoveInDate,
          initialPayment: false,
          address: activeReservation.user.defaultAddress._id,
          phoneNumber: activeReservation?.user?.defaultPhoneNumber?._id,
          isDraft: false,
          autoPay,
        },
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );

    // // *** 202 Uncomment next line
    // return setSubmitting(false);

    if (res.data.status === 500) {
      setSubmitting(false);
      return "paymentError";
    }

    // props.updateReservationsList()

    history.push(`/tenants/add/thank-you/${params.reservationId}`);
  };

  return (
    <Portal
      width="354px"
      height="auto"
      noCustomScrollbar={true}
      zIndex="100"
      opacity="0.45"
      title="Payment Information"
      close={() => history.push(`/tenants/${activeReservation?.user._id}`)}
    >
      <div className={`df ffc ${styles.paymentPopup}`}>
        <p className={`ffnsb fs14 ${styles.priceSum}`}>
          Total Due today{" "}
          <span>
            {formatFromCurrencyToMoney(reservationCost?.totalDueToday)}
          </span>
        </p>

        {paymentError ? (
          <div
            className={`m-t-10 tac p-l-10 p-r-10 p-t-15 p-b-20 ${styles.errorWrpr}`}
          >
            <WarningSVG fill="#fff" height="30" width="30" />
            <p className={`ffnb fs14 m-t-8 ${styles.errorHeading}`}>
              There was an error processing your payment
            </p>
            <p className={`ffnr fs14 m-t-8 ${styles.errorSub}`}>
              Please check your payment and try again
            </p>
          </div>
        ) : undefined}

        <div className={styles.paymentTypesWrpr}>
          {!selectedPayMethod ? (
            <>
              <div
                onClick={() => {
                  setShowCardSelection(true);
                  return setSelectedPaymentType("card");
                }}
                className={`df acsa ${styles.paymentType} ${
                  selectedPaymentType === "card" ? styles.selected : ""
                }`}
              >
                <div
                  className={`${
                    selectedPaymentType === "card" ? styles.selected : ""
                  } ${styles.radioIcon}`}
                ></div>
                <p className={`ffnr fs14 p-l-10`}>Card</p>
              </div>
              {userId && showCardSelection ? (
                <CardsPopup
                  type="cc"
                  setAddCardAction={setAddCardAction}
                  close={() => {
                    setShowCardSelection(false);
                    setSelectedPaymentType(undefined);
                  }}
                  setCardType={setSelectedPayMethod}
                  selected={selectedPayMethod}
                  userId={userId}
                ></CardsPopup>
              ) : undefined}

              <div
                className={`df acsa ${styles.paymentType} ${
                  selectedPayMethod === "cash" ? styles.selected : ""
                }`}
              >
                <div
                  className={`${
                    selectedPayMethod === "cash" ? styles.selected : ""
                  } ${styles.radioIcon}`}
                  onClick={() => {
                    setSelectedPayMethod("cash");
                    setAutoPay(false);
                  }}
                ></div>
                <p
                  onClick={() => {
                    setSelectedPayMethod("cash");
                    setAutoPay(false);
                  }}
                  className={`ffnr fs14 p-l-10`}
                >
                  Cash
                </p>
              </div>
              <div
                className={`df acsa ${styles.paymentType} `}
                onClick={() => setShowPayCheck(true)}
              >
                <div
                  className={`${
                    selectedPaymentType === "check" ? styles.selected : ""
                  } ${styles.radioIcon}`}
                  onClick={() => setSelectedPaymentType("check")}
                ></div>
                <p className={`ffnr fs14 p-l-10`}>Check</p>
              </div>
              {showPayCheck ? (
                <PayCheck
                  close={() => {
                    setShowPayCheck(false);
                    setSelectedPaymentType(undefined);
                  }}
                  userId={userId}
                  setPaymentMethod={selectedPayMethod}
                  setCardType={setSelectedPayMethod}
                />
              ) : undefined}
            </>
          ) : (
            <>
              {selectedPayMethod === "cash" ? (
                <>
                  <div className="df acsa m-t-10 m-b-20">
                    <div className={`cashInputWrpr ${styles.cashInputWrpr}`}>
                      <CurrencyInput
                        height="44px"
                        label="Amount tendered"
                        id="cashTendered"
                        value={cashTendered}
                        onChange={(val) => setCashTendered(val.fullNumber)}
                      />
                    </div>

                    <div className={styles.cashIcon}>
                      <ChangeSVG />
                    </div>

                    <div className={`cashInputWrpr ${styles.cashInputWrpr}`}>
                      <CurrencyInput
                        height="44px"
                        disabled={true}
                        label="Change required"
                        id="cashTendered"
                        value={
                          cashTendered <= reservationCost?.totalDueToday ||
                          reservationCost?.totalDueToday === "0.00" ||
                          reservationCost?.totalDueToday === "0" ||
                          reservationCost?.totalDueToday === 0
                            ? 0
                            : cashTendered - reservationCost?.totalDueToday
                        }
                        // onChange={(val) => setCashTendered(val.fullNumber)}
                      />
                    </div>
                  </div>
                  <button
                    className="secondary small swipe_btn w100"
                    onClick={() => {
                      setSelectedPaymentType(undefined);
                      setSelectedPayMethod(undefined);
                    }}
                  >
                    Change payment method
                  </button>
                </>
              ) : selectedPayMethodDetails ? (
                <>
                  <>
                    <div className={`df acsa ${styles.selectedPayMethodWrpr}`}>
                      <div className={`df acsa ffnr fs14`}>
                        {selectedPayMethodDetails?.xCardType ? (
                          <div className={`${styles.iconWrpr}`}>
                            <div className="icon_wrpr">
                              {selectedPayMethodDetails.xCardType
                                ? getIcon()
                                : undefined}
                            </div>
                          </div>
                        ) : undefined}
                        {selectedPayMethodDetails?.xCardType || (
                          <span className={`ttuc m-r-5`}>
                            {selectedPayMethodDetails?.type}
                          </span>
                        )}{" "}
                        ****
                        {selectedPayMethodDetails.xMaskedCardNumber?.substring(
                          selectedPayMethodDetails.xMaskedCardNumber?.length -
                            4,
                          selectedPayMethodDetails.xMaskedCardNumber?.length
                        ) ||
                          selectedPayMethodDetails.xMaskedAccountNumber?.substring(
                            selectedPayMethodDetails.xMaskedAccountNumber
                              ?.length - 4,
                            selectedPayMethodDetails.xMaskedAccountNumber
                              ?.length
                          )}
                      </div>
                    </div>
                    <button
                      className={`secondary small swipe_btn w100`}
                      onClick={() => setSelectedPayMethod(undefined)}
                    >
                      Change payment method
                    </button>
                  </>
                </>
              ) : undefined}
            </>
          )}
        </div>
        <div className={`p-t-22 ${styles.billingAddressWrpr}`}>
          <h5 className={`ffnsb fs14`}>Autopay</h5>
          <div
            className={`ffnr fs14 p-b-20`}
            style={{ borderBottom: "1px solid #e5e5e5" }}
          >
            <div className={`df acsa`}>
              <input
                id="yes"
                type="radio"
                name="autopay"
                checked={autoPay === true}
                onChange={() => setAutoPay(true)}
              />
              <label htmlFor="yes" className={`m-l-5`}>
                Yes
              </label>
              <input
                checked={autoPay === false}
                onChange={() => setAutoPay(false)}
                className={`m-l-20`}
                id="no"
                type="radio"
                name="autopay"
              />
              <label htmlFor="no" className={`m-l-5`}>
                No
              </label>
            </div>
            <NonFormikSelect
              onChange={setAutoPayDay}
              value={autoPayDay}
              options={autoPayDays(activeReservation?.invoiceDay)}
              size="medium"
              menuPortalTarget={document.body}
              label="Select autopay day"
            ></NonFormikSelect>
          </div>
        </div>
        {address ? (
          <div className={`p-t-22 ${styles.billingAddressWrpr}`}>
            <h5 className={`ffnsb fs14`}>Billing Information</h5>
            <p className={`ffnr fs14`}>{activeReservation?.user?.fullName}</p>
            <p className={`ffnr fs14`}>{address.deliveryLine1}</p>
            <p className={`ffnr fs14`}>{address.lastLine}</p>
          </div>
        ) : undefined}

        <button
          disabled={!selectedPayMethod || submitting}
          className={`ffnb fs14 ttuc sbmt_btn primary m-t-10 ${
            paymentError ? "error" : ""
          }`}
          onClick={async () => {
            await updateReservation();
            let payment = await processPayment();
            // *** 202 Uncomment next line
            // return;
            if (payment === "paymentError") return setPaymentError(true);
            history.push(
              `/tenants/add/documents/${params.reservationId}/${selectedPayMethod}`
            );
          }}
          //   disabled={
          //     "!gateAccessCode || !expectedMoveInDate || !invoiceDay || saving"
          //   }
        >
          Pay and Sign contract
        </button>

        {/* <button
          disabled={!selectedPayMethod || submitting}
          className={` ffnb fs14 ttuc sbmt_btn secondary m-t-10`}
          onClick={processPayment}
        >
          Process Payment
        </button> */}
      </div>
    </Portal>
  );
};

export default ReservationPayment;
