import React from "react";

const ThreeDotsIcon = (props) => {
	return (
		<svg
			width="4"
			height="17"
			viewBox="0 0 4 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.2">
				<path
					d="M1.99858 14.1667C2.85211 14.1667 3.54403 14.8009 3.54403 15.5833C3.54403 16.3657 2.85211 17 1.99858 17C1.14505 17 0.453125 16.3657 0.453125 15.5833C0.453125 14.8009 1.14505 14.1667 1.99858 14.1667Z"
					fill="#1F2041"
				/>
				<path
					d="M1.99858 7.08333C2.85211 7.08333 3.54403 7.7176 3.54403 8.5C3.54403 9.2824 2.85211 9.91667 1.99858 9.91667C1.14505 9.91667 0.453125 9.2824 0.453125 8.5C0.453125 7.7176 1.14505 7.08333 1.99858 7.08333Z"
					fill="#1F2041"
				/>
				<path
					d="M1.99858 0C2.85211 0 3.54403 0.634264 3.54403 1.41667C3.54403 2.19907 2.85211 2.83333 1.99858 2.83333C1.14505 2.83333 0.453125 2.19907 0.453125 1.41667C0.453125 0.634264 1.14505 0 1.99858 0Z"
					fill="#1F2041"
				/>
			</g>
		</svg>
	);
};

export default ThreeDotsIcon;
