import { useEffect, useState } from "react";
import axios from "axios";
// import update from "immutability-helper";

import styles from "./UnitTypes.module.scss";
// import { useSelector } from "react-redux";
// import { formatFromCurrencyToMoney } from "../../../../helpers";
// import AddUnitSizePopup from "./add-popup/AddPopup";

const UnitTypes = () => {
  // const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  //   const [showAdd, setShowAdd] = useState(false);
  const [types, setTypes] = useState(undefined);
  //   const [editUnitSize, setEditUnitSize] = useState(undefined);

  const getUnitTypes = async () => {
    console.log("Fetching unit sizes");

    let localTypes = await axios.get(`/api/unit-types`);

    setTypes(localTypes?.data?.data);
  };

  useEffect(() => {
    getUnitTypes();
    // eslint-disable-next-line
  }, []);

  //   const saved = (size) => {
  //     const updated = update(sizes, { $push: [size] });
  //     setSizes(updated);
  //   };

  //   const updated = (size) => {
  //     let index = sizes.findIndex((elem) => elem._id === size._id);

  //     console.log(index, size);
  //     const updated = update(sizes, { [index]: { $set: size } });
  //     setSizes(updated);
  //   };

  //   const deleteUnitSize = async (id) => {
  //     await axios.delete(`/api/unit-size/${id}`);

  //     let index = sizes.findIndex((elem) => elem.id === id);

  //     let updated = update(sizes, { $splice: [[index, 1]] });

  //     setSizes(updated);
  //   };

  return (
    <div className={`m-t-40 ${styles.unitSettings}`}>
      <div className={styles.card}>
        <div className={`df acsa ${styles.cardHdr}`}>
          <div>
            <p className={`ffnsb fs12 ttuc`}>Unit sizes</p>
            <p className={`ffnr fs12 m-t-10`}>Manage unit sizes</p>
          </div>
          <button
            className={`mla ffnr s14 ${styles.addBtn}`}
            // onClick={() => setShowAdd(true)}
          >
            Add unit size
          </button>

          {/* {showAdd ? (
            <AddUnitSizePopup
              close={() => setShowAdd(false)}
              saved={saved}
              updated={updated}
              size={editUnitSize}
            />
          ) : undefined} */}
          {/* {showAddRule ? (
            <Portal
              title="Create a new delinquency rule"
              width="354px"
              height="auto"
              zIndex="100"
              opacity="0.45"
              close={() => {
                setShowAddRule(false);
                setEditRule(undefined);
              }}
              customClass={"delinquency-rule-portal"}
            >
              <DelinquencyRule
                close={() => {
                  setShowAddRule(false);
                  setEditRule(undefined);
                }}
                delinquencyRules={delinquencyRules}
                refreshRules={getDelinquencyRules}
                rule={editRule}
              />
            </Portal>
          ) : undefined} */}
        </div>

        {types?.map((size) => (
          <div
            className={`ffnr fs14 df acsa ${styles.sizeLine}`}
            key={size._id}
            onClick={() => {
              //   setEditUnitSize(size);
              //   setShowAdd(true);
            }}
          >
            <div className={`m-r-30 pw20 ${styles.sizeSec}`}>
              <img src={size.imageUrl} alt="" />
            </div>
            <div className={`m-r-30 pw20 ${styles.sizeSec}`}>
              <p className={`fs12 ${styles.label}`}>Label</p>
              <p>{size.label}</p>
            </div>

            <p
              className={`mla`}
              onClick={(e) => {
                e.stopPropagation();
                // deleteUnitSize(size._id);
              }}
            >
              delete
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnitTypes;
