import { useEffect, useState } from "react";
import axios from "axios";

import "./FixPopup.scss";

const {
  NonFormikInput,
} = require("../../../../../../components/inputs/NonFormikInput");
const {
  default: Portal,
} = require("../../../../../../components/portal/Portal");

const FixPopup = ({ fixCustomer, close, updateAddress }) => {
  const [search, setSearch] = useState("");
  const [line1, setLine1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmedAddress, setConfirmedAddress] = useState(undefined);

  const getAutocomplete = async () => {
    let res = await axios.get(`/api/smarty-autocomplete/${search}`);
    console.log(res.data);
    setSuggestionList(res.data.suggestions);
  };

  useEffect(() => {
    if (search.length === 0) return setSuggestionList([]);
    if (search?.length > 5) getAutocomplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const validateAddress = async () => {
    console.log("validateAddress");

    let validatedAddress = await axios.get(
      `/api/smarty-validate/?q=${encodeURIComponent(line1)}${
        address2 ? ` ${encodeURIComponent(address2)}` : ""
      } ${encodeURIComponent(city)} ${encodeURIComponent(
        state
      )} ${encodeURIComponent(zip)}`
    );

    // if (validatedAddress.data.delivery_line_1) {
    //   console.log("I have a validated address");
    setShowConfirm(true);
    setConfirmedAddress(validatedAddress.data);
    // } else {
    //   console.log("I don't have a validated address");
    // }
  };

  const saveAddress = async (missing) => {
    if (missing) {
      console.log("missing", missing);
      let updated = await axios.put(
        `/api/address/${fixCustomer.defaultAddress._id}`,
        {
          missingAddress: true,
        }
      );

      updateAddress(updated.data);

      close();
      return;
    }

    let data = {};

    if (confirmedAddress.delivery_line_1) {
      data = {
        ...confirmedAddress,
        deliveryLine1: confirmedAddress.delivery_line_1,
        lastLine: confirmedAddress.last_line,
      };
    } else {
      data = {
        deliveryLine1: `${line1} ${address2}`,
        lastLine: `${city}, ${state} ${zip}`,
      };
    }

    // {line1} {address2}
    // {city} {state} {zip}
    let updated = await axios.put(
      `/api/address/${fixCustomer.defaultAddress._id}`,
      {
        ...data,
      }
    );

    updateAddress(updated.data);

    close();
  };

  return (
    <Portal
      opacity="0.45"
      zIndex="99"
      height="auto"
      title={`Fix address for ${fixCustomer.fullName}`}
      close={close}
    >
      <div className={"popupContent"}>
        <form>
          <p className="ffnr fs14 italicText">
            Current address:{" "}
            <span className="errorText">
              {fixCustomer.defaultAddress.importedString}
            </span>
          </p>

          <div className="addressAutocompleteWrpr">
            <NonFormikInput
              label="Search ad&zwj;dr&zwj;ess"
              value={search}
              id="search"
              type="text"
              change={setSearch}
              autoComplete="off"
            ></NonFormikInput>
            {suggestionList.length ? (
              <div className="autocompleteSuggestions">
                {suggestionList.map((suggestion, idx) => {
                  return (
                    <div
                      className="ffnr fs14 suggestion"
                      onClick={() => {
                        setSearch("");
                        setLine1(suggestion.street_line);
                        setAddress2(suggestion.secondary);
                        setCity(suggestion.city);
                        setState(suggestion.state);
                        setZip(suggestion.zipcode);
                      }}
                    >
                      <p className="m-b-4 ">
                        {suggestion.street_line}
                        {suggestion.secondary ? ` ${suggestion.secondary}` : ""}
                      </p>
                      <p className="fs12 sub">
                        {`${suggestion.city}`}
                        {` ${suggestion.state}`}
                        {` ${suggestion.zipcode}`}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : undefined}
          </div>

          <NonFormikInput
            label="Address"
            value={line1}
            id="address1"
            type="text"
            change={setLine1}
          ></NonFormikInput>

          <NonFormikInput
            label="Apt/Suite"
            value={address2}
            id="address1"
            type="text"
            change={setAddress2}
          ></NonFormikInput>
          <NonFormikInput
            label="City"
            value={city}
            id="city"
            type="text"
            change={setCity}
          ></NonFormikInput>
          <NonFormikInput
            label="State"
            value={state}
            id="state"
            type="text"
            change={setState}
          ></NonFormikInput>
          <NonFormikInput
            label="Zip"
            value={zip}
            id="zip"
            type="text"
            change={setZip}
          ></NonFormikInput>
        </form>

        <button
          className="m-t-15 ffnb fs14 ttuc primary w100"
          onClick={validateAddress}
        >
          Validate address
        </button>
        <button
          onClick={() => saveAddress(true)}
          className="m-t-10 ffnb fs14 ttuc error w100"
        >
          Missing address
        </button>

        {showConfirm ? (
          <Portal
            zIndex="109"
            title="Confirm address"
            opacity="0.4"
            height="auto"
            close={() => setShowConfirm(false)}
          >
            <div className="confirmedPopupContent">
              {confirmedAddress === "error getting place" ? (
                <div className="p-b-22 p-t-22">
                  <p className="ffnr fs14 m-b-15">
                    We were unable to validate your address
                  </p>

                  <p className="ffnr fs18 m-b-10">
                    {line1} {address2}
                  </p>
                  <p className="ffnr fs18">
                    {city} {state} {zip}
                  </p>
                </div>
              ) : (
                <div className="p-b-22 p-t-22">
                  <p className="ffnr fs18 m-b-10">
                    {confirmedAddress?.delivery_line_1}
                  </p>
                  <p className="ffnr fs18">{confirmedAddress?.last_line}</p>
                </div>
              )}
              <button
                className="m-t-15 ffnb fs14 ttuc primary w100"
                onClick={() => saveAddress(false)}
              >
                Confirm
              </button>
              <button
                onClick={() => setShowConfirm(false)}
                className="m-t-15 ffnb fs14 ttuc secondary w100"
              >
                Cancel
              </button>
            </div>
          </Portal>
        ) : undefined}
      </div>
    </Portal>
  );
};

export default FixPopup;
