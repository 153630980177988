import Axios from "axios";
import React, { useEffect, useState } from "react";
import Cleave from "cleave.js/react";

import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";
import Portal from "../../../../../components/portal/Portal";
import "./AddCard.scss";
// import { useSelector } from "react-redux";

let style = {
  height: "35px",
  border: "1px solid rgba(31, 32, 65, 0.25)",
  "border-radius": "4px",
  transition: "all 0.2s",
  padding: "0px 12px",
  width: "100%",
  outline: "none",
  "box-sizing": "border-box",
  "font-size": "14px",
  "font-family": "roboto",
};

const AddCard = (props) => {
  // const { activeReservation } = useSelector(({ activeReservation }) => ({
  //   activeReservation,
  // }));

  //   const history = useHistory();

  // const [loading, setLoading] = useState(true);
  const [nameOnCard, setNameOnCard] = useState("");
  // const [cardNumber, setCardNumber] = useState("");
  const [cardToken, setCardToken] = useState();
  const [cardEXP, setCardEXP] = useState("");
  // const [cardCVV, setCardCVV] = useState("");
  const [CVVToken, setCVVToken] = useState();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const setUpIfields = async () => {
    window.addIfieldKeyPressCallback((data) => {
      setErrorMessage(undefined);
      window.setIfieldStyle("card-number", style);
      window.setIfieldStyle("cvv", style);
      if (data.cardNumberIsValid && data.cvvIsValid) getCCTokens();
    });

    window.setAccount(
      "ifields_ShmilicomDev_Test2_811847608264488db4",
      "Shmili.com",
      "0.1.2"
    );

    await window.setIfieldStyle("card-number", style);
    await window.setIfieldStyle("cvv", style);
    await window.enableAutoFormatting(" ");
    // setTimeout(() => {
    // 	setLoading(false);
    // }, 1000);
  };

  useEffect(() => {
    setUpIfields();
    // eslint-disable-next-line
  }, []);

  const getCCTokens = () => {
    window.getTokens(() => {
      let cardToken = document.querySelector(
        "[data-ifields-id='card-number-token']"
      ).value;
      let cvvToken = document.querySelector(
        "[data-ifields-id='cvv-token']"
      ).value;
      // 	// window.clearIfield("cvv");
      // 	// this.props.updateCCDetails(cardToken, cvvToken);
      setCardToken(cardToken);
      setCVVToken(cvvToken);
    });
  };

  const submitCard = async () => {
    console.log(cardEXP);
    let EXPArray = cardEXP.split("/");

    let expMonth = EXPArray[0];
    let expYear = EXPArray[1];

    console.log(EXPArray);

    let re = await Axios.post("/api/payment-method", {
      userId: props.userId,
      cc_number: cardToken,
      CVVToken,
      nameOnCard,
      exp_m: expMonth,
      exp_y: expYear,
    }).catch((e) => e);

    if (re?.response?.status === 500) {
      console.log("Error adding card");
      // setError(true)
      setErrorMessage(re.response.data);

      await window.setIfieldStyle("card-number", {
        ...style,
        border: "1px solid #ec407a",
      });
      await window.setIfieldStyle("cvv", {
        ...style,
        border: "1px solid #ec407a",
      });
    } else {
      props.getCards();
      props.close();
    }
  };

  return (
    <Portal
      zIndex="107"
      width="315px"
      height="auto"
      title="Card Info"
      noCustomScrollbar
      close={() => props.close(false)}
      opacity="0.25"
    >
      <div className="add_card">
        <div className="inner_cntnt_wrpr">
          <div className="ffnsb fs10 ttuc ttl">Saved Cards</div>

          <div className="form_wrpr">
            <div className="df acsa line">
              <div className="field_wrpr">
                <NonFormikInput
                  size="small"
                  label="Name on card"
                  // field={nameOnCard}
                  change={(val) => setNameOnCard(val)}
                  value={nameOnCard}
                  id="noc"
                ></NonFormikInput>
              </div>
            </div>
            <div className="df acsa line">
              <div className="field_wrpr">
                {/* <NonFormikInput
									size="small"
									label="Card number"
									// field={nameOnCard}
									change={(val) => setCardNumber(val)}
									value={cardNumber}
									id="cn"
								></NonFormikInput> */}

                {/* {!loading ? ( */}
                <iframe
                  id="hhhhhh"
                  title="cc_num"
                  width="100%"
                  height="35px"
                  data-ifields-id="card-number"
                  data-ifields-placeholder="Card Number"
                  src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
                ></iframe>

                <input
                  data-ifields-id="card-number-token"
                  name="xCardNum"
                  type="hidden"
                ></input>
                {/* ) : undefined} */}
              </div>
            </div>
            <div className="df acsa line multi">
              <div className="field_wrpr">
                {/* <NonFormikInput
                  size="small"
                  label="Exp (mm/yy)"
                  // field={nameOnCard}
                  change={(val) => setCardEXP(val)}
                  value={cardEXP}
                  id="exp"
                ></NonFormikInput> */}

                <div className="__inpt_wrpr small">
                  <Cleave
                    style={{
                      fontSize: "14px",
                      fontFamily: "nunito-semi-bold",
                      borderColor: errorMessage ? "#ec407a" : "",
                    }}
                    options={{ date: true, datePattern: ["m", "y"] }}
                    value={cardEXP}
                    onChange={(e) => {
                      setErrorMessage(undefined);
                      window.setIfieldStyle("card-number", style);
                      window.setIfieldStyle("cvv", style);
                      return setCardEXP(e.target.value);
                    }}
                    placeholder="Exp (MM/YY)"
                  />
                  <label className=" __inpt_lbl move ffnsb fs11">
                    Exp (MM/YY)
                  </label>
                </div>
              </div>
              <div className="field_wrpr">
                {/* <NonFormikInput
									size="small"
									label="CVV"
									// field={nameOnCard}
									change={(val) => setCardCVV(val)}
									value={cardCVV}
									id="cvv"
								></NonFormikInput> */}
                <iframe
                  title="cc_cvv"
                  width="100%"
                  height="35px"
                  data-ifields-id="cvv"
                  data-ifields-placeholder="CVV"
                  src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
                ></iframe>{" "}
                <input
                  data-ifields-id="cvv-token"
                  name="xCVV"
                  type="hidden"
                ></input>
              </div>
            </div>
          </div>

          <div className="sbmt_wrpr">
            <button
              className="button secondary small w100"
              onClick={submitCard}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default AddCard;
