const PrintSVG = (props) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5H16V0H4V5H3C1.34 5 0 6.34 0 8V14H4V18H16V14H20V8C20 6.34 18.66 5 17 5ZM6 2H14V5H6V2ZM14 16V14V12H6V16H14ZM16 12V10H4V12H2V8C2 7.45 2.45 7 3 7H17C17.55 7 18 7.45 18 8V12H16ZM15 8.5C15 7.94772 15.4477 7.5 16 7.5C16.5523 7.5 17 7.94772 17 8.5C17 9.05228 16.5523 9.5 16 9.5C15.4477 9.5 15 9.05228 15 8.5Z"
      fill="#1F2041"
      fillOpacity="0.8"
    />
  </svg>
);

export default PrintSVG;
