import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
// import ordinal from "ordinal";
import plur from "plur";
import Portal from "../../../../../../components/portal/Portal";
import NonFormikSelect from "../../../../../../components/inputs/drop-down/non-formik/NonFormikSelect";

import styles from "./AutoPay.module.scss";
// import NewDropdown from "../../../../../../components/inputs/drop-down/new-dropdow/NewDropdown";
import AddCard from "../../../payments-popup/add-card/AddCard";

let options = [
  -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
  17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
].map((day) => ({
  label: `${
    day < 0
      ? `${Math.abs(day)} ${plur("day", day)}  before invoice is due`
      : day === 0
      ? "On invoice due date"
      : `${day} ${plur("day", day)} after invoice is due`
  }`,
  value: day,
}));

const AutoPay = ({
  close,
  tenantId,
  day = 0,
  payMethod,
  rentalId,
  updateRental,
}) => {
  const token = localStorage.getItem("x-auth");
  const [step, setStep] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentId, setSelectedPaymentId] = useState(payMethod);
  const [selectedDay, sseSelectedDay] = useState(0);
  const [showAdd, setShowAdd] = useState(false);

  const getPayMethods = async () => {
    await console.log("Getting payment methods");

    let re = await axios.get(`/api/payment-methods/${tenantId}/cc`);

    setPaymentMethods(re?.data?.data);
  };

  const setupSelectedDay = () => {
    let index = options.findIndex((el) => el.value === day);

    sseSelectedDay(options[index]);
  };

  useEffect(() => {
    getPayMethods();
    setupSelectedDay();
    // eslint-disable-next-line
  }, []);

  const save = async () => {
    let response = await axios.put(
      `/api/reservation-auto-pay/${rentalId}`,
      {
        autoPay: true,
        autoPayDay: selectedDay?.value,
        autoPayMethod: selectedPaymentId,
        autoPaymentFrequency: "monthly",
      },
      { headers: { "x-auth": token } }
    );

    if (response.data.message === "ok") {
      updateRental(response.data.data);
      close();
    }
  };
  return (
    <Portal
      close={close}
      zIndex="2"
      opacity="0.2"
      height="auto"
      width="auto"
      noCustomScrollbar
      title={step === 1 ? "Select autopay method" : "Select autopay day"}
    >
      <div className={styles.autoPay}>
        {step === 1 ? (
          <div className={`${styles.listWrpr}`}>
            {paymentMethods.map((pm) => (
              <div className={`df acsa ${styles.line}`}>
                <div
                  className={`df acsa __radio_wrpr ${
                    selectedPaymentId === pm._id ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="pm"
                    id={`id_${pm._id}`}
                    className={`dn`}
                    value={pm._id}
                    onChange={(e) => setSelectedPaymentId(e.target.value)}
                  />

                  <label className={`__inpt`} htmlFor={`id_${pm._id}`}></label>
                  <label htmlFor={`id_${pm._id}`} className={`ffnr fs14 __lbl`}>
                    {pm.xCardType} ****{" "}
                    {pm.xMaskedCardNumber.substring(
                      pm.xMaskedCardNumber.length - 4,
                      pm.xMaskedCardNumber.length
                    )}
                  </label>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {/* <NewDropdown/> */}
            <NonFormikSelect
              displayBlock={true}
              menuPortalTarget={document.body}
              sub
              label="Select rule"
              value={selectedDay}
              options={options}
              onChange={(e) => sseSelectedDay(e)}
            />
          </div>
        )}

        <div className={`df ffc m-t-15 ${styles.footer}`}>
          {step === 1 ? (
            <>
              <button
                className={`primary process_btn ttuc`}
                onClick={() => setStep(2)}
              >
                Set day
              </button>
              <button
                className={`m-t-10 secondary process_btn ttuc`}
                onClick={() => setShowAdd(true)}
              >
                Add card
              </button>
            </>
          ) : (
            <>
              <button className={`primary process_btn ttuc`} onClick={save}>
                Save
              </button>
              <button
                className={`m-t-10 secondary process_btn ttuc`}
                onClick={() => setStep(1)}
              >
                Back
              </button>
            </>
          )}
        </div>
      </div>

      {showAdd ? (
        <AddCard
          userId={tenantId}
          close={() => setShowAdd(false)}
          getCards={getPayMethods}
        />
      ) : undefined}
    </Portal>
  );
};

export default AutoPay;
