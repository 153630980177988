import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import adminStyles from "../AdminSettings.module.scss";
import AddBracket from "./add-bracket/AddBracket";
import AddPopup from "./add-popup/AddPopup";
import Brackets from "./Brackets";

const Insurance = (props) => {
  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [providers, setProviders] = useState([]);
  const [addToId, setAddToId] = useState(undefined);

  const getProviders = async () => {
    let list = await axios.get(
      `/api/insurance-company/${reduxUser.selectedLocation._id}`
    );
    setProviders(list.data.data);
  };

  useEffect(() => {
    getProviders();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`m-b-30 ${adminStyles.card}`}>
      <div className={`df acsa ${adminStyles.cardHdr}`}>
        <div>
          <p className={`ffnsb fs12 ttuc`}>Insurance</p>
          <p className={`ffnr fs12 m-t-10`}>Setup your tenant insurance</p>
        </div>
        <button
          className={`mla ffnr s14 ${adminStyles.addBtn}`}
          onClick={() => setShowAddPopup(true)}
        >
          Add provider
        </button>
        {showAddPopup ? (
          <AddPopup close={() => setShowAddPopup(false)} />
        ) : undefined}
      </div>
      {providers.map((provider) => (
        <div key={provider._id}>
          <p>{provider.name}</p>
          <Brackets companyId={provider._id} />
          <p onClick={() => setAddToId(provider._id)}>Add bracket</p>
        </div>
      ))}

      {addToId ? (
        <AddBracket companyId={addToId} close={() => setAddToId(undefined)} />
      ) : undefined}
    </div>
  );
};

export default Insurance;
