import { GET_TENANTS, ADDED_USER, ERROR_ADDING_USER } from "../actions/types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case GET_TENANTS:
      return action.payload;
    case ADDED_USER:
      return { ...state, lastAdded: action.payload };
    case ERROR_ADDING_USER:
      return { ...state, lastAdded: "failed" };
    default:
      return state;
  }
};

export default reducer;
