import React from "react";

const CommunicationsSvg = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V2.83C7.03 2.83 12.73 8.53 12.73 15.56H15.56C15.56 6.97 8.59 0 0 0ZM2.18 11.2C0.976019 11.2 0 12.176 0 13.38C0 14.584 0.976019 15.56 2.18 15.56C3.38398 15.56 4.36 14.584 4.36 13.38C4.36 12.176 3.38398 11.2 2.18 11.2ZM0 8.49V5.66C5.47 5.66 9.9 10.09 9.9 15.56H7.07C7.07 11.66 3.9 8.49 0 8.49Z"
      fill={props.fill || ""}
      fillOpacity={props.fillOpacity || ""}
    />
  </svg>
);

export default CommunicationsSvg;
