import React, { useEffect, useState } from "react";
import { ImageUploadSVG } from "../../../assets/js-svgs/image-upload.svg";
import { TrashSVG } from "../../../assets/js-svgs/trash.svg";
import { EditSVG } from "../../../assets/js-svgs/edit.svg";
import ImageUploadPopup from "../ImageUploadPopup";

import styles from "./UploadThumb.module.scss";
import axios from "axios";

const UploadThumb = (props) => {
  const [showPopup, setShowPopup] = useState(props.openDefault);
  const [imageBlob, setImageBlob] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [imageFileName, setImageFileName] = useState("");

  useEffect(() => {
    if (!base64Image) return;

    const i = base64Image.indexOf("base64,");
    const buffer = Buffer.from(base64Image.slice(i + 7), "base64");
    const file = new File([buffer], imageFileName);

    const fd = new FormData();
    fd.append("file", file);

    axios.post("/api/file/upload", fd).then((res) => {
      console.log(res.data.data.url);
      props.setImages({
        base64Image,
        imgUrl: res.data.data.url,
        idx: props.idx,
        id: props.kkk,
      });
    });

    // eslint-disable-next-line
  }, [base64Image]);

  useEffect(() => {
    setImageBlob(props.imgUrl);
  }, [props.imgUrl]);

  const setImageBlobAction = (d) => {
    setImageBlob(d);
  };

  return (
    <React.Fragment key={props.kkk}>
      {/* <div
        className={`df acc ${styles.thumbWrpr}`}
        onClick={() => setShowPopup(true)}
      >
        <ImageUploadSVG height="24" width="24" />
      </div> */}

      <div
        className={`df acc ${styles.thumbWrapper} ${
          imageBlob ? styles.hasImg : ""
        }`}
        onClick={() => setShowPopup(true)}
      >
        {/* {props.imgUrl ? (
          <p
            className={`df acc ffsr ${styles.delete}`}
            onClick={(e) => {
              e.stopPropagation();
              props.removeImage(props.idx);
            }}
          >
            x
          </p>
        ) : undefined} */}
        {props.status === "pending" ? (
          <div className={styles.loader} />
        ) : props.status === "done" ? (
          <div className={styles.done} />
        ) : undefined}
        {imageBlob ? <img src={imageBlob} alt="img" /> : <ImageUploadSVG />}
        <div className={styles.overlay}>
          <div className={`df acc ${styles.overlayBtns}`}>
            <div className={`df acc ${styles.overlayBtn}`}>
              <EditSVG fill="#30be76" height="18" width="18" />
            </div>
            <div
              className={`df acc ${styles.overlayBtn}`}
              onClick={(e) => {
                e.stopPropagation();
                props.removeImage(props.idx);
              }}
            >
              <TrashSVG fill="#ec407a" />
            </div>
          </div>
        </div>
      </div>
      {showPopup ? (
        <ImageUploadPopup
          close={setShowPopup}
          setBase64Image={setBase64Image}
          setImageBlob={setImageBlobAction}
          imageUrl={props.imgUrl}
          setImageFileName={setImageFileName}
        />
      ) : undefined}
    </React.Fragment>
  );
};

export default UploadThumb;
