const SettingsNavSVG = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75031 12.0397L3.255 13.2356C3.255 13.2356 2.08094 15.8834 2.18344 15.9856L3.73844 17.5072C3.84312 17.6103 6.49562 16.4012 6.49562 16.4012L7.72 16.8966C7.72 16.8966 8.79969 19.5809 8.94937 19.5809H11.1441C11.2959 19.5809 12.2966 16.8881 12.2966 16.8881L13.5216 16.3928C13.5216 16.3928 16.2222 17.5431 16.3269 17.4425L17.8825 15.9222C17.99 15.8162 16.7513 13.2203 16.7513 13.2203L17.255 12.0225C17.255 12.0225 20 10.9644 20 10.8194V8.66937C20.0003 8.52187 17.2513 7.54469 17.2513 7.54469L16.7459 6.34437C16.7459 6.34437 17.9216 3.695 17.8166 3.59281L16.2622 2.075C16.1538 1.97 13.5034 3.17969 13.5034 3.17969L12.2803 2.68562C12.2803 2.68562 11.2003 0 11.0516 0H8.8575C8.705 0 7.70437 2.69219 7.70437 2.69219L6.48375 3.18719C6.48375 3.18719 3.77781 2.03687 3.67219 2.13937L2.12062 3.66C2.01219 3.76375 3.24812 6.36094 3.24812 6.36094L2.74406 7.55875C2.74406 7.55875 1.06482e-07 8.61562 1.06482e-07 8.75844V10.9084C-0.000624894 11.06 2.75031 12.0397 2.75031 12.0397ZM9.99969 6.34062C11.9447 6.34156 13.5263 7.88781 13.5263 9.78937C13.5263 11.6919 11.9444 13.2419 9.99969 13.2419C8.05656 13.2419 6.4725 11.6919 6.4725 9.78937C6.47281 7.88781 8.05656 6.34062 9.99969 6.34062Z"
      fill={props.fill || ""}
    />
  </svg>
);

export default SettingsNavSVG;
