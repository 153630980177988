import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import Portal from "../../../../components/portal/Portal";

const AddPage = ({ close, order, added }) => {
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [title, setTitle] = useState("");

  //   /api/flow-page

  const savePage = async () => {
    let res = await axios.post("/api/flow-page", {
      title,
      order,
      accountId: user.account,
      locationId: reduxUser.selectedLocation._id,
    });
    added(res.data);
  };

  return (
    <Portal
      height="auto"
      width="340px"
      title="Add flow page"
      zIndex="3"
      opacity="0.2"
      close={close}
    >
      <div className={`p-l-20 p-r-20 p-b-20`}>
        <NonFormikInput label="Page title" value={title} change={setTitle} />{" "}
        <button
          onClick={savePage}
          className={`w100 ttuc ffnb fs14 rwt btn primary m-t-30`}
        >
          Add Flow Page
        </button>
      </div>
    </Portal>
  );
};

export default AddPage;
