import { useState } from "react";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import axios from "axios";
import { useSelector } from "react-redux";

const AddUserPopup = () => {
  const { user } = useSelector(({ user }) => ({ user }));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const save = (async) => {
    axios.post("/api/user", {
      email,
      firstName,
      lastName,
      type: "accountAdmin",
      account: user.account,
      locations: user.locations.map((lo) => lo._id),
    });
  };

  return (
    <div className="popupContent">
      <div className="inputWrpr">
        <NonFormikInput
          label="First name"
          value={firstName}
          change={(e) => {
            setFirstName(e);
          }}
        />
      </div>
      <div className="inputWrpr">
        <NonFormikInput
          label="Last name"
          value={lastName}
          change={(e) => {
            setLastName(e);
          }}
        />
      </div>
      <div className="inputWrpr">
        <NonFormikInput
          label="Email"
          value={email}
          change={(e) => {
            setEmail(e);
          }}
        />
      </div>

      <button
        onClick={save}
        className="ffmb fs14 w100 ttuc __btn primary edgy m-t-15"
      >
        Save user
      </button>
    </div>
  );
};

export default AddUserPopup;
