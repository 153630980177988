import { useHistory, useParams } from "react-router-dom";
import Portal from "../../../../components/portal/Portal";
import { CheckInCircle } from "../../../../assets/js-svgs/check-in-circle.svg";

import styles from "./ThankYou.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";

const ThankYou = (props) => {
  const params = useParams();
  const history = useHistory();

  const [reservation, setReservation] = useState();

  const getReservation = async () => {
    let res = await axios.get(`/api/reservation/${params.reservationId}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });
    setReservation(res?.data?.data);
  };

  useEffect(() => {
    if (!reservation) return;
    setTimeout(() => {
      history.push(`/tenants/${reservation?.user?._id}`);
    }, 3000);
    // eslint-disable-next-line
  }, [reservation]);

  useEffect(() => {
    getReservation();

    // eslint-disable-next-line
  }, []);

  return (
    <Portal
      zIndex="1"
      width="354px"
      height="438px"
      opacity="0.45"
      title="Reservation complete"
      close={() => history.push("/tenants")}
      noCustomScrollbar={true}
    >
      <div className={`df ffc acc ${styles.thankYou}`}>
        <div className={styles.circleWrpr}>
          <CheckInCircle height="36" width="36" />
        </div>

        <p className={`ffnr fs14 m-t-30 m-b-10`}>Payment was successful!</p>
      </div>
    </Portal>
  );
};

export default ThankYou;
