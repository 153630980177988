import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import Plus from "../../../../../assets/js-svgs/plus.svg";
import NonFormikRadio from "../../../../../components/inputs/NonFormikRadio";
import Portal from "../../../../../components/portal/Portal";
import Axios from "axios";
import AddCard from "../add-card/AddCard";

import "./CardsPopup.scss";

const cardTypes = {
  visa: "Visa",
  mastercard: "Master",
  amex: "Amex",
  discover: "Discover",
};

const CardsPopup = (props) => {
  // const history = useHistory();
  // const params = useParams();

  const [selectedCard, setCard] = useState();
  const [cards, setCards] = useState([]);
  const [addCard, setAddCard] = useState(false);

  const setCardAction = (e, field) => {
    return setCard(field);
  };

  const getCards = async () => {
    let cardsList = await Axios.get(
      `/api/payment-methods/${props.userId}/${props.type}`
    );
    setCards(cardsList.data.data);
    setCard(props.selected);
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line
  }, []);

  return (
    <Portal
      zIndex="105"
      width="315px"
      height="auto"
      title="Credit Card"
      noCustomScrollbar
      close={() => props.close()}
      opacity="0.25"
    >
      <div className="card_popup">
        <div className="inner_cntnt_wrpr">
          <div className="ffnsb fs10 ttuc ttl">Saved Cards</div>
          <div className="list_wrpr">
            {cards.map((card, i) => {
              return (
                <div className="df acsa line" key={i}>
                  <NonFormikRadio
                    notBold
                    label={`${
                      card.xCardType
                        ? cardTypes[card.xCardType.toLowerCase()]
                        : "Bank account"
                    } ****
                    ${
                      card.xMaskedCardNumber
                        ? card.xMaskedCardNumber.substring(
                            card.xMaskedCardNumber.length - 4,
                            card.xMaskedCardNumber.length
                          )
                        : card.xMaskedAccountNumber?.substring(
                            card.xMaskedAccountNumber?.length - 4,
                            card.xMaskedAccountNumber?.length
                          )
                    }
                    `}
                    onChange={setCardAction}
                    field={String(card._id)}
                    value={selectedCard === String(card._id)}
                    id={`a_${card._id}`}
                    name="card"
                  ></NonFormikRadio>
                </div>
              );
            })}
          </div>
          <div className="sbmt_wrpr">
            <button
              disabled={!selectedCard}
              className="button secondary small w100"
              onClick={() => {
                props.setCardType(selectedCard);
                props.close();
              }}
            >
              Select
            </button>
          </div>
        </div>
        <div className="ftr">
          <p
            className="df acc ffnr fs14 add_btn"
            onClick={() => setAddCard(true)}
          >
            <Plus></Plus> Add Another Card
          </p>
        </div>
      </div>

      {addCard ? (
        <AddCard
          getCards={getCards}
          userId={props.userId}
          close={setAddCard}
        ></AddCard>
      ) : undefined}
    </Portal>
  );
};

export default CardsPopup;
