import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import styles from "./AddStep.module.scss";

const AddStep = ({
  page,
  stepsLength,
  stepAdded,
  stepUpdated,
  close,
  step,
}) => {
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [label, setLabel] = useState(step?.label || "");
  const [type, setType] = useState(step?.type || "");
  const [handle, setHandle] = useState(step?.handle || "");
  // eslint-disable-next-line
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    if (!label) return;
    let copy = label
      .split(" ")
      .map((word, i) =>
        i > 0
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.charAt(0).toLowerCase() + word.slice(1)
      )
      .join("")
      .replace(/[\W_]+/g, " ")
      .replaceAll(" ", "");

    setHandle(copy);
  }, [label]);

  const addFlowStep = async () => {
    if (step?._id) {
      let added = await axios.put(`/api/flow-step/${step._id}`, {
        accountId: user.account,
        locationId: reduxUser.selectedLocation._id,
        label: label,
        type: type,
        handle: handle,
        editable: true,
        page: page,
        order: stepsLength + 1,
      });

      stepUpdated(added.data.data);
      close();
    } else {
      let added = await axios.post("/api/flow-step", {
        accountId: user.account,
        locationId: reduxUser.selectedLocation._id,
        label: label,
        type: type,
        handle: handle,
        editable: true,
        page: page,
        order: stepsLength + 1,
      });
      stepAdded(added.data.data);
      close();
    }
  };
  return (
    <div className={`p-r-20 p-l-20 ${styles.addStep}`}>
      <NonFormikInput
        label="Field label"
        value={label}
        id="label"
        type="text"
        change={setLabel}
      />
      <NonFormikInput
        label="Field handle"
        value={handle}
        id="handle"
        type="text"
        change={setHandle}
      />
      {/* <NonFormikInput
        label="Label"
        value={label}
        id="label"
        type="text"
        change={setLabel}
      /> */}
      {/* <input
        type="checkbox"
        // value={editable}
        checked={editable}
        onChange={(e) => setEditable(e.target.checked)}
        id="editable"
      /> */}
      {/* <label>Editable</label> */}
      <div className={`m-t-15 ffmr fs12`}>
        <p className={`ffmsb fs12`}>Field type</p>
        <div className={`df m-t-10 m-b-20`}>
          <div className={`df acsa`}>
            <input
              type="radio"
              value="string"
              onChange={(e) => setType(e.target.value)}
              name="stepType"
              id="string"
              checked={type === "string"}
            />
            <label htmlFor="string">Text</label>
          </div>
          <div className={`df acsa m-l-20`}>
            <input
              type="radio"
              value="date"
              onChange={(e) => setType(e.target.value)}
              name="stepType"
              id="date"
              checked={type === "date"}
            />
            <label htmlFor="date">Date</label>
          </div>
          <div className={`df acsa m-l-20`}>
            <input
              type="radio"
              value="number"
              onChange={(e) => setType(e.target.value)}
              name="stepType"
              id="number"
              checked={type === "number"}
            />
            <label htmlFor="number">Number</label>
          </div>
        </div>
      </div>

      <div className={`m-t-15 ffmr fs12`}>
        <p className={`ffmsb fs12`}>Preset types</p>

        <div className={`df m-t-10 m-b-20`}>
          <div className={`df acsa`}>
            <input
              type="radio"
              value="insuranceSelection"
              onChange={(e) => setType(e.target.value)}
              name="stepType"
              id="insuranceSelection"
              checked={type === "insuranceSelection"}
            />
            <label htmlFor="insuranceSelection">Insurance Selection</label>
          </div>

          <div className={`df acsa m-l-20`}>
            <input
              type="radio"
              value="phoneNumber"
              onChange={(e) => setType(e.target.value)}
              name="stepType"
              id="phoneNumber"
              checked={type === "phoneNumber"}
            />
            <label htmlFor="phoneNumber">Phone number</label>
          </div>
          <div className={`df acsa m-l-20`}>
            <input
              type="radio"
              value="address"
              onChange={(e) => setType(e.target.value)}
              name="stepType"
              id="address"
              checked={type === "address"}
            />
            <label htmlFor="address">Address</label>
          </div>
        </div>

        <div className={`df m-t-10 m-b-20`}>
          <div className={`df acsa`}>
            <input
              type="radio"
              value="serviceMember"
              onChange={(e) => setType(e.target.value)}
              name="serviceMember"
              id="serviceMember"
              checked={type === "serviceMember"}
            />
            <label htmlFor="serviceMember">Service Member</label>
          </div>
          <div className={`df acsa`}>
            <input
              type="radio"
              value="alternateContact"
              onChange={(e) => setType(e.target.value)}
              name="alternateContact"
              id="alternateContact"
              checked={type === "alternateContact"}
            />
            <label htmlFor="alternateContact">Alternate Contact</label>
          </div>
        </div>

        <div className={`df m-t-10 m-b-20`}>
          <div className={`df acsa`}>
            <input
              type="radio"
              value="storedContent"
              onChange={(e) => setType(e.target.value)}
              name="storedContent"
              id="storedContent"
              checked={type === "storedContent"}
            />
            <label htmlFor="storedContent">Stored Content</label>
          </div>

          <div className={`df acsa`}>
            <input
              type="radio"
              value="payMethod"
              onChange={(e) => setType(e.target.value)}
              name="payMethod"
              id="payMethod"
              checked={type === "payMethod"}
            />
            <label htmlFor="payMethod">Payment Method</label>
          </div>
        </div>
      </div>

      {/* storedContent */}
      <button
        onClick={addFlowStep}
        className={`w100 ttuc ffnb fs14 rwt btn primary m-b-20`}
      >
        Add
      </button>
    </div>
  );
};

export default AddStep;
