import { useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Task from "./task/Task";
import styles from "./Tasks.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { SET_TASKS_VISIBLE } from "../../store/actions/types";

const Tasks = (props) => {
  const dispatch = useDispatch();
  const [openTask, setOpenTask] = useState(1);

  const { tasks } = useSelector(({ tasks }) => ({ tasks }));

  return (
    <>
      <div
        className={styles.tasksOverlay}
        onClick={() => dispatch({ type: SET_TASKS_VISIBLE })}
      ></div>
      <div className={styles.tasksSec}>
        <div className={`df acsa ${styles.hdr}`}>
          <p className={`ffnsb fs16 ${styles.title}`}>
            Tasks{" "}
            <span className={styles.count}>
              {tasks?.tasks.filter((elem) => elem.completed !== true)?.length}
            </span>
          </p>
        </div>
        <div className={styles.listWrpr}>
          <TransitionGroup
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {tasks?.tasks &&
              tasks?.tasks
                .filter((elem) => elem.completed !== true)
                .map((task, i) => (
                  <CSSTransition
                    key={task._id}
                    timeout={500}
                    classNames={`item`}
                  >
                    <Task
                      focusMode={openTask !== undefined}
                      task={task}
                      open2={openTask}
                      open={openTask === task._id}
                      setOpenTask={setOpenTask}
                    />
                  </CSSTransition>
                ))}
          </TransitionGroup>
        </div>
      </div>
    </>
  );
};

export default Tasks;
