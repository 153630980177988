import React, { Component } from "react";

// import scss
import "./Locations.scss";

class Locations extends Component {
  render() {
    return (
      <div className="route_wrpr locations">
        Locations page !!
        <div className="list_wrpr">
          <div className="inner_list_wrpr">
            <div className="df acsa line">Hello line</div>
            <div className="df acsa line">Hello line</div>
            <div className="df acsa line">Hello line</div>
            <div className="df acsa line">Hello line</div>
            <div className="df acsa line">Hello line</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Locations;
