export const CheckInCircleFull = (props) => (
  <svg
    width={props.width || "15"}
    height={props.height || "15"}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5ZM6.25 8.85508L10.3687 4.73633L11.25 5.62383L6.25 10.6238L3.75 8.12383L4.63125 7.24258L6.25 8.85508Z"
      fill={props.fill || "#30BE76"}
    />
  </svg>
);
