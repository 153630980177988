import { GET_UNITS_FOR_RESERVATION } from "../actions/types";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNITS_FOR_RESERVATION:
      return { ...state, units: action.payload };
    default:
      return state;
  }
};

export default reducer;
