import { GET_USER, SET_BLUR } from "../actions/types";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER:
      return action.payload;
    case SET_BLUR:
      return { ...state, blurBG: action.payload };
    default:
      return state;
  }
};

export default reducer;
