import React, { Component } from "react";
import { Tabs } from "../../components/tabs/tabs";
import Portal from "../../components/portal/Portal";
import AddUser from "./add-user/AddUser";

const count = 200;
class Users extends Component {
  state = { showAdd: true };

  toggleAddPopup = () =>
    this.setState((prevState) => ({ showAdd: !prevState.showAdd }));
  setTab = (val) => console.log(val);
  render() {
    return (
      <div className="route_wrpr tenants_list">
        <div className="df route_page_hdr">
          <Tabs
            function={this.setTab}
            tabs={[
              { label: `All Units (${count})`, value: "123" },
              { label: `Occupied Units (${count - 150})`, value: "456" },
              { label: `Available Units (${count - 100})`, value: "789" },
            ]}
          ></Tabs>

          <div className="df mla actns_wrpr">
            <button
              className="__btn primary ffqsb fs14 ttcaps"
              onClick={() => this.toggleAddPopup()}
            >
              Invite user
            </button>
            {this.state.showAdd && (
              <Portal
                minHeight="488px"
                width="522px"
                zIndex="100"
                opacity="0.15"
                title="Invite user"
                close={this.toggleAddPopup}
              >
                <AddUser></AddUser>
              </Portal>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
