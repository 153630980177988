import axiosDefaults from "axios/lib/defaults";

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  axiosDefaults.baseURL = "https://slfstrg.azurewebsites.net/";
}

export * from "./user";
export * from "./unitA";
export * from "./tenantA";
export * from "./reservationA";
