import SettingsNav from "../settings-nav/SettingNav";
import pageStyles from "../SettingsHome.module.scss";
import UnitSizes from "./unit-sizes/UnitSizes";
import UnitTypes from "./unit-types/UnitTypes";

const UnitSettings = () => {
  return (
    <div>
      <SettingsNav actions={[]} />
      <div className={pageStyles.page}>
        <UnitSizes />
        <UnitTypes />
      </div>
    </div>
  );
};

export default UnitSettings;
