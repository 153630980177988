import axios from "axios";
// import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// import { Checkbox } from "../../../../../components/inputs/checkbox/checkbox";
import Portal from "../../../../../components/portal/Portal";
import AnvilDocument from "../../../documents/anvil/AnvilDocument";
import PrintSVG from "../../../../../assets/js-svgs/print.svg";
import EmailSVG from "../../../../../assets/js-svgs/email.svg";
import SendTextSVG from "../../../../../assets/js-svgs/send-text.svg";
import LinkSVG from "../../../../../assets/js-svgs/link.svg";
import { copyTextToClipboard } from "../../../../../helpers";

import styles from "./DocumentSelection.module.scss";
import SendPopup from "./send-popup/SendPopup";
import { useSelector } from "react-redux";

// const types = [
//   {
//     id: "123",
//     type: "contract",
//     checked: false,
//     label: "Rental Agreement",
//     disabled: false,
//   },
//   {
//     id: "456",
//     type: "autopay",
//     checked: false,
//     label: "Autopay Form",
//     disabled: true,
//   },
//   {
//     id: "789",
//     type: "mv",
//     checked: false,
//     label: "Motor Vehicle Storage Agreement",
//     disabled: true,
//   },
// ];

const DocumentsSelection = (props) => {
  const params = useParams();
  const history = useHistory();

  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [showPopup, setPopup] = useState(undefined);
  // const [docTypes, setDocTypes] = useState(types);
  const [loadingEsign, setLoadingEsign] = useState(false);
  const [showSignaturePopup, setShowSignaturePopup] = useState(false);
  const [sigId, setSigId] = useState();
  const [contractDocId, setContractDocId] = useState("");
  const [showSend, setShowSend] = useState(undefined);
  const [activeReservation, setActiveReservation] = useState(undefined);

  // const setDocument = (i, checked) => {
  //   let cloned = cloneDeep(docTypes);
  //   cloned[i].checked = checked;
  //   setDocTypes(cloned);
  // };

  const printDocuments = () => {
    console.log("Printing documents");
  };

  useEffect(() => {
    if (!sigId) return;
    setShowSignaturePopup(true);
  }, [sigId]);

  const eSignSetup = async () => {
    setLoadingEsign(true);
    const contract = await axios.post(
      `/api/create-get-contract/${params.reservationId || props.reservationId}`,
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    setLoadingEsign(false);
    setSigId(contract.data.data.eSigSignatures[0].eid);
    setContractDocId(contract.data.data._id);
  };

  // const hasSelected = () => docTypes.filter((doc) => doc.checked).length > 0;

  const getLink = async () => {
    let response = await axios.post(`/api/send-external-sign-url`, {
      websiteProfileId: reduxUser?.selectedLocation?.defaultWebsiteProfile,
      type: "copy",
      reservationId: params.reservationId || props.reservationId,
    });

    copyTextToClipboard(response.data.data);
  };

  const setActiveReservationAction = async () => {
    let d = await axios.get(
      `/api/portal-reservation/${params.reservationId || props.reservationId}`
    );
    setActiveReservation(d.data?.data);
  };
  useEffect(() => {
    setActiveReservationAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reservationId || props.reservationId]);
  return (
    <Portal
      // minHeight="438px"
      width="354px"
      height="auto"
      zIndex="100"
      opacity="0.45"
      title="Documents"
      close={() => {
        props.close ? props.close() : history.push("/tenants");
      }}
      overlayClick={() => (showPopup ? setPopup(undefined) : null)}
    >
      <div className={styles.documentSelectionPopup}>
        {/* {docTypes.map((line, i) => (
          <div className={`df acsa ${styles.line}`} key={i}>
            <Checkbox
              checked={line.checked}
              label={line.label}
              id={line.id}
              idx={i}
              change={setDocument}
              width="auto"
              disabled={line.disabled}
            />
          </div>
        ))} */}

        <div className={`df aic m-t-20 ${styles.signOptions}`}>
          <div
            className={`df acc cursor_pointer ${styles.sec}`}
            onClick={() => printDocuments()}
          >
            <PrintSVG />
          </div>
          <div
            onClick={() => setShowSend("email")}
            className={`df acc cursor_pointer ${styles.sec}`}
          >
            <EmailSVG />
          </div>
          <div
            onClick={() => setShowSend("text")}
            className={`df acc cursor_pointer ${styles.sec}`}
          >
            <SendTextSVG />
          </div>
          <div
            onClick={getLink}
            className={`df acc cursor_pointer ${styles.sec}`}
          >
            <LinkSVG />
          </div>
        </div>

        {showSend !== undefined ? (
          <SendPopup
            type={showSend}
            close={() => setShowSend(undefined)}
            phone={activeReservation?.user?.phone.number}
            email={activeReservation?.user?.email}
          />
        ) : undefined}

        <div className={`df ffc m-t-20 ${styles.ftr}`}>
          <button
            disabled={loadingEsign}
            onClick={eSignSetup}
            className={`ffnb fs14 ttuc sbmt_btn primary  m-t-10`}
          >
            E-Sign
          </button>
          {showSignaturePopup ? (
            <AnvilDocument
              signerEid={sigId}
              signerName={"Shmili Breuer"}
              close={() => {
                setShowSignaturePopup(false);
                setSigId(undefined);
              }}
              onFinish={async () => {
                await axios.put(`/api/document-details/${contractDocId}`, {
                  completed: true,
                });
                props.close
                  ? props.close()
                  : history.push(
                      `/tenants/add/thank-you/${
                        params.reservationId || props.reservationId
                      }`
                    );
              }}
            ></AnvilDocument>
          ) : undefined}

          {/* <button className={`ffnb fs14 ttuc sbmt_btn secondary m-t-10`}>
            Share selected documents
          </button> */}
          {/* <button
            disabled={loadingEsign || !hasSelected()}
            className={`ffnb fs14 ttuc sbmt_btn secondary m-t-10`}
          >
            Print selected
          </button> */}
          {/* {!props.hideSkip ? (
            <button
              className={`ffnb fs14 ttuc sbmt_btn secondary  m-t-10`}
              onClick={() =>
                history.push(
                  `/tenants/add/process-payment/${
                    params.reservationId || props.reservationId
                  }/${params.paymethod}`
                )
              }
            >
              Skip
            </button>
          ) : undefined} */}
        </div>
      </div>
    </Portal>
  );
};

export default DocumentsSelection;
