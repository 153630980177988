import { combineReducers } from "redux";
import user from "./userReducer";
import reduxUser from "./reduxUserReducer";
import unitSetupDetails from "./unitSetupDetailsR";
import unitsList from "./unitsListR";
import tenants from "./tenantsR";
import reservationUnits from "./reservationUnitsR";
import activeReservation from "./activeReservationR";
import lengthOfStayList from "./lengthOfStayListR";
import tasks from "./tasksR";

export default combineReducers({
  user,
  reduxUser,
  unitSetupDetails,
  unitsList,
  tenants,
  reservationUnits,
  activeReservation,
  lengthOfStayList,
  tasks,
});
