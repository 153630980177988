import React, {useEffect} from "react";
import Portal from "../../../../components/portal/Portal";
import {CheckInCircle} from "../../../../assets/js-svgs/check-in-circle.svg";

import "./ReservationSuccess.scss";
import {useSelector, useDispatch} from "react-redux";
import Axios from "axios";
import {GET_ACTIVE_RESERVATION} from "../../../../store/actions/types";

const ReservationSuccess = (props) => {
	let dispatch = useDispatch();
	const activeReservation = useSelector(
		({activeReservation}) => activeReservation
	);
	const setActiveReservation = async () => {
		// console.log("------");
		let d = await Axios.get(
			`/api/portal-reservation/${props.match.params.reservationId}`
		);
		// console.log(d);
		dispatch({type: GET_ACTIVE_RESERVATION, payload: d.data?.data || null});
	};
	useEffect(() => {
		if (!activeReservation) setActiveReservation(); //console.log("Loading active reservation...");
		// eslint-disable-next-line
	}, [activeReservation]);

	return (
		<Portal
			minHeight="488px"
			width="354px"
			height="516px"
			zIndex="100"
			opacity="0.45"
			title=""
			close={() => this.props.history.push("/tenants")}
		>
			{activeReservation ? (
				<div className="df ffc  res_success_page">
					<div className="df ffc acsa cntnt_wrpr">
						<div className="check_wrpr">
							<CheckInCircle></CheckInCircle>
						</div>

						<h5 className="ffnr fs14 pdt">
							Reservation Created successfully!
						</h5>

						<p className="ffnr fs14 pdt_op80 name">
							{activeReservation.firstName}{" "}
							{activeReservation.lastName}
						</p>
						<p className="ffnr fs14 pdt_op80 unit">
							Reseved unit {activeReservation.unitSize.label} (
							{activeReservation.unitSize.height}x
							{activeReservation.unitSize.width})
						</p>
						<p className="ffnr fs14 pdt_op80 acct_num">
							Account number {activeReservation.userAccountNum}
						</p>
						<p className="ffnr fs14 pdt_op80 conf_num">
							Confirmation number:{" "}
							{activeReservation.reservationNum}
						</p>
					</div>

					<div className="df ffc acsa mta ftr">
						<div className="btn_line">
							<button
								className="ffnb fs14 ttuc sbmt_btn primary"
								onClick={() =>
									props.history.push(
										`/tenants/add/additional-info/${props.match.params.reservationId}`
									)
								}
							>
								Next
							</button>
						</div>
						<div className="btn_line">
							{/* {console.log(props)} */}
							<button
								className="ffnb fs14 ttuc sbmt_btn secondary"
								onClick={() => props.history.push("/tenants")}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			) : (
				"Loading"
			)}
		</Portal>
	);
};

export default ReservationSuccess;
