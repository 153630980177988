import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import styles from "./PaymentProcessing.module.scss";
import AddMerchantAccount from "./add/Add";

const PaymentProcessing = (props) => {
  const { user } = useSelector(({ user }) => ({
    user,
  }));

  const [merchantAccount, setMerchantAccount] = useState();
  const [loading, setLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);

  const getMerchantAccount = async () => {
    console.log("Gettig merchant account");

    let d = await axios.get(`/api/account/merchant-account/${user.account}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });
    setLoading(false);
    if (d.status === 204) {
      return setMerchantAccount("noData");
    }

    setMerchantAccount(d.data.data);
  };

  useEffect(() => {
    getMerchantAccount();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`${styles.paymentProcessingPage}`}>
      <h3>Payment processing</h3>

      <div className={`${styles.maWrpr}`}>
        <div className={`df acsa`}>
          <p className={`m-b-10 ffmm fs13`}>Merchant account</p>
          <div
            className={`mla ffmb fs12`}
            onClick={() => {
              if (!loading) {
                setShowAdd(true);
              }
            }}
          >
            {merchantAccount === "noData" ? "Setup" : "Edit"}
          </div>
        </div>

        {showAdd ? (
          <AddMerchantAccount
            close={() => setShowAdd(false)}
            data={merchantAccount}
          ></AddMerchantAccount>
        ) : undefined}

        {merchantAccount === "noData" ? (
          "No data"
        ) : merchantAccount ? (
          <div className={styles.valuesWrpr}>
            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs12 ${styles.lbl}`}>API URL:</p>{" "}
              <p className={`mla ffmr fs12 ${styles.val}`}>
                {merchantAccount.API_URL}
              </p>
            </div>
            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs12 ${styles.lbl}`}>P ublic Key:</p>{" "}
              <p className={`mla ffmr fs12 ${styles.val}`}>
                {merchantAccount.publicKey}
              </p>
            </div>
            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs12 ${styles.lbl}`}>xKey:</p>{" "}
              <p className={`mla ffmr fs12 ${styles.val}`}>
                {merchantAccount.xKey}
              </p>
            </div>
            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs12 ${styles.lbl}`}>xSoftwareName:</p>{" "}
              <p className={`mla ffmr fs12 ${styles.val}`}>
                {merchantAccount.xSoftwareName}
              </p>
            </div>
            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs12 ${styles.lbl}`}>xSoftwareVersion:</p>{" "}
              <p className={`mla ffmr fs12 ${styles.val}`}>
                {merchantAccount.xSoftwareVersion}
              </p>
            </div>

            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs12 ${styles.lbl}`}>xVersion:</p>{" "}
              <p className={`mla ffmr fs12 ${styles.val}`}>
                {merchantAccount.xVersion}
              </p>
            </div>
          </div>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};

export default PaymentProcessing;
