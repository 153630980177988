import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Checkbox } from "../../../../components/inputs/checkbox/checkbox";
// import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import Portal from "../../../../components/portal/Portal";
import CardsPopup from "./cards-popup/CardsPopup";

import * as creditCards from "../../../../assets/images/credit-cards";

import "./PaymentsPopup.scss";
import PayCash from "./pay-cash-popup/PayCash";
import { formatFromCurrencyToMoney } from "../../../../helpers";
import CurrencyInput from "../../../../components/inputs/CurrencyInput";
import WarningSVG from "../../../../assets/js-svgs/warning.svg";
import ChangeSVG from "../../../../assets/js-svgs/change.svg";

import styles from "./PaymentsPopup.module.scss";
import PayCheck from "./checks-popup/ChecksPopup";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";

const PaymentsPopup = (props) => {
  const history = useHistory();
  const params = useParams();

  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  console.log("props", props, "reduxUser", reduxUser);

  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState(undefined);
  const [selectedPaymentType, setSelectedPaymentType] = useState(undefined);
  // eslint-disable-next-line
  const [addCard, setAddCard] = useState(false);
  const [selectedPayMethod, setSelectedPayMethod] = useState();
  const [selectedPayMethodDetails, setSelectedPayMethodDetails] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const [processDisabled, setProcessDisabled] = useState(false);
  const [showPayCard, setShowPayCard] = useState(false);
  const [showPayCash, setShowPayCash] = useState(false);
  const [showPayCheck, setShowPayCheck] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [cashTendered, setCashTendered] = useState("");
  // eslint-disable-next-line
  const [moAmount, setMoAmount] = useState("");
  const [moRef, setMoRef] = useState("");

  const setAddCardAction = (val) => setAddCard(val);

  // const setSelectedReservations = (val, checked) => {
  //   if (checked) {
  //     setSelected([...selected, val]);
  //   } else {
  //     const local = [...selected];
  //     const index = local.indexOf(val);

  //     local.splice(index, 1);
  //     setSelected(local);
  //   }
  // };

  const getBalances = async () => {
    let localBalances = await Axios.get(
      `/api/reservation-balance/${params.id}${
        params.resId ? `/${params.resId}` : ""
      }`
    );
    setBalances(localBalances.data.data);
  };

  const getPayMethodDetails = async () => {
    let localSelectedPayMethodDetails = await Axios.get(
      `/api/payment-method/${selectedPayMethod}`
    );
    setSelectedPayMethodDetails(localSelectedPayMethodDetails.data.data);
  };

  useEffect(() => {
    getBalances();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedPayMethod) return;

    getPayMethodDetails();
    // eslint-disable-next-line
  }, [selectedPayMethod]);

  useEffect(() => {
    if (!balances) return;
    setLoading(false);

    let ttl = balances
      .reduce((a, b) => {
        return a + (b?.reservationBalance || 0);
      }, 0)
      .toFixed(2);

    setPayAmount(ttl);

    setTotalAmount(ttl);
  }, [balances]);

  const processPayment = async () => {
    let saved;
    if (selectedPaymentType === "cash") {
      saved = await Axios.post("/api/payment-method", {
        userId: params.id,
        type: "cash",
      });
    } else if (selectedPaymentType === "m-order") {
      saved = await Axios.post("/api/payment-method", {
        userId: params.id,
        type: "m-order",
        xRefNum: moRef,
      });
    }

    setPaymentError(false);
    setProcessDisabled(true);
    let payment = await Axios.post(
      `/api/portal-payment/?${
        params.resId ? `reservationid=${params.resId}` : "type=auto-apply"
      }`,
      {
        amount: payAmount,
        payMethod: saved?.data?.data?._id || selectedPayMethod,
        userId: params.id,
        location: reduxUser.selectedLocation._id,
        account: user.account,
      }
    );

    if (payment.data?.status === 200) {
      props.refreshData();
      setTimeout(() => {
        close();
      }, 500);
    } else if (payment.data?.status === 500) {
      console.log("Payment error");
      setPaymentError(true);
      setProcessDisabled(false);
    }
  };
  // useEffect(() => {
  // 	setTotalPayAction();
  // }, [selected]);

  // useEffect(() => {
  // 	setTotalPay(totalAmount);
  // }, [totalAmount]);

  // const setTotalPayAction = (val) => {
  // 	setTotalPay(val);
  // };

  const getIcon = (selectedPayMethodDetails) => {
    // console.log(")IU7nyUNY", selectedPayMethodDetails);
    // console.log(selectedPayMethodDetails);
    if (!selectedPayMethodDetails) return;
    if (selectedPayMethodDetails.type === "check") return "BA";
    let CardLogo = creditCards[selectedPayMethodDetails.xCardType];
    return <CardLogo />;
  };

  const close = () => history.push(`/tenants/${params.id}`);

  return (
    <Portal
      zIndex="101"
      width="354px"
      height="auto"
      title="Payment"
      noCustomScrollbar
      close={close}
      opacity="0.65"
    >
      {!loading ? (
        <div className="payments_popup">
          <div className="rentals_list">
            <div className="df acsa hdr ffnsb fs10 ttuc">
              <div className="title">Rentals</div>
              {/* <div className="df acsa mla actns_wrpr">
								<p className="">All</p>
								<p className="">None</p>
							</div> */}
            </div>

            {balances.length ? (
              <div className="list_wrpr">
                {balances
                  .filter((b) => b.reservationBalance || (b && b.unit))
                  .map((reservation, idx) => (
                    <label
                      key={idx}
                      className={`ffnr fs14 df acsa line`}
                      // htmlFor={reservation._id}
                    >
                      {/* <Checkbox
											change={setSelectedReservations}
											value=""
											idx={Math.random()}
											id={reservation._id}
											small
											idx={reservation._id}
											checked={selected.includes(
												reservation._id
											)}
										/> */}
                      <p>
                        {reservation ? "Unit " : "Other"}
                        {reservation ? reservation.unit.unitId : ""}
                      </p>
                      <p className="mla">
                        {/* ${reservation.reservationTotalOutstanding.toFixed(2)} -  */}

                        {reservation.reservationBalance
                          ? formatFromCurrencyToMoney(
                              reservation.reservationBalance
                            )
                          : formatFromCurrencyToMoney(0)}
                      </p>
                    </label>
                  ))}
              </div>
            ) : (
              <>
                <p className={`ffnr fs16 m-b-8`}>No outstanding balances</p>
                <p className={`ffnr fs11 m-b-12`}>
                  You can still make a payment, it will be applied as account
                  credit
                </p>
              </>
            )}
          </div>

          {paymentError ? (
            <div
              className={`m-t-10 tac p-l-10 p-r-10 p-t-15 p-b-20 ${styles.errorWrpr}`}
            >
              <WarningSVG fill="#fff" height="30" width="30" />
              <p className={`ffnb fs14 m-t-8 ${styles.errorHeading}`}>
                There was an error processing your payment
              </p>
              <p className={`ffnr fs14 m-t-8 ${styles.errorSub}`}>
                Please check your payment and try again
              </p>
            </div>
          ) : undefined}
          <div className="payments_section">
            <p className="ffnsb fs14 title">
              Balance Due:{" "}
              <span className="sub">
                {formatFromCurrencyToMoney(totalAmount)}
              </span>
            </p>

            {/* <NonFormikInput
              label="Amount"
              value={payAmount}
              change={setPayAmount}
              id="amt"
            ></NonFormikInput> */}

            <CurrencyInput
              label="Amount"
              id="amt"
              value={payAmount}
              onChange={(val) => setPayAmount(val.fullNumber)}
            />

            {console.log("************")}
            {console.log(selectedPayMethod)}
            {console.log("************")}
            {/* {selectedPayMethod ===  ? "Cash" : "No cash"} */}

            {selectedPaymentType === "cash" ? (
              <>
                <div className="df acsa m-t-10 m-b-20">
                  <div className={`cashInputWrpr ${styles.cashInputWrpr}`}>
                    <CurrencyInput
                      label="Amount tendered"
                      id="cashTendered"
                      value={cashTendered}
                      onChange={(val) => setCashTendered(val.fullNumber)}
                    />
                  </div>

                  <div className={styles.cashIcon}>
                    <ChangeSVG />
                  </div>

                  <div className={`cashInputWrpr ${styles.cashInputWrpr}`}>
                    <CurrencyInput
                      disabled={true}
                      label="Change required"
                      id="cashTendered"
                      value={
                        cashTendered <= payAmount ||
                        payAmount === "0.00" ||
                        payAmount === "0" ||
                        payAmount === 0
                          ? 0
                          : cashTendered - payAmount
                      }
                      // onChange={(val) => setCashTendered(val.fullNumber)}
                    />
                  </div>
                </div>
                <button
                  className="secondary small swipe_btn"
                  onClick={() => {
                    setSelectedPaymentType(undefined);
                    setSelectedPayMethod(undefined);
                  }}
                >
                  Change payment method
                </button>
              </>
            ) : selectedPaymentType === "m-order" ? (
              <>
                {/* <div className={`cashInputWrpr ${styles.cashInputWrpr}`}>
                  <CurrencyInput
                    label="Money order amount"
                    id="moAmount"
                    value={moAmount}
                    onChange={(val) => setMoAmount(val.fullNumber)}
                  />
                </div> */}
                <div className={`cashInputWrpr m-b-10 ${styles.cashInputWrpr}`}>
                  <NonFormikInput
                    label="Money order reference #"
                    id="moRef"
                    value={moRef}
                    change={setMoRef}
                  />
                </div>
                <button
                  className="secondary small swipe_btn"
                  onClick={() => {
                    setSelectedPaymentType(undefined);
                    setSelectedPayMethod(undefined);
                  }}
                >
                  Change payment method
                </button>
              </>
            ) : (
              <>
                {!selectedPayMethod ? (
                  <>
                    <div className="p_types_wrpr">
                      <div
                        onClick={() => setShowPayCard(true)}
                        className={`df acsa p_type`}
                      >
                        <div className={`radio_icon`}></div>
                        <p className="ffnr fs14">Card</p>
                      </div>

                      <div
                        onClick={() => {
                          // setShowPayCash(true);
                          setSelectedPaymentType("cash");
                        }}
                        className={`df acsa p_type`}
                      >
                        <div className={`radio_icon`}></div>
                        <p className="ffnr fs14">Cash</p>
                      </div>
                      <div
                        onClick={() => {
                          setShowPayCheck(true);
                          // setSelectedPaymentType(undefined)
                        }}
                        className={`df acsa p_type`}
                      >
                        <div className={`radio_icon`}></div>
                        <p className="ffnr fs14">Check</p>
                      </div>
                      <div
                        onClick={() => {
                          // setShowPayCash(true);
                          setSelectedPaymentType("m-order");
                        }}
                        className={`df acsa p_type`}
                      >
                        <div className={`radio_icon`}></div>
                        <p className="ffnr fs14">Money Order</p>
                      </div>
                    </div>

                    <button disabled className="secondary small swipe_btn">
                      Begin Swipe
                    </button>
                  </>
                ) : (
                  <>
                    {selectedPayMethodDetails ? (
                      <>
                        <div className="df acsa selected_pm_wrpr">
                          <div className={`df acsa ffnr fs14`}>
                            {selectedPayMethodDetails.xCardType ? (
                              <div className="icon_wrpr">
                                {selectedPayMethodDetails.xCardType
                                  ? getIcon(selectedPayMethodDetails)
                                  : undefined}
                              </div>
                            ) : undefined}
                            {selectedPayMethodDetails.xCardType || (
                              <p className={`ttuc m-r-4`}>
                                {selectedPayMethodDetails.type}{" "}
                              </p>
                            )}{" "}
                            {selectedPayMethodDetails.type ===
                            "cash" ? undefined : (
                              <span className={` m-r-4`}>****</span>
                            )}
                            {selectedPayMethodDetails.xMaskedCardNumber?.substring(
                              selectedPayMethodDetails.xMaskedCardNumber
                                ?.length - 4,
                              selectedPayMethodDetails.xMaskedCardNumber?.length
                            ) ||
                              selectedPayMethodDetails.xMaskedAccountNumber?.substring(
                                selectedPayMethodDetails.xMaskedAccountNumber
                                  ?.length - 4,
                                selectedPayMethodDetails.xMaskedAccountNumber
                                  ?.length
                              )}
                          </div>
                        </div>
                        <button
                          className="secondary small swipe_btn"
                          onClick={() => {
                            setSelectedPaymentType(undefined);
                            setSelectedPayMethod(undefined);
                          }}
                        >
                          Change payment method
                        </button>
                      </>
                    ) : undefined}
                  </>
                )}
              </>
            )}

            {showPayCard ? (
              <CardsPopup
                type="cc"
                setAddCardAction={setAddCardAction}
                close={() => setShowPayCard(false)}
                setCardType={setSelectedPayMethod}
                selected={selectedPayMethod}
                userId={params.id}
              ></CardsPopup>
            ) : undefined}

            {showPayCash ? (
              <PayCash
                amount={payAmount}
                close={(d) => setShowPayCash(false)}
                setPaymentMethod={setSelectedPayMethod}
                userId={params.id}
                setCardType={setSelectedPayMethod}
              />
            ) : undefined}

            {showPayCheck ? (
              <PayCheck
                close={() => setShowPayCheck(false)}
                userId={params.id}
                setPaymentMethod={setSelectedPayMethod}
                setCardType={setSelectedPayMethod}
              />
            ) : undefined}
          </div>

          <div className="billing_section ffnr fs14">
            <p className="df acsa ffnsb fs14 title">Billing Information</p>
            <p className="address_line">{props?.tenant?.fullName}</p>
            <p className="address_line">
              {props?.tenant?.defaultAddress?.deliveryLine1}
            </p>
            <p className="address_line">
              {props?.tenant?.defaultAddress?.lastLine}
            </p>
          </div>

          <div className="footer">
            <button
              className={`primary process_btn ttuc`}
              onClick={() => processPayment()}
              disabled={
                (selectedPaymentType !== "cash" &&
                  selectedPaymentType !== "m-order" &&
                  !selectedPayMethod) ||
                processDisabled
              }
            >
              Process payment of {formatFromCurrencyToMoney(payAmount)}
            </button>
            {/* Cash pay method ID 6320fe17b7e84275bc7fc32f */}
            {/* <button className="primary swipe_btn">Save</button> */}
          </div>
        </div>
      ) : (
        "Loading"
      )}
    </Portal>
  );
};

export default PaymentsPopup;
