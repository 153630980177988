import React, { Component } from "react";
// import {  } from "react-router-dom";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { loginUser, updateUser } from "../../store/actions";

// import scss
import "./Login.scss";
import { DefaultInput } from "../../components/inputs/DefaultInput";

class LoginPage extends Component {
  state = {
    varify: false,
  };
  async componentDidMount() {
    let token = await localStorage.getItem("x-auth");
    if (token) return this.props.history.push("/");
  }
  render() {
    return (
      <div className="df acc login_page">
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstName: "",
            lastName: "",
          }}
          onSubmit={async (values) => {
            await this.props.loginUser(values);
            let token = localStorage.getItem("x-auth");

            if (token) {
              return this.props.history.push("/");
            }

            // console.log("=======================", token);
            // if (token)
          }}
        >
          {/* updateUser */}
          {(props) => (
            <div className="form_wrpr">
              <h3 className="ffqsm fs25 ttuc tac">Login</h3>
              <form className="df ffc acsa" onSubmit={props.handleSubmit}>
                {this.state.varify ? (
                  <>
                    <div className="inpt_wrpr">
                      <Field
                        name="firstName"
                        label="First name"
                        id="frstname"
                        component={DefaultInput}
                      />
                    </div>

                    <div className="inpt_wrpr">
                      <Field
                        name="lastName"
                        label="Last name"
                        id="lstname"
                        component={DefaultInput}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="inpt_wrpr">
                      <Field
                        name="email"
                        label="Username"
                        id="logn_usernmae"
                        component={DefaultInput}
                      />
                    </div>
                    <div className="inpt_wrpr">
                      <Field
                        type="password"
                        name="password"
                        label="Password"
                        id="logn_pass"
                        component={DefaultInput}
                      />
                    </div>
                  </>
                )}
                <button className="ffmb fs14 ttuc sbmt_btn" type="submit">
                  Login
                </button>
              </form>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { loginUser, updateUser })(LoginPage);
