import React from "react";

const ChatSvg = (props) => (
  <svg
    width={props.width || "19"}
    height={props.height || "19"}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.48664 17.4121L4.26476 15.8433L4.45255 15.4647L4.16792 15.1523L3.70668 14.646C2.2762 13.0759 1.49318 11.0758 1.49318 9.01514C1.49318 4.3856 5.2606 0.618181 9.89014 0.618181C14.5197 0.618181 18.2871 4.3856 18.2871 9.01514C18.2871 13.6447 14.5197 17.4121 9.89014 17.4121H3.48664Z"
      stroke={props.fill || ""}
      strokeOpacity={props.fillOpacity || ""}
      strokeWidth="1.23636"
    />
  </svg>
);

export default ChatSvg;
