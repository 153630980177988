import React from "react";
// import "react-phone-number-input/style.css";

// import scss
import "./DefaultInput.scss";

export const NonFormikInput = (props) => {
  return (
    <>
      {props.multilevels ? (
        <>
          {/* <div
            className={`__inpt_wrpr ${errors[field.name] ? "error" : ""} ${
              props.size
            }`}
          >
            {field.name.toLowerCase().includes("phone") ? (
              <>
              
                <Input
                  country="US"
                  className="ffnsb fs14"
                  type="text"
                  {...field}
                  {...props}
                  
                />
              
              </>
            ) : (
              <input
                className="ffnsb fs14"
                type="text"
                {...field}
                {...props}
                id={props.id}
                autoComplete={props.autocomplete || field.name}
              />
            )}

            <label
              htmlFor={props.id}
              className={` __inpt_lbl ${
                values[props.arrayname][props.i][props.fieldname] &&
                values[props.arrayname][props.i][props.fieldname].length
                  ? "move ffnsb fs11"
                  : "ffnr fs14"
              }`}
            >
              {props.label}
            </label>
          </div> */}
        </>
      ) : (
        <>
          {props.dummy ? (
            <div
              className={`__inpt_wrpr ${false ? "error" : ""}  ${props.size}`}
            >
              <div className="df acsa dummy_inpt_wrpr">{props.children}</div>
            </div>
          ) : (
            <div
              className={`__inpt_wrpr ${false ? "error" : ""}  ${props.size}`}
            >
              <input
                disabled={props.disabled}
                id={props.id}
                className="ffnsb fs14"
                type="text"
                value={props.value}
                onChange={(e) => props.change(e.target.value)}
                autoComplete={props.autocomplete}
                spellCheck={false}
              />

              {/* <Input
								className="ffnsb fs14"
								country="US"
								type="text"
								{...field}
								{...props}
								value={phoneNumber}
								autoComplete={props.autocomplete || field.name}
							/> */}
              <label
                htmlFor={props.id}
                className={` __inpt_lbl ${
                  props.value || props.value === 0
                    ? "move ffnsb fs11"
                    : "ffnr fs14"
                }`}
              >
                {props.label}
              </label>
              {props.error && (
                <p className="fs10 ffnsb inpt_error_lbl">{props.error}</p>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
