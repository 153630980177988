import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import SettingsNav from "../settings-nav/SettingNav";

import pageStyles from "../SettingsHome.module.scss";
import styles from "./Account.module.scss";

const AccountSettings = () => {
  const { user } = useSelector(({ user }) => ({ user }));
  const [merchantAccount, setMerchantAccount] = useState({});

  // const [xKey, setXKey] = useState("");
  // const [publicKey, setPublicKey] = useState("");

  const getMerchantAccount = async () => {
    console.log("Gettig merchant account");

    let d = await axios.get(`/api/account/merchant-account/${user.account}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });
    // setLoading(false);
    if (d.status === 204) {
      return setMerchantAccount("noData");
    }

    setMerchantAccount(d.data.data);
  };

  //   accountId;
  //   xKey;
  //   xSoftwareName;
  //   xSoftwareVersion;
  //   xVersion;
  //   publicKey;
  //   API_URL;

  const saveMerchantInfo = async () => {
    if (merchantAccount?._id) {
      axios.put(
        `/api/account/merchant-account/${merchantAccount._id}`,
        {
          ...merchantAccount,
        },
        { headers: { "x-auth": localStorage.getItem("x-auth") } }
      );
    } else {
      await axios.post(
        "/api/account/merchant-account",
        {
          ...merchantAccount,
          accountId: user.account,
          xSoftwareName: "Shmili.com",
          xSoftwareVersion: "1.0",
          xVersion: "4.5.9",

          API_URL: "https://x1.cardknox.com/gatewayjson",
        },
        { headers: { "x-auth": localStorage.getItem("x-auth") } }
      );
    }
  };

  useEffect(() => {
    getMerchantAccount();

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <SettingsNav />
      <div className={`${styles.accountSettings} ${pageStyles.page}`}>
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>Merchent Account</p>
              <p className={`ffnr fs12 m-t-10`}>
                Set up your merchant account credentials
              </p>
            </div>
            {/* <button
              className={`mla ffnr s14 ${styles.addBtn}`}
              onClick={() => setShowAddRule(true)}
            >
              Add rule
            </button> */}
          </div>
          <div>
            <div className={`df aic ${styles.settingLine}  ${styles.hasInput}`}>
              <div className={`${styles.inputSec}`}>
                <input
                  style={{ width: "100%" }}
                  value={merchantAccount?.xKey}
                  onChange={(e) =>
                    setMerchantAccount({
                      ...merchantAccount,
                      xKey: e.target.value,
                    })
                  }
                  placeholder="Merchant Key (xKey)"
                  label="Merchant Key (xKey)"
                  id="xKey"
                />
              </div>
              <div className={`m-l-20 m-r-20 ${styles.inputSec}`}>
                <input
                  style={{ width: "100%", display: "block" }}
                  value={merchantAccount?.publicKey}
                  onChange={(e) =>
                    setMerchantAccount({
                      ...merchantAccount,
                      publicKey: e.target.value,
                    })
                  }
                  placeholder="Public Key"
                  label="Public Key"
                  id="publicKey"
                />
              </div>
              <button
                className={`mla ${styles.actionBtn}`}
                onClick={() => {
                  saveMerchantInfo();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
