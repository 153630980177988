import React, { Component } from "react";
import { Tabs } from "../../../components/tabs/tabs";
import { Scrollbars } from "react-custom-scrollbars";

//import scss
import "./GroupedUnits.scss";

// import images

import secondFloor from "../../../assets/images/2nd-level.png";
import groundLevel from "../../../assets/images/ground-level.png";
import driveUp from "../../../assets/images/drive-up.png";

const imgs = {
  secondFloor,
  groundLevel,
  driveUp
};

let count = 250;

class GroupedUnits extends Component {
  setTab = () => console.log("OOO");
  render() {
    return (
      <div className="route_wrpr units_grpd">
        <div className="df route_page_hdr">
          <Tabs
            function={this.setTab}
            tabs={[
              { label: `All Units (${count})`, value: "123" },
              { label: `Occupied Units (${count - 150})`, value: "456" },
              { label: `Available Units (${count - 100})`, value: "789" }
            ]}
          ></Tabs>

          <div className="df mla actns_wrpr">
            <button className="__btn primary ffqsb fs14 ttcaps">
              Add unit
            </button>

            <button className="df acc primary round __btn download"></button>
          </div>
        </div>

        <div className="df list_wrpr">
          <Scrollbars
            className="__scrollbar"
            style={{ height: "100%" }}
            renderTrackVertical={this.renderTrackVertical}
            renderTrackHorizontal={this.renderTrackHorizontal}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "8px 16px 16px"
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7].map(li => (
                <div className="li">
                  <h4 className="ffmb fs175">
                    5’ x 7’ x 8’ Small Unit - 25sqft{" "}
                  </h4>
                  {[1, 2].map(sec => (
                    <div className="sec_wrpr">
                      <h5 className="df ffmr fs14">
                        <div className="df acc icon_wrpr">
                          <div
                            className="icon"
                            style={{
                              backgroundImage: `url(${imgs["groundLevel"]})`
                            }}
                          ></div>
                        </div>
                        <div className="df acsa sec unit_type">
                          Ground level
                        </div>
                        <div className="df acsa sec availability">
                          availability 12/19
                        </div>
                        <div className="df acsa sec top_features">
                          No climeat, Rollup
                        </div>
                      </h5>
                      <div className="df sec_cntnt_wrpr">
                        {[
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },

                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a2",
                            size: "10x10",
                            status: "rented",
                            features: ["no climate", "drive up"],
                            keyFeature: "driveUp"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "moveOut",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },

                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },

                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },

                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },

                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },

                          {
                            id: "a1",
                            size: "small 5X7",
                            status: "reserved",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "groundLevel"
                          },

                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          },
                          {
                            id: "a3",
                            size: "small 5X7",
                            status: "available",
                            features: ["climate", "digital lock", "drive up"],
                            keyFeature: "secondFloor"
                          }
                        ].map(unit => (
                          <div
                            className={`df acc ffmsb fs14 ttuc unit status_bg_color reserved ${unit.status}`}
                          >
                            a1
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default GroupedUnits;
