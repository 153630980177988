import Axios from "axios";
import moment from "moment";
import {
  ADDED_USER,
  ERROR_ADDING_USER,
  GET_LENGTH_OF_STAY_LIST,
  GET_TENANTS,
  GET_UNITS_FOR_RESERVATION,
} from "./types";

const getTenantsByLocations = async (locations) => {
  let token = await localStorage.getItem("x-auth");
  return await Axios.post("/api/tenants-by-locations", locations, {
    headers: { "x-auth": token },
  });
};

export const getTenantsByLocation = () => async (dispatch, getState) => {
  let { reduxUser } = getState();

  let { _id } = reduxUser.selectedLocation;
  let tenants = await getTenantsByLocations([_id]);
  // console.log(tenants);

  dispatch({ type: GET_TENANTS, payload: tenants.data.data });
};

export const addTenant = (data) => async (dispatch) => {
  // data.source = data.source.value;
  // data.leadType = data.leadType.value;

  let user = await Axios.post("/api/create-user-from-portal", {
    ...data,
  }).catch((e) => e);

  // console.log(user);

  if (user.response && user.response.status === 500)
    return dispatch({
      type: ERROR_ADDING_USER,
      payload: "Error adding user",
    });

  let { payload } = user.data.data;
  payload.reservations = [];

  dispatch({ type: ADDED_USER, payload: user.data.data.payload });
};

export const getAllUnitsForReservation = () => async (dispatch, getState) => {
  // /api/unique-unit-sizes-populated

  const { reduxUser } = getState();
  // console.log(reduxUser);
  const units = await Axios.post("/api/unique-unit-sizes-populated", {
    locationId: reduxUser.selectedLocation._id,
  });

  // console.log(units.data);

  dispatch({ type: GET_UNITS_FOR_RESERVATION, payload: units.data });
};

export const reserveUnit =
  (unitId, userId, moveInDate) => async (dispatch, getState) => {
    const { reduxUser, user } = getState();
    // console.log("LOL LOL LOL LOL LOL LOL", unitId, userId);

    let d = await Axios.post("/api/portal-reservation", {
      unit: unitId,
      user: userId,
      locationId: reduxUser.selectedLocation._id,
      accountId: user.account,
      moveInDate: Number(moment(moveInDate)),
      initialPayment: false,
      stages: {
        reserved: "done",
      },
    });
    // console.log(d);

    if (d.data && d.data.status === "success") {
    } else {
      dispatch({
        type: "L",
      });
    }
    // /api/portal-reservation
  };

export const getLengthOfStayList = () => async (dispatch) => {
  let b = await Axios.get("/api/length-of-stay-list");
  dispatch({ type: GET_LENGTH_OF_STAY_LIST, payload: b.data?.data || null });
};
