const SendTextSVG = (props) => (
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 0H1.99C0.89 0 0 0.85 0 1.95V19.95C0 21.05 0.89 22 1.99 22H11.99C13.09 22 13.99 21.05 13.99 19.95V1.95C13.99 0.85 13.09 0 11.99 0ZM11.99 18H1.99V4H11.99V18ZM7.79 13.99V12.24C5.57 12.24 4.1 12.92 2.99 14.42C3.44 12.28 4.68 10.15 7.79 9.72V8.02L10.99 11L7.79 13.99Z"
      fill="#1F2041"
      fillOpacity="0.8"
    />
  </svg>
);

export default SendTextSVG;
