import React from "react";

import "./AddTenant.scss";
import { Route } from "react-router-dom";
import AddUser from "./add-user/AddUser";
import AllUnits from "./all-units/AllUnits";
import ReservationSuccess from "./all-units/ReservationSuccess";
// import MarketingAndFollowup from "./marketing-and-followup/MarketingNFollowup";
// import AdditionalInfo from "./additional-info/AdditionalInfo";
import Ids from "./ids/Ids";
import DocumentsSelection from "./documents/document-selection/DocumentSelection";
import FinalizeResrvation from "./finalize/Finalize";
import ReservationPayment from "./additional-info/payment/Payment";
import ProcessPayment from "./process-payment/ProcessPayment";
import ThankYou from "./thank-you/ThankYou";
import PersonalInfo from "./personel-info/PersonalInfo";
import NewInsurancePopup from "./additional-info/insurance/NewInsurance";

const AddTenant = (props) => {
  return (
    <>
      <Route path="/tenants/add/user" component={AddUser}></Route>
      <Route
        path="/tenants/add/all-units/:userId/:reservationsId?"
        component={AllUnits}
      ></Route>
      <Route
        path="/tenants/add/reservation-success/:reservationId"
        component={ReservationSuccess}
      ></Route>
      {/* <Route
        path="/tenants/add/marketing-and-followup/:reservationId"
        component={MarketingAndFollowup}
      ></Route> */}
      {/* <Route
        path="/tenants/add/additional-info/:reservationId"
        component={AdditionalInfo}
      ></Route> */}
      <Route
        path="/tenants/add/personal-info/:reservationId"
        component={PersonalInfo}
      ></Route>
      <Route
        path="/tenants/add/insurance/:reservationId"
        component={NewInsurancePopup}
      ></Route>
      <Route
        path="/tenants/add/finalize/:reservationId"
        component={FinalizeResrvation}
      ></Route>
      <Route
        path="/tenants/add/payment-info/:reservationId"
        component={ReservationPayment}
      ></Route>
      <Route
        path="/tenants/add/documents/:reservationId/:paymethod"
        component={DocumentsSelection}
      ></Route>
      <Route
        path="/tenants/add/process-payment/:reservationId/:paymethod"
        component={ProcessPayment}
      ></Route>
      <Route
        path="/tenants/add/thank-you/:reservationId"
        component={ThankYou}
      ></Route>
      {/* <Route
        path="/tenants/add/tenant-info/:reservationId"
        component={TenantInfo}
      ></Route> */}

      <Route path="/tenants/add/ids/:reservationId" component={Ids}></Route>
    </>
  );
};

export default AddTenant;
