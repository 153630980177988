import { useEffect, useState } from "react";
import axios from "axios";
import SettingsNav from "../settings-nav/SettingNav";

import pageStyles from "../SettingsHome.module.scss";
import styles from "./Locations.module.scss";
import { useSelector } from "react-redux";
import { Route, Link } from "react-router-dom";
import LocationSetup from "./location-setup/LocationSetup";
const Locations = () => {
  const { user } = useSelector(({ user }) => ({ user }));

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    let re = await axios.get(`/api/location/account/${user.account}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setLocations(re?.data?.data || []);
  };

  useEffect(() => {
    if (!user) return;
    getLocations();
    // eslint-disable-next-line
  }, [user]);
  return (
    <>
      <SettingsNav />
      <div className={pageStyles.page}>
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>Locations</p>
              <p className={`ffnr fs12 m-t-10`}>Setup your account locations</p>
            </div>
          </div>

          {locations.map((location) => (
            <Link
              to={`/settings/locations/${location._id}`}
              className={`df acsa ffnsb fs14 ${styles.locationLine}`}
            >
              <div
                className={`df acc fs12 ${styles.locationAvatar}`}
                style={{ background: location.color }}
              >
                {location.name[0]}
              </div>
              <p>{location.name}</p>
            </Link>
          ))}
        </div>

        <Route path="/settings/locations/:id" component={LocationSetup}></Route>
      </div>
    </>
  );
};

export default Locations;
