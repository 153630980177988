import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import Portal from "../../../../components/portal/Portal";
// import { GET_ACTIVE_RESERVATION } from "../../../../store/actions/types";
import styles from "./Finalize.module.scss";
import DatePicker from "../../../../components/inputs/datepicker/DatePicker";
import NonFormikSelect from "../../../../components/inputs/drop-down/non-formik/NonFormikSelect";
import { useHistory, useParams } from "react-router-dom";
import { formatFromCurrencyToMoney } from "../../../../helpers";
import { useSelector } from "react-redux";

const dates = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28,
];

const FinalizeResrvation = (props) => {
  // let dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  // eslint-disable-next-line
  const [reservation, setReservation] = useState({});
  const [showPopup, setPopup] = useState(undefined);
  // eslint-disable-next-line
  const [gateAccessCode, setGateAccessCode] = useState("");
  const [invoiceDay, setInvoiceDay] = useState(undefined);
  const [activeReservation, setActiveReservation] = useState(undefined);
  const [reservationCost, setReservationCost] = useState(undefined);

  // const [autoPay, setAutopay] = useState(false);
  // const [autopayDay, setAutopayDay] = useState(1);
  const [expectedMoveInDate, setExpectedMoveInDate] = useState();

  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const setActiveReservationAction = async () => {
    let d = await axios.get(`/api/portal-reservation/${params.reservationId}`);
    setActiveReservation(d.data?.data);
  };

  const getReservationDetails = async () => {
    let d = await axios.get(
      `/api/reserve-unit/?unitid=${activeReservation?.unit?._id}&moveindate=${expectedMoveInDate}&reservationid=${params.reservationId}`
    );

    setReservation(d.data.data);
  };

  useEffect(() => {
    if (!activeReservation) {
      return setActiveReservationAction();
    }

    setExpectedMoveInDate(activeReservation.expectedMoveInDate);
    // eslint-disable-next-line
  }, [activeReservation]);

  // insuranceBracket

  const getReservationCost = async () => {
    console.log("activeReservation ::: ", reduxUser);
    let theUnit = activeReservation?.unit;
    let url = `/api/unit-details/?size=${
      theUnit?.unitSize?._id
    }&features=${activeReservation?.unitFeatures
      ?.map((f) => f._id)
      .join(",")}&location=${
      activeReservation?.unit?.unitLocation
    }&date=${expectedMoveInDate}&websiteProfileId=${
      reduxUser?.selectedLocation?.defaultWebsiteProfile
    }&insuranceId=${activeReservation?.insuranceBracket}`;
    console.log("This is my running task###");
    const cost = await axios.get(url);

    console.log("cost.data.cost", cost.data.cost);

    setReservationCost(cost.data.cost);
  };

  useEffect(() => {
    if (!expectedMoveInDate || !activeReservation) return;
    console.log("Active reservation ", activeReservation);
    getReservationDetails();
    getReservationCost();
    // eslint-disable-next-line
  }, [expectedMoveInDate, activeReservation]);

  useEffect(() => {
    console.log(params.reservationId);
  }, [params.reservationId]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!activeReservation || invoiceDay != undefined) return;
    console.log(activeReservation.invoiceDay);
    setInvoiceDay(
      {
        label: activeReservation.invoiceDay,
        value: activeReservation.invoiceDay,
      } || {
        label: 1,
        value: 1,
      }
    );
    setGateAccessCode(activeReservation?.user?.gateKey);
    // eslint-disable-next-line
  }, [activeReservation]);

  // const setAutopayAction = (idx, value) => {
  //   if (value === false) setAutopayDay(undefined);
  //   setAutopay(value);
  // };

  const updateReservation = async (next) => {
    setSaving(true);
    const token = await localStorage.getItem("x-auth");
    if (true) {
      await axios.put(
        `/api/web-user/${activeReservation?.user?._id}/?simple=true`,
        { gateKey: gateAccessCode },
        { headers: { "x-auth": token } }
      );
    }

    await axios.put(
      `/api/reservation/${params.reservationId}`,
      { expectedMoveInDate: expectedMoveInDate, invoiceDay: invoiceDay?.value },
      { headers: { "x-auth": token } }
    );
    setSaving(false);

    history.push(`/tenants/add/payment-info/${params.reservationId}`);

    // payment-info
  };

  return (
    <Portal
      minHeight="438px"
      width="354px"
      height="auto"
      zIndex="100"
      opacity="0.45"
      title="Finalize"
      close={() => history.push("/tenants")}
      overlayClick={() => (showPopup ? setPopup(undefined) : null)}
      noCustomScrollbar={true}
    >
      <div className={`df ffc ${styles.finalizePopup}`}>
        <div className={styles.itemsWrpr}>
          {/* *************** */}
          {/* {reservation.proRatedMonth ? (
            <>
              <div className={`df acsa ${styles.line}`}>
                <div className={`ffnr fs14 ${styles.leftSec}`}>
                  <p className={``}>
                    Unit #<span className={`ttuc`}>{reservation.unitId}</span>{" "}
                    {reservation?.unitSize?.label} (
                    {reservation?.unitSize?.length}x
                    {reservation?.unitSize?.width})
                  </p>
                  <p>
                    {dayjs(
                      expectedMoveInDate || reservation.expectedMoveInDate
                    ).format("MM/DD/YY")}{" "}
                    -{" "}
                    {dayjs(expectedMoveInDate || reservation.expectedMoveInDate)
                      .endOf("month")
                      .format("MM/DD/YY")}{" "}
                    (prorated)
                  </p>
                </div>
                <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                  {formatFromCurrencyToMoney(Number(reservation.proRatedMonth))}
                </div>
              </div>
              <div className={`df acsa ${styles.line}`}>
                <div className={`ffnr fs14 ${styles.leftSec}`}>
                  <p className={``}>Tenant protection</p>
                  <p>
                    {dayjs(
                      expectedMoveInDate || reservation.expectedMoveInDate
                    ).format("MM/DD/YY")}{" "}
                    -{" "}
                    {dayjs(expectedMoveInDate || reservation.expectedMoveInDate)
                      .endOf("month")
                      .format("MM/DD/YY")}{" "}
                    (prorated)
                  </p>
                </div>
                <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                  {formatFromCurrencyToMoney(
                    Number(reservation.proRatedInsurance)
                  )}
                </div>
              </div>
            </>
          ) : undefined} */}
          {/* *************** */}
          {reservationCost?.lineItems?.map((line) => (
            <div className={`df acsa ${styles.line}`}>
              <div className={`ffnr fs14 ${styles.leftSec}`}>
                <p className={`${styles.label}`}>
                  {/* Unit #<span className={`ttuc`}>{reservation.unitId}</span>{" "}
                  {reservation?.unitSize?.label} (
                  {reservation?.unitSize?.length}x{reservation?.unitSize?.width}
                  ) */}
                  {line?.label}
                </p>

                <p>{line?.subLabel}</p>
              </div>
              <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                {formatFromCurrencyToMoney(line.amount)}
              </div>
            </div>
          ))}
          {/* <div className={`df acsa ${styles.line}`}>
            <div className={`ffnr fs14 ${styles.leftSec}`}>
              <p className={``}>Tenant protection</p>
              <p>
                {dayjs(expectedMoveInDate || reservation.expectedMoveInDate)
                  .add(1, "month")
                  .startOf("month")
                  .format("MM/DD/YY")}{" "}
                -{" "}
                {dayjs(expectedMoveInDate || reservation.expectedMoveInDate)
                  .add(1, "month")
                  .endOf("month")
                  .format("MM/DD/YY")}
              </p>
            </div>
            <div className={`mla ffnr fs14 ${styles.rightSec}`}>
              {formatFromCurrencyToMoney(Number(reservation.monthlyInsurance))}
            </div>
          </div> */}

          {/* ******** */}
          {/* {reservation?.securityDeposit ? (
            <div className={`df acsa ${styles.line}`}>
              <div className={`ffnr fs14 ${styles.leftSec}`}>
                <p className={``}>Security Deposit</p>
              </div>
              <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                {formatFromCurrencyToMoney(
                  Number(reservation?.securityDeposit)
                )}
              </div>
            </div>
          ) : undefined} */}

          {/* ********* */}
          {/* {reservation?.adminFee ? (
            <div className={`df acsa ${styles.line}`}>
              <div className={`ffnr fs14 ${styles.leftSec}`}>
                <p className={``}>Admin fee</p>
              </div>
              <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                {formatFromCurrencyToMoney(Number(reservation?.adminFee))}
              </div>
            </div>
          ) : undefined} */}

          {/* ******** */}

          <div className={`df acsa ${styles.line} ${styles.full}`}>
            <div className={`mla ffnsb fs14 ${styles.rightSec}`}>
              {/* <p className={`df acsa ${styles.totalLine}`}>
                <span className={styles.label}>Subtotal</span>{" "}
                <span className={`mla`}>
                  {formatFromCurrencyToMoney(
                    Number(reservation.totalBeforeTaxes)
                  )}
                </span>
              </p> */}
              <p className={`df acsa ${styles.totalLine}`}>
                <span className={styles.label}>Taxes</span>
                <span className={`mla`}>
                  {formatFromCurrencyToMoney(
                    Number(reservationCost?.totalTaxesDueToday)
                  )}
                </span>
              </p>
              <p className={`df acsa ${styles.totalLine}`}>
                <span className={styles.label}>Total due</span>
                <span className={`mla`}>
                  {formatFromCurrencyToMoney(
                    Number(reservationCost?.totalDueToday)
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>

        {expectedMoveInDate ? (
          <DatePicker
            defaultDate={expectedMoveInDate}
            size="large"
            field=""
            onChange={(a, date) => {
              console.log(date);
              setExpectedMoveInDate(date);
            }}
          ></DatePicker>
        ) : undefined}

        {/* <NonFormikInput
          id="gate_access"
          label="Gate Access Code"
          type="text"
          value={gateAccessCode}
          change={setGateAccessCode}
          disabled={activeReservation?.user?.gateKey}
        ></NonFormikInput> */}

        {/* <NonFormikInput
          id="invoice_day"
          label="Invoice day"
          type="text"
          value={invoiceDay}
          change={setInvoiceDay}
        ></NonFormikInput> */}
        <NonFormikSelect
          menuPortalTarget={document.body}
          options={dates.map((ic) => ({
            label: ic,
            value: ic,
          }))}
          onChange={setInvoiceDay}
          value={invoiceDay}
          label="Select invoice due date"
        />

        <button
          className={`ffnb fs14 ttuc sbmt_btn primary m-t-10`}
          onClick={updateReservation}
          disabled={
            // !gateAccessCode ||
            !expectedMoveInDate || !invoiceDay || saving
          }
        >
          Next
        </button>

        <button
          disabled={
            // !gateAccessCode ||
            !expectedMoveInDate || !invoiceDay || saving
          }
          className={` ffnb fs14 ttuc sbmt_btn secondary m-t-10`}
        >
          Save
        </button>
      </div>
    </Portal>
  );
};

export default FinalizeResrvation;
