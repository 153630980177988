import axios from "axios";
import { useEffect, useState } from "react";
import RentRollTable from "./RentRollTable";

import styles from "./RentRoll.module.scss";

const RentRoll = () => {
  const [report, setReport] = useState(undefined);

  const getReport = async () => {
    let localReport = await axios.get("/api/reports/rent-roll");

    setReport(localReport.data);
  };

  useEffect(() => {
    getReport();
  }, []);
  return (
    <div className={styles.rentRoll}>
      {report ? <RentRollTable data={report} /> : undefined}
    </div>
  );
};

export default RentRoll;
