import React, { useEffect, useState } from "react";
import axios from "axios";
import importStyles from "../Import.module.scss";
import styles from "./ReservationList.module.scss";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const ReservationsList = ({ done }) => {
  const params = useParams();
  const [list, setList] = useState([]);

  const getReservations = async () => {
    let res = await axios.get(`/api/new-import/reservations/${params.id}`);
    setList(res.data);
  };

  useEffect(() => {
    getReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.reservationList} ${importStyles.card}`}>
      <div className={`df acsa ${importStyles.cardHdr}`}>
        <div>
          <p className={`ffnsb fs12 ttuc`}>
            Easy Storage Import | Review reservations
          </p>
          <p className={`ffnr fs12 m-t-10`}>Review your reservations.</p>
        </div>
      </div>
      <div className={`df aic ffc ${importStyles.contentWrpr}`}>
        <div
          className={`df acsa ffnr fs12 ttuc ${importStyles.hdr} ${importStyles.line}`}
        >
          <p className={styles.tnSec}>Tenant Name</p>
          <p className={styles.idSec}>Unit ID</p>{" "}
          <p className={styles.sizeSec}>Unit size (l x w x h)</p>
          <p className={styles.typeSec}>Unit Type</p>
          <p className={styles.moveinSec}>Movein Date</p>
        </div>
        {list.map((user, idx) => (
          <React.Fragment key={user._id}>
            {user.reservations.map(({ unit, moveInDate }, index) => (
              <div
                key={`${idx}-${index}-${unit._id}`}
                className={`df acsa ffnr fs14  ${importStyles.line}`}
              >
                <p className={styles.tnSec}>{user?.user?.fullName}</p>
                <p className={styles.idSec}>{unit.unitId}</p>
                <p className={styles.sizeSec}>
                  {unit.unitSize.length}x{unit.unitSize.width}x
                  {unit.unitSize.height} ({unit.unitSize.label})
                </p>
                <p className={styles.typeSec}>{unit.unitType.label}</p>
                <p className={styles.moveinSec}>
                  {dayjs(moveInDate).format("MM/DD/YYYY")}
                </p>

                {/*  {console.log("reservation")} */}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>

      <button onClick={done} className={`button primary w100 m-t-20 ttuc`}>
        All good
      </button>
    </div>
  );
};

export default ReservationsList;
