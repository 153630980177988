import axios from "axios";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BackArrow from "../../../../assets/js-svgs/back-arrow.svg";
// import SearchIcon from "../../../../assets/js-svgs/search-icon.svg";
import DatePicker from "../../../../components/inputs/datepicker/DatePicker";
import NonFormikSelect from "../../../../components/inputs/drop-down/non-formik/NonFormikSelect";
import Portal from "../../../../components/portal/Portal";
import {
  addReservation,
  getAllUnitsForReservation,
  getLengthOfStayList,
  reserveUnit,
} from "../../../../store/actions";
// import ReservationStatus from "../reservation-status'/ReservationStatus";
import "./AllUnits.scss";
import { formatFromCurrencyToMoney } from "../../../../helpers";

const AllUnits = (props) => {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  // create a function to get the "unit" from location.search
  const getUnit = () => {
    const search = new URLSearchParams(location.search);
    const unit = search.get("unit");
    return unit;
  };
  // const [searchTerm, setSearchTerm] = useState("");
  const [reservationUser, setReservationUser] = useState(undefined);
  const [selectedId, setSelectedId] = useState(undefined);
  // eslint-disable-next-line
  const [LOSList, setLOSList] = useState([]);
  const [selectedLOS, setSelectedLOS] = useState(undefined);
  const [selectedUnitId, setSelectedUnitId] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [unitsList, setUnitsList] = useState([]);
  const [expectedMoveInDate, setExpectedMoveInDate] = useState(
    Number(new Date())
  );
  const [saving, setSaving] = useState(false);
  const [hasUnitQuery, setHasUnitQuery] = useState(getUnit());
  const [reservationCost, setReservationCost] = useState(undefined);

  const getUnitSizeList = async () => {
    const units = await axios.post("/api/unique-unit-sizes-populated", {
      locationId: reduxUser.selectedLocation._id,
    });
    setUnitsList(units.data);
  };

  const getLengthOfStayList = async () => {
    const LOS = await axios.get("/api/length-of-stay-list");
    setLOSList(LOS.data.data);
  };

  const getUserDetails = async () => {
    let user = await axios.get(`/api/web-user/?user=${params.userId}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });
    setReservationUser(user.data);
  };

  const setUnitFromQuery = () => {
    let typeIDX = unitsList.findIndex((unit) =>
      unit?.units.some((elem) => elem._id === hasUnitQuery)
    );

    if (typeIDX === -1) return;

    let selectedUnitFromQuery = unitsList[typeIDX].units?.find(
      (u) => u._id === hasUnitQuery
    );

    let temp = {
      label: selectedUnitFromQuery.unitId,
      value: selectedUnitFromQuery._id,
    };
    setSelectedId(typeIDX);
    setSelectedUnitId(temp);

    setHasUnitQuery(undefined);

    // if (typeIDX > -1) {
    //   setSelectedItem(unitsList[typeIDX]);
    // }
  };

  // size: 65ca9ddf9ca4beb8d3437692
  // features: 620988865072dff52a4d6909,620988865072dff52a4d690e
  // location: 6495bdda4cf9d5e3ec0599c8
  // date: 1712000472804
  // websiteProfileId: 649dd3224cf9d5e3ec059bd4
  // insuranceId: 64a498f0c847630133873bad

  const getReservationCost = async () => {
    // console.log(selectedItem.find((u) => u._id === selectedUnitId.value));
    const theUnit = selectedItem.find((u) => u._id === selectedUnitId.value);
    console.log(
      "The unit is ",
      theUnit,
      reduxUser?.selectedLocation?.defaultWebsiteProfile,

      theUnit?.unit?.unitLocation,
      theUnit?.unitSize?._id,
      theUnit?.unitFeatures?.map((f) => f._id).join(",")
    );
    let url = `/api/unit-details/?size=${
      theUnit?.unitSize?._id
    }&features=${theUnit?.unitFeatures?.map((f) => f._id).join(",")}&location=${
      theUnit?.unit?.unitLocation
    }&date=${expectedMoveInDate}&websiteProfileId=${
      reduxUser?.selectedLocation?.defaultWebsiteProfile
    }`;
    console.log("This is my running task###");
    const cost = await axios.get(url);
    console.log("cost.data", cost.data);
    setReservationCost(cost.data.cost);
  };

  useEffect(() => {
    if (!selectedUnitId || !expectedMoveInDate) return;
    getReservationCost();
    // eslint-disable-next-line
  }, [selectedUnitId, expectedMoveInDate]);

  useEffect(() => {
    getUnitSizeList();
    getLengthOfStayList();
    getUserDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("LOLOLOL", params);
    const search = new URLSearchParams(location.search);
    const unit = search.get("unit");

    console.log(unit);
    if (!unitsList?.length || !hasUnitQuery) return;
    setUnitFromQuery();
    // eslint-disable-next-line
  }, [unitsList]);

  const save = async (next) => {
    setSaving(true);

    let selectedLocation = JSON.parse(localStorage.getItem("selectedLocation"));

    let reservation = await axios.post(
      "/api/reservation/false/?setupPrice=true",
      {
        user: params.userId,
        unit: selectedUnitId.value,
        locationId: reduxUser.selectedLocation._id,
        accountId: user.account,
        expectedMoveInDate: expectedMoveInDate,
        initialPayment: false,
        address: reservationUser?.defaultAddress?._id,
        phoneNumber: reservationUser?.defaultPhoneNumber?._id,
        lengthOfStayId: selectedLOS?.value,
        isDraft: next,
        webProfileId: selectedLocation.selectedLocation.defaultWebsiteProfile,
      },
      {
        headers: {
          "x-auth": localStorage.getItem("x-auth"),
        },
      }
    );

    if (reservation.data.message === "ok") {
      if (next) {
        history.push(`/tenants/add/personal-info/${reservation.data.data._id}`);
      } else {
        history.push("/tenants");
      }
    }
  };

  useEffect(() => {
    if (hasUnitQuery) return;
    if (selectedId === undefined) return;
    setSelectedItem(unitsList[selectedId].units);
    // eslint-disable-next-line
  }, [selectedId]);

  return (
    <Portal
      minHeight="360px"
      // maxHeight="632px"
      width="354px"
      height="auto"
      zIndex="100"
      opacity="0.45"
      title="Select a unit"
      close={() => history.push("/tenants")}
      noCustomScrollbar={true}
    >
      <div className="df ffc all_units_popup">
        {/* <div className="unit_srch_wrpr">
          <div className="srch_icn_wrpr">
            <SearchIcon fill="rgba(0,0,0,0.3)"></SearchIcon>
          </div>
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Find units"
          ></input>
        </div> */}

        {/* <ReservationStatus /> */}

        <div className="units_list_wrpr">
          {console.log(
            "::::::{}{}{}:::::::::",
            JSON.parse(localStorage.getItem("selectedLocation")),
            reduxUser
          )}
          {selectedId === undefined ? (
            unitsList
              // .filter(
              //   (el) => {
              //     return (
              //       el._id.size.label.toLowerCase().includes(searchTerm) ||
              //       el._id.size.length.toString().includes(searchTerm) ||
              //       el._id.size.width.toString().includes(searchTerm) ||
              //       el._id.size.price.toString().includes(searchTerm) ||
              //       el._id.feature.some((fe) =>
              //         fe.label.includes(searchTerm)
              //       ) ||
              //       el._id.unitType?.label
              //         ?.toLowerCase()
              //         .includes(searchTerm) ||
              //       el.units.some((u) =>
              //         u.unitId.toLowerCase().includes(searchTerm)
              //       )
              //     );

              //   }
              // )
              .map((unit, index) => {
                return (
                  <div
                    key={index}
                    className={`line_wrpr ${
                      unit.units.filter((line) => !line.hasReservation)
                        .length === 0
                        ? "no_units"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        unit.units.filter((line) => !line.hasReservation)
                          .length === 0
                      )
                        return;
                      setSelectedId(index);
                    }}
                  >
                    <div className="df acsa ffnr fs14 pdt_op75 line top">
                      <p className="label flcap">
                        {unit._id.size?.label} ({unit._id.size?.length}x
                        {unit._id.size?.width})
                      </p>
                      <p className="mla value">
                        {formatFromCurrencyToMoney(unit._id.size?.price)}
                      </p>
                    </div>

                    <div className="df acsa ffnr pdt_op50 fs14 line">
                      <div className="df acsa features_wrpr">
                        <p className="df acsa">
                          <span>(</span>
                          <span
                            className="flcap"
                            style={{
                              display: "block",
                            }}
                          >
                            {unit._id.unitType?.label}
                          </span>
                          <span>) </span>
                        </p>
                        <p className="other_features">
                          {" "}
                          -{" "}
                          {unit._id.feature.map((feature, idx) => (
                            <span className="ttcaps" key={feature._id}>
                              {feature.label.trim()}
                              {idx < unit._id.feature.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </p>
                      </div>
                      <p className="mla">
                        {
                          unit.units.filter((line) => !line.hasReservation)
                            .length
                        }
                        /{unit.units.length}
                      </p>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="selected_unit_wrpr">
              <div className="df acsa hdr">
                <div
                  className="df acc back_btn"
                  onClick={() => {
                    setSelectedId(undefined);
                    setSelectedLOS(undefined);
                    setSelectedUnitId(undefined);
                    setSelectedItem(undefined);
                    setReservationCost(undefined);
                  }}
                >
                  <BackArrow></BackArrow>
                </div>
              </div>
              <div className="df ffc content">
                <div className="line_wrpr">
                  <div className="df acsa ffnr fs14 pdt_op75 line top">
                    {selectedItem?.length ? (
                      <p className="label flcap">
                        {selectedItem[0].unitSize.label} (
                        {selectedItem[0].unitSize.length}x
                        {selectedItem[0].unitSize.width})
                      </p>
                    ) : undefined}
                    <p className="mla value">
                      {/* ${selectedItem[0].unitSize.price} */}
                    </p>
                  </div>

                  <div className="df acsa ffnr pdt_op50 fs14 line">
                    <div className="df acsa features_wrpr">
                      <p className="df acsa">
                        <span>(</span>
                        <span
                          className="flcap"
                          style={{
                            display: "block",
                          }}
                        >
                          {selectedItem?.length &&
                            selectedItem[0].unitType?.label}
                        </span>
                        <span>)</span>
                      </p>
                      <p className="other_features">
                        {" "}
                        -{" "}
                        {selectedItem?.length
                          ? selectedItem[0]?.unitFeatures?.map(
                              (feature, idx) => (
                                <span className="ttcaps" key={feature._id}>
                                  {feature.label.trim()}
                                  {idx <
                                  selectedItem[0]?.unitFeatures?.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              )
                            )
                          : undefined}
                      </p>
                    </div>
                    <p className="mla">
                      {selectedItem !== undefined
                        ? selectedItem.filter((line) => !line.hasReservation)
                            .length
                        : ""}
                      /{selectedItem !== undefined ? selectedItem.length : ""}
                    </p>
                  </div>
                </div>

                <div className="dds_wrpr" onAuxClick={(e) => console.log}>
                  <NonFormikSelect
                    onChange={setSelectedUnitId}
                    value={selectedUnitId}
                    options={selectedItem
                      ?.filter((el) => !el.hasReservation)
                      .map((line) => ({
                        label: line.unitId,
                        value: line._id,
                        disabled: line.hasReservation ? true : false,
                      }))}
                    size="small"
                    menuPortalTarget={document.body}
                    label="Select unit ID"
                  ></NonFormikSelect>

                  <DatePicker
                    size="small"
                    field=""
                    onChange={(a, b) => setExpectedMoveInDate(b)}
                    value={expectedMoveInDate}
                    minDate={Number(dayjs(new Date()).startOf("day"))}
                  ></DatePicker>
                  {/* <NonFormikSelect
                    label="Select length of stay"
                    onChange={setSelectedLOS}
                    value={selectedLOS}
                    options={LOSList.map((line) => ({
                      label: line.label,
                      value: line._id,
                    }))}
                    size="small"
                    menuPortalTarget={document.body}
                  ></NonFormikSelect> */}
                </div>

                <div className="ffnr fs14 lines_wrpr">
                  {reservationCost?.lineItems.map((line) => (
                    <div className="df acsa line">
                      <p className="lbl">{line.label}</p>
                      <p className="mla val">
                        {reservationCost
                          ? `${formatFromCurrencyToMoney(line?.amount)}`
                          : "--"}
                      </p>
                    </div>
                  ))}

                  {/* {reservationCost?.proRatedMonth ? (
                    <div className="df acsa line">
                      <p className="lbl">Prorated month</p>
                      <p className="mla val">
                        {reservationCost
                          ? `${formatFromCurrencyToMoney(
                              Number(reservationCost.proRatedMonth)
                            )}`
                          : "--"}
                      </p>
                    </div>
                  ) : undefined} */}

                  {/* {reservationCost?.adminFee ? (
                    <div className="df acsa line">
                      <p className="lbl">Administrative Fees</p>
                      <p className="mla val">
                        {reservationCost
                          ? `${formatFromCurrencyToMoney(
                              Number(reservationCost.adminFee)
                            )}`
                          : "--"}
                      </p>
                    </div>
                  ) : undefined} */}
                  <div className="ffnsb df acsa line full">
                    <p className="lbl">Total Due at Move-In </p>
                    <p className="mla val">
                      {reservationCost
                        ? `${formatFromCurrencyToMoney(
                            reservationCost.totalDueToday
                          )}`
                        : "--"}
                    </p>
                  </div>
                  {/* <div className="df acsa line">
                    <p className="lbl">Total Due Monthly </p>
                    <p className="mla val">{reservationCost ? "OK" : "--"}</p>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          {/* {this.state.selectedId === undefined ? (
            this.props.reservationUnits?.units?.map((unit, index) => (
              <div
                key={index}
                className="line_wrpr"
                onClick={() =>
                  this.setState({
                    selectedId: index,
                  })
                }
              >
                <div className="df acsa ffnr fs14 pdt_op75 line top">
                  <p className="label flcap">
                    {unit._id.size.label} ({unit._id.size.length}x
                    {unit._id.size.width})
                  </p>
                  <p className="mla value">${unit._id.size.price}</p>
                </div>

                <div className="df acsa ffnr pdt_op50 fs14 line">
                  <div className="df acsa features_wrpr">
                    <p className="df acsa">
                      <span>(</span>
                      <span
                        className="flcap"
                        style={{
                          display: "block",
                        }}
                      >
                        {unit._id.unitType?.label}
                      </span>
                      <span>) </span>
                    </p>
                    <p className="other_features">
                      {" "}
                      -{" "}
                      {unit._id.feature.map((feature, idx) => (
                        <span className="ttcaps" key={feature._id}>
                          {feature.label.trim()}
                          {idx < unit._id.feature.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </p>
                  </div>
                  <p className="mla">
                    {unit.units.filter((line) => !line.hasReservation).length}/
                    {unit.units.length}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <>
              {selectedItem && selectedItem.length >= 0 && (
                <div className="selected_unit_wrpr">
                  <div className="df acsa hdr">
                    <div
                      className="df acc back_btn"
                      onClick={() =>
                        this.setState({
                          selectedId: undefined,
                          selectedLOS: undefined,
                          selectedUnitId: undefined,
                        })
                      }
                    >
                      <BackArrow></BackArrow>
                    </div>
                  </div>
                  <div className="df ffc content">
                    <div className="line_wrpr">
                      <div className="df acsa ffnr fs14 pdt_op75 line top">
                        <p className="label flcap">
                          {selectedItem[0].unitSize.label} (
                          {selectedItem[0].unitSize.length}x
                          {selectedItem[0].unitSize.width})
                        </p>
                        <p className="mla value">
                          ${selectedItem[0].unitSize.price}
                        </p>
                      </div>

                      <div className="df acsa ffnr pdt_op50 fs14 line">
                        <div className="df acsa features_wrpr">
                          <p className="df acsa">
                            <span>(</span>
                            <span
                              className="flcap"
                              style={{
                                display: "block",
                              }}
                            >
                              {selectedItem[0].unitType?.label}
                            </span>
                            <span>)</span>
                          </p>
                          <p className="other_features">
                            {" "}
                            -{" "}
                            {selectedItem[0].unitFeatures.map(
                              (feature, idx) => (
                                <span className="ttcaps" key={feature._id}>
                                  {feature.label.trim()}
                                  {idx < selectedItem[0].unitFeatures.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              )
                            )}
                          </p>
                        </div>
                        <p className="mla">
                          {
                            selectedItem.filter((line) => !line.hasReservation)
                              .length
                          }
                          /{selectedItem.length}
                        </p>
                      </div>
                    </div>

                    <div className="dds_wrpr" onAuxClick={(e) => console.log}>
                      <NonFormikSelect
                        onChange={this.onChangeUnit}
                        value={this.state.selectedUnitId}
                        options={selectedItem.map((line) => ({
                          label: line.unitId,
                          value: line._id,
                          disabled: line.hasReservation ? true : false,
                        }))}
                        size="small"
                      ></NonFormikSelect>

                      <DatePicker
                        size="small"
                        field=""
                        onChange={this.onChangeDate}
                      ></DatePicker>

                      <NonFormikSelect
                        onChange={this.onChangeLOS}
                        value={this.state.selectedLOS}
                        options={this.props.lengthOfStayList.map((line) => ({
                          label: line.label,
                          value: line._id,
                        }))}
                        size="small"
                      ></NonFormikSelect>
                    </div>

                    <div className="ffnr fs14 lines_wrpr">
                      <div className="df acsa line">
                        <p className="lbl">Rent Rate</p>
                        <p className="mla val">
                          ${selectedItem[0].unitSize.price}
                        </p>
                      </div>

                      <div className="df acsa line">
                        <p className="lbl"> Administrative Fees </p>
                        <p className="mla val">$20</p>
                      </div>
                      <div className="df acsa line">
                        <p className="lbl">Total Due at Move-In </p>
                        <p className="mla val">$20</p>
                      </div>
                      <div className="df acsa line">
                        <p className="lbl">Total Due Monthly </p>
                        <p className="mla val">$20</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )} */}
        </div>

        {selectedId !== undefined ? (
          <>
            <button
              disabled={!selectedUnitId || saving}
              className="ffnb fs14 ttuc sbmt_btn primary m-t-10"
              onClick={() => save(true)}
            >
              Next
            </button>
            <button
              disabled={!selectedUnitId || saving}
              className="ffnb fs14 ttuc sbmt_btn secondary m-t-10"
              onClick={() => save(false)}
            >
              {/* {console.log(this.props.reduxUser.selectedLocation._id)} */}
              Save
            </button>
          </>
        ) : undefined}
      </div>
    </Portal>
  );
  // }
};

// const mapStateToProps = ({
//   reduxUser,
//   reservationUnits,
//   user,
//   activeReservation,
//   lengthOfStayList,
// }) => ({
//   reduxUser,
//   reservationUnits,
//   user,
//   activeReservation,
//   lengthOfStayList,
// });

export default connect(undefined, {
  getAllUnitsForReservation,
  reserveUnit,
  addReservation,
  getLengthOfStayList,
})(AllUnits);
