export const camelCaseToText = (camelCase) => {
  var result = camelCase.replace(/([A-Z])/g, " $1");
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

// Source https://stackoverflow.com/a/30810322/3161811
export const copyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = 0;
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log('Copied "' + text + " " + msg + '" to clipboard');
  } catch (err) {
    console.log("Oops, unable to copy");
  }

  document.body.removeChild(textArea);
};

export const formatMoney = (amount = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(amount));
};

export const formatFromCurrencyToMoney = (amount = 0) => {
  amount = Number(amount);
  amount = amount / 100;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(amount));
};
