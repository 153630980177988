const NoteSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M9.97541 17.5H9.97613C10.2438 17.5004 10.5083 17.4432 10.7517 17.3323L10.8505 17.2873H10.8917C11.0639 17.1963 11.2218 17.0801 11.3599 16.9425L9.97541 17.5ZM9.97541 17.5H2.45902C1.93916 17.5 1.44078 17.2939 1.07346 16.9272C0.706174 16.5606 0.5 16.0635 0.5 15.5455V2.45455C0.5 1.93646 0.706174 1.43941 1.07346 1.07279C1.44078 0.706145 1.93916 0.5 2.45902 0.5H15.5703C16.0844 0.507665 16.5749 0.716723 16.9358 1.08189C17.2975 1.44776 17.5 1.9409 17.5 2.4545V2.45455V9.97363V9.97381C17.5001 10.2427 17.4446 10.5088 17.3369 10.7553L17.2951 10.851V10.8924C17.2085 11.0627 17.0951 11.2184 16.9592 11.3534L16.9582 11.3543L11.3601 16.9423L9.97541 17.5ZM16.3934 10.3182H16.8934V9.81818V2.45455C16.8934 2.10465 16.7542 1.76927 16.5066 1.52213C16.259 1.27502 15.9235 1.13636 15.5738 1.13636H2.45902C2.10931 1.13636 1.77375 1.27502 1.52619 1.52213C1.2786 1.76927 1.13934 2.10465 1.13934 2.45455V15.5455C1.13934 15.8953 1.2786 16.2307 1.52619 16.4779C1.77375 16.725 2.10931 16.8636 2.45902 16.8636H9.83607H10.3361V16.3636V11.4545C10.3361 11.1535 10.4559 10.8645 10.6695 10.6513C10.883 10.4381 11.1729 10.3182 11.4754 10.3182H16.3934ZM10.9754 15.21V16.4156L11.8286 15.5639L15.5909 11.8084L16.4464 10.9545H15.2377H11.4754H10.9754V11.4545V15.21Z"
      fill="#5B5B5B"
      stroke="#1F2041"
    />
  </svg>
);

export default NoteSVG;
