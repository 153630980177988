export const GET_USER = "GET_USER";
export const SET_REDUX_USER = "SET_REDUX_USER";
export const GET_UNIT_TYPES = "GET_UNIT_TYPES";
export const GET_UNIT_STATUSES = "GET_UNIT_STATUSES";
export const GET_UNIT_SIZES = "GET_UNIT_SIZES";
export const GET_UNIT_FEATURES = "GET_UNIT_FEATURES";
export const ADD_UNIT = "ADD_UNIT";
export const GET_UNITS = "GET_UNITS";
export const GET_TENANTS = "GET_TENANTS";
export const SET_BLUR = "SET_BLUR";
export const ERROR_ADDING_USER = "ERROR_ADDING_USER";
export const ADDED_USER = "ADDED_USER";
export const GET_UNITS_FOR_RESERVATION = "GET_UNITS_FOR_RESERVATION";
export const GET_ACTIVE_RESERVATION = "GET_ACTIVE_RESERVATION";
export const GET_LENGTH_OF_STAY_LIST = "GET_LENGTH_OF_STAY_LIST";
export const GET_TASKS = "GET_TASKS";
export const SET_TASKS_VISIBLE = "SET_TASKS_VISIBLE";
export const SET_TASK_COMPLETE = "SET_TASK_COMPLETE";
export const SET_TASK_STARRED = "SET_TASK_STARRED";
