import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Promos = () => {
  const [promos, setPromos] = useState([]);

  const { reduxUser } = useSelector(({ reduxUser }) => ({
    reduxUser,
  }));

  const getPromos = async () => {
    let localPromos = await axios.get(
      `/api/promos/${reduxUser.selectedLocation._id}`,
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );
    setPromos(localPromos?.data?.data);
  };

  const savePromo = async () => {
    let localPromo = await axios.post(
      `/api/promo`,
      {
        title: "20% off units",
        description: "20% off units for all tenants",
        code: "off20",
        accountId: reduxUser.account,
        locationId: reduxUser.selectedLocation._id,
        active: true,
        startDate: Date.now(),
        applicableUnitsTypes: [
          "65ca9ddf9ca4beb8d343768f",
          "65ca9ddf9ca4beb8d3437690",
        ],
        applyAutomatically: true,
        recurring: true,
        discountType: "percentage",
        discountValue: 20,
      },
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );

    console.log("localPromo", localPromo?.data);

    setPromos([...promos, localPromo?.data?.data]);
  };

  // accountId: Types.ObjectId,
  // title: String,
  // description: String,
  // code: String,
  // active: Boolean,
  // startDate: Number,
  // endDate: Number,
  // locationId: Types.ObjectId,
  // applyAutomatically: Boolean,
  // applicableUnitsTypes: [Types.ObjectId],
  // recurring: Boolean,
  // discountType: String,
  // discountValue: Number,
  // months: [
  //   {
  //     value: Number,
  //     discountType: String,
  //     discountValue: Number,
  //   },
  // ],

  useEffect(() => {
    getPromos();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <button onClick={savePromo}>Add Promo</button>
      Promos live here
      {promos?.map((promo) => {
        return <div key={promo._id}>{promo.title}</div>;
      })}
    </div>
  );
};

export default Promos;
