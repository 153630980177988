const IndentSVG = (props) => (
  <svg
    width={props.width || "292"}
    height={props.height || "303"}
    viewBox="0 0 292 303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M291.212 190.399C291.056 194.402 289.407 199.031 286.661 201.95L197.059 297.153C190.989 302.61 179.477 304.926 172.559 298.551C165.876 292.336 166.075 280.289 172.731 274.051L235.731 207.199H16.801C8.00411 207.199 0 199.195 0 190.398V16.348C0 7.07069 7.52341 0 16.801 0C26.0783 0 33.602 7.07029 33.602 16.348V173.608H235.722L172.722 106.756C166.988 101.014 165.929 88.541 172.55 82.256C179.167 75.9708 191.226 77.9787 197.05 83.6544L286.652 178.857C289.718 182.115 291.211 185.939 291.203 190.408L291.212 190.399Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default IndentSVG;
