import { GET_ACTIVE_RESERVATION } from "./types";
import Axios from "axios";

export const addReservation = (data) => async (dispatch) => {
  console.log("::::::::::::::::::::::::");
  let d = await Axios.post(
    "/api/portal-reservation",
    {
      ...data,
    },
    {
      headers: {
        "x-auth": localStorage.getItem("x-auth"),
      },
    }
  ).catch((e) => e);

  dispatch({ type: GET_ACTIVE_RESERVATION, payload: d.data?.data });
};

// export const getLengthOfStayList = () => async (dispatch) => {
//   let data = await Axios.get("/api/length-of-stay-list");

//   console.log(data.data.data);
// };

// export const getActiveReservation = (id) => async (dispatch) => {

//   let d = await Axios.get("/api/portal-reservation");

// };
