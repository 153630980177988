import React, {Component} from "react";

import "./UnitsMap.scss";

class UnitsMap extends Component {
	constructor(props) {
		super(props);
		this.pageRef = React.createRef();
	}

	state = {
		containerHeight: undefined,
		containerWidth: undefined,
	};

	componentDidMount() {
		// console.log(this.pageRef.current);
		this.setState({
			containerHeight:
				this.pageRef.current && this.pageRef.current.clientHeight,
			containerWidth:
				this.pageRef.current && this.pageRef.current.clientWidth,
		});
	}

	render() {
		return (
			<div className="units_map_page">
				{console.log(
					this.pageRef
					// &&
					// this.pageRef.current.clientWidth
				)}
				<div ref={this.container}>Hello world</div>

				<div onClick={() => console.log(this.container)}>
					Click me!!!
				</div>

				<canvas
					height="100"
					width={
						this.pageRef &&
						this.pageRef.current &&
						this.pageRef.clientWidth
					}
				></canvas>
			</div>
		);
	}
}

export default UnitsMap;
