import { useState } from "react";
// import { useSelector } from "react-redux";
import Axios from "axios";
import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";
import Portal from "../../../../../components/portal/Portal";

import styles from "./PayCash.module.scss";
import { formatFromCurrencyToMoney } from "../../../../../helpers";

const PayCash = (props) => {
  // const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
  //   reduxUser,
  //   user,
  // }));

  const [tenderedAmount, setTenderedAmount] = useState();

  const addPaymentMethod = async () => {
    console.log("O:K");

    let saved = await Axios.post("/api/payment-method", {
      userId: props.userId,
      type: "cash",
    });

    props.setCardType(saved?.data?.data?._id);

    props.close();

    return "OK";
  };

  return (
    <Portal
      zIndex="105"
      width="315px"
      height="auto"
      title="Cash Amount"
      noCustomScrollbar
      close={() => props.close()}
      opacity="0.25"
    >
      <div className={styles.payCash}>
        <div className={styles.innerCntntWrpr}>
          <p className={`ffmr fs12 m-b-15`}>
            Amount due:{" "}
            <span className={`${styles.amount}`}>
              {formatFromCurrencyToMoney(props.amount)}
            </span>
          </p>
          <NonFormikInput
            change={setTenderedAmount}
            label="Cash tendered"
            size="small"
            value={tenderedAmount}
            id="payCashInput"
          />

          <p className={`ffnsb fs14 ${styles.title}`}>
            Change due:{" "}
            {Number(tenderedAmount) > Number(props.amount) / 100 ? (
              <span className={styles.sub}>
                ${(tenderedAmount - Number(props.amount / 100)).toFixed(2)}
              </span>
            ) : (
              0.0
            )}
          </p>
        </div>
        <div className={styles.ftr}>
          <button
            disabled={
              !tenderedAmount ||
              Number(tenderedAmount)?.toFixed(2) < props.amount / 100
            }
            className={`button secondary small w100`}
            onClick={() => {
              addPaymentMethod();
              // props.setPaymentMethod("cash");
              // props.close();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default PayCash;
