import axios from "axios";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import dayjs from "dayjs";
import PDFSVG from "../../../../assets/js-svgs/pdf.svg";
import Arrow from "../../../../assets/js-svgs/arrow.svg";
import Portal from "../../../../components/portal/Portal";

import styles from "./Invoices.module.scss";

const Invoices = ({ userId }) => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedId, setSelectedId] = useState(undefined);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const getInvoices = async () => {
    let localList = await axios.get(`/api/invoices/${userId}`);
    setInvoiceList(localList.data.data);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(
    () => {
      getInvoices();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className={`p-l-20 p-r-20 p-t-20 p-b-20`}>
      {invoiceList.map((invoice) => (
        <div
          className={`df acsa ${styles.line}`}
          onClick={() => setSelectedId(invoice._id)}
        >
          <PDFSVG />
          <p className={`m-l-15 ffnsb fs14`}>
            Due on: {dayjs(invoice.dueDate).format("MMM/DD/YYYY")}
          </p>
        </div>
      ))}
      {selectedId ? (
        <Portal
          zIndex="999"
          height="100%"
          width="940px"
          noCustomScrollbar
          close={() => setSelectedId(undefined)}
        >
          <div>
            <Document
              file={`${
                process.env.NODE_ENV && process.env.NODE_ENV === "production"
                  ? "https://slfstrg.azurewebsites.net"
                  : "http://localhost:5008"
              }/api/invoice/?id=${selectedId}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} width={900} />
            </Document>
            {numPages > 1 ? (
              <div className={`df acc ffnsb fs14 ${styles.footer}`}>
                <div
                  className={`df acc m-r-15 ${styles.navButtonWrpr} ${
                    pageNumber <= 1 ? styles.disabled : ""
                  }`}
                  onClick={() => {
                    if (numPages > 0 && pageNumber > 1)
                      setPageNumber(pageNumber - 1);
                  }}
                >
                  <Arrow fill="rgba(31, 32, 65, 0.8)" />
                </div>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <div
                  className={`df acc m-l-15 ${styles.navButtonWrpr} ${
                    styles.next
                  } ${numPages === pageNumber ? styles.disabled : ""}`}
                  onClick={() => {
                    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
                  }}
                >
                  <Arrow fill="rgba(31, 32, 65, 0.8)" />
                </div>
              </div>
            ) : undefined}
          </div>
        </Portal>
      ) : undefined}
    </div>
  );
};

export default Invoices;
