const EmailSVG = (props) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0H2C0.9 0 0.01 0.9 0.01 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM2 4L10 9L18 4V14H2V4ZM2 2L10 7L18 2H2Z"
      fill="#1F2041"
      fillOpacity="0.8"
    />
  </svg>
);

export default EmailSVG;
