import { useHistory, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
import Portal from "../../../../components/portal/Portal";
import AddressAutoComplete from "../../../../containers/tenants/add-tenant/add-user/address-autocomplete/AddressAutoComplete";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";

import MotorVehicleSVG from "../../../../assets/js-svgs/motor-vehicle.svg";
import TrailerSVG from "../../../../assets/js-svgs/trailer.svg";
import WatercraftSVG from "../../../../assets/js-svgs/watercraft.svg";
import RandomSVG from "../../../../assets/js-svgs/random.svg";

import styles from "./PersonalInfo.module.scss";
import { useState } from "react";
import Cleave from "cleave.js/react";
import axios from "axios";
import HouseholdItemsSVG from "../../../../assets/js-svgs/storage-types/household-items.svg";
import FurnitureTypeSVG from "../../../../assets/js-svgs/storage-types/furniture.svg";
import DocumentTypeSVG from "../../../../assets/js-svgs/storage-types/document.svg";
import ToolsTypeSVG from "../../../../assets/js-svgs/storage-types/tools.svg";
import BoxesTypeSVG from "../../../../assets/js-svgs/storage-types/boxes.svg";

const contentTypes = [
  {
    label: "Household items",
    value: "householdItems",
    icon: HouseholdItemsSVG,
  },
  {
    label: "Furniture",
    value: "furniture",
    icon: "FurnitureTypeSVG",
  },
  {
    label: "Documents",
    value: "documents",
    icon: "DocumentTypeSVG",
  },
  {
    label: "Tools",
    value: "tools",
    icon: "ToolsTypeSVG",
  },
  {
    label: "Boxes",
    value: "boxes",
    icon: "BoxesTypeSVG",
  },
  {
    label: "Motor vehicle",
    value: "motorVehicle",
    icon: "MotorVehicleSVG",
  },
];

const PersonalInfo = (props) => {
  const params = useParams();
  const history = useHistory();

  // const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  // const [insuranceBrackets, setInsuranceBrackets] = useState([]);

  // eslint-disable-next-line
  const [dob, setDob] = useState("");
  // eslint-disable-next-line
  const [lastFourSSN, setLastFourSSN] = useState("");
  // eslint-disable-next-line
  const [lastFourDriversLicense, setLastFourDriversLicense] = useState("");
  // eslint-disable-next-line
  const [licenseState, setLicenseState] = useState("");
  // eslint-disable-next-line
  const [licenseExpiration, setLicenseExpiration] = useState("");

  //   Alternate contact info
  const [alternateContact, setAlternateContact] = useState(false);
  const [altName, setAltName] = useState("");
  const [alt_delivery_line_1, setAltDeliveryLine1] = useState("");
  const [alt_last_line, setAltLastLine] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [altEmail, setAltEmail] = useState("");

  // Stored content
  const [storedContent, setStoredContent] = useState([]);

  // Vehicle details
  // const [isVehicle, setIsVehicle] = useState(false);
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleVin, setVehicleVin] = useState("");
  const [vehicleLength, setVehicleLength] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleDescription, setVehicleDescription] = useState("");

  // Insurance bracket
  // const [insuranceBracket, setInsuranceBracket] = useState(undefined);

  // Service member
  // const [serviceMember, setServiceMember] = useState("");

  // const getInsuranceBrackets = async () => {
  //   let insCompany = await axios.get(
  //     `/api/insurance-company/${reduxUser.selectedLocation._id}`
  //   );
  //   let companyId = insCompany.data.data[0]._id;
  //   let brs = await axios.get(`/api/insurance-brackets/${companyId}`);

  //   setInsuranceBrackets(brs.data.data);
  // };

  const isVehicleType = (type) => {
    return vehicleType === type;
  };

  const handleSelectStoredContent = (type) => {
    let includes = storedContent.includes(type);
    if (includes) {
      setStoredContent(storedContent.filter((item) => item !== type));
    } else {
      setStoredContent([...storedContent, type]);
    }
  };

  const isTypeSelected = (type) => {
    return storedContent.includes(type);
  };

  const save = async () => {
    await axios.put(
      `/api/reservation/${params.reservationId}`,
      {
        dob: Number(new Date(dob)),
        lastFourSSN,
        lastFourDriversLicense,
        licenseState,
        licenseExpiration: Number(new Date(licenseExpiration)),
        alternateContact,
        altName,
        alt_delivery_line_1,
        alt_last_line,
        altPhone,
        altEmail,
        isVehicle: isTypeSelected("motorVehicle"),
        vehicleType: isTypeSelected("motorVehicle") ? vehicleType : null,
        vehicleMake: isTypeSelected("motorVehicle") ? vehicleMake : null,
        vehicleModel: isTypeSelected("motorVehicle") ? vehicleModel : null,
        vehicleVin: isTypeSelected("motorVehicle") ? vehicleVin : null,
        vehicleLength: isTypeSelected("motorVehicle") ? vehicleLength : null,
        vehicleColor: isTypeSelected("motorVehicle") ? vehicleColor : null,
        vehicleDescription: isTypeSelected("motorVehicle")
          ? vehicleDescription
          : null,
        storedContent,
        // insuranceBracket,
        // serviceMember,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    history.push(`/tenants/add/insurance/${params.reservationId}`);
  };

  const getIcon = (value, isSelected) => {
    switch (value) {
      case "householdItems":
        return (
          <HouseholdItemsSVG fill={isSelected ? "#fff" : "rgb(26, 131, 78)"} />
        );
      case "furniture":
        return (
          <FurnitureTypeSVG fill={isSelected ? "#fff" : "rgb(26, 131, 78)"} />
        );
      case "documents":
        return (
          <DocumentTypeSVG fill={isSelected ? "#fff" : "rgb(26, 131, 78)"} />
        );
      case "tools":
        return <ToolsTypeSVG fill={isSelected ? "#fff" : "rgb(26, 131, 78)"} />;
      case "boxes":
        return <BoxesTypeSVG fill={isSelected ? "#fff" : "rgb(26, 131, 78)"} />;
      case "motorVehicle":
        return (
          <MotorVehicleSVG fill={isSelected ? "#fff" : "rgb(26, 131, 78)"} />
        );
      default:
        return null;
    }
  };

  // useEffect(() => {
  //   console.log("+++++++++++++++++++++", storedContent);
  //   let hasMotorVehicle = storedContent.find((item) => item === "motorVehicle");

  //   console.log("hasMotorVehicle", hasMotorVehicle);
  //   if (hasMotorVehicle) {
  //     setIsVehicle(true);
  //   }
  // }, [storedContent]);

  // useEffect(
  //   () => {
  //     getInsuranceBrackets();
  //   },
  //   // eslint-disable-next-line
  //   []
  // );

  const isDisabled = () => {
    console.log("checking disabled");
    return (
      // !dob ||
      // !lastFourSSN ||
      // !lastFourDriversLicense ||
      // !licenseState ||
      // !licenseExpiration ||
      alternateContact && !altName
    );
  };

  return (
    <Portal
      minHeight="auto"
      // maxHeight="632px"
      width="354px"
      height="auto"
      zIndex="100"
      opacity="0.45"
      title="Personal information"
      close={() => history.push("/tenants")}
      noCustomScrollbar={true}
    >
      <div className={`p-l-20 p-r-20 p-b-30 ${styles.personalInfo}`}>
        {/* <div className={`df m-b-10 w100`}>
          <div className={`m-r-10`}>
            <div className={`__inpt_wrpr`}>
              <Cleave
                className={`ffnsb fs14`}
                value={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                }}
                options={{
                  date: true,
                  datePattern: ["m", "d", "Y"],
                }}
                id="dob"
              />
              <label
                htmlFor="dob"
                className={`ffnsb fs14 ${dob?.length ? "move" : ""}`}
              >
                Date of birth
              </label>
            </div>
          </div>

          <div className={`__inpt_wrpr`}>
            <Cleave
              className={`ffnsb fs14`}
              value={lastFourSSN}
              onChange={(e) => {
                console.log(e);
                setLastFourSSN(e.target.value);
              }}
              options={{
                numericOnly: true,
                blocks: [4],
              }}
              id="lastFourSSN"
            />
            <label
              htmlFor="lastFourSSN"
              className={`ffnsb fs14 ${lastFourSSN?.length ? "move" : ""}`}
            >
              SS or pass. (last 4)
            </label>
          </div>
        </div> */}
        {/* <div className={`m-b-3`}>
          <div className={`__inpt_wrpr`}>
            <Cleave
              className={`ffnsb fs14`}
              value={lastFourDriversLicense}
              onChange={(e) => {
                console.log(e);
                setLastFourDriversLicense(e.target.value);
              }}
              options={{
                numericOnly: true,
                blocks: [4],
              }}
            />
            <label
              className={`ffnsb fs14 ${
                lastFourDriversLicense?.length ? "move" : ""
              }`}
            >
              Drivers license #
            </label>
          </div>
        </div> */}
        {/* <div className={`df m-b-10 w100`}>
          <div className={`m-r-10`}>
            <div className={`__inpt_wrpr`}>
              <Cleave
                id="licenseState"
                className={`ffnsb fs14`}
                value={licenseState}
                onChange={(e) => {
                  console.log(e);
                  setLicenseState(e.target.value);
                }}
                options={{
                  blocks: [2],
                  uppercase: true,
                }}
              />
              <label
                htmlFor="licenseState"
                className={`ffnsb fs14 ${licenseState?.length ? "move" : ""}`}
              >
                License state
              </label>
            </div>
          </div>

          <div className={`__inpt_wrpr`}>
            <Cleave
              id="expDate"
              className={`ffnsb fs14`}
              value={licenseExpiration}
              onChange={(e) => setLicenseExpiration(e.target.value)}
              options={{
                date: true,
                datePattern: ["m", "d", "Y"],
              }}
            />
            <label
              htmlFor="expDate"
              className={`ffnsb fs14 ${
                licenseExpiration?.length ? "move" : ""
              }`}
            >
              Exp. date
            </label>
          </div>
        </div> */}

        <div className="p-t-20">
          <p className="df acsa ffqsb fs14 ttuc m-b-6">Items to be stored</p>
          <p className={`df acsa ffqsb fs11  m-b-20`}>Select all that apply</p>
          <div className={`df jcc w100 ${styles.itemTypesWrpr}`}>
            {/* {console.log(storedContent)} */}
            {contentTypes.map((item, index) => (
              <div
                className={`df ffc acc ${styles.typeItem} ${
                  isTypeSelected(item.value) ? styles.selected : ""
                }`}
                key={index}
                onClick={() => {
                  handleSelectStoredContent(item.value);
                }}
              >
                {getIcon(item.value, isTypeSelected(item.value))}
                <p className={`ttuc fs8 ffmb m-t-8`}>{item.label}</p>
              </div>
            ))}
          </div>
        </div>

        {isTypeSelected("motorVehicle") ? (
          <>
            <div className="p-t-20">
              <p className="df acsa ffqsb fs14 ttuc m-b-20">
                Vehicle information
              </p>
            </div>
            <div className={`df aic m-t-10 ${styles.vehicleTypes}`}>
              <div
                className={`df acc ffc ${styles.typeOption}`}
                style={{
                  backgroundColor: `${
                    isVehicleType("car") ? "#30be76" : "#fff"
                  }`,
                }}
                onClick={() => setVehicleType("car")}
              >
                <MotorVehicleSVG
                  fill={isVehicleType("car") ? "#fff" : "#30be76"}
                />
                <p
                  className={`m-t-8 fs9 ttuc ffmb tac`}
                  style={{
                    color: `${isVehicleType("car") ? "#fff" : "#30be76"}`,
                  }}
                >
                  Car
                </p>
              </div>
              <div
                className={`df acc ffc ${styles.typeOption}`}
                onClick={() => setVehicleType("trailer")}
                style={{
                  backgroundColor: `${
                    isVehicleType("trailer") ? "#30be76" : "#fff"
                  }`,
                }}
              >
                <TrailerSVG
                  fill={isVehicleType("trailer") ? "#fff" : "#30be76"}
                />
                <p
                  className={`m-t-8 fs9 ttuc ffmb tac`}
                  style={{
                    color: `${isVehicleType("trailer") ? "#fff" : "#30be76"}`,
                  }}
                >
                  Trailer
                </p>
              </div>
              <div
                className={`df acc ffc ${styles.typeOption}`}
                style={{
                  backgroundColor: `${
                    isVehicleType("watercraft") ? "#30be76" : "#fff"
                  }`,
                }}
                onClick={() => setVehicleType("watercraft")}
              >
                <WatercraftSVG
                  fill={isVehicleType("watercraft") ? "#fff" : "#30be76"}
                />
                <p
                  className={`m-t-8 fs9 ttuc ffmb tac`}
                  style={{
                    color: `${
                      isVehicleType("watercraft") ? "#fff" : "#30be76"
                    }`,
                  }}
                >
                  Watercraft
                </p>
              </div>
              <div
                className={`df acc ffc ${styles.typeOption}`}
                style={{
                  backgroundColor: `${
                    isVehicleType("other") ? "#30be76" : "#fff"
                  }`,
                }}
                onClick={() => setVehicleType("other")}
              >
                <RandomSVG fill={isVehicleType("other") ? "#fff" : "#30be76"} />
                <p
                  className={`m-t-8 fs9 ttuc ffmb tac`}
                  style={{
                    color: `${isVehicleType("other") ? "#fff" : "#30be76"}`,
                  }}
                >
                  Other
                </p>
              </div>
            </div>

            <div className={`df aic `}>
              <div className={`m-r-5`}>
                <NonFormikInput
                  label="Make"
                  value={vehicleMake}
                  change={(e) => setVehicleMake(e)}
                />
              </div>
              <div className={`m-l-5`}>
                <NonFormikInput
                  label="Model"
                  value={vehicleModel}
                  change={(e) => setVehicleModel(e)}
                />
              </div>
            </div>

            <div className={`m-t-10`}>
              <NonFormikInput
                label="VIN"
                value={vehicleVin}
                change={(e) => setVehicleVin(e)}
              />
            </div>
            <div className={`df aic `}>
              <div className={`m-t-10 m-r-5`}>
                <NonFormikInput
                  label="Length"
                  value={vehicleLength}
                  change={(e) => setVehicleLength(e)}
                />
              </div>

              <div className={`m-t-10 m-l-5`}>
                <NonFormikInput
                  label="Color(s)"
                  value={vehicleColor}
                  change={(e) => setVehicleColor(e)}
                />
              </div>
            </div>

            <div className={`m-t-10`}>
              <NonFormikInput
                label="Description"
                value={vehicleDescription}
                change={(e) => setVehicleDescription(e)}
              />
            </div>
          </>
        ) : undefined}

        {/* <div className="p-t-20">
          <p className="df acsa ffqsb fs14 ttuc m-b-20">
            Are you a service member
          </p>
        </div>
        <div>
          <div className={`df aic`}>
            <div className={`df aic`}>
              <input
                type="radio"
                name="serviceMember"
                id="yesServiceMember"
                checked={serviceMember}
                onChange={() => setServiceMember(true)}
              />
              <label
                className={`ffnsb fs14 m-t-3 m-l-5`}
                htmlFor="yesServiceMember"
              >
                Yes
              </label>
            </div>
            <div className={`df aic m-l-15`}>
              <input
                type="radio"
                name="serviceMember"
                id="noServiceMember"
                checked={serviceMember === false}
                onChange={() => setServiceMember(false)}
              />
              <label
                className={`ffnsb fs14 m-t-3 m-l-5`}
                htmlFor="noServiceMember"
              >
                No
              </label>
            </div>
          </div>
        </div> */}

        <div className="p-t-20">
          <p className="df acsa ffqsb fs14 ttuc m-b-20">Alternate contact</p>
        </div>
        <div>
          <div className={`df aic`}>
            <div className={`df aic`}>
              <input
                type="radio"
                name="altContact"
                id="altContact"
                checked={alternateContact}
                onChange={() => setAlternateContact(true)}
              />
              <label className={`ffnsb fs14 m-t-3 m-l-5`} htmlFor="altContact">
                Yes
              </label>
            </div>
            <div className={`df aic m-l-15`}>
              <input
                type="radio"
                name="altContact"
                id="altContactNo"
                checked={!alternateContact}
                onChange={() => {
                  setAltName("");
                  setAltDeliveryLine1("");
                  setAltLastLine("");
                  setAltEmail("");
                  setAltPhone("");
                  setAlternateContact(false);
                }}
              />
              <label
                className={`ffnsb fs14 m-t-3 m-l-5`}
                htmlFor="altContactNo"
              >
                No
              </label>
            </div>
          </div>
          {alternateContact ? (
            <div>
              <div className={`m-t-10`}>
                <NonFormikInput
                  className={`w100`}
                  change={(e) => {
                    setAltName(e);
                  }}
                  value={altName}
                  label="Contacts name"
                  id="altName"
                />
              </div>
              <div className={`m-t-10`}>
                <AddressAutoComplete
                  onUpdate={(e) => {
                    setAltDeliveryLine1(e.deliveryLine1);
                    setAltLastLine(e.lastLine);
                  }}
                />
              </div>

              {/* altEmail */}

              <div className={`m-t-10`}>
                <NonFormikInput
                  className={`w100`}
                  change={(e) => {
                    setAltEmail(e);
                  }}
                  value={altEmail}
                  label="Contacts email"
                  id="altEmail"
                />
              </div>

              <div className={`m-t-10`}>
                <div className={`__inpt_wrpr`}>
                  <Cleave
                    className={`ffmr fs14 ${styles.input}`}
                    options={{
                      blocks: [0, 3, 3, 4],
                      delimiters: ["(", ") ", "-"],
                      numericOnly: true,
                    }}
                    value={altPhone}
                    onChange={(event) => {
                      setAltPhone(`+${event.target.rawValue}`);
                    }}
                    id="altPhone"
                  />
                  <label
                    htmlFor="altPhone"
                    className={`ffnsb fs14 ${altPhone?.length ? "move" : ""}`}
                  >
                    Phone number
                  </label>
                </div>
              </div>

              {/*    */}
            </div>
          ) : undefined}
        </div>
      </div>
      <div className={`p-l-20 p-r-20 p-b-30`}>
        <button
          className={`ffnb w100 fs14 ttuc sbmt_btn primary m-t-10`}
          onClick={save}
          disabled={isDisabled()}
        >
          Next
        </button>
        {/* <button
          className={`ffnb w100 fs14 ttuc sbmt_btn secondary m-t-10`}
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Portal>
  );
};

export default PersonalInfo;
