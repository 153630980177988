const DocSVG = (props) => (
  <svg
    width={props.width || "28"}
    height={props.height || "34"}
    viewBox="0 0 28 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.22982 0.332031H17.5632L27.5632 10.332V30.332C27.5632 32.1654 26.0632 33.6654 24.2298 33.6654H4.21315C2.37982 33.6654 0.896484 32.1654 0.896484 30.332L0.913151 3.66536C0.913151 1.83203 2.39648 0.332031 4.22982 0.332031ZM4.23033 3.66509V30.3318H24.2303V11.9984H15.897V3.66509H4.23033Z"
      fill={props.fill || "#1F2041"}
      fillOpacity={props.fillOpacity || "0.5"}
    />
  </svg>
);

export default DocSVG;
