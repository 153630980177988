import React, { Component } from "react";
import Select, { components } from "react-select";

//import scss
import "./MultiSelect.scss";

const { Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return child && child.type !== Placeholder ? child : null;
      })}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  return (
    <div className="__list_line">
      <components.Option {...props} />
    </div>
  );
};

const customStyles = (error) => ({
  menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
  option: (base, state) => ({
    // ...base,
    // borderBottom: "1px dotted pink",
    // color: state.isSelected ? "red" : "blue"
    fontFamily: "mont-reg",
    fontSize: "14px",
    color: " rgba(31, 32, 65, 0.75)",
    height: "44px",
    lineHeight: "44px",
    padding: "0px 18px",
    cursor: "pointer",
    // padding: 20
  }),
  control: (base, state) => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    // ...base,
    display: "flex",
    minHeight: "48px",
    borderRadius: "4px",
    border: `1px solid rgba(31, 32, 65, 0.25)`,
    borderColor: `${error ? "#ec407a" : "rgba(31, 32, 65, 0.25)"}`,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (base, state) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid rgba(31, 32, 65, 0.20)",
    borderRadius: "22px",
    background: "none",
    fontFamily: "mont-med",
    fontSize: "14px",
    // textTransform: "lowercase"
    //
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    // backgroundColor: "red"
  }),
  multiValueRemove: () => ({
    //backgroundColor: "orange"
  }),

  // clearIndicator: () => ({
  //   backgroundColor: "green"
  // }),
  // placeholder: (base, state) => ({
  //   color: state.isSelected || state.isFocused ? "red" : "orange",
  //   top: "0px",
  //   position: "absolute",
  //   display: "block"
  // })
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "mont-reg",
    // top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
    // transition: "top 0.1s, font-size 0.1s",
    fontSize: state.hasValue ? "10px" : "14px",
    textTransform: state.hasValue && "uppercase",
    height: state.hasValue ? "initial" : "18px",
    position: "absolute",

    top: state.hasValue ? "0px" : "22px",
    left: "11px",
    color: state.hasValue
      ? "rgba(31, 32, 65, 0.75)"
      : error
      ? "rgba(236, 64, 122, 0.5)"
      : "rgba(31, 32, 65, 0.3)",
    padding: "3px 6px 0px",
    transition: "all 0.17s",
    borderRadius: "3px",
    backgroundColor: "white",
  }),
  valueContainer: (base) => ({
    ...base,
    overflow: "visible",
    padding: "6px 8px",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    alignSelf: "start",
    top: "6px",
  }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = "opacity 300ms";

  //   return { ...provided, opacity, transition };
  // }
});

class MultiSelect extends Component {
  state = { open: false };
  handleChange = (value, b, c) => {
    // this is going to call setFieldValue and manually update field 'from this.props.name'
    // console.log("CHANGED");
    this.props.onChange(value);
  };

  handleBlur = () => {
    // console.log("blurred");
    // this is going to call setFieldTouched and manually update field 'from this.props.name'
    this.props.onBlur(this.props.name, true);
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="__inpt_wrpr expandable __ms">
        <Select
          menuPortalTarget={this.props.menuPortalTarget}
          getOptionValue={(option) => option._id}
          id="color"
          options={this.props.options}
          isMulti={this.props.isMulti}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
          styles={customStyles(this.props.error)}
          closeMenuOnSelect={!this.props.isMulti}
          isClearable={false}
          onFocus={() => console.log("LLLLLLLL")}
          components={{
            ValueContainer: CustomValueContainer,
            Option,
          }}
          placeholder={this.props.label}
        />
        {!!this.props.error && this.props.touched && (
          <div style={{ color: "#ec407a" }}>{this.props.error}</div>
        )}
      </div>
    );
  }
}

export default MultiSelect;
