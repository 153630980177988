import { NavLink } from "react-router-dom";

import styles from "./SettingsNav.module.scss";

const SettingsNav = ({ actions = [] }) => (
  <div className={`${styles.topBar}`}>
    <div className={`df acsa ${styles.header}`}>
      <h1 className={`ffnsb fs16 ${styles.navSec}`}>Settings</h1>
      <NavLink
        exact
        to="/settings"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        General
      </NavLink>
      <NavLink
        exact
        to="/settings/account"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Account
      </NavLink>
      <NavLink
        to="/settings/admin"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Administrative
      </NavLink>
      {/* <NavLink
      to="/settings/alerts"
      className={`ffnsb fs14 ${styles.navSec}`}
      activeClassName={styles.selected}
    >
      Alerts
    </NavLink> */}
      <NavLink
        to="/settings/users"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Users
      </NavLink>
      <NavLink
        to="/settings/billing"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Billing
      </NavLink>

      <NavLink
        to="/settings/units"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Units
      </NavLink>

      <NavLink
        to="/settings/flow"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Flow
      </NavLink>

      <NavLink
        to="/settings/website"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Website
      </NavLink>

      <NavLink
        to="/settings/locations"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Locations
      </NavLink>

      <NavLink
        to="/settings/templates"
        className={`ffnsb fs14 ${styles.navSec}`}
        activeClassName={styles.selected}
      >
        Templates
      </NavLink>

      <div className={`df mla`}>
        {actions.map((action, i) => (
          <button
            key={i}
            onClick={action.onClick}
            className={`df ffnb fs14 rwt ${styles.mainAction}`}
          >
            {action.label}
          </button>
        ))}
      </div>
    </div>

    <div className={styles.filtersWrpr}></div>
    {/* filtersWrpr */}
  </div>
);

export default SettingsNav;
