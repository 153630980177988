import Axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Plus from "../../../../../assets/js-svgs/plus.svg";
import "./RentalsTabs.scss";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);

const typesList = [
  { label: "Active", value: "active" },
  { label: "Draft", value: "draft" },
  { label: "All", value: "all" },
  { label: "Past", value: "past" },
];

const RentalsTabs = (props) => {
  const params = useParams();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [rentalType, setRentalType] = useState("all");
  const [showTypesOptions, setShowTypesOptions] = useState(false);
  // const [selectedTab, setSelectedTab] = useState(null);

  const getList = async () => {
    let resList = await Axios.get(
      `/api/user-reservations/${params.id}?showType=${rentalType}`
    );
    setList(resList.data.data);
  };

  useEffect(() => {
    if (props.selectedRental && props.selectedRental !== "no-rentals") return;
    if (list.length > 0) return props.setSelectedRental(list[0]._id);
    props.setSelectedRental("no-rentals");
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    props.setSelectedRental("no-rentals");
    getList();
    // eslint-disable-next-line
  }, [params.id, props.refresh, rentalType]);

  return (
    <div className="df acsa rentals_tabs_wrpr">
      <div className={`df acsa`}>
        <p className="ffnsb fs14 lbl">Rentals: </p>
        <div className={`typeDropDown ${showTypesOptions ? "open" : ""}`}>
          <p
            onClick={() => setShowTypesOptions(!showTypesOptions)}
            className={`ffnsb fs14 ttcaps typeLabel`}
          >
            {rentalType}
          </p>
          {showTypesOptions ? (
            <div className={`listMenu`}>
              {typesList.map((type, idx) => (
                <p
                  key={`tab--${idx}`}
                  className={`ffnsb fs14 line`}
                  onClick={() => {
                    setRentalType(type.value);
                    setShowTypesOptions(false);
                  }}
                >
                  {type.label}
                </p>
              ))}
            </div>
          ) : undefined}
        </div>
      </div>
      <div className={`tabsWrpr`}>
        <Swiper
          centeredSlidesBounds
          slidesPerView={"auto"}
          spaceBetween={0}
          navigation
          pagination={{ clickable: true }}
          className={`customized`}
          // initialSlide={props.initialSlide}
        >
          {list.map((tab, i) => (
            <SwiperSlide
              title={
                tab.moveOutDate
                  ? `Moved out on ${dayjs(new Date(tab.moveOutDate)).format(
                      "MM/DD/YYYY h:mma"
                    )}`
                  : tab.moveInDate
                  ? `Moved in on ${dayjs(new Date(tab.moveInDate)).format(
                      "MM/DD/YYYY h:mma"
                    )}`
                  : `Expected move in on ${dayjs(
                      new Date(tab.expectedMoveInDate)
                    ).format("MM/DD/YYYY")}`
              }
              className={`df acc tabWrpr tac ffnr fs14 tab ttuc ${
                props.selectedRental === tab._id ? "selected" : ""
              } ${tab.hasPastDue ? "hasPastDue" : ""} ${
                tab.moveOutDate ? "movedOut" : ""
              } ${
                !tab.moveInDate && !tab.moveOutDate && tab.expectedMoveInDate
                  ? "notMovedIn"
                  : ""
              }`}
              key={tab.unit?._id + i}
              onClick={() => props.setSelectedRental(tab._id)}
            >
              {/* <div className={`ffnr fs14 tab ttuc`} > */}
              {tab.unit?.unitId}{" "}
              <span className={`sub m-l-3`}>
                ({tab.unit?.unitSize.length}x{tab.unit?.unitSize.width})
              </span>
              {tab.hasPastDue ? <span className={`star`}>*</span> : undefined}
              {/* {!tab.moveInDate && !tab.moveOutDate && tab.expectedMoveInDate ? (
                <span className={`notMovedIn`}></span>
              ) : undefined} */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <button
        className={`addButton m-l-10 df acc`}
        onClick={() => history.push(`/tenants/add/all-units/${params.id}`)}
      >
        <Plus fill="#323232" fillOpacity="0.8" />
      </button>
    </div>
  );
};

export default RentalsTabs;
