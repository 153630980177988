import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";

import SettingsNav from "../settings-nav/SettingNav";
import pageStyles from "../SettingsHome.module.scss";
import AddStep from "./add-step/AddStep";
import styles from "./ReservationFlow.module.scss";
import { TrashSVG } from "../../../assets/js-svgs/trash.svg";
import Portal from "../../../components/portal/Portal";
import AddPage from "./add-page/AddPage";

const ReservationFlow = () => {
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [flow, setFlow] = useState([]);
  const [activePage, setActivePage] = useState(null);
  // eslint-disable-next-line
  const [stepsLength, setStepsLength] = useState(null);
  const [showAddStep, setShowAddStep] = useState(false);
  const [showAddPage, setShowAddPage] = useState(false);
  const [step, setStep] = useState(undefined);

  // const addFlow = () => {
  //   axios.post("/api/flow", {
  //     accountId: user.account,
  //     locationId: reduxUser.selectedLocation._id,
  //   });
  // };

  const addFlowStep = () => {
    axios.post("/api/flow-step", {
      accountId: user.account,
      locationId: reduxUser.selectedLocation._id,
      label: "Gust Name",
      type: "string",
      handle: "gustNameHere",
      editable: true,
      page: 3,
      order: 1,
    });
  };

  const getFlows = async () => {
    let localFlow = await axios.get(
      `/api/flow/${reduxUser.selectedLocation._id}`
    );

    setFlow(localFlow.data.data);
  };

  const stepAdded = (step) => {
    let pageIndex = flow.findIndex((elem) => elem._id === step.page);
    let updated = update(flow, {
      [pageIndex]: { steps: { $push: [step] } },
    });

    setFlow(updated);
  };

  const stepUpdated = (step) => {
    let pageIndex = flow.findIndex((elem) => elem._id === step.page);

    let itemIndex = flow[pageIndex].steps.findIndex(
      (elem) => elem._id === step._id
    );
    let updated = update(flow, {
      [pageIndex]: { steps: { [itemIndex]: { $set: step } } },
    });

    setFlow(updated);
  };

  useEffect(() => {
    getFlows();
    // eslint-disable-next-line
  }, []);

  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer

  //   userSelect: "none",
  //   // padding: grid * 2,
  //   margin: `0 0 5px 0`,

  //   // change background colour if dragging
  //   background: isDragging ? "lightgreen" : "#fff",

  //   // styles we need to apply on draggables
  //   ...draggableStyle,
  // });

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    var updatedFlow = cloneDeep(flow);

    const sourcePageIndex = updatedFlow.findIndex(
      (elem) => elem._id === source.droppableId
    );
    const destinationPageIndex = updatedFlow.findIndex(
      (elem) => elem._id === destination.droppableId
    );

    const [reorderedItem] = updatedFlow[sourcePageIndex].steps.splice(
      source.index,
      1
    );

    updatedFlow[destinationPageIndex].steps.splice(destination.index, 0, {
      ...reorderedItem,
      page: destination.droppableId,
    });

    setFlow(updatedFlow);
    await axios.put("/api/flow", updatedFlow);
  };

  const deleteStep = async (stepId, page, idx) => {
    // let deleted =
    await axios.delete(`/api/flow/${stepId}`);

    const updated = update(flow, {
      [page]: { steps: { $splice: [[idx, 1]] } },
    });
    setFlow(updated);
  };

  const addedPage = async (page) => {
    console.log(page);
    setFlow([...flow, page]);
  };

  return (
    <>
      <SettingsNav actions={[{ label: "Add Flow", onClick: addFlowStep }]} />
      <div className={`${pageStyles.page}`}>
        <div className={styles.flowPage}>
          <div className={styles.card}>
            <div className={`df acsa ${styles.cardHdr}`}>
              <div>
                <p className={`ffnsb fs12 ttuc`}>Reservation flow</p>
                <p className={`ffnr fs12 m-t-10`}>
                  Set up the flow of your reservations
                </p>
              </div>
              {/* <button
                className={`mla ffnr s14 ${styles.addBtn}`}
                onClick={() => setShowAddRule(true)}
              >
                Add rule
              </button> */}
            </div>
            <div className={`df ${styles.cardContent}`}>
              <DragDropContext onDragEnd={onDragEnd}>
                {flow ? (
                  <>
                    {flow.map((page, pageIndex) => (
                      <Droppable
                        droppableId={page._id.toString()}
                        key={page._id}
                      >
                        {(provided, snapshot) => (
                          <div className={styles.page} ref={provided.innerRef}>
                            <p className={`ffmsb fs12 ${styles.pageHdr}`}>
                              {page.title}
                            </p>
                            <div className={`p-t-10 p-b-10 ${styles.stepList}`}>
                              {page.steps?.map((step, index) => (
                                // <p
                                //   onClick={() => {
                                //     setActivePage(page._id);
                                //     setStepsLength(page.steps.length + 1);
                                //   }}
                                //   className={`ffmr fs14 p-l-20 p-r-20 p-t-12 p-b-12 ${styles.stepLine}`}
                                // >
                                //   {step.order}) {step.label}
                                // </p>
                                <Draggable
                                  key={step._id}
                                  draggableId={step._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // style={getItemStyle(
                                      //   snapshot.isDragging,
                                      //   provided.draggableProps.style
                                      // )}
                                      className={`df aic ffmr fs14 p-l-20 p-r-20 p-t-12 p-b-12 ${styles.stepLine}`}
                                    >
                                      {step.label}
                                      <div
                                        className={`${styles.edit}`}
                                        onClick={() => {
                                          setStep(step);
                                          setShowAddStep(true);
                                        }}
                                      >
                                        Edit
                                      </div>
                                      <div
                                        className={`mla ${styles.deleteIcon}`}
                                        onClick={() =>
                                          deleteStep(step._id, pageIndex, index)
                                        }
                                      >
                                        <TrashSVG fill="#ec407a" />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                            <div className={`m-l-20 m-r-20 m-b-15`}>
                              <button
                                onClick={() => {
                                  setActivePage(page._id);
                                  setShowAddStep(true);
                                }}
                                className={`w100 ttuc ffnb fs14 rwt btn primary`}
                              >
                                Add field
                              </button>
                            </div>
                          </div>
                        )}
                      </Droppable>
                    ))}
                    <div
                      onClick={() => setShowAddPage(true)}
                      className={`${styles.addPage}`}
                    >
                      AddPage
                    </div>
                  </>
                ) : (
                  "Add flow"
                )}
              </DragDropContext>
            </div>
          </div>
        </div>

        {showAddPage ? (
          <AddPage
            close={() => setShowAddPage(false)}
            order={flow?.length ? flow.length : 0}
            added={addedPage}
          />
        ) : undefined}
        {showAddStep ? (
          <Portal
            height="auto"
            width="350px"
            opacity="0.2"
            zIndex="1"
            title="Add field"
            close={() => {
              setActivePage(undefined);
              setShowAddStep(false);
            }}
          >
            <AddStep
              close={() => {
                setStep(undefined);
                setShowAddStep(false);
              }}
              page={activePage}
              stepsLength={stepsLength}
              stepAdded={stepAdded}
              stepUpdated={stepUpdated}
              step={step}
            />
          </Portal>
        ) : undefined}
      </div>
    </>
  );
};

export default ReservationFlow;
