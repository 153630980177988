import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { formatFromCurrencyToMoney } from "../../../../helpers";

const UnitHistory = ({ id }) => {
  const [history, setHistory] = useState([]);

  const getHistory = async () => {
    const list = await axios.get(`/api/unit-history/${id}`);
    setHistory(list.data);
  };

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div>
      {history.map((re) => (
        <div>
          {re.user.fullName}{" "}
          {re.price?.price ? formatFromCurrencyToMoney(re.price.price) : "N/A"}{" "}
          {re.moveInDate ? dayjs(re.moveInDate).format("MMM/DD/YYYY") : "N/A"} -
          {re.moveOutDate ? dayjs(re.moveOutDate).format("MMM/DD/YYYY") : "N/A"}
        </div>
      ))}
    </div>
  );
};

export default UnitHistory;
