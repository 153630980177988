import Axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const StartImport = (props) => {
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));
  const history = useHistory();

  const startImport = () => {
    // console.log(reduxUser, user);
    Axios.post("/api/import/create-job", {
      accountId: user.account,
      locationId: reduxUser.selectedLocation._id,
    }).then((r) =>
      history.push(`/settings/import/${r.data.data._id}/customers`)
    );
  };

  return (
    <div>
      <button onClick={startImport}>Start Import</button>
    </div>
  );
};

export default StartImport;
