import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import update from "immutability-helper";
import adminStyles from "../AdminSettings.module.scss";
import AddPopup from "./add-fee-popup/AddPopup";
import { formatFromCurrencyToMoney } from "../../../../helpers";

import styles from "./Fees.module.scss";

const Fees = () => {
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [fees, setFees] = useState([]);

  async function getFees() {
    let localFees = await axios.get("/api/fee");
    setFees(localFees?.data?.data);
  }

  useEffect(() => {
    getFees();
  }, []);

  async function feeAdded(fee) {
    let updated = await update(fees, { $push: [fee] });
    setFees(updated);
  }

  return (
    <div className={`m-b-30 ${adminStyles.card}`}>
      <div className={`df acsa ${adminStyles.cardHdr}`}>
        <div>
          <p className={`ffnsb fs12 ttuc`}>Account fees</p>
          <p className={`ffnr fs12 m-t-10`}>Setup your account fees</p>
        </div>
        <button
          className={`mla ffnr s14 ${adminStyles.addBtn}`}
          onClick={() => setShowAddPopup(true)}
        >
          Add fee
        </button>
        {showAddPopup ? (
          <AddPopup
            feeAdded={feeAdded}
            close={(fee) => {
              setShowAddPopup(false);
            }}
          />
        ) : undefined}
      </div>

      {fees?.map((fee) => (
        <div key={fee._id} className={`ffnr fs14 df acsa ${styles.feeLine}`}>
          <div className={`m-r-30`}>
            <p className={`fs12 m-b-4`}>{fee.label}</p>
            <p>{formatFromCurrencyToMoney(fee.value)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Fees;
