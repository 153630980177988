import { useEffect, useState } from "react";
import axios from "axios";
import AnvilSignatureModal from "@anvilco/react-signature-modal";
import Portal from "../../../../components/portal/Portal";
import { DocumentLoading } from "../../../../assets/js-svgs/document-loading.svg";

import "@anvilco/react-signature-modal/dist/styles.css";
import styles from "./AnvilDocument.module.scss";

const AnvilDocument = (props) => {
  const [sigURL, setSigURL] = useState();
  const [error, setError] = useState(undefined);

  const getUrl = async () => {
    let { data } = await axios.post(`/api/get-anvil-url`, {
      signerName: props.signerName,
      signerEid: props.signerEid,
    });
    if (!data.data) {
      return setError("Error getting your signature");
    }
    setSigURL(data.data);
  };

  useEffect(() => {
    getUrl();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!sigURL ? (
        <Portal
          height="400px"
          width="600px"
          customHeader={true}
          close={props.close}
          opacity="0.4"
          zIndex="99999"
        >
          <div className={error && styles.error}>
            <h3 className={`tac ffmsb fs14 w100 ttuc ptc m-b-30 m-t-130`}>
              {!error ? "Loading your documents" : error}
            </h3>
            <div className={`df acc`}>
              <div className={styles.docIcon}>
                <DocumentLoading
                  fill={!error ? "#15698e" : "#d12f2f"}
                ></DocumentLoading>
              </div>
              <div className={styles.docIcon}>
                <DocumentLoading
                  fill={!error ? "#15698e" : "#d12f2f"}
                ></DocumentLoading>
              </div>
              <div className={styles.docIcon}>
                <DocumentLoading
                  fill={!error ? "#15698e" : "#d12f2f"}
                ></DocumentLoading>
              </div>
            </div>
          </div>
        </Portal>
      ) : (
        <AnvilSignatureModal
          signURL={sigURL}
          isOpen={true}
          onClose={() => {
            console.log("()*)*)(HIUHI");
            return props.close();
          }}
          //   onLoad={(a) => console.log("ByBy map", a.target)}
          onFinish={(redirectURL) => props.onFinish()}
          onError={() => console.log("Errorrrrrrrrefdgfg")}
        />
      )}
    </>
  );
};

export default AnvilDocument;
