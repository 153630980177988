// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { updateReduxUser } from "../../store/actions";

// import scss
// import { Link } from "react-router-dom";
import styles from "./TopBar.module.scss";
// import { LocationDropDown } from "./locations-drop-down/LocationsDropDown";

// class TopBar extends Component {
//   render() {
//     return (
//       <div className="df acsa top_bar">
//         <div className="df acsa left_sec">
//           <div className="menu_opnr"></div>
//           <div className="ttuc ffmb logo_wrpr">Our logo</div>
//         </div>

//         <div className="ffqsb fs22 page_title">Payments</div>

//         <div className="srch_wrpr">
//           <input type="text"></input>
//         </div>

//         <div className="df mla right_sec">
//           <div className="df acc settings_opnr_wrpr">
//             <div className="settings_opnr"></div>
//           </div>
//           <div className="df acc notifs_opnr_wrpr">
//             <div className="notifs_opnr"></div>
//           </div>{" "}
//           123123123
//           {this.props.user &&
//             this.props.user.locations &&
//             this.props.user.locations.length > 1 && (
//               <div className="locations_dd_wrpr">
//                 <LocationDropDown
//                   locations={this.props.user.locations}
//                   selectedLocation={this.props.reduxUser.selectedLocation}
//                   changeHandler={this.props.updateReduxUser}
//                 ></LocationDropDown>
//               </div>
//             )}
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = ({ user, reduxUser }) => ({ user, reduxUser });

// export default connect(mapStateToProps, { updateReduxUser })(TopBar);

const TopBar = (props) => {
  return (
    <div className={styles.topBar}>
      <div className={`df acsa ${styles.header}`}>
        <div className="ffnsb fs16">{props.title}</div>
        <div className={`df accsa mla ${styles.actionWrpr}`}>
          {/* <Link
            to="/tenants/add/user"
            className={`df ffnb fs14 rwt ${styles.mainAction}`}
            onClick={() => {
              // this.props.setBgBlur(true);
              this.setState({ showAddTenant: true });
            }}
          >
            Add Tenant
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
