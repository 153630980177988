import styles from "./PastDue.module.scss";
import { LateSVGIcon } from "../../../../assets/js-svgs/late.svg";
import Arrow from "../../../../assets/js-svgs/arrow.svg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
// import ordinal from "ordinal";
// import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";
import { useSelector } from "react-redux";
import DelinquencyStage from "../rentals-section/rental-details/delinquency-stage/DelinquencyStage";

const PastDueSection = (props) => {
  const params = useParams();

  const { user } = useSelector(({ user }) => ({ user }));

  // console.log(params);
  // eslint-disable-next-line
  const [pastDueDetails, setPastDueDetails] = useState(undefined);
  const [showDetails, setShowDetails] = useState(false);
  // eslint-disable-next-line
  const [selectedTab, setSelectedTab] = useState();
  const [pastDues, setPastDues] = useState([]);
  const [selectedPastDue, setSelectedPastDue] = useState();
  // const [selectedTabDetails, setSelectedTabDetails] = useState("lockout");

  const getPastDues = async () => {
    const r = await axios.get(`/api/pastdues/${params.id}`);
    setPastDues(r.data);
    setSelectedPastDue(r.data[0]);
  };

  const getDetailsAction = async () => {
    console.log("GETTING HTIS &*^RV^UTGBNI&", selectedPastDue);
    // const r = await axios.get(
    //   `/api/pastdue/${params.id}/?detailed=true&accountId=${user.account}`
    // );
    const r = await axios.get(
      `/api/pastdue-test/${selectedPastDue._id}/?detailed=true&accountId=${user.account}`
    );
    // console.log(r.data);
    setPastDueDetails(r.data);
  };

  useEffect(() => {
    // getDetailsAction();
    getPastDues();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedPastDue) return;
    getDetailsAction();
    // eslint-disable-next-line
  }, [selectedPastDue]);

  // const saveAction = async (id) => {
  //   // console.log("ACTION SAVED", id);

  //   let d = await axios.put(`/api/pastdue-action/${id}`, { completed: true });

  //   let clone = cloneDeep(pastDueDetails);

  //   let itemIndex = clone.steps[d.data.type].findIndex(
  //     (elem) => elem._id === d.data._id
  //   );

  //   clone.steps[d.data.type][itemIndex].completed = d.data.completed;
  //   clone.steps[d.data.type][itemIndex].completedDate = d.data.completedDate;

  //   // console.log(d.data.completed);

  //   setPastDueDetails(clone);
  // };

  // const actionTypes = {
  //   lockout: [{ label: "Locked Out", action: saveAction }],
  //   preLienNotice: [{ label: "Sent Pre Lien Notice", action: saveAction }],
  //   scheduledAuction: [{ label: "Scheduled Auction", action: saveAction }],
  //   scheduledAuctionNotice: [
  //     { label: "Sent Auction Notice", action: saveAction },
  //   ],
  // };

  // const getStatus = (step) => {
  //   if (!pastDueDetails) return;
  //   const totalLength = pastDueDetails?.steps[step].length;
  //   let a = pastDueDetails?.steps[step].filter(
  //     (elem) => elem.completed === true
  //   ).length;

  //   // console.log(a, totalLength);
  //   if (a === totalLength) {
  //     return "all";
  //   } else if (a > 0) return "some";

  //   return "none";
  // };

  return (
    <div className={`p-t-8 ${styles.pastDueSec}`}>
      <div
        className={`df acsa ffmr fs14 p-l-34 p-r-34 ${styles.hdr}`}
        onClick={() => setShowDetails(!showDetails)}
      >
        <LateSVGIcon />{" "}
        <p className={`m-l-15`}>
          This tenant is past due as of{" "}
          {dayjs(props.pastDue.startDate).format("MMM/DD/YYYY")}.
        </p>
        <div
          className={`mla ${styles.opener} ${showDetails ? styles.rotate : ""}`}
        >
          <Arrow fill="#ffffff" height="8" width="16" fillOpacity="1" />
        </div>
      </div>
      {showDetails ? (
        <>
          <Swiper
            centeredSlidesBounds
            slidesPerView={"auto"}
            spaceBetween={0}
            // navigation
            pagination={{ clickable: true }}
            className={`customized ${styles.tabsSec}`}
          >
            {pastDues?.map((pd) => (
              <SwiperSlide
                key={pd?._id}
                className={`df ffc tac ffmr fs14 ${styles.tab} 
                ${pd._id === selectedPastDue._id ? styles.selected : ""}
               `}
                onClick={() => setSelectedPastDue(pd)}
              >
                {/* ${
                 selectedTab === rule._id ? styles.selected : ""
               } */}
                {/* <div
                  key={pd?._id}
                  onClick={() => setSelectedPastDueId(pd?._id)}
                > */}
                Unit #{pd?.unit?.unitId}
                {/* </div> */}
              </SwiperSlide>
            ))}
          </Swiper>
          {pastDueDetails ? (
            <>
              {pastDueDetails.map((rule) => (
                <DelinquencyStage
                  key={rule._id}
                  stage={rule}
                  selectedPastDue={selectedPastDue}
                />
                // <div className={`${styles.ruleLine}`}>
                //   <div className={`p-l-25 p-r-25 df acsa ${styles.ruleHeader}`}>
                //     <div className={`${styles.dayWrprContainer}`}>
                //       <div
                //         className={`df acc ffc ffqsb fs12 ${styles.dayWrpr}`}
                //       >
                //         <p className="m-b-2 fs9">DAY</p>
                //         <p>
                //           {dayjs(rule.scheduledDate).diff(
                //             pastDueDetails?.startDate,
                //             "d"
                //           )}
                //         </p>
                //       </div>
                //     </div>
                //     <div className={`ffmr fs13`}>
                //       <p className={`m-b-4`}>
                //         {rule.title}{" "}
                //         <span className={`${styles.sub}`}>
                //           ({rule?.actions?.length} action
                //           {rule?.actions?.length > 1 ? "s" : ""})
                //         </span>
                //       </p>
                //       <p className={`fs11 ${styles.scheduledDate}`}>
                //         Scheduled for{" "}
                //         {dayjs(rule.scheduledDate).format("MMM DD, YYYY")}
                //       </p>
                //     </div>
                //   </div>
                //   <div className={`${styles.actionsWrpr}`}>
                //     {rule.actions.map((action) => (
                //       <div className={`df acsa ${styles.actionLine}`}>
                //         <p className={`ffmr fs12 p-l-20`}>{action?.v?.label}</p>
                //       </div>
                //     ))}
                //   </div>
                // </div>
              ))}
            </>
          ) : undefined}
        </>
      ) : undefined}
      {}
      {/* {showDetails ? (
        <>
          <Swiper
            centeredSlidesBounds
            slidesPerView={"auto"}
            spaceBetween={0}
            // navigation
            pagination={{ clickable: true }}
            className={`customized ${styles.tabsSec}`}
          >
            {pastDueDetails.map((rule) => (
              <SwiperSlide
                className={`df ffc ${styles.tab} ${
                  selectedTab === rule._id ? styles.selected : ""
                }`}
                onClick={() => setSelectedTab(rule._id)}
              >
                <p className={`ffnr fs14 ttcaps ${styles.lbl}`}>{rule.title}</p>
                <p className={`ffnr fs14 ${styles.val}`}>
                  {ordinal(Number(rule.selectedDay))} day of{" "}
                  {rule.daysPastType.label}
                </p>
               
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : undefined}
      {showDetails ? (
        <div className={styles.cntntContainer}>
          <div className={styles.listWrpr}>
            {selectedTab
              ? pastDueDetails
                  .find((det) => det._id === selectedTab)
                  ?.reservations?.map((reservation) => (
                    <div className={`df acsa ffmr fs14 ${styles.line}`}>
                      <div className={styles.lineSec}>
                        <p>Unit {reservation.unit.unitId}</p>
                        <p className={`ffmr fs12 m-t-5 ${styles.sub}`}>
                          Scheduled for{" "}
                          {dayjs(reservation.pastDue.startDate)
                            .add(
                              pastDueDetails.find(
                                (det) => det._id === selectedTab
                              ).selectedDay,
                              "day"
                            )
                            .format("MM/DD/YYYY")}
                        </p>
                      </div>
                      <div className={`df acsa mla ${styles.lineSec}`}>
                      
                      </div>
                    </div>
                  ))
              : undefined}
          </div>
        </div>
      ) : undefined} */}
    </div>
  );
};

export default PastDueSection;
