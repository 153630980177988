import axios from "axios";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./UnitTypes.module.scss";

const UnitTypeFilter = ({ selected = [], updateFilter, close }) => {
  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [unitTypes, setUnitTypes] = useState(undefined);
  const [localSelected, setLocalSelected] = useState(selected);

  //   /api/unit-size/?locationId=4326
  const getUnitTypes = async () => {
    let localTypes = await axios.get(
      `/api/unit-size/?locationId=${reduxUser?.selectedLocation?._id}`
    );
    return setUnitTypes(localTypes?.data?.data);
  };

  useEffect(() => {
    if (!reduxUser) return;
    getUnitTypes();
    // eslint-disable-next-line
  }, [reduxUser]);

  useEffect(() => {
    updateFilter(localSelected);
    // eslint-disable-next-line
  }, [localSelected]);

  const toggleValue = (val) => {
    if (localSelected.includes(val)) {
      let idx = localSelected.indexOf(val);
      let copy = cloneDeep(localSelected);
      copy.splice(idx, 1);
      setLocalSelected(copy);
    } else {
      return setLocalSelected([...localSelected, val]);
    }
  };

  const isSelected = (val) => {
    // console.log(localSelected.includes(val), val);
    return localSelected.includes(val);
  };

  const selectAll = () => setLocalSelected(unitTypes.map((type) => type._id));

  return (
    <div
      className={`p-l-20 p-r-20 p-t-20 p-b-20 ${styles.typesFilterPopupContent}`}
    >
      {/* {console.log(localSelected)} */}
      <div className={`df acsa ffnsb fs10 ttuc m-b-4`}>
        <p>Unit Types</p>
        <div className={`df acsa mla ${styles.allNoneWrpr}`}>
          <p className={`${styles.selectors}`} onClick={selectAll}>
            All
          </p>
          <p
            className={`m-l-5 ${styles.selectors}`}
            onClick={() => setLocalSelected([])}
          >
            None
          </p>
        </div>
      </div>
      {unitTypes?.map((type) => (
        <div
          onClick={() => toggleValue(type._id)}
          className={`df acsa ffnr fs14 ${styles.line} ${
            isSelected(type._id) ? styles.selected : ""
          }`}
        >
          <div
            className={`m-r-8 ${styles.checkbox} ${
              isSelected(type._id) ? styles.checked : ""
            }`}
          ></div>
          <p>{type.label}</p>
        </div>
      ))}
      <button
        className={`button primary small w100 m-t-20 ttuc`}
        onClick={close}
      >
        Done
      </button>
    </div>
  );
};

export default UnitTypeFilter;
