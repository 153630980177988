import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Route, useHistory } from "react-router-dom";
import axios from "axios";
import TenantDetails from "../../tenants/tenant-details/TenantDetails";

// import redux types

//import scss
import "./UnitsList.scss";
import listStyles from "./NewUnitsList.module.scss";

//import components
import Portal from "../../../components/portal/Portal";
import AddUnit from "../add-unit/AddUnit";
import UnitsTable from "./UnitsTable";
import UnitTypeFilter from "../../tenants/filters/unit-types/UnitTypes";

import Lottie from "react-lottie";

import loader from "../../../assets/lotties/loader.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
// import icons
// import { FilterSVG } from "../../../assets/js-svgs/filter-icon.svg";

// const tempSizes = [
//   {
//     accountId: "5de08bbfb1759958473471f7",
//     calculatedQF: 400,
//     calculatedSQF: 50,
//     currency: "USD",
//     height: 8,
//     label: "Small",
//     length: 5,
//     locationId: "5def08c3bbd5014276c9d08c",
//     price: 60,
//     label: "Small",
//     width: 10,
//     _id: "620991d75072dff52a4d7a17",
//   },

//   {
//     accountId: "5de08bbfb1759958473471f7",
//     calculatedQF: 800,
//     calculatedSQF: 100,
//     currency: "USD",
//     height: 8,
//     label: "Medium",
//     length: 10,
//     locationId: "5def08c3bbd5014276c9d08c",
//     price: 90,
//     label: "Medium",
//     width: 10,
//     _id: "620991d75072dff52a4d7a18",
//   },
// ];

const UnitsList = (props) => {
  const history = useHistory();

  const { reduxUser } = useSelector(({ reduxUser }) => ({
    reduxUser,
  }));

  const [filters, setFilters] = useState({});
  // eslint-disable-next-line
  const [unitSizes, setUnitSizes] = useState([]);
  // eslint-disable-next-line
  const [showFilters, setShowFilters] = useState(false);
  // eslint-disable-next-line
  const [filtersString, setFiltersString] = useState("");
  const [unitsList, setUnitsList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [showAdjustPopup, setShowAdjustPopup] = useState(true);

  // const updateFilters = (filterType, id) => {
  //   let newData;
  //   const filterIdx = filters[filterType].findIndex((elem) => elem === id);

  //   if (filterIdx < 0) {
  //     newData = update(filters, {
  //       [filterType]: { $push: [id] },
  //     });
  //   } else {
  //     newData = update(filters, {
  //       [filterType]: { $splice: [[filterIdx, 1]] },
  //     });
  //   }

  //   setFilters(newData);
  // };

  // state = {
  //   selectedLines: [],
  // };

  // componentDidMount() {
  // this.props.getUnits(this.state.filters);
  // }

  const getUnits = async () => {
    let filtersString = "";
    for (let index = 0; index < Object.keys(filters).length; index++) {
      filtersString += `${index === 0 ? "?" : "&"}${
        Object.keys(filters)[index]
      }=${filters[Object.keys(filters)[index]]}`;
    }

    let locationId = reduxUser.selectedLocation._id;
    let token = await localStorage.getItem("x-auth");

    let unitsList = await axios.get(
      `/api/unit/${locationId}${
        Object.keys(filters).length ? filtersString : ""
      }`,
      {
        headers: { "x-auth": token },
      }
    );
    setUnitsList(unitsList.data.data);
    // dispatch({ type: GET_UNITS, payload: unitsList.data.data });
  };

  const getUnitSizes = async () => {
    let locationId = reduxUser.selectedLocation._id;
    let response = await axios.get(`/api/unit-size/?locationId=${locationId}`);
    setUnitSizes(response.data.data);
  };

  // const setupFiltersString = () => {
  //   let filtersString = "";
  //   for (let index = 0; index < Object.keys(filters).length; index++) {
  //     filtersString += `${index === 0 ? "?" : "&"}${
  //       Object.keys(filters)[index]
  //     }=${filters[Object.keys(filters)[index]]}`;
  //   }
  //   // setFiltersString(filtersString);
  // };

  useEffect(() => {
    getUnits();
    getUnitSizes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setupFiltersString();
    getUnits();
    // eslint-disable-next-line
  }, [filters]);

  // useEffect(() => {
  //   getUnits();
  //   // eslint-disable-next-line
  // }, [filtersString]);

  const updateFilters = async (key, vals) => {
    setFilters({ ...filters, [key]: vals });
  };

  // setTab = (val) => console.log(val);

  // toggleCheckbox = (id) => {
  //   let set = [...this.state.selectedLines];
  //   if (set.includes(id)) {
  //     set.splice(set.indexOf(id), 1);
  //   } else {
  //     set.push(id);
  //   }

  //   this.setState({ selectedLines: set });
  // };

  const closeAddPopup = () => history.push("/units");

  const toggleShowAdjustPopup = () => setShowAdjustPopup(!showAdjustPopup);

  // const resetFilters = () => {
  //   setFilters({});
  // };

  // const reload = () => {
  //   console.log("LOL Reloading");
  //   getUnits();
  // };

  return (
    <div className="route_wrpr units_list">
      {/* {filters?.sizes?.map((filter) => (
        <p>{filter}</p>
      ))} */}
      <div className="top_bar">
        <div className="df acsa header">
          <div className="ffnsb fs16">Units</div>
          <div className="df accsa mla actions_wrpr">
            <Link to="/units/add" className="df ffnb fs14 rwt main_action">
              Add Unit
            </Link>
          </div>
        </div>
        <div className="df acsa filtersWrpr">
          <p className={`ffnl fs14`}>Filter by:</p>

          <div className={listStyles.filterWrpr}>
            <div
              className={`m-l-10 df acsa ffnl fs14 ${listStyles.filterTypeWrpr}`}
              onClick={() => setShowFilter(true)}
            >
              <p>Unit Type</p>{" "}
              {filters?.sizes?.length ? (
                <p className={`df acc ffnb fs10 m-l-5 ${listStyles.countPill}`}>
                  {filters?.sizes?.length}
                </p>
              ) : undefined}
            </div>

            {showFilter ? (
              <Portal
                zIndex="9"
                opacity="0.2"
                close={() => setShowFilter(false)}
                height="auto"
                title="Select Unit Type"
                width="300px"
                customHeader={true}
              >
                <UnitTypeFilter
                  selected={filters?.sizes}
                  close={() => setShowFilter(false)}
                  updateFilter={(vals) => {
                    updateFilters("sizes", vals);
                  }}
                />
              </Portal>
            ) : undefined}
          </div>
          {/* <div
            className={`df acsa filterWrpr ${
              filters?.sizes?.length ? "hasItems" : ""
            }`}
          >
            {filters?.sizes?.length ? (
              <>
                <p className="df acsa ffnl fs14">
                  Size <span className={`arrow`}></span>{" "}
                </p>
                :
                {filters.sizes.map((size, idx) => (
                  <span className="ffnr fs14 filterItem">
                    {getSize(size)}
                    {idx < filters.sizes.length - 1 ? "," : ""}{" "}
                  </span>
                ))}
              </>
            ) : undefined}
          </div>
          <button className={`m-l-10 openFiltersBtn`} onClick={toggleFilters}>
            <FilterSVG />
          </button> */}
        </div>
      </div>
      {/*  */}
      {/* {showFilters ? (
        <div className={`df ${styles.filtersPopup}`}>
          <div className={styles.tabs}>
            <div>Sizes</div>
            <div>Status</div>
          </div>
          <div className={styles.filterList}>
            {unitSizes.map((size) => (
              <p
                className={`${
                  filters?.sizes?.includes(size._id) ? styles.selected : ""
                }`}
                onClick={() => updateFilters("sizes", size._id)}
              >
                {size.length}x{size.width} {size.label}
              </p>
            ))}

            <button>Apply</button>
          </div>
        </div>
      ) : undefined} */}
      <Route
        path="/units/tenant/:id"
        render={() => <TenantDetails closeUrl="/units" />}
      ></Route>
      <Route
        path="/units/add/:id?"
        render={() => {
          return (
            <Portal
              // minHeight="488px"
              width="354px"
              height="auto"
              zIndex="100"
              opacity="0.15"
              title="Add unit"
              close={closeAddPopup}
            >
              <AddUnit></AddUnit>
            </Portal>
          );
        }}
      ></Route>
      <div className="df acsa p-l-20 p-r-20 actionsWrpr">
        {false && selectedUnits?.length ? (
          <>
            <p className={`ffnsb fsti fs14`}>
              {selectedUnits?.length} Units Selected
            </p>
            <div className={`df acsa mla`}>
              <div className="button secondary small df acc ffnb fs14 p-l-20 p-r-20 m-r-10">
                Move out
              </div>
              <div className="button secondary small df acc ffnb fs14 p-l-20 p-r-20  m-r-10">
                Delete
              </div>
              <div
                onClick={toggleShowAdjustPopup}
                className="button secondary small df acc ffnb fs14 p-l-20 p-r-20"
              >
                Adjust rate
              </div>
            </div>
          </>
        ) : undefined}
      </div>
      {console.log("UnitsList", unitsList)}
      {unitsList?.length ? (
        <UnitsTable
          updateSelectedUnits={(val) => setSelectedUnits(val)}
          selectedUnitsProp={selectedUnits}
          data={unitsList.map((unit) => ({
            ...unit,
            description: {
              unitType: unit.unitType,
              unitFeatures: unit.unitFeatures,
            },
          }))}
        />
      ) : (
        <div className={`p-t-100 ${listStyles.loaderWrpr}`}>
          <Lottie options={defaultOptions} height={60} width={60} />
        </div>
      )}

      {/* {showAdjustPopup ? (
        <AdjustRate
          close={toggleShowAdjustPopup}
          units={selectedUnits}
          resetFilters={resetFilters}
          reload={reload}
        />
      ) : undefined} */}
    </div>
  );
};

export default UnitsList;
