import Axios from "axios";
import { useEffect, useState } from "react";
import Plus from "../../../../../assets/js-svgs/plus.svg";
import NonFormikRadio from "../../../../../components/inputs/NonFormikRadio";
import Portal from "../../../../../components/portal/Portal";
import AddCheck from "./add-check/AddCheck";

import styles from "./ChecksPopup.module.scss";

const PayCheck = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const getAccounts = async () => {
    let cardsList = await Axios.get(
      `/api/payment-methods/${props.userId}/check`
    );
    setAccounts(cardsList.data.data);
    setAccount(props.selected);
  };

  useEffect(() => {
    getAccounts();

    // eslint-disable-next-line
  }, []);

  return (
    <Portal
      zIndex="105"
      width="315px"
      height="auto"
      title="Check Details"
      noCustomScrollbar
      close={() => props.close()}
      opacity="0.25"
    >
      <div className={styles.payCheck}>
        {accounts.map((card, i) => {
          return (
            <div className={`df acsa ${styles.line}`} key={i}>
              <NonFormikRadio
                notBold
                label={`Bank account ****
                    ${
                      card.xMaskedCardNumber
                        ? card.xMaskedCardNumber.substring(
                            card.xMaskedCardNumber.length - 4,
                            card.xMaskedCardNumber.length
                          )
                        : card.xMaskedAccountNumber?.substring(
                            card.xMaskedAccountNumber?.length - 4,
                            card.xMaskedAccountNumber?.length
                          )
                    }
                    `}
                onChange={(e, field) => {
                  console.log("LOLP");
                  setAccount(field);
                }}
                field={String(card._id)}
                value={account === String(card._id)}
                id={`a_${card._id}`}
                name="card"
              ></NonFormikRadio>
            </div>
          );
        })}

        <div className={`${styles.sbmtWrpr}`}>
          <button
            disabled={!"selectedCard"}
            className="button secondary small w100"
            onClick={() => {
              props.setCardType(account);
              props.close();
            }}
          >
            Select Account
          </button>
        </div>

        {showAdd ? (
          <AddCheck
            userId={props.userId}
            setAccount={(v) => setAccount(v)}
            close={() => setShowAdd(false)}
            getAccounts={getAccounts}
          />
        ) : undefined}

        <div className={styles.addFtr}>
          <p
            className={`df acc ffnr fs14 ${styles.addBtn}`}
            onClick={() => setShowAdd(true)}
          >
            <Plus></Plus> Add Another Account
          </p>
        </div>
      </div>
    </Portal>
  );
};

export default PayCheck;
