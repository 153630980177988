import axios from "axios";
import { useEffect, useState } from "react";
import NoBalanceSVG from "../../../../../assets/js-svgs/no-balance.svg";
import BackArrow from "../../../../../assets/js-svgs/arrow.svg";

import styles from "./MoveOut.module.scss";
import dayjs from "dayjs";
import { formatFromCurrencyToMoney } from "../../../../../helpers";
import PayBalance from "./pay-balance/PayBalance";

const MoveOut = (props) => {
  const [rentalDetails, setRentalDetails] = useState(null);
  const [step, setStep] = useState(0);
  // const [unappliedPayments, setUnappliedPayments] = useState([]);

  // /api/payment/unapplied/

  function getUnappliedPayments() {
    if (!rentalDetails?.user) return;
    axios.get(`/api/payment/unapplied/${rentalDetails?.user}`);
  }

  const getRentalDetails = async () => {
    let localReservation = await axios.get(
      `/api/detailed-reservation/${props.selectedRental}`,
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    setRentalDetails(localReservation?.data?.data);
  };

  useEffect(() => {
    if (props.selectedRental) {
      getRentalDetails();
      getUnappliedPayments();
    }
    // eslint-disable-next-line
  }, [props.selectedRental]);

  useEffect(() => {
    if (rentalDetails) {
      getUnappliedPayments();
    }
    // eslint-disable-next-line
  }, [rentalDetails]);

  const completeMoveOut = async () => {
    await axios.put(
      `/api/reservation/${props.selectedRental}`,
      { moveOutDate: Number(new Date()) },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    props.refreshAction();
    props.close();
  };

  const waiveInvoiceItem = async (id) => {
    await axios.put(
      `/api/invoice-item/${id}`,
      { void: true },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    props.refreshAction();
    getRentalDetails();
  };

  return (
    <div className={styles.moveOut}>
      <div
        className={`df aic p-t-22 p-b-22 p-l-20 p-r-20 ${styles.moveOutHrd}`}
      >
        <div
          style={{ transform: "rotate(90deg)", marginTop: "-2px" }}
          className={`m-r-8 cursor_pointer`}
          onClick={props.close}
        >
          <BackArrow fill="#343434" height="14" width="14" />
        </div>
        <h3 className={`ffnsb fs14`}>
          Move Out Unit {rentalDetails?.unit?.unitId}
        </h3>
      </div>

      {(rentalDetails?.reservationBalance === 0 ||
        rentalDetails?.reservationBalance === undefined) &&
      props.accountCredit === 0 ? (
        <div className={`df ffc acc p-t-60 p-b-60`}>
          <p className={`ffnsb fs18 m-b-45`}>There is no outstanding balance</p>
          <NoBalanceSVG fill="rgba(0,0,0,0.25)" />
        </div>
      ) : (
        <>
          {step === 0 ? (
            <div className={styles.outstandingWrpr}>
              {rentalDetails?.invoiceItems
                ?.filter((elem) => elem.totalPaid < elem.amount)
                .map((invoice) => (
                  <div
                    key={invoice._id}
                    className={`df acsa p-l-10 p-r-5 ffnl fs14 ${styles.line}`}
                  >
                    <div className={`${styles.invoiceDate}`}>
                      {invoice.createdAt
                        ? dayjs(invoice.createdAt).format("MM/DD/YYYY")
                        : dayjs(new Date()).format("MM/DD/YYYY")}
                    </div>
                    <div className={`${styles.invoiceType}`}>
                      {invoice.type}
                    </div>
                    <div className={`${styles.invoiceAmount}`}>
                      {formatFromCurrencyToMoney(invoice.totalOutstanding)}
                    </div>

                    <div className={`df ${styles.action}`}>
                      <p
                        className={`mla ffnr fs14 ${styles.waiveBtn}`}
                        onClick={() => {
                          console.log(invoice);
                          waiveInvoiceItem(invoice._id);
                        }}
                      >
                        Waive
                      </p>
                    </div>
                  </div>
                ))}
              {props.accountCredit ? (
                <div
                  className={`df acsa p-l-10 p-r-5 ffnl fs14 ${styles.line}`}
                >
                  <div className={`${styles.invoiceDate}`}>N/A</div>
                  <div className={`${styles.invoiceType}`}>Account credit</div>
                  <div className={`${styles.invoiceAmount}`}>
                    {formatFromCurrencyToMoney(props.accountCredit)}
                  </div>

                  {/* <div className={`df ${styles.action}`}>
                <p className={`mla ffnr fs14 ${styles.waiveBtn}`}>Refund</p>
              </div> */}
                </div>
              ) : undefined}
              <div className={`df ${styles.totalsWrpr}`}>
                <div className={`mla ${styles.totals}`}>
                  <div className={`df ffnl fs14 ${styles.totalLine}`}>
                    <p className={styles.label}>Subtotal</p>
                    <p className={`tar ffnr ${styles.val}`}>
                      {formatFromCurrencyToMoney(
                        rentalDetails?.reservationBalance || 0
                      )}
                    </p>
                  </div>
                  <div className={`df ffnl fs14 ${styles.totalLine}`}>
                    <p className={styles.label}>Credits</p>
                    <p className={`tar ffnr ${styles.val}`}>
                      {formatFromCurrencyToMoney(props.accountCredit)}
                    </p>
                  </div>

                  <div className={`df ffnl fs14 ${styles.totalLine}`}>
                    <p className={styles.label}>Total Due</p>
                    <p className={`tar ffmm ${styles.val}`}>
                      {formatFromCurrencyToMoney(
                        (rentalDetails?.reservationBalance || 0) -
                          props.accountCredit
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 1 ? (
            <PayBalance
              reservationId={rentalDetails._id}
              stepBack={() => setStep(step - 1)}
              userId={rentalDetails?.user}
              balance={rentalDetails?.reservationBalance || 0}
              credits={props.accountCredit}
              close={props.close}
              refreshAction={props.refreshAction}
            />
          ) : undefined}
        </>
      )}
      {step === 1 ? undefined : (
        <div className={`${styles.moveOutFtr} df p-t-20 p-b-20 p-l-20 p-r-20`}>
          <div className={"mla"}>
            {(rentalDetails?.reservationBalance === 0 ||
              rentalDetails?.reservationBalance === undefined) &&
            props.accountCredit === 0 ? (
              <button
                className={`${styles.moveOutBtn} primary ttuc p-l-25 p-r-25`}
                onClick={completeMoveOut}
              >
                Complete Move Out
              </button>
            ) : (
              <>
                <button
                  disabled={step === 0}
                  className={`${styles.moveOutBtn} secondary ttuc m-r-15 p-l-25 p-r-25`}
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </button>
                <button
                  className={`${styles.moveOutBtn} primary ttuc p-l-25 p-r-25`}
                  onClick={() => setStep(step + 1)}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MoveOut;
