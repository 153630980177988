import { Fragment, useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import dayjs from "dayjs";

import { formatFromCurrencyToMoney } from "../../../helpers";

import styles from "./RentRoll.module.scss";
import "./RentRoll.scss";
console.log(formatFromCurrencyToMoney);

const RentRollTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Unit",
        accessor: "unit.unitId",
      },
      {
        Header: "Unit Size",
        accessor: "unit.unitSize",
        Cell: ({ value }) => (
          <>
            {value ? (
              <p className="ffmr fs14">
                <span className="ttcaps">{value?.label} </span> ({value?.length}{" "}
                x {value?.width})
              </p>
            ) : (
              "N/A"
            )}
          </>
        ),
      },
      {
        Header: "Tenant",
        accessor: "user",
        Cell: ({ value }) => (
          <>{value ? <p className="ffmr fs14">{value?.fullName}</p> : "N/A"}</>
        ),
      },
      {
        Header: "Account Number",
        accessor: "user.accountNum",
      },
      {
        Header: "Move In Date",
        accessor: "moveInDate",
        Cell: ({ value }) => (
          <>
            {value ? (
              <p className="ffmr fs14">{dayjs(value).format("MMM/DD/YYYY")}</p>
            ) : (
              "N/A"
            )}
          </>
        ),
      },
      {
        Header: "Paid Through",
        accessor: "paidThrough",
        Cell: ({ value }) => (
          <>
            {value ? (
              <p className="ffmr fs14">
                {dayjs(value.toDate).format("MMM/DD/YYYY")}
              </p>
            ) : (
              "N/A"
            )}
          </>
        ),
      },
      {
        Header: "Rent Rate",
        accessor: "reservationPrice",
        Cell: ({ value }) => (
          <>
            {value ? (
              <p className="ffmr fs14">
                {formatFromCurrencyToMoney(value.price)}
              </p>
            ) : (
              "N/A"
            )}
          </>
        ),
      },
      {
        Header: "Ins. Prem.",
        accessor: "reservationInsurance",
        Cell: ({ value }) => (
          <>
            {value ? (
              <p className="ffmr fs14">
                {formatFromCurrencyToMoney(value.insuranceBracket.premium)}
              </p>
            ) : (
              "N/A"
            )}
          </>
        ),
      },
      {
        Header: "Deposit",
        accessor: "test",
        Cell: ({ value }) => <p>Missing</p>,
      },
      {
        Header: "Rent Due",
        accessor: "test1",
        Cell: ({ value }) => <p>Missing</p>,
      },
    ],

    // reservationPrice

    // eslint-disable-next-line
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: data }, useSortBy);
  return (
    <div>
      <div {...getTableProps()} className="list_wrpr">
        <div className="df acsa ffqsb fs12 ttuc list_hdr line">
          {headerGroups.map((headerGroup, idx) => (
            <Fragment key={idx}>
              {/* ************* COME BACK TO THIS *************** */}
              {/* <tr {...headerGroup.getHeaderGroupProps()}> */}
              {headerGroup.headers.map((column) => (
                <div
                  className={`df acsa ${styles.sec} unit_num ${
                    styles[column.id]
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <p className="df acsa ttuc ffqsb fs12">
                    {/* <span
                    onClick={(e) =>
                      column.toggleSortBy(
                        column.isSortedDesc ? false : true,
                        false
                      )
                    }
                  >
                    Toggle
                  </span> */}
                    {column.render("Header")}
                    {column.isSorted ? (
                      <span
                        className={`sortArrow ${
                          column.isSortedDesc ? "up" : "down"
                        }`}
                      ></span>
                    ) : undefined}
                  </p>
                </div>
              ))}
            </Fragment>
          ))}
          <div className={`mla df acc ${styles.sec} ${styles.tenantLink}`}>
            {/* <ThreeDotsIcon /> */}
          </div>
          <div className="mla df acc moreOptionsBtn">
            {/* <ThreeDotsIcon /> */}
          </div>
        </div>
        <div {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className={`df acsa ffmr fs14 line`}
                // onClick={() => history.push(`/units/add/${row.original._id}`)}
              >
                {/* <div
                  className={`df acc ${styles.checkboxWrpr}`}
                  onClick={() => toggleCheckbox(row.original)}
                >
                  <div
                    className={`df acc ${styles.checkbox} ${
                      isUnitSelected(row.original._id) ? styles.checked : ""
                    }`}
                  ></div>
                </div> */}
                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={`df acsa sec  ${styles.sec} ${
                        styles[cell.column.id]
                      }`}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      {/* {data?.map((line) => (
        <p>Line</p>
      ))} */}
    </div>
  );
};

export default RentRollTable;
