import axios from "axios";
import React, { useEffect, useState } from "react";
import Portal from "../../../components/portal/Portal";
import DelinquencyRule from "./delinquency-rule/DelinquencyRule";
import ordinal from "ordinal";
import update from "immutability-helper";
import { TrashSVG } from "../../../assets/js-svgs/trash.svg";
import { EditSVG } from "../../../assets/js-svgs/edit.svg";
import IndentSVG from "../../../assets/js-svgs/indent.svg";
import SettingsNav from "../settings-nav/SettingNav";

import pageStyles from "../SettingsHome.module.scss";
import styles from "./BillingSettings.module.scss";
import { useSelector } from "react-redux";

const BillingSettings = (props) => {
  const { user } = useSelector(({ user }) => ({ user }));

  const [showAddRule, setShowAddRule] = useState(false);
  const [delinquencyRules, setDelinquencyRules] = useState(undefined);
  const [editRule, setEditRule] = useState(undefined);

  const getLocationFromLocalStorage = () => {
    const selectedLocationLocalStorage = JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    return selectedLocationLocalStorage?.selectedLocation;
  };

  const setDaysBeforeFromLocalStorage = () => {
    const selectedLocationLocalStorage = JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    return selectedLocationLocalStorage?.selectedLocation?.settings
      ?.daysInvoiceBeforeDue;
  };

  const setDaysAutopayPastDueFromLocalStorage = () => {
    const selectedLocationLocalStorage = JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    return (
      selectedLocationLocalStorage?.selectedLocation?.settings
        ?.daysAutopayPastDue || 10
    );
  };

  const setBillingTypeFromLocalStorage = () => {
    const selectedLocationLocalStorage = JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    return selectedLocationLocalStorage?.selectedLocation?.settings
      ?.billingType;
  };

  const [billingType, setBillingType] = useState(
    setBillingTypeFromLocalStorage()
  );
  const [daysInvoiceBeforeDue, setDaysInvoiceBeforeDue] = useState(
    setDaysBeforeFromLocalStorage()
  );
  const [daysAutopayPastDue, setDaysAutopayPastDue] = useState(
    setDaysAutopayPastDueFromLocalStorage()
  );
  const [location, setLocation] = useState(getLocationFromLocalStorage());

  const getDelinquencyRules = async () => {
    let rules = await axios.get(
      `/api/settings/delinquency-rules?graph=true&accountId=${user.account}`
    );
    setDelinquencyRules(rules?.data?.data);
  };

  //  accountId: user.account,

  useEffect(() => {
    getDelinquencyRules();
    getLocationFromLocalStorage();
    // eslint-disable-next-line
  }, []);

  const editRuleAction = (rule) => {
    setEditRule(rule);
    setShowAddRule(true);
  };

  const deleteRule = async (ruleId) => {
    await axios.delete(`/api/settings/run-delinquency-rules/${ruleId}`);

    let index = delinquencyRules.findIndex((elem) => elem._id === ruleId);

    console.log(index);

    let updateRules = await update(delinquencyRules, {
      $splice: [[index, 1]],
    });

    setDelinquencyRules(updateRules);
  };

  const saveSetting = async (field) => {
    let data = await axios.put(
      `/api/location/${location._id}`,
      {
        settings: {
          ...location.settings,
          // eslint-disable-next-line no-eval
          [field]: eval(field),
        },
      },
      {
        headers: {
          "x-auth": localStorage.getItem("x-auth"),
        },
      }
    );

    console.log(data.data.data);

    setLocation(data.data.data);

    localStorage.setItem(
      "selectedLocation",
      JSON.stringify({ selectedLocation: data.data.data })
    );
  };

  const getDeps = (rule) => {
    console.log(rule);
    return (
      <>
        {rule.deps.map((dependent) => (
          <>
            <div
              key={dependent._id}
              className={`df acsa fs14 p-l-22 ${styles.ruleLine} ${styles.dependent}`}
            >
              <span style={{ paddingLeft: `${dependent.level * 24}px` }}>
                <IndentSVG height="14" width="14" fill="#30be76" />
              </span>
              <span className={`ffnsb m-r-4 ttcaps m-l-10 ${styles.value}`}>
                {dependent.title}
              </span>
              <span className={`ffnr m-r-4`}>
                applied {dependent.recurring ? `(every month)` : ""} on the
              </span>
              <span className={`ffnsb m-r-4 ${styles.value}`}>
                {ordinal(Number(dependent.selectedDay))}
              </span>
              <span className={`ffnr m-r-4`}>day of</span>
              <span className={`ffnsb m-r-4 ${styles.value}`}>
                {dependent?.daysPastType?.label}:
              </span>{" "}
              {dependent?.actions.length ? (
                dependent?.actions.map((action, idx) => (
                  <React.Fragment key={idx}>
                    <span className={`ffnsb m-r-4 ${styles.value}`}>
                      {action?.v?.label}
                    </span>{" "}
                    {dependent.actions.length > 1 &&
                    idx + 1 < dependent.actions.length ? (
                      <>
                        <span className={`ffnr m-r-4`}>and</span>{" "}
                      </>
                    ) : undefined}
                  </React.Fragment>
                ))
              ) : (
                <span className={`ffnr m-r-4`}>No action taken</span>
              )}
              <div className={`df aic mla ${styles.actionsWrpr}`}>
                <div
                  onClick={() => editRuleAction(dependent)}
                  className={`cursor_pointer m-r-15 ${styles.trash}`}
                >
                  <EditSVG height="16" fill="#1f2041cc" opacity="1" />
                </div>
                <div
                  onClick={() => deleteRule(dependent._id)}
                  className={`cursor_pointer ${styles.trash}`}
                >
                  <TrashSVG fill="#1f2041cc" />
                </div>
              </div>
            </div>
            {dependent?.deps?.length ? getDeps(dependent) : undefined}
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <SettingsNav />
      <div className={pageStyles.page}>
        <div className={styles.billingSettings}>
          <div className={styles.card}>
            <div className={`df acsa ${styles.cardHdr}`}>
              <div>
                <p className={`ffnsb fs12 ttuc`}>Delinquency Stages</p>
                <p className={`ffnr fs12 m-t-10`}>
                  Set up rules for delinquencies
                </p>
              </div>
              <button
                className={`mla ffnr s14 ${styles.addBtn}`}
                onClick={() => setShowAddRule(true)}
              >
                Add rule
              </button>
              {showAddRule ? (
                <Portal
                  title="Create a new delinquency rule"
                  width="354px"
                  height="auto"
                  zIndex="100"
                  opacity="0.45"
                  close={() => {
                    setShowAddRule(false);
                    setEditRule(undefined);
                  }}
                  customClass={"delinquency-rule-portal"}
                >
                  <DelinquencyRule
                    close={() => {
                      setShowAddRule(false);
                      setEditRule(undefined);
                    }}
                    delinquencyRules={delinquencyRules}
                    refreshRules={getDelinquencyRules}
                    rule={editRule}
                  />
                </Portal>
              ) : undefined}
            </div>
            {delinquencyRules?.length ? (
              delinquencyRules.map((rule) => (
                <>
                  <div
                    key={rule._id}
                    className={`df acsa fs14 ${styles.ruleLine}`}
                  >
                    <span className={`ffnsb m-r-4 ttcaps ${styles.value}`}>
                      {rule.title}
                    </span>
                    <span className={`ffnr m-r-4`}>
                      applied {rule.recurring ? `(every month)` : ""} on the
                    </span>
                    <span className={`ffnsb m-r-4 ${styles.value}`}>
                      {ordinal(Number(rule.selectedDay))}
                    </span>
                    <span className={`ffnr m-r-4`}>day of</span>
                    <span className={`ffnsb m-r-4 ${styles.value}`}>
                      {rule?.daysPastType?.label}:
                    </span>{" "}
                    {rule?.actions.length ? (
                      rule?.actions.map((action, idx) => (
                        <React.Fragment key={idx}>
                          <span className={`ffnsb m-r-4 ${styles.value}`}>
                            {action?.v?.label}
                          </span>{" "}
                          {rule.actions.length > 1 &&
                          idx + 1 < rule.actions.length ? (
                            <>
                              <span className={`ffnr m-r-4`}>and</span>{" "}
                            </>
                          ) : undefined}
                        </React.Fragment>
                      ))
                    ) : (
                      <span className={`ffnr m-r-4`}>No action taken</span>
                    )}
                    <div className={`df aic mla ${styles.actionsWrpr}`}>
                      <div
                        onClick={() => editRuleAction(rule)}
                        className={`cursor_pointer m-r-15 ${styles.trash}`}
                      >
                        <EditSVG height="16" fill="#1f2041cc" opacity="1" />
                      </div>
                      <div
                        onClick={() => deleteRule(rule._id)}
                        className={`cursor_pointer ${styles.trash}`}
                      >
                        <TrashSVG fill="#1f2041cc" />
                      </div>
                    </div>
                  </div>
                  {getDeps(rule)}
                  {/* {rule?.deps?.map((dependent) => (
                    <div
                      key={dependent._id}
                      className={`df acsa fs14 p-l-22 ${styles.ruleLine} ${styles.dependent}`}
                    >
                      <IndentSVG height="14" width="14" fill="#30be76" />
                      <span
                        className={`ffnsb m-r-4 ttcaps m-l-10 ${styles.value}`}
                      >
                        {dependent.title}
                      </span>
                      <span className={`ffnr m-r-4`}>applied on the</span>
                      <span className={`ffnsb m-r-4 ${styles.value}`}>
                        {ordinal(Number(dependent.selectedDay))}
                      </span>
                      <span className={`ffnr m-r-4`}>day of</span>
                      <span className={`ffnsb m-r-4 ${styles.value}`}>
                        {dependent?.daysPastType?.label}:
                      </span>{" "}
                      {dependent?.actions.length ? (
                        dependent?.actions.map((action, idx) => (
                          <React.Fragment key={idx}>
                            <span className={`ffnsb m-r-4 ${styles.value}`}>
                              {action?.v?.label}
                            </span>{" "}
                            {dependent.actions.length > 1 &&
                            idx + 1 < dependent.actions.length ? (
                              <>
                                <span className={`ffnr m-r-4`}>and</span>{" "}
                              </>
                            ) : undefined}
                          </React.Fragment>
                        ))
                      ) : (
                        <span className={`ffnr m-r-4`}>No action taken</span>
                      )}
                      <div className={`df aic mla ${styles.actionsWrpr}`}>
                        <div
                          onClick={() => editRuleAction(dependent)}
                          className={`cursor_pointer m-r-15 ${styles.trash}`}
                        >
                          <EditSVG height="16" fill="#1f2041cc" opacity="1" />
                        </div>
                        <div
                          onClick={() => deleteRule(dependent._id)}
                          className={`cursor_pointer ${styles.trash}`}
                        >
                          <TrashSVG fill="#1f2041cc" />
                        </div>
                      </div>
                    </div>
                  ))} */}
                </>
              ))
            ) : (
              <div className={`ffnr fs14 ${styles.listPlaceholder}`}>
                <p>
                  Set up rules for delinquencies and manage them We'll send you
                  an e-mail when your project hits the milestone you've set.
                  Read how it works
                </p>
              </div>
            )}
          </div>

          <div className={`m-t-25 ${styles.card}`}>
            <div className={`df acsa ${styles.cardHdr}`}>
              <div>
                <p className={`ffnsb fs12 ttuc`}>Billing Cycle</p>
                <p className={`ffnr fs12 m-t-10`}>Set up billing cycle</p>
              </div>
            </div>
            <div
              className={`df acsa fs14 ${styles.settingLine} ${styles.hasInput}`}
            >
              <p className={`ffnsb`}>
                Invoice{" "}
                <input
                  style={{ width: "50px" }}
                  value={daysInvoiceBeforeDue}
                  onChange={(e) => {
                    setDaysInvoiceBeforeDue(e.target.value);
                  }}
                />{" "}
                days before bill is due
              </p>
              {daysInvoiceBeforeDue !== "" &&
              daysInvoiceBeforeDue?.toString() !==
                location?.settings?.daysInvoiceBeforeDue?.toString() ? (
                <button
                  onClick={() => saveSetting("daysInvoiceBeforeDue")}
                  className={`mla ${styles.actionBtn}`}
                >
                  Save
                </button>
              ) : undefined}
            </div>

            <div className={`df acsa fs14 ${styles.settingLine}`}>
              <p className={`ffnsb m-r-6`}>
                Billing type {console.log(location)}
              </p>
              <div className={`df ${styles.toggleWrpr}`}>
                <div
                  onClick={() => {
                    setBillingType("proRated");
                  }}
                  className={`df acc ${styles.toggle} ${
                    billingType === "proRated"
                      ? styles.toggleOn
                      : styles.toggleOff
                  }`}
                >
                  <p className={`ffnr fs12 m-l-6`}>Pro Rated</p>
                </div>
                <div
                  onClick={() => {
                    setBillingType("anniversary");
                  }}
                  className={`df acc ${styles.toggle} ${
                    billingType === "anniversary"
                      ? styles.toggleOn
                      : styles.toggleOff
                  }`}
                >
                  <p className={`ffnr fs12 m-l-6`}>Anniversary</p>
                </div>
              </div>

              {billingType !== "" &&
              billingType !== location?.settings?.billingType ? (
                <button
                  className={`mla ${styles.actionBtn}`}
                  onClick={() => {
                    saveSetting("billingType");
                  }}
                >
                  Save
                </button>
              ) : undefined}
            </div>

            <div
              className={`df acsa fs14 ${styles.settingLine} ${styles.hasInput}`}
            >
              <p className={`ffnsb`}>
                Autopay can be upto
                <input
                  style={{ width: "50px" }}
                  value={daysAutopayPastDue}
                  onChange={(e) => {
                    setDaysAutopayPastDue(e.target.value);
                  }}
                />{" "}
                days after bill is due
              </p>
              {daysAutopayPastDue !== "" &&
              daysAutopayPastDue?.toString() !==
                location?.settings?.daysAutopayPastDue?.toString() ? (
                <button
                  onClick={() => saveSetting("daysAutopayPastDue")}
                  className={`mla ${styles.actionBtn}`}
                >
                  Save
                </button>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingSettings;
