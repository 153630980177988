import React, { Component } from "react";
import Select, { components } from "react-select";

// import scss
// import "./DefaultSelect.scss";

const { Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return child && child.type !== Placeholder ? child : null;
      })}
    </components.ValueContainer>
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {/* <span
      className={`unit_type_icon dark`}
      style={{ backgroundImage: `url('${props.data.imageUrl}')` }}
    ></span> */}
    {children}
  </components.SingleValue>
);

const Option = (props) => {
  return (
    <div className={`__list_line`}>
      <components.Option {...props}>
        {/* <span
          className={`unit_type_icon dark `}
          style={{ backgroundImage: `url('${props.data.imageUrl}')` }}
        ></span> */}
        {props.label}
      </components.Option>
    </div>
  );
};

class NonFormikSelect extends Component {
  state = { open: false };
  handleChange = (value, b, c) => {
    // this is going to call setFieldValue and manually update field 'from this.props.name'
    this.props.onChange(value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update field 'from this.props.name'
    this.props.onBlur(this.props.name, true);
    this.setState({ open: false });
  };

  customStyles = (error) => ({
    menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
    option: (base, state) => ({
      fontFamily: "mont-reg",
      fontSize: this.props.size === "small" ? "12px" : "14px",
      color: state.isDisabled
        ? "rgba(31, 32, 65, 0.20)"
        : "rgba(31, 32, 65, 0.75)",
      height: this.props.size === "small" ? "35px" : "44px",
      lineHeight: this.props.size === "small" ? "35px" : "44px",
      padding: "0px 10px",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      display: this.props.displayBlock ? "block" : "flex",
      alignItems: "center",
      textTransform: "capitalize",

      "&:hover": {
        backgroundColor: state.isDisabled
          ? "transparent"
          : state.isFocused
          ? "rgba(31, 32, 65, 0.16)"
          : "rgba(31, 32, 65, 0.10)",
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    }),
    control: (base, state) => ({
      display: "flex",
      minHeight: this.props.size === "small" ? "initial" : "48px",
      height: this.props.size === "small" ? "35px" : "initial",
      borderRadius: "4px",
      border: "1px solid rgba(31, 32, 65, 0.25)",
      // marginTop: "8px",
      borderColor: `${error ? "#ec407a" : "#e5e5e5"}`,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    multiValue: (base, state) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0px 10px",
      boxSizing: "border-box",
      border: "1px solid #e5e5e5",
      borderRadius: "22px",
      background: "none",
      fontFamily: "mont-reg",
      fontSize: "14px",
      textTransform: "lowercase",
      height: this.props.size === "small" ? "12px" : "18px",
      lineHeight: this.props.size === "small" ? "12px" : "18px",
    }),
    singleValue: (base, state) => ({
      ...base,
      fontFamily: "mont-med",
      fontSize: this.props.size === "small" ? "12px" : "14px",
      height: "38px",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      color: this.props.textColor || undefined,
    }),
    multiValueLabel: (base, state) => ({
      ...base,
    }),
    // multiValueRemove: () => ({
    //backgroundColor: "orange"
    // }),

    clearIndicator: () => ({
      backgroundColor: "green",
    }),
    // placeholder: (base, state) => ({
    //   color: state.isSelected || state.isFocused ? "red" : "orange",
    //   top: "0px",
    //   position: "absolute",
    //   display: "block"
    // })
    placeholder: (base, state) => ({
      ...base,
      fontFamily: "nunito-semi-bold",
      marginLeft: "0px",
      fontSize: state.hasValue ? "10px" : "14px",
      textTransform: state.hasValue && "uppercase",
      height: state.hasValue ? "initial" : "18px",
      position: "absolute",
      top:
        !state.hasValue && this.props.size === "small"
          ? "15px"
          : !state.hasValue
          ? "22px"
          : "8px",
      left: "8px",
      color: state.hasValue
        ? this.props.textColor || "rgba(31, 32, 65, 0.75)"
        : error
        ? "rgba(236, 64, 122, 0.5)"
        : this.props.textColor || "rgba(31, 32, 65, 0.3)",
      padding: "3px 6px 0px",
      transition: "all 0.17s",
      borderRadius: "3px",
      backgroundColor: this.props.bgColor || "white",
      display:
        this.props.size === "small" && state.hasValue ? "none" : "initial",
    }),
    valueContainer: (base) => ({
      ...base,
      overflow: "visible",
      padding: "6px 8px",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      alignSelf: "start",
      top: this.props.size === "small" ? "-2px" : "6px",
    }),
  });

  render() {
    return (
      <div
        className={`__inpt_wrpr ${this.props.size === "small" ? "small" : ""}`}
      >
        <Select
          isDisabled={this.props.disabled}
          isOptionDisabled={(option) => option.disabled}
          isSearchable={false}
          getOptionValue={(option) => option._id}
          id="color"
          options={this.props.options}
          isMulti={this.props.isMulti}
          onChange={this.handleChange}
          //   onBlur={this.handleBlur}
          menuPortalTarget={this.props.menuPortalTarget}
          menuPlacement={this.props.placement || "auto"}
          value={this.props.value}
          styles={this.customStyles(this.props.error)}
          closeMenuOnSelect={!this.props.isMulti}
          isClearable={false}
          components={{
            ValueContainer: CustomValueContainer,
            Option,
            SingleValue,
          }}
          placeholder={this.props.label}
          // menuIsOpen={true}
        />
        {/* {console.log(this.props)} */}
        {!!this.props.error && this.props.touched && (
          <div style={{ color: "#ec407a" }}>{this.props.error}</div>
        )}
      </div>

      // </div>
    );
  }
}

export default NonFormikSelect;
