import React from "react";

export const Visa = (props) => {
  return (
    <svg
      width={props.width || "504"}
      height={props.height || "153"}
      viewBox="0 0 504 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M184.801 148.801L210.401 4.80078H250.401L225.601 148.801H184.801Z"
        fill="#3C58BF"
      />
      <path
        d="M184.801 148.801L217.601 4.80078H250.401L225.601 148.801H184.801Z"
        fill="#293688"
      />
      <path
        d="M370.4 6.39999C362.4 3.19999 349.6 0 333.6 0C293.6 0 264.8 20 264.8 48.8C264.8 70.4 284.8 81.6 300.8 88.8C316.8 96 321.6 100.8 321.6 107.2C321.6 116.8 308.8 121.6 297.6 121.6C281.6 121.6 272.8 119.2 259.2 113.6L253.6 111.2L248 144C257.6 148 275.2 152 293.6 152C336 152 364 132 364 101.6C364 84.8 353.6 72 329.6 61.6C315.2 54.4 306.4 50.4 306.4 43.2C306.4 36.8 313.6 30.4 329.6 30.4C343.2 30.4 352.8 32.8 360 36L364 37.6L370.4 6.39999Z"
        fill="#3C58BF"
      />
      <path
        d="M370.4 6.39999C362.4 3.19999 349.6 0 333.6 0C293.6 0 272 20 272 48.8C272 70.4 284.8 81.6 300.8 88.8C316.8 96 321.6 100.8 321.6 107.2C321.6 116.8 308.8 121.6 297.6 121.6C281.6 121.6 272.8 119.2 259.2 113.6L253.6 111.2L248 144C257.6 148 275.2 152 293.6 152C336 152 364 132 364 101.6C364 84.8 353.6 72 329.6 61.6C315.2 54.4 306.4 50.4 306.4 43.2C306.4 36.8 313.6 30.4 329.6 30.4C343.2 30.4 352.8 32.8 360 36L364 37.6L370.4 6.39999Z"
        fill="#293688"
      />
      <path
        d="M439.2 4.80078C429.6 4.80078 422.4 5.60079 418.4 15.2008L358.4 148.801H401.6L409.6 124.801H460.8L465.6 148.801H504L470.4 4.80078H439.2V4.80078ZM420.8 100.801C423.2 93.6008 436.8 58.4008 436.8 58.4008C436.8 58.4008 440 49.6008 442.4 44.0008L444.8 57.6008C444.8 57.6008 452.8 93.6008 454.4 101.601H420.8V100.801Z"
        fill="#3C58BF"
      />
      <path
        d="M448.8 4.80078C439.2 4.80078 432 5.60079 428 15.2008L358.4 148.801H401.6L409.6 124.801H460.8L465.6 148.801H504L470.4 4.80078H448.8V4.80078ZM420.8 100.801C424 92.8008 436.8 58.4008 436.8 58.4008C436.8 58.4008 440 49.6008 442.4 44.0008L444.8 57.6008C444.8 57.6008 452.8 93.6008 454.4 101.601H420.8V100.801Z"
        fill="#293688"
      />
      <path
        d="M111.199 105.601L107.199 84.8006C99.9992 60.8006 76.7992 34.4006 51.1992 21.6006L87.1992 149.601H130.399L195.199 5.60059H151.999L111.199 105.601Z"
        fill="#3C58BF"
      />
      <path
        d="M111.199 105.601L107.199 84.8006C99.9992 60.8006 76.7992 34.4006 51.1992 21.6006L87.1992 149.601H130.399L195.199 5.60059H159.999L111.199 105.601Z"
        fill="#293688"
      />
      <path
        d="M0 4.80078L7.2 6.40079C58.4 18.4008 93.6 48.8008 107.2 84.8008L92.8 16.8008C90.4 7.20078 83.2 4.80078 74.4 4.80078H0V4.80078Z"
        fill="#FFBC00"
      />
      <path
        d="M0 4.80078C51.2 16.8008 93.6 48.0008 107.2 84.0008L93.6 27.2008C91.2 17.6008 83.2 12.0008 74.4 12.0008L0 4.80078Z"
        fill="#F7981D"
      />
      <path
        d="M0 4.80078C51.2 16.8008 93.6 48.0008 107.2 84.0008L97.6 52.8008C95.2 43.2008 92 33.6008 80.8 29.6008L0 4.80078Z"
        fill="#ED7C00"
      />
      <path
        d="M151.2 100.801L124 73.6008L111.2 104.001L108 84.0008C100.8 60.0008 77.6 33.6008 52 20.8008L88 148.801H131.2L151.2 100.801Z"
        fill="#051244"
      />
      <path
        d="M225.601 148.801L191.201 113.601L184.801 148.801H225.601Z"
        fill="#051244"
      />
      <path
        d="M317.6 99.2012C320.8 102.401 322.4 104.801 321.6 108.001C321.6 117.601 308.8 122.401 297.6 122.401C281.6 122.401 272.8 120.001 259.2 114.401L253.6 112.001L248 144.801C257.6 148.801 275.2 152.801 293.6 152.801C319.2 152.801 340 145.601 352 132.801L317.6 99.2012Z"
        fill="#051244"
      />
      <path
        d="M364 148.801H401.6L409.6 124.801H460.8L465.6 148.801H504L490.4 90.401L442.4 44.001L444.8 56.801C444.8 56.801 452.8 92.801 454.4 100.801H420.8C424 92.801 436.8 58.401 436.8 58.401C436.8 58.401 440 49.601 442.4 44.001"
        fill="#051244"
      />
    </svg>
  );
};
