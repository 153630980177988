import React from "react";

const DashboardSvg = (props) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9H0L10 0L20 9H17V17H11V11H9V17H3V9ZM15 7.19L10 2.69L5 7.19V15H7V9H13V15H15V7.19Z"
      fill={props.fill || ""}
      fillOpacity={props.fillOpacity || ""}
    />
  </svg>
);

export default DashboardSvg;
