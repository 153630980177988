import React from "react";

const TransferArrowIcon = (props) => {
	return (
		<svg
			width="15"
			height="17"
			viewBox="0 0 15 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 11L9 17L7.58 15.58L11.17 12H0V0H2V10H11.17L7.58 6.42L9 5L15 11Z"
				fill="#1F2041"
				fillOpacity="0.2"
			/>
		</svg>
	);
};

export default TransferArrowIcon;
