import { useSelector } from "react-redux";
import EasyStorageSVG from "../../../assets/js-svgs/easy-storage.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";

import pageStyles from "../SettingsHome.module.scss";
import styles from "./AdminSettings.module.scss";
import SettingsNav from "../settings-nav/SettingNav";
import Insurance from "./insurance/Insurance";
import Fees from "./fees/Fees";
import Promos from "./promos/Promos";
// import NonFormikSelect from "../../../components/inputs/drop-down/non-formik/NonFormikSelect";
// import { useEffect, useState } from "react";

const AdminSettings = (props) => {
  const history = useHistory();

  const { user, reduxUser } = useSelector(({ user, reduxUser }) => ({
    user,
    reduxUser,
  }));

  const startImport = async () => {
    let job = await axios.post("/api/new-import/start", {
      accountId: user.account,
      locationId: reduxUser.selectedLocation._id,
    });
    history.push(`/settings/admin/import/${job.data._id}`);
  };

  return (
    <>
      <SettingsNav />
      <div className={pageStyles.page}>
        <div className={styles.adminSettings}>
          <Insurance />
          <Fees />
          <Promos />
          <div className={styles.card}>
            <div className={`df acsa ${styles.cardHdr}`}>
              <div>
                <p className={`ffnsb fs12 ttuc`}>Imports</p>
                <p className={`ffnr fs12 m-t-10`}>
                  Import data from another platform
                </p>
              </div>
            </div>

            <div className={`df aic`}>
              <div className={`df acc ffc ${styles.section}`}>
                <EasyStorageSVG height="56" />
                <button
                  onClick={() => startImport("es")}
                  className={`button primary w100 m-t-20 ttuc`}
                >
                  Import from Easy storage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettings;
