import { Link } from "react-router-dom";
import styles from "./Nav.module.scss";

const ReportsNav = (props) => {
  return (
    <div className={styles.reportsHubNav}>
      <div className={`df ${styles.cardsWrpr}`}>
        <Link to="/reports-hub/occupancy" className={styles.card}>
          <p className={`ffnsb fs14 m-b-8 ${styles.title}`}>
            Occupancy Statistics{" "}
          </p>

          <p className={`ffnr fs12 ${styles.sub}`}>
            See how many units are occupied on each location
          </p>
        </Link>
        <Link to="/reports-hub/rent-roll" className={styles.card}>
          <p className={`ffnsb fs14 m-b-8 ${styles.title}`}>Rent Roll </p>

          <p className={`ffnr fs12 ${styles.sub}`}>
            See rent roll details by tenant and by unit
          </p>
        </Link>
        <div className={styles.card}>
          <p className={`ffnsb fs14 m-b-8 ${styles.title}`}>
            Occupancy Statistics{" "}
          </p>

          <p className={`ffnr fs12 ${styles.sub}`}>
            See how many units are occupied on each location
          </p>
        </div>
        <div className={styles.card}>
          <p className={`ffnsb fs14 m-b-8 ${styles.title}`}>
            Occupancy Statistics{" "}
          </p>

          <p className={`ffnr fs12 ${styles.sub}`}>
            See how many units are occupied on each location
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportsNav;
