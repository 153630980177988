import axios from "axios";
import { useState } from "react";
import CurrencyInput from "../../../../../../components/inputs/CurrencyInput";

const {
  default: Portal,
} = require("../../../../../../components/portal/Portal");

const ApplyCredit = ({ userId, close }) => {
  const [amount, setAmount] = useState("");

  const saveCredit = async () => {
    console.log(amount, userId);

    // let re =
    await axios.post("/api/credit", {
      amount,
      userId,
    });

    close();
  };

  return (
    <Portal
      title="Apply credit"
      opacity="0.2"
      zIndex="3"
      close={close}
      height="auto"
      minHeight="200px"
      width="300px"
    >
      <div className={`p-l-20 p-r-20 p-b-30`}>
        <CurrencyInput
          label="Amount"
          id="amt"
          value={amount}
          onChange={(val) => setAmount(val.fullNumber)}
        />
        <button className={`m-t-20 primary w100 ttuc`} onClick={saveCredit}>
          Save credit
        </button>
      </div>
    </Portal>
  );
};

export default ApplyCredit;
