import { Route } from "react-router-dom";
import AdminSettings from "./admin/AdminSettings";
import Import from "./admin/import/Import";
import BillingSettings from "./billing/BillingSettings";
import ReservationFlow from "./reservation-flow/ReservationFlow";
import SettingsNav from "./settings-nav/SettingNav";

import styles from "./SettingsHome.module.scss";
import UnitSettings from "./unit-settings/UnitSettings";
import Users from "./users/Users";
import AccountSettings from "./account/Account";
import Website from "./website/Website";
import Locations from "./locations/Locations";
import TemplateSettings from "./templates/Templates";

const SettingsHome = (props) => (
  <div className={styles.settingsPage}>
    {/* <SettingsNav /> */}
    {/* <div className={`df ${styles.cardsWrpr}`}> */}
    {/* <NavLink to="/settings/import" className={styles.card}>
        <p className={`ffnsb fs14 m-b-8 ${styles.title}`}>Import customer</p>

        <p className={`ffnr fs12 ${styles.sub}`}>
          Click here to import a new customer
        </p>
      </NavLink>
      <NavLink to="/settings/payment-processing" className={styles.card}>
        <p className={`ffnsb fs14 m-b-8 ${styles.title}`}>Payment processing</p>

        <p className={`ffnr fs12 ${styles.sub}`}>
          Click here to set up payment/merchant info
        </p>
      </NavLink> */}
    {/* </div> */}
    {/* <div className={styles.page}> */}
    <Route
      exact
      path="/settings/"
      component={() => <SettingsNav />}
    ></Route>{" "}
    <Route path="/settings/account" component={AccountSettings}></Route>
    <Route path="/settings/billing" component={BillingSettings}></Route>
    <Route path="/settings/admin" exact component={AdminSettings}></Route>
    <Route path="/settings/users" exact component={Users}></Route>
    <Route path="/settings/units" exact component={UnitSettings}></Route>{" "}
    <Route path="/settings/flow" exact component={ReservationFlow}></Route>
    <Route path="/settings/website" exact component={Website}></Route>
    <Route path="/settings/locations" component={Locations}></Route>
    <Route path="/settings/templates" component={TemplateSettings}></Route>
    <Route
      path="/settings/admin/import/:id?/:fileid?"
      component={Import}
    ></Route>
    {/* </div> */}
  </div>
);

export default SettingsHome;
