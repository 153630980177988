import React, { Component } from "react";

//import scss
import "./AddUser.scss";

class AddUser extends Component {
  render() {
    return <div className="add_user_popup">Add User popup</div>;
  }
}

export default AddUser;
