import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import RateDirectionArrow from "../../../../assets/js-svgs/rate-direction-arrow.svg";
import Arrow from "../../../../assets/js-svgs/arrow.svg";
import { formatFromCurrencyToMoney } from "../../../../helpers";
import styles from "./AdjustLine.module.scss";

dayjs.extend(relativeTime);

const AdjustLine = ({
  user,
  toggleCheckbox,
  selected,
  amount,
  type,
  selectAllTenant,
  deSelectAllTenant,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const isChecked = (id) =>
    selected.findIndex((el) => id === el.reservationId) >= 0;

  const tenantChecked = () => {
    let ownReservations = selected.filter((res) =>
      user.reservations
        .map((userRes) => userRes._id)
        .includes(res.reservationId)
    );

    return ownReservations?.length === user.reservations.length
      ? "all"
      : ownReservations?.length > 0
      ? "some"
      : "";
  };

  const updatedAmount = (currentPrice) => {
    if (type === "dollar") {
      let newAmount = currentPrice + amount * 100;
      let diffCalculator = (a, b) => Math.abs(a - b);

      let diff = `%${(
        (100 * diffCalculator(currentPrice, newAmount)) /
        currentPrice
      ).toFixed(0)}${newAmount > currentPrice ? " up" : " down"}`;

      return { newAmount, diff };
    } else {
      let newAmount = currentPrice + currentPrice * (amount / 100);
      let diffCalculator = (a, b) => Math.abs(a - b);

      let diff = `${formatFromCurrencyToMoney(
        diffCalculator(currentPrice, newAmount)
      )}${newAmount > currentPrice ? " up" : " down"}`;

      return { newAmount, diff };
    }
  };

  return (
    <div className={`${styles.adjustLine}`}>
      {/* <p>{user.user.fullName}</p> */}
      <div className={`df acsa p-b-18 p-t-18 ${styles.userHeader}`}>
        <div
          className={`df acc m-r-15 ${styles.checkbox} ${
            tenantChecked() === "all"
              ? styles.checked
              : tenantChecked() === "some"
              ? styles.some
              : ""
          }`}
          onClick={() => {
            if (tenantChecked() === "all") {
              deSelectAllTenant(user._id);
            } else {
              selectAllTenant(user._id);
            }
          }}
        ></div>
        <div className={styles.nameSec}>
          <p className={`ffmb fs14`}>{user.user.fullName}</p>
          <p className={`ffnr fs12 m-t-3`}>
            {user?.user?.createdAt
              ? dayjs(new Date(user?.user?.createdAt)).format("MMM/DD/YYYY")
              : ""}
          </p>
        </div>

        {user?.reservations?.length ? (
          <div className={`df acc fs16 ffnr ${styles.unitCount}`}>
            <span className={`ffnb m-r-5`}>{user?.reservations?.length}</span>{" "}
            Units
          </div>
        ) : undefined}

        <div onClick={() => setShowDetails(!showDetails)} className={`mla`}>
          <Arrow fill="#000" height="6" width="12" fillOpacity="1" />
        </div>
      </div>

      {showDetails ? (
        <div className={` ${styles.moreDetails}`}>
          <div
            className={`df acsa m-b-8 ${styles.line} ${styles.detailsHeader}`}
          >
            <div
              className={`ffnb fs12 ttuc ${styles.sec} ${styles.checkboxSec}`}
            >
              {/* <div className={styles.checkbox}></div> */}
            </div>
            <div className={`ffnb fs12 ttuc ${styles.sec} ${styles.id}`}>
              <p>Unit Id</p>
            </div>
            <div className={`ffnb fs12 ttuc ${styles.sec} ${styles.size}`}>
              <p>Unit Size</p>
            </div>
            <div className={`ffnb fs12 ttuc ${styles.sec} ${styles.date}`}>
              <p>Move In Date</p>
            </div>
            <div className={`ffnb fs12 ttuc ${styles.sec} ${styles.rate}`}>
              <p>Current Rate / Street Rate</p>
            </div>
            <div className={`ffnr fs14 ${styles.sec} ${styles.updated}`}>
              <p>Updated Rate</p>
            </div>
            <div className={`ffnb fs12 ttuc ${styles.sec} ${styles.change}`}>
              <p>Upcoming Rate Change</p>
            </div>
          </div>
          {user.reservations.map((reservation) => (
            <div className={`df acsa ${styles.line}`}>
              <div className={`ffnr fs14 ${styles.sec} ${styles.checkboxSec}`}>
                <div
                  onClick={() =>
                    toggleCheckbox({
                      unitId: reservation?.unit?._id,
                      reservationId: reservation?._id,
                      currentPrice: reservation?.currentRate?.price,
                    })
                  }
                  className={`df acc ${styles.checkbox} ${
                    isChecked(reservation._id) ? styles.checked : ""
                  }`}
                ></div>
              </div>
              <div className={`ffnr fs14 ${styles.sec} ${styles.id}`}>
                <p>#{reservation.unit.unitId}</p>
              </div>
              <div className={`ffnr fs14 ${styles.sec} ${styles.size}`}>
                <p>
                  {reservation.unit.size.label} ({reservation.unit.size.length}{" "}
                  x {reservation.unit.size.width})
                </p>
              </div>
              <div className={`ffnr fs14 ${styles.sec} ${styles.date}`}>
                <p>
                  {reservation.moveInDate
                    ? dayjs(reservation.moveInDate).format("MM/DD/YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className={`ffnr fs14 ${styles.sec} ${styles.rate}`}>
                <p className={`df`}>
                  {/* styles.up */}
                  {reservation.currentRate.price ===
                  reservation.unit.size.price ? undefined : (
                    <span
                      className={`df acc m-r-3 ${
                        reservation.currentRate.price >
                        reservation.unit.size.price
                          ? styles.arrowUp
                          : styles.arrowDown
                      }`}
                    >
                      <RateDirectionArrow
                        fill={
                          reservation.currentRate.price >
                          reservation.unit.size.price
                            ? "#30be76"
                            : "#FF4081"
                        }
                      />
                    </span>
                  )}
                  <span
                    className={`m-r-3 ${
                      reservation.currentRate.price >
                      reservation.unit.size.price
                        ? styles.up
                        : reservation.currentRate.price <
                          reservation.unit.size.price
                        ? styles.down
                        : ""
                    }`}
                  >
                    {formatFromCurrencyToMoney(reservation.currentRate.price)}
                  </span>{" "}
                  {reservation.currentRate.price ===
                  reservation.unit.size.price ? undefined : (
                    <>
                      <span className={`m-r-3`}>/</span>
                      {formatFromCurrencyToMoney(reservation.unit.size.price)}
                    </>
                  )}
                </p>
              </div>
              <div className={`ffnr fs14 ${styles.sec} ${styles.updated}`}>
                {isChecked(reservation._id) && amount && type ? (
                  <>
                    {formatFromCurrencyToMoney(
                      updatedAmount(reservation.currentRate.price).newAmount
                    )}{" "}
                    <span className={styles.sub}>
                      ({updatedAmount(reservation.currentRate.price).diff})
                    </span>
                  </>
                ) : (
                  ""
                )}
                {/* <p>Updated Rate</p> */}
              </div>
              <div className={`ffnr fs14 ${styles.sec} ${styles.change}`}>
                <p>
                  {reservation?.upcomingRate ? (
                    <>
                      {formatFromCurrencyToMoney(
                        reservation.upcomingRate.price
                      )}{" "}
                      {dayjs(reservation.upcomingRate.activeDate).fromNow()}
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            // <div className={`df acsa m-t-15`}>
            //   <div>
            //     <p>Unit ID</p>
            //     <p>{reservation.unit.unitId}</p>
            //   </div>
            //   <div className={`m-l-10`}>
            //     <p>Move in date</p>
            //     <p>
            //       {reservation.moveInDate
            //         ? dayjs(reservation.moveInDate).format("MM/DD/YYYY")
            //         : "N/A"}
            //     </p>
            //   </div>

            //   <div className={`m-l-10`}>
            //     <p>Next Rate Change</p>
            //     <p>
            //       {reservation?.reservationPrice
            //         ? dayjs(reservation?.reservationPrice?.activeDate).format(
            //             "MM/DD/YYYY"
            //           )
            //         : "N/A"}
            //     </p>
            //   </div>
            // </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default AdjustLine;
