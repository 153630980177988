import axios from "axios";
import Portal from "../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import { useState } from "react";

import styles from "./AddTemplate.module.scss";

const AddTemplate = ({ template, accountId, close }) => {
  const [currentTemplate, setTemplate] = useState(
    template || {
      name: "",
      value: "",
      active: false,
      type: "", // email || sms || pdf
      category: "", // notice || contract
      accountId: accountId,
    }
  );
  const saveTemplate = async () => {
    await axios.post(`/api/template`, currentTemplate);
    close();
  };
  return (
    <Portal
      title="Add Template"
      zIndex="3"
      opacity="0.2"
      close={close}
      height="auto"
      width="400px"
    >
      <div className={`p-l-20 p-r-20 p-b-20`}>
        <div className={`ffnr fs11 ttuc m-b-20`}>
          <NonFormikInput
            label="Name"
            change={(e) => setTemplate({ ...currentTemplate, name: e })}
            value={currentTemplate.name}
          />
        </div>
        <div className={`ffnr fs11 ttuc m-b-20`}>
          <NonFormikInput
            change={(e) => setTemplate({ ...currentTemplate, value: e })}
            value={currentTemplate.value}
            label="Value"
          />
        </div>
        <div className={`df acsa ${styles.typeSelector}`}>
          <div
            onClick={() => {
              setTemplate({
                ...currentTemplate,
                type: "email",
              });
            }}
            className={`${styles.sec} ${
              currentTemplate.type === "email" ? styles.active : ""
            }`}
          >
            Email
          </div>
          <div
            onClick={() => {
              setTemplate({
                ...currentTemplate,
                type: "sms",
              });
            }}
            className={`${styles.sec} ${
              currentTemplate.type === "sms" ? styles.active : ""
            }`}
          >
            SMS
          </div>
          <div
            onClick={() => {
              setTemplate({
                ...currentTemplate,
                type: "pdf",
              });
            }}
            className={`${styles.sec} ${
              currentTemplate.type === "pdf" ? styles.active : ""
            }`}
          >
            PDF
          </div>
        </div>

        <div className={`df acsa ${styles.categorySelector}`}>
          <div
            onClick={() => {
              setTemplate({
                ...currentTemplate,
                category: "notice",
              });
            }}
            className={`${styles.sec} ${
              currentTemplate.category === "notice" ? styles.active : ""
            }`}
          >
            Notice
          </div>
          <div
            onClick={() => {
              setTemplate({
                ...currentTemplate,
                category: "contract",
              });
            }}
            className={`${styles.sec} ${
              currentTemplate.category === "contract" ? styles.active : ""
            }`}
          >
            Contract
          </div>

          <div
            onClick={() => {
              setTemplate({
                ...currentTemplate,
                category: "reservation",
              });
            }}
            className={`${styles.sec} ${
              currentTemplate.category === "reservation" ? styles.active : ""
            }`}
          >
            Reservation
          </div>
        </div>

        <div className={`df acsa`}>
          <input
            type="checkbox"
            checked={currentTemplate.active}
            onChange={() =>
              setTemplate({
                ...currentTemplate,
                active: !currentTemplate.active,
              })
            }
          />
        </div>
        <button onClick={saveTemplate}>Save Template</button>
      </div>
    </Portal>
  );
};

export default AddTemplate;
