import { useSelector } from "react-redux";
import axios from "axios";

import SettingsNav from "../settings-nav/SettingNav";
import pageStyles from "../SettingsHome.module.scss";
import { useEffect, useState } from "react";
import AddTemplate from "./add-template/AddTemplate";

const TemplateSettings = () => {
  const { user } = useSelector(({ user }) => ({ user }));

  const [showAdd, setShowAdd] = useState(false);
  const [templates, setTemplates] = useState([]);

  const getTemplates = async () => {
    let re = await axios.get(`/api/template-list/${user.account}`);
    setTemplates(re.data);
  };

  useEffect(() => {
    getTemplates();

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <SettingsNav actions={[]} />
      <div className={pageStyles.page}>
        {templates.map((template) => (
          <p>{template.name}</p>
        ))}
        <button onClick={() => setShowAdd(true)}>Add Template</button>
        {showAdd ? (
          <AddTemplate
            accountId={user.account}
            close={() => setShowAdd(false)}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default TemplateSettings;
