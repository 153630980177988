import Axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ordinal from "ordinal";
import plur from "plur";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CardOutlingIcon from "../../../../../assets/js-svgs/card-outline.svg";
import MoveOutArrow from "../../../../../assets/js-svgs/move-out-arrow.svg";
import ThreeDotsIcon from "../../../../../assets/js-svgs/three-dots.svg";
import TransferArrowIcon from "../../../../../assets/js-svgs/transfer-arrow.svg";
import NoRentalsSVG from "../../../../../assets/images/no-rentals.svg";
import "./RentalDetails.scss";
import InvoiceSetup from "../invoice-setup/InvoiceSetup";
import Arrow from "../../../../../assets/js-svgs/arrow.svg";
import { LateSVGIcon } from "../../../../../assets/js-svgs/late.svg";
import DelinquencyStage from "./delinquency-stage/DelinquencyStage";
import DocumentsSelection from "../../../add-tenant/documents/document-selection/DocumentSelection";
import { formatFromCurrencyToMoney } from "../../../../../helpers";
import AutoPay from "./auto-pay/AutoPay";
import Portal from "../../../../../components/portal/Portal";
import DatePicker from "../../../../../components/inputs/datepicker/DatePickerNew";
import ApplyCredit from "./apply-credit/ApplyCredit";

dayjs.extend(utc);

const RentalDetails = (props) => {
  const history = useHistory();
  const params = useParams();

  const [showAutoPaySetup, setShowAutoPaySetup] = useState(false);
  const [rentalDetails, setRentalDetails] = useState(undefined);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showInvoiceSetup, setShowInvoiceSetup] = useState(false);
  const [showDelinquencyDetails, setShowDelinquencyDetails] = useState(false);
  const [showContractSignSelection, setShowContractSignSelection] =
    useState(false);
  const [showMoveOutDets, setShowMoveOutDets] = useState(false);
  const [showScheduleMoveOut, setShowScheduleMoveOut] = useState(false);
  const [expectedMoveOutDate, setExpectedMoveOutDate] = useState(
    Number(dayjs(new Date()).startOf("day").add(1, "day"))
  );

  const [showApplyCredit, setShowApplyCredit] = useState(false);

  const toggleShowMoveOut = () => setShowMoveOutDets(!showMoveOutDets);

  const autoPayDate = (day) =>
    `${plur("day", day.toString().replace("-", ""))} day${
      day > 1 || day < -1 ? "s" : ""
    } ${day < 0 ? "before" : day > 0 ? "after" : ""} due date`;

  const getDetails = async () => {
    let details = await Axios.get(
      `/api/detailed-reservation/${props.selectedRental}`,
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );

    setRentalDetails(details.data.data);
  };

  useEffect(() => {
    if (!props.selectedRental || props.selectedRental === "no-rentals") return;
    getDetails();
    // eslint-disable-next-line
  }, [props.selectedRental]);

  const isContractSigned = () => {
    if (rentalDetails?.documents?.length === 0) return false;
    return rentalDetails.documents.find(
      (doc) => doc.documentType === "contract"
    )?.completed;

    // documentType === "contract"
  };

  const setExpectedMoveOut = async () => {
    let res = await Axios.put(
      `/api/reservation/${props.selectedRental}`,
      {
        expectedMoveOutDate,
      },
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );

    setShowScheduleMoveOut(false);

    console.log("Res === ", res.data);
  };

  return (
    <div className="rental_details_sec">
      {props.selectedRental === "no-rentals" ? (
        <div className={`df ffc acc no_rentals_wrpr m-t-40 noRentalsWrpr`}>
          <div className="iconWrpr">
            <img src={NoRentalsSVG} alt="No Rentals" />
          </div>
          <p className={`ffmr fs14 m-t-30`}>Select a rental above</p>
        </div>
      ) : props.selectedRental && rentalDetails ? (
        <div className="rental_content_wrpr">
          {/* /tenants/add/payment-info/630e147407b16f94b281149c */}

          {!rentalDetails?.initialPayment ||
          (!isContractSigned() && !rentalDetails.ignoreSignNow) ? (
            <div className={`df acsa missingActionWrapper`}>
              <p className={`ffnr fs14`}>
                Tenant still needs to sign their contract{" "}
                {!rentalDetails?.initialPayment
                  ? "and make their initial payment"
                  : undefined}
              </p>

              {!rentalDetails?.initialPayment ? (
                <Link
                  className={`df acc mla white small button p-l-15 p-r-15 tdn ffnsb fs14`}
                  to={`/tenants/add/payment-info/${rentalDetails._id}`}
                >
                  Sign and make payment
                </Link>
              ) : (
                <button
                  onClick={() => setShowContractSignSelection(true)}
                  className={`df acc mla white small button p-l-15 p-r-15 tdn ffnsb fs14`}
                >
                  Sign contract
                </button>
              )}
            </div>
          ) : undefined}

          {rentalDetails.expectedMoveOutDate ? (
            <div className={`df acsa scheduledMoveoutWrapper`}>
              <p className={`ffnr fs16`}>
                Rental is scheduled to move out on{" "}
                {dayjs(rentalDetails.expectedMoveOutDate).format("MMM/DD/YYYY")}
              </p>

              <button
                onClick={props.setMovingOutAction}
                className={`df acc mla warning small button p-l-15 p-r-15 tdn ffnsb fs14`}
              >
                Complete Moveout
              </button>
            </div>
          ) : undefined}

          {showContractSignSelection ? (
            <DocumentsSelection
              reservationId={rentalDetails._id}
              close={() => setShowContractSignSelection(false)}
              hideSkip={true}
            />
          ) : undefined}
          <div className="df acsa details_hdr">
            <div className="sec_wrpr">
              <p className="lbl ttuc ffnsb fs10">Balance</p>
              <p className="ffnr fs14 val">
                {formatFromCurrencyToMoney(rentalDetails?.reservationBalance) ||
                  "0.00"}
              </p>
            </div>
            {/* <div className="sec_wrpr">
							<p className="lbl ttuc ffnsb fs10">Outstanding</p>
							<p className="ffnr fs14 val">
								$
								{rentalDetails.invoiceItem
									? rentalDetails.invoiceItem.pastDue
									: "0.00"}
							</p>
						</div>
						<div className="sec_wrpr">
							<p className="lbl ttuc ffnsb fs10">Credit</p>
							<p className="ffnr fs14 val">
								$
								{rentalDetails.invoiceItem
									? rentalDetails.invoiceItem.credit
									: "0.00"}
							</p>
						</div> */}
            <div className="mla df acsa actns_wrpr">
              {!rentalDetails.moveInDate ||
              rentalDetails?.moveOutDate ? undefined : (
                <>
                  <div
                    title="Make A Payment"
                    className="df acc btn_wrpr"
                    onClick={() =>
                      history.push(
                        `/tenants/${params.id}/payments/${rentalDetails._id}`
                      )
                    }
                  >
                    <CardOutlingIcon></CardOutlingIcon>
                  </div>

                  <div
                    className="df acc btn_wrpr"
                    title="Move Out"
                    onClick={toggleShowMoveOut}
                  >
                    <MoveOutArrow></MoveOutArrow>
                    {showMoveOutDets ? (
                      <div
                        className={`moveoutPopup`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <h4 className={`ffnsb fs16 m-b-10`}>Move out</h4>
                        <p
                          className={`df acsa ffnr fs14 innerAction`}
                          onClick={props.setMovingOutAction}
                        >
                          Move out now
                        </p>
                        <p
                          onClick={() => {
                            setShowScheduleMoveOut(true);
                            setShowMoveOutDets(false);
                          }}
                          className={`df acsa ffnr fs14 innerAction`}
                        >
                          Move out later
                        </p>
                      </div>
                    ) : undefined}
                  </div>

                  <div className="df acc btn_wrpr" title="Transfer">
                    <TransferArrowIcon></TransferArrowIcon>
                  </div>
                </>
              )}

              <div
                className="df acc btn_wrpr"
                title="More Options"
                onClick={() => setShowMoreOptions(!showMoreOptions)}
              >
                <ThreeDotsIcon></ThreeDotsIcon>
                {showMoreOptions ? (
                  <div
                    className={`moreOptionsWrpr`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className={`ffmr fs14 line`}
                      onClick={() => {
                        setShowAutoPaySetup(true);
                        setShowMoreOptions(false);
                      }}
                    >
                      Recurring Payments
                    </div>

                    <div
                      className={`ffmr fs14 line`}
                      onClick={() => {
                        setShowApplyCredit(true);
                        setShowMoreOptions(false);
                      }}
                    >
                      Apply Credit <span className={`sub`}></span>
                    </div>
                    <div
                      className={`ffmr fs14 line`}
                      // onClick={() => {
                      //   setShowInvoiceSetup(true);
                      //   setShowMoreOptions(false);
                      // }}
                    >
                      Billing Cycle <span className={`sub`}>(coming soon)</span>
                    </div>
                    <div className={`ffmr fs14 line`}>
                      Discount <span className={`sub`}>(coming soon)</span>
                    </div>
                    <div className={`ffmr fs14 line`}>
                      Rate Change <span className={`sub`}>(coming soon)</span>
                    </div>
                  </div>
                ) : undefined}
              </div>

              {showApplyCredit ? (
                <ApplyCredit
                  close={() => setShowApplyCredit(false)}
                  userId={params.id}
                />
              ) : undefined}

              {showScheduleMoveOut ? (
                <Portal
                  title="Schedule move out"
                  height="auto"
                  width="300px"
                  zIndex="99"
                  opacity="0.3"
                  close={() => setShowScheduleMoveOut(false)}
                >
                  <div className={`p-l-20 p-r-20 p-b-20`}>
                    <DatePicker
                      size="medium"
                      field=""
                      onChange={(a, b) => setExpectedMoveOutDate(b)}
                      defaultDate={expectedMoveOutDate}
                      value={expectedMoveOutDate}
                      minDate={Number(
                        dayjs(new Date()).startOf("day").add(1, "day")
                      )}
                    />

                    <button
                      onClick={setExpectedMoveOut}
                      className="btn primary w100 m-t-18 ttuc"
                    >
                      Schedule Moveout
                    </button>
                  </div>
                </Portal>
              ) : undefined}
              {showAutoPaySetup ? (
                <AutoPay
                  day={rentalDetails.autoPayDay}
                  payMethod={rentalDetails.autoPayMethod}
                  close={() => setShowAutoPaySetup(false)}
                  tenantId={params.id}
                  rentalId={rentalDetails._id}
                  updateRental={(data) =>
                    setRentalDetails({
                      ...rentalDetails,
                      autoPayMethod: data.autoPayMethod,
                      autoPayDay: data.autoPayDay,
                      autoPay: data.autoPay,
                    })
                  }
                />
              ) : undefined}
              {showInvoiceSetup ? (
                <InvoiceSetup
                  close={() => {
                    getDetails();
                    setShowInvoiceSetup(false);
                  }}
                  reservationId={rentalDetails._id}
                  invoiceScheduleType={rentalDetails.invoiceSchedule}
                  selectedDay={rentalDetails.invoiceDay}
                />
              ) : undefined}
            </div>
          </div>
          {rentalDetails.delinquency ? (
            <div className="delinquentWrpr">
              <div className="df acsa delinquentHdr">
                {/* <Arrow /> */}
                <LateSVGIcon />
                <p className="ffmr fs14 m-l-6">
                  This reservation is past due as of{" "}
                  {dayjs(rentalDetails.delinquency.startDate).format(
                    "MM/DD/YYYY"
                  )}
                </p>
                <div className="mla">
                  <div
                    className={`p-r-10 p-l-10 p-t-10 p-b-10 cursor_pointer opener ${
                      showDelinquencyDetails ? "active" : ""
                    }`}
                    onClick={() =>
                      setShowDelinquencyDetails(!showDelinquencyDetails)
                    }
                  >
                    <Arrow
                      fill="#ffffff"
                      height="8"
                      width="16"
                      fillOpacity="1"
                    />
                  </div>
                </div>
              </div>

              {showDelinquencyDetails ? (
                <div className="delinquencyList">
                  {rentalDetails.delinquency.stages.map((stage) => (
                    <DelinquencyStage stage={stage} key={stage._id} />
                    // <div className={`ffnr fs14  delinquencyLine`}>
                    //   <div
                    //     className={`df acsa p-t-8 p-b-8 stageHdr  ${
                    //       stage.rule.scheduledDate <= Number(new Date())
                    //         ? "active"
                    //         : ""
                    //     }`}
                    //   >
                    //     <div>
                    //       <p className="ffml label">{stage.rule.title}</p>
                    //       <p className="ffmr fs12 m-t-8 date">
                    //         {stage.scheduled ? "Scheduled for" : "Expected"}{" "}
                    //         {dayjs(stage.rule.scheduledDate).format(
                    //           "MM/DD/YYYY"
                    //         )}
                    //       </p>
                    //     </div>
                    //     <div className="mla">
                    //       <Arrow
                    //         fill="#30be76"
                    //         fillOpacity="0.8"
                    //         height="6"
                    //         width="14"
                    //       />
                    //     </div>
                    //   </div>

                    //   <div className="stageActions">
                    //     {stage.rule.actions.map((action) => (
                    //       <div className="actionLine">{action.v?.label}</div>
                    //     ))}
                    //   </div>
                    // </div>
                  ))}
                </div>
              ) : undefined}
            </div>
          ) : undefined}
          {/* ***** */}
          {/* <div className="df acsa ffnl fs14 line">
                    <p>
                      <span className="lbl">Moved Out: </span>
                      <span
                        className="val"
                        title={dayjs(
                          new Date(rentalDetails.moveOutDate)
                        ).format("MM/DD/YYYY [at] h:mma")}
                      >
                        {rentalDetails.moveOutDate
                          ? moment(rentalDetails.moveOutDate).format(
                              "MM/DD/YYYY"
                            )
                          : "N/A"}
                      </span>
                    </p>
                  </div> */}
          {/* ***** */}
          <div className="df acsa details_line">
            <div className="sec">
              <h4 className="ffnr fs14">Move-in</h4>
              <div className="dtls_sec_cntnt_wrpr">
                {rentalDetails.moveInDate ? (
                  <>
                    <div className="df acsa ffnl fs14 line">
                      <p>
                        <span className="lbl">Moved In: </span>
                        <span className="val">
                          {rentalDetails.moveInDate
                            ? moment(rentalDetails.moveInDate).format(
                                "MM/DD/YYYY"
                              )
                            : "N/A"}
                        </span>
                      </p>
                    </div>
                    {rentalDetails.moveOutDate ? (
                      <div className="df acsa ffnl fs14 line">
                        <p>
                          <span className="lbl">Moved Out: </span>
                          <span
                            className="val"
                            title={dayjs(
                              new Date(rentalDetails.moveOutDate)
                            ).format("MM/DD/YYYY [at] h:mma")}
                          >
                            {rentalDetails.moveOutDate
                              ? moment(rentalDetails.moveOutDate).format(
                                  "MM/DD/YYYY"
                                )
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                    ) : undefined}
                  </>
                ) : rentalDetails.expectedMoveInDate ? (
                  <div className="df acsa ffnl fs14 line">
                    <p>
                      <span className="lbl">Expected Move-in: </span>
                      <span className="val">
                        {rentalDetails.expectedMoveInDate
                          ? moment(rentalDetails.expectedMoveInDate).format(
                              "MM/DD/YYYY"
                            )
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                ) : undefined}

                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl">Lease Number: </span>
                    <span className="val">{rentalDetails.reservationNum}</span>
                  </p>
                </div>

                {/* reservationNum */}
              </div>
            </div>
            <div className="sec">
              <h4 className="ffnr fs14">Billing</h4>

              <div className="dtls_sec_cntnt_wrpr">
                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl">Billing Cycle: </span>
                    <span className="val">
                      {rentalDetails.invoiceSchedule === "monthly" ? (
                        <>
                          {rentalDetails?.invoiceDay ? (
                            <>{ordinal(rentalDetails.invoiceDay)} of month</>
                          ) : undefined}
                        </>
                      ) : (
                        rentalDetails?.invoiceSchedule
                      )}
                    </span>
                  </p>
                </div>

                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl">Monthly Rate: </span>
                    <span className="val">
                      {formatFromCurrencyToMoney(rentalDetails?.price?.price)}
                    </span>

                    {Number(rentalDetails?.price?.price).toFixed(2) !==
                    rentalDetails.unit.unitSize.price.toFixed(2) ? (
                      <>
                        {" "}
                        <span className="sub">
                          (reg.{" "}
                          {formatFromCurrencyToMoney(
                            rentalDetails.unit.unitSize.price
                          )}
                          )
                        </span>
                      </>
                    ) : undefined}
                  </p>
                </div>

                {/* nextInvoiceDue */}

                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl">Current invoice Due:</span>
                    <span className="val">
                      {rentalDetails.currentInvoiceDue
                        ? dayjs
                            .utc(rentalDetails.currentInvoiceDue)
                            .format("MM/DD/YYYY")
                        : "N/A"}
                    </span>
                  </p>
                </div>

                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl">Insurance </span>
                    <span className="val">
                      {rentalDetails?.insurance?.bracket?.coverageAmount ? (
                        <>
                          {formatFromCurrencyToMoney(
                            rentalDetails?.insurance?.bracket?.premium
                          )}
                          <span className={`sub`}>
                            {" "}
                            /{" "}
                            {formatFromCurrencyToMoney(
                              rentalDetails.insurance?.bracket?.coverageAmount
                            )}
                          </span>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </p>
                </div>

                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl">Autopay </span>
                    <span className="val">
                      {rentalDetails?.autoPay ? (
                        <>
                          On
                          <span className={`sub`}>
                            {" "}
                            /{" "}
                            {rentalDetails.autoPayDay === 0
                              ? `On bill due date`
                              : autoPayDate(rentalDetails.autoPayDay)}
                          </span>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </p>
                </div>

                {/* autoPay */}
              </div>
            </div>
          </div>
          <div className="df acsa details_line">
            <div className="sec">
              <h4 className="ffnr fs14">Payment history</h4>
              <div className="dtls_sec_cntnt_wrpr">
                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl"></span>
                    <span className="val"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="sec">
              <h4 className="ffnr fs14">Documents</h4>
              <div className="dtls_sec_cntnt_wrpr">
                <div className="df acsa ffnl fs14 line">
                  <p>
                    <span className="lbl"></span>
                    <span className="val"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "Loading"
      )}
    </div>
  );
};

export default RentalDetails;
