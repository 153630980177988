import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import Portal from "../../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";

import checkMark from "../../../../../assets/images/checkmark.svg";

import styles from "./AddPopup.module.scss";

const AddPopup = ({ close }) => {
  const { user, reduxUser } = useSelector(({ user, reduxUser }) => ({
    user,
    reduxUser,
  }));

  const [name, setName] = useState("");
  const [active, setActive] = useState(false);

  const save = async () => {
    // console.log(user.account, reduxUser.selectedLocation._id, name, active);
    await axios.post("/api/insurance-company", {
      name,
      active,
      accountId: user.account,
      locationId: reduxUser.selectedLocation._id,
    });

    close();
  };

  return (
    <Portal
      zIndex="1"
      opacity="0.3"
      close={close}
      height="auto"
      width="400px"
      title="Add Insurance Provider"
    >
      <div className={`p-l-20 p-r-20 p-t-10 ${styles.addInsProvider}`}>
        <NonFormikInput
          label="Provider name"
          value={name}
          change={(val) => setName(val)}
        />
        <div
          className={`df acsa ${styles.activeSwitch} ${
            active ? styles.active : ""
          }`}
          onClick={() => setActive(!active)}
        >
          <div className={`df acc ${styles.activeCheckbox}`}>
            {active ? (
              <img className={`m-t-2`} src={checkMark} alt="" />
            ) : undefined}
          </div>
          <p className={`ffnsb fs14`}>Active</p>
        </div>
        <div className={`footer p-b-30`}>
          <button onClick={save} className="button primary w100 m-t-20 ttuc">
            Save
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default AddPopup;

// **** Insurance company **** //

// Endpoint: /api/insurance-company
// name: String,
// active: Boolean,
// accountId: { type: Types.ObjectId, ref: "portalAccount" },
// locationId: { type: Types.ObjectId, ref: "portalLocation" },
