import React, { useMemo, useState } from "react";
import Portal from "../../../components/portal/Portal";
import { Link, useHistory } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
// import { cloneDeep } from "lodash";

import ThreeDotsIcon from "../../../assets/js-svgs/three-dots.svg";
import ReserveUnitFromList from "./reserve-unit/ReserveUnit";

import styles from "./UnitsTable.module.scss";
import { useEffect } from "react";
import UnitHistory from "./unit-history/UnitHistory";

const UnitsTable = ({ data, updateSelectedUnits, selectedUnitsProp }) => {
  const [openMenu, setOpenMenu] = useState(undefined);
  const [activeUnit, setActiveUnit] = useState(undefined);
  const [unitHistory, setUnitHistory] = useState(undefined);

  //
  // eslint-disable-next-line
  const [selectedUnits, setSelectedUnits] = useState(selectedUnitsProp || []);

  const history = useHistory();

  const sizeSort = useMemo(
    () => (rowA, rowB, columnId) => {
      if (
        rowA.values[columnId].calculatedQF > rowB.values[columnId].calculatedQF
      )
        return -1;
      if (
        rowB.values[columnId].calculatedQF > rowA.values[columnId].calculatedQF
      )
        return 1;
      return 0;
    },
    []
  );

  const statusSort = useMemo(
    () => (rowA, rowB, columnId) => {
      if (rowA.values[columnId]) return -1;
      if (rowB.values[columnId]) return 1;
      return 0;
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Unit ID",
        accessor: "unitId", // accessor is the "key" in the data
      },
      {
        Header: "Unit Size",
        accessor: "unitSize", // accessor is the "key" in the data
        Cell: ({ value }) => (
          <p className="ffmr fs14">
            <span className="ttcaps">{value?.label} </span> ({value?.length} x{" "}
            {value?.width})
          </p>
        ),
        sortType: sizeSort,
      },

      {
        Header: "Description",
        accessor: "description", // accessor is the "key" in the data
        Cell: ({ value }) => (
          <p className="ftr_list_wrpr">
            <>({value.unitType ? value.unitType.label : "N/A"}) - </>
            {value.unitFeatures.map((ftr, i) => (
              <span className="ttcaps" key={i}>
                {ftr.label}

                {i + 1 < value.unitFeatures.length ? `, ` : ""}
              </span>
            ))}
          </p>
        ),
        canSort: false,
      },
      {
        Header: "Status",
        accessor: "isRented",
        Cell: ({ value, row }) => (
          <p
            className={`${styles.statusSec} ${
              row?.original?.rentable === false
                ? styles.unrentable
                : value
                ? styles.rented
                : ""
            }`}
          >
            {row?.original?.rentable === false
              ? "Unrentable"
              : value
              ? "Rented"
              : "Vacant"}
          </p>
        ),
        sortType: statusSort,
      },
    ],
    // eslint-disable-next-line
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: data }, useSortBy);

  useEffect(
    () => {
      updateSelectedUnits(selectedUnits);
    },
    // eslint-disable-next-line
    [selectedUnits]
  );

  return (
    <>
      <div {...getTableProps()} className="list_wrpr">
        <div className="df acsa ffqsb fs12 ttuc list_hdr line">
          {headerGroups.map((headerGroup, idx) => (
            <React.Fragment key={idx}>
              {/* ************* COME BACK TO THIS *************** */}
              {/* <tr {...headerGroup.getHeaderGroupProps()}> */}
              {headerGroup.headers.map((column) => (
                <div
                  className={`df acsa ${styles.sec} unit_num ${
                    styles[column.id]
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <p className="df acsa ttuc ffqsb fs12">
                    {/* <span
                    onClick={(e) =>
                      column.toggleSortBy(
                        column.isSortedDesc ? false : true,
                        false
                      )
                    }
                  >
                    Toggle
                  </span> */}
                    {column.render("Header")}
                    {column.isSorted ? (
                      <span
                        className={`sortArrow ${
                          column.isSortedDesc ? "up" : "down"
                        }`}
                      ></span>
                    ) : undefined}
                  </p>
                </div>
              ))}
            </React.Fragment>
          ))}
          <div className={`mla df acc ${styles.sec} ${styles.tenantLink}`}>
            {/* <ThreeDotsIcon /> */}
          </div>
          <div className="mla df acc moreOptionsBtn">
            {/* <ThreeDotsIcon /> */}
          </div>
        </div>
        <div {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className={`df acsa ffmr fs14 line`}
                // onClick={() => history.push(`/units/add/${row.original._id}`)}
              >
                {/* <div
                  className={`df acc ${styles.checkboxWrpr}`}
                  onClick={() => toggleCheckbox(row.original)}
                >
                  <div
                    className={`df acc ${styles.checkbox} ${
                      isUnitSelected(row.original._id) ? styles.checked : ""
                    }`}
                  ></div>
                </div> */}

                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={`df acsa sec  ${styles.sec} ${
                        styles[cell.column.id]
                      }`}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
                <div
                  className={`mla df acc ${styles.sec} ${styles.tenantLink}`}
                >
                  {/* <ThreeDotsIcon /> */}
                  {row.original.tenantId ? (
                    <Link
                      className={`tdn fs14 ${styles.viewTenant}`}
                      to={`/units/tenant/${row.original.tenantId}`}
                    >
                      View tenant
                    </Link>
                  ) : undefined}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMenu(
                      openMenu === undefined || openMenu !== row.original._id
                        ? row.original._id
                        : undefined
                    );
                  }}
                  className="mla df acc moreOptionsBtn"
                >
                  <ThreeDotsIcon />
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={`moreOptionsWrpr ${
                      openMenu === row.original._id ? "active" : ""
                    }`}
                  >
                    <div
                      className={`ffmr fs14 optnLine ${
                        row.original.rentable && !row.original.isRented
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() => {
                        if (!row.original.rentable || row.original.isRented)
                          return;
                        setOpenMenu(undefined);
                        setActiveUnit(row.original);
                      }}
                    >
                      Reserve Unit
                    </div>
                    <div
                      className="ffmr fs14  optnLine"
                      onClick={() => {
                        setOpenMenu(undefined);
                        return history.push(`/units/add/${row.original._id}`);
                      }}
                    >
                      Edit Unit
                    </div>
                    <div className="ffmr fs14  optnLine disabled">
                      Delete Unit
                    </div>
                    <div
                      className="ffmr fs14  optnLine"
                      onClick={() => setUnitHistory(row.original._id)}
                    >
                      {/* {console.log(row.original._id)} */}
                      Unit Rental History
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {unitHistory ? (
        <Portal
          close={() => setUnitHistory(undefined)}
          title={`Unit History`}
          zIndex={99}
          opacity={0.15}
          height="auto"
        >
          <UnitHistory id={unitHistory} />
        </Portal>
      ) : undefined}

      {activeUnit ? (
        <Portal
          close={() => setActiveUnit(undefined)}
          title={`Reserve unit #${activeUnit.unitId.toUpperCase()}`}
          zIndex={99}
          opacity={0.15}
          height="auto"
        >
          <ReserveUnitFromList unitId={activeUnit._id} />
        </Portal>
      ) : undefined}
    </>
  );
};

export default UnitsTable;
