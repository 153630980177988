import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import update from "immutability-helper";
import { formatFromCurrencyToMoney } from "../../../../helpers";
import Portal from "../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";

import styles from "./Payments.module.scss";
import CurrencyInput from "../../../../components/inputs/CurrencyInput";
import WarningSVG from "../../../../assets/js-svgs/warning.svg";

const Payments = ({ userId }) => {
  const params = useParams();
  // const params

  const [payments, setPayments] = useState([]);
  const [selectedPaymentId, setSelectedPaymentId] = useState(undefined);
  const [showReverse, setShowReverse] = useState(false);
  const [refunding, setRefunding] = useState(false);

  // Reverse data
  const [reverseAmount, setReverseAmount] = useState("");
  const [reverseNote, setReverseNote] = useState("");

  const getPayments = async () => {
    let localPayments = await axios.get(`/api/payment/${userId}`);
    setPayments(localPayments.data.data);
  };

  useEffect(
    () => {
      getPayments();
    },
    // eslint-disable-next-line
    []
  );

  const getItemType = (type) => {
    switch (type) {
      case "rent":
        return "Rent";
      case "adminFee":
        return "Admin fee";
      case "proratedMonth":
        return "Prorated rent";
      case "tenantProtection":
        return "Tenant protection";
      default:
        break;
    }
  };

  const processRefund = async () => {
    setRefunding(true);
    let re = await axios.post("/api/payment-refund/", {
      reverseAmount,
      reverseNote,
      paymentId: selectedPaymentId?._id,
      userId: params.id,
      paymentRef: selectedPaymentId?.refNum,
    });

    if (re.data.message === "Success") {
      setShowReverse(false);

      const index = payments.findIndex(
        (elem) => elem._id === re.data.data.originalPaymentId
      );

      console.log("Hey index ", index);

      const updated = update(payments, {
        [index]: {
          totalRefunded: {
            $set: payments[index].totalRefunded + re.data.data.amount,
          },
          refundableAmount: {
            $set: payments[index].refundableAmount - re.data.data.amount,
          },
          refunds: { $push: [re.data.data] },
        },
      });

      setPayments(updated);
      setSelectedPaymentId(updated[index]);
    }

    setRefunding(false);
  };

  useEffect(() => {
    if (!selectedPaymentId) return;
    console.log("::: SETTING SELECTED", selectedPaymentId.refundableAmount);
    setReverseAmount(selectedPaymentId.refundableAmount);
  }, [selectedPaymentId]);

  useEffect(() => {
    setReverseNote("");
  }, [showReverse]);

  return (
    <div className={`p-l-20 p-r-20 p-t-20 p-b-20`}>
      {payments.map((payment) => (
        <div className={`fs14 ffnl fs14 ${styles.line}`} key={payment._id}>
          <div
            className={`df acsa fs14 ffnl fs14 ${styles.innerLine} ${
              selectedPaymentId?._id === payment._id ? styles.selected : ""
            }`}
            onClick={() => setSelectedPaymentId(payment)}
          >
            <div className={`df ${styles.cardType}`}>
              <p className={`m-r-10 ttcaps`}>{payment.cardType}</p>
              <p className={`m-r-10`}>
                {payment?.maskedCardNumber ? (
                  <>
                    ****
                    {payment.maskedCardNumber?.substr(
                      payment.maskedCardNumber?.length - 4
                    )}
                  </>
                ) : undefined}
              </p>
              {payment.merchantStatus === "Error" ? (
                <span
                  className={`${styles.errorWrpr}`}
                  title={`Error code #${payment.errorCode} - ${
                    payment?.errorMessage || ""
                  }`}
                >
                  <WarningSVG fill="#ec407a" />
                </span>
              ) : undefined}
            </div>

            <p className={`${styles.amount}`}>
              {formatFromCurrencyToMoney(payment.amount)}{" "}
              <span className={`ffnsb ${styles.sub}`}>
                {payment.totalRefunded > 0
                  ? `(${formatFromCurrencyToMoney(
                      payment.totalRefunded
                    )} refunded)`
                  : undefined}

                {/* {formatFromCurrencyToMoney(payment.calculatedApplied)} */}
              </span>
            </p>
            <p className={`mla`}>
              {payment.createdAt
                ? dayjs(payment.createdAt).format("MM/DD/YYYY")
                : "N/A"}
            </p>
          </div>
          {selectedPaymentId?._id === payment._id ? (
            <div className={styles.details}>
              {payment.payments.map((pm) => (
                <div className={`df ffnl fs14 p-t-13 p-b-13`} key={pm._id}>
                  <p>
                    Unit {pm.unit?.unitId} {getItemType(pm.invoiceItem.type)}{" "}
                    {pm.invoiceItem.fromDate ? (
                      <>
                        {dayjs(pm.invoiceItem.fromDate).format("MM/DD/YYYY")} -{" "}
                        {dayjs(pm.invoiceItem.toDate).format("MM/DD/YYYY")}
                      </>
                    ) : undefined}
                  </p>
                  <p className={`mla`}>
                    {formatFromCurrencyToMoney(pm.appliedAmount)}
                  </p>
                </div>
              ))}
              {payment.refunds.map((rf) => (
                <div
                  key={rf._id}
                  className={`df ffnl fs14 p-t-13 p-b-13 ${styles.refundLine}`}
                >
                  <p>
                    {rf.createdAt
                      ? ` ${dayjs(rf.createdAt).format("MM/DD/YYYY")}`
                      : "N/A"}
                    {rf.reverseNote ? <span> - ({rf.reverseNote})</span> : ""}
                    {/* Unit {pm.unit.unitId} {getItemType(pm.invoiceItem.type)}{" "}
                    {pm.invoiceItem.fromDate ? (
                      <>
                        {dayjs(pm.invoiceItem.fromDate).format("MM/DD/YYYY")} -{" "}
                        {dayjs(pm.invoiceItem.toDate).format("MM/DD/YYYY")}
                      </>
                    ) : undefined} */}
                  </p>
                  <p className={`mla`}>
                    -{formatFromCurrencyToMoney(rf.amount)}
                  </p>
                </div>
              ))}
              <div className={`df acsa m-t-25 m-b-12`}>
                <button
                  className={`primary process_btn ttuc p-l-20 p-r-20 m-r-12`}
                >
                  Send receipt
                </button>
                <button
                  disabled={payment.refundableAmount === 0}
                  onClick={() => setShowReverse(true)}
                  className={`primary process_btn ttuc p-l-20 p-r-20`}
                >
                  refund payment
                </button>
              </div>
            </div>
          ) : undefined}
        </div>
      ))}
      {showReverse ? (
        <Portal
          height="auto"
          width="auto"
          noCustomScrollbar
          close={() => {
            setReverseAmount("");
            setReverseNote("");
            setShowReverse(false);
          }}
          title="Refund payment"
          opacity="0.3"
          zIndex="1"
        >
          <div className={`p-l-20 p-r-20 ${styles.reversePaymentPopup}`}>
            {/* <div className={`__inpt_wrpr`}>
              <Cleave
                className={`ffnsb fs14`}
                value={reverseAmount}
                onChange={(e) => {
                  // console.log(e.target.rawValue);
                  setReverseAmount(e.target.rawValue);
                }}
                options={{
                  // prefix: `${reverseAmount.toString().length > 0 ? "$" : ""}`,
                  numeral: true,
                  // numeralThousandsGroupStyle: "lakh",
                  rawValueTrimPrefix: true,
                }}
                id="reAmount"
              />
              <label
                htmlFor="reAmount"
                className={`ffnsb fs14 ${reverseAmount !== "" ? "move" : ""}`}
              >
                Amount
              </label>
            </div> */}
            <CurrencyInput
              id="reAmount"
              value={reverseAmount}
              label="amount"
              onChange={(val) => setReverseAmount(val.fullNumber)}
              // onChange={(val) => console.log(val.fullNumber)}
            />
            <NonFormikInput
              id="rNote"
              label="Note"
              change={(v) => setReverseNote(v)}
              value={reverseNote}
            />

            <button
              disabled={refunding}
              onClick={() => processRefund()}
              className={`primary w100 process_btn ttuc m-t-30 p-l-20 p-r-20`}
            >
              refund payment!
            </button>

            <button
              onClick={() => {
                setReverseAmount("");
                setReverseNote("");
                setShowReverse(false);
              }}
              className={`secondary w100 process_btn ttuc m-t-12 p-l-20 p-r-20 m-b-35`}
            >
              Cancel
            </button>
          </div>
        </Portal>
      ) : undefined}
    </div>
  );
};

export default Payments;
