import React, { useRef } from "react";
import { Formik, Field } from "formik";
import Axios from "axios";
import UploadIcon from "../../../../assets/js-svgs/upload-icon.svg";
import { DefaultInput } from "../../../../components/inputs/DefaultInput";
import DatePicker from "../../../../components/inputs/datepicker/DatePicker";

const DL = (props) => {
  const submitBtn = useRef(null);

  const outsideProps = props;
  return (
    <Formik
      initialValues={{
        fullName:
          outsideProps.idList && outsideProps.idList.fullName
            ? outsideProps.idList.fullName
            : "",
        idNumber:
          outsideProps.idList && outsideProps.idList.idNumber
            ? outsideProps.idList.idNumber
            : "",
        stateIssued:
          outsideProps.idList && outsideProps.idList.stateIssued
            ? outsideProps.idList.stateIssued
            : "",
        expirationDate:
          outsideProps.idList && outsideProps.idList.expirationDate
            ? outsideProps.idList.expirationDate
            : "",
      }}
      // validationSchema={WebUserSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, { isValid }) => {
        // let dateData = values.expirationDate.split("/");

        // values.expirationDate = Number(
        // 	new Date(`${dateData[0]}/01/${dateData[1]}`)
        // );

        let id = outsideProps.idList ? `?id=${outsideProps.idList._id}` : "";

        let response = await Axios.post(
          `/api/portal-reservation-id/${id}`,
          {
            ...values,
            type: outsideProps.selectedType,
            reservationId: outsideProps.match.params.reservationId,
          },
          {
            headers: {
              "x-auth": localStorage.getItem("x-auth"),
            },
          }
        );

        console.log("RESPONSE === ", response.data);

        // {
        // 	documetId: imageDetails._id,
        // 	type: req.query.type,
        // 	reservationId: req.params.id,
        // },
        // if (token)
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        isValid,
      }) => (
        <>
          <div className="au_form_wrpr">
            <form
              className="df ffc acsa"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="inpt_wrpr">
                <input
                  className="dn"
                  type="file"
                  id="upload"
                  onChange={(e) => {
                    outsideProps.selectFile(e);
                  }}
                ></input>

                <label
                  htmlFor="upload"
                  className="df acc ffnr fs14 tac upload_btn"
                >
                  <div className="icon_wrpr">
                    <UploadIcon></UploadIcon>
                  </div>
                  Upload
                </label>

                <div className="uploads_wrpr">
                  <p className="ffnr fs10">
                    {outsideProps.idList &&
                      outsideProps.idList.documetId &&
                      outsideProps.idList.documetId.fileName}
                  </p>
                </div>
                <Field
                  name="fullName"
                  label="Full Name"
                  id="fn"
                  component={DefaultInput}
                />

                <Field
                  name="idNumber"
                  label="Drivers License Number"
                  id="ln"
                  component={DefaultInput}
                />

                <Field
                  name="stateIssued"
                  label="Drivers License State"
                  id="ls"
                  component={DefaultInput}
                />

                <Field
                  name="expirationDate"
                  label="Expiration Date"
                  id="lexp"
                  onChange={setFieldValue}
                  component={DatePicker}
                />
                {/* 
                                    ifHasError={() => console.log()}
                                    label="Move in date"
                                    placeholder="Select date"
                                    startDate={this.state.startDate}
                                    onChange={console.log}
                                    value={this.state.selectedDate}
                                    readonly={true}
                                    selectedDate={this.state.selectedDate}
                                    updateDate={this.updateDatePicker}
                                    earliestDate={new Date()} 
                                */}

                {/* fullName: "",
                            licenceNumber: "",
                            licenceState: "",
                            expiration: "",
                             */}
              </div>

              <button
                ref={submitBtn}
                className="dn ffnb fs14 ttuc sbmt_btn primary"
                type="submit"
                style={{ display: "none" }}
              >
                Next
              </button>
            </form>
            <div className="df ffc form_ftr">
              <button
                className="ffnb fs14 ttuc sbmt_btn primary"
                onClick={async () => {
                  // this.setState(
                  // 	{next: true},
                  // 	() =>
                  submitBtn.current.click();
                  // );
                }}
                type="button"
              >
                Next
              </button>

              <button
                className="ffnb fs14 ttuc sbmt_btn"
                type="button"
                onClick={async () => {
                  // this.setState(
                  // 	{next: false},
                  // 	() =>
                  // 		this.submitBtn.current.click()
                  // );
                }}
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default DL;
