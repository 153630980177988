const RateDirectionArrow = ({ fill }) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8125 5.87476L6.68723 1.00003L11.562 5.87476"
      stroke={fill || "#fff"}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.68718 12.3744V1.00008"
      stroke={fill || "#fff"}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default RateDirectionArrow;
