import { useState } from "react";
import { NonFormikInput } from "../../../../components/inputs/NonFormikInput";
import axios from "axios";
import { useSelector } from "react-redux";
import NonFormikSelect from "../../../../components/inputs/drop-down/non-formik/NonFormikSelect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";
import update from "immutability-helper";
import Plus from "../../../../assets/js-svgs/plus.svg";

import styles from "./DelinquencyRule.module.scss";
import { TrashSVG } from "../../../../assets/js-svgs/trash.svg";
import { useEffect } from "react";

// import delinquencyRuleTypes from "./rulesList";

const DelinquencyRule = (props) => {
  const [delinquencyRules, setDelinquencyRules] = useState([]);
  const [title, setTitle] = useState(props.rule?.title || "");
  const [selectedDay, setSelectedDay] = useState(props.rule?.selectedDay || "");
  const [recurring, setRecurring] = useState(props.rule?.recurring || false);
  const [customDay, setCustomDay] = useState(
    props.rule?.selectedDay && props.rule.selectedDay > 28 ? true : false
  );
  const [actions, setActions] = useState(props.rule?.actions || []);
  const [daysPastType, setDaysPastType] = useState(
    props.rule?.daysPastType || ""
  );
  const [dependsOnRule, setDependsOnRule] = useState(
    props.rule?.dependsOnRule || ""
  );
  const [dependsOnAction, setDependsOnAction] = useState(
    props.rule?.dependsOnAction || ""
  );
  const [delinquencyRuleTypes, setDelinquencyRuleTypes] = useState(undefined);

  const { user } = useSelector(({ user }) => ({ user }));

  const getRuleTypes = async () => {
    let d = await axios.get(`/api/settings/delinquency-rule-types`);
    setDelinquencyRuleTypes(d.data);
  };

  const getDelinquencyRules = async () => {
    let rules = await axios.get(
      `/api/settings/delinquency-rules?graph=false&accountId=${user.account}`
    );
    setDelinquencyRules(rules?.data?.data);
  };

  useEffect(() => {
    getRuleTypes();
    getDelinquencyRules();
    // eslint-disable-next-line
  }, []);

  const setAction = async (val, i, isFollowUp) => {
    // setShowList(false);

    if (val.level === "delete") {
      // console.log("The index is ", i);
      let newCollection = await update(actions, { $splice: [[i, 1]] });
      setActions(newCollection);
      return;
    }

    if (!isFollowUp) {
      let newCollection = update(actions, {
        [i]: {
          $set: {
            v: { label: val.label, value: val.value, auto: val.auto },
          },
        },
      });
      setActions(newCollection);
    } else {
      let newCollection = update(actions, {
        [i]: {
          $set: {
            ...actions[i],
            followUpActionV: { label: val.label, value: val.value },
            model: val.model,
          },
        },
      });
      setActions(newCollection);
    }
  };

  const getNextSteps = (v) => {
    return delinquencyRuleTypes.find((rule) => rule.value === v.value)
      .followUpAction;
  };

  const saveRule = async () => {
    await axios.post(`/api/settings/delinquency-rule`, {
      title,
      selectedDay,
      daysPastType,
      actions,
      dependsOnRule,
      dependsOnAction,
      accountId: user.account,
      recurring,
    });

    props.refreshRules();
    props.close();
  };

  const updateRule = async () => {
    await axios.put(`/api/settings/delinquency-rule/${props.rule._id}`, {
      title,
      selectedDay,
      daysPastType,
      actions,
      dependsOnRule,
      dependsOnAction,
      accountId: user.account,
      recurring,
    });
    props.refreshRules();
    props.close();
  };

  return (
    <div className={styles.delinquencyRule}>
      <NonFormikInput
        label="Rule name"
        value={title}
        id="title"
        change={setTitle}
      />
      {/* {console.log(actions)} */}
      <p className={`ffnr fs11 ttuc m-t-20`}>Applied </p>
      <div>
        <Swiper
          // centeredSlidesBounds
          slidesPerView={"auto"}
          spaceBetween={0}
          //   navigation
          pagination={{ clickable: true }}
          className={`customized ${styles.daysWrpr}`}
          // initialSlide={props.initialSlide}
        >
          <SwiperSlide
            className={`df acc ffmr fs12 ${styles.day} ${
              customDay ? styles.selected : ""
            }`}
            onClick={() => {
              if (!customDay) setSelectedDay();
              setCustomDay(true);
            }}
          >
            <input
              className={`acc ffmr fs12 tac`}
              value={customDay ? selectedDay : ""}
              onChange={(e) => {
                setSelectedDay(e.target.value);
              }}
            />
          </SwiperSlide>
          {[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28,
          ].map((day) => (
            <SwiperSlide
              key={day}
              // className={`tabWrpr ffnr fs14 tab ttuc ${
              //   props.selectedRental === tab._id ? "selected" : ""
              // } ${tab.hasPastDue ? "hasPastDue" : ""}`}
              // key={tab.unit?._id + i}
              className={`df acc ffmr fs12 ${styles.day} ${
                selectedDay === day ? styles.selected : ""
              }`}
              onClick={() => {
                setSelectedDay(day);
                setCustomDay(false);
              }}
            >
              {day}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <p className={`ffnr fs11 ttuc m-t-15`}>days past</p>
      <NonFormikSelect
        disabled={!selectedDay}
        menuPortalTarget={document.body}
        label="Select"
        value={daysPastType}
        options={[
          { label: "Rent due", value: "rentDue" },
          { label: "Previous action completed", value: "prevAction" },
          // { label: "Pre lien action postmark", value: "preLienActionPstmrk" },
          // { label: "Monthly billing cycle", value: "monthlyBillCycle" },
        ]}
        onChange={(val) => {
          setDependsOnRule(undefined);
          setDaysPastType(val);
        }}
      />
      {daysPastType?.value === "monthlyBillCycle" && selectedDay > 28 ? (
        <p
          className={`ffmr fs9 m-t-6 lh-h-25 p-r-4 p-l-4 ${styles.dayWarning}`}
        >
          Please note that if the month is less then {selectedDay} days, the
          rule will run on the last day of the month
        </p>
      ) : undefined}
      {daysPastType?.value === "prevAction" ? (
        <NonFormikSelect
          displayBlock={true}
          menuPortalTarget={document.body}
          label="Select rule"
          value={dependsOnRule}
          options={delinquencyRules
            // .filter((r) => !r.dependsOnRule)
            .map((rule) => ({
              label: rule.title,
              value: rule._id,
            }))}
          onChange={setDependsOnRule}
        />
      ) : undefined}

      {dependsOnRule?.value ? (
        <NonFormikSelect
          displayBlock={true}
          menuPortalTarget={document.body}
          label="Select action"
          value={dependsOnAction}
          options={
            delinquencyRules
              ?.find((elem) => elem._id === dependsOnRule?.value)
              ?.actions.map((action) => ({
                label: action.v.label,
                value: action._id,
              }))
            //   props.delinquencyRules.map((rule) => ({
            //   label: rule.title,
            //   value: rule._id,
            // }))
          }
          onChange={setDependsOnAction}
        />
      ) : undefined}
      {/*  */}
      <div
        className={`m-t-15 m-b-15 ${styles.dvdr}`}
        style={{ height: "2px", backgroundColor: "#C4C4C4", opacity: "50%" }}
      />

      {delinquencyRuleTypes &&
        actions.map((action, idx) => {
          return (
            <>
              <div className={styles.ruleWrpr} key={`ppp-${idx}-r32r`}>
                <p
                  onClick={() => setAction({ level: "delete" }, idx)}
                  className={`df acsa p-t-3 ffnr fs11 ttuc ${styles.actionHdr}`}
                >
                  Action {idx + 1}{" "}
                  <span className={`mla m-r-5 cursor_pointer`}>
                    <TrashSVG fill="#ec407a" />
                  </span>
                </p>
                {console.log(action)}
                <NonFormikSelect
                  menuPortalTarget={document.body}
                  label={delinquencyRuleTypes.placeholder}
                  value={action.v}
                  options={delinquencyRuleTypes}
                  onChange={(val) => {
                    setAction(val, idx);
                  }}
                />

                {action?.v && getNextSteps(action.v)?.length ? (
                  <NonFormikSelect
                    menuPortalTarget={document.body}
                    label={delinquencyRuleTypes.placeholder}
                    value={action.followUpActionV}
                    options={getNextSteps(action.v)}
                    onChange={(val) => {
                      setAction(val, idx, true);
                    }}
                  />
                ) : undefined}
              </div>
            </>
          );
        })}
      <div
        className={`df acsa ffnr fs14 m-t-15`}
        onClick={() => {
          let localActions = [...actions, {}];
          setActions(localActions);
        }}
      >
        <Plus />{" "}
        <p className={`m-l-6 m-t-2 ${styles.addBtn}`}>
          Add {actions?.length ? "another" : ""} action
        </p>
      </div>

      <div
        className={`df aic ffnr fs14 m-t-10 m-b-10 cursor_pointer ${styles.recurringSwitch}`}
      >
        <div
          className={`${styles.sec} ${!recurring ? styles.selected : ""}`}
          onClick={() => setRecurring(false)}
        >
          One Time
        </div>
        <div
          className={`${styles.sec} ${recurring ? styles.selected : ""}`}
          onClick={() => setRecurring(true)}
        >
          Recurring
        </div>
      </div>
      <button
        disabled={!actions?.length || !title || !selectedDay || !daysPastType}
        className={`button primary w100 m-t-20 ttuc`}
        onClick={props.rule?._id ? updateRule : saveRule}
      >
        {props.rule?._id ? "update" : "create"} rule
      </button>
    </div>
  );
};

export default DelinquencyRule;
