import React from "react";

const UploadIcon = (props) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13V7H14L7 0L0 7H4V13H10ZM7 2.83L9.17 5H8V11H6V5H4.83L7 2.83ZM14 17V15H0V17H14Z"
      fill="#323232"
      fillOpacity="0.5"
    />
  </svg>
);

export default UploadIcon;
