import React from "react";
import { Route, Switch } from "react-router-dom";
import AutoPay from "./auto-pay/AutoPay";
import ImportCustomers from "./customers/Customers";
import DelinquenciesImport from "./delinquencies/Delinquencies";
import InvoiceSchedule from "./invoices/invoice-schedule/InvoiceSchedule";
import Invoices from "./invoices/Invoices";
import PaymentProcessing from "./payment-processing/PaymentProcessing";
import AllReservationsImport from "./reservations/AllReservations";
import SettingsHome from "./SettingsHome";
import StartImport from "./start/Start";
import UnitFeatures from "./units/unit-features/UnitFeatures";
import UnitSizes from "./units/unit-sizes/UnitSizes";
import UnitTypes from "./units/unit-types/UnitTypes";
import ImportUnits from "./units/units/Units";

const SettingsRoutes = (props) => {
  return (
    <div className={`h100 w100`}>
      <Switch>
        <Route
          path="/settings/import/:id/customers"
          component={ImportCustomers}
        ></Route>

        <Route
          path="/settings/import/:id/unit-sizes"
          component={UnitSizes}
        ></Route>
        <Route
          path="/settings/import/:id/unit-types"
          component={UnitTypes}
        ></Route>
        <Route
          path="/settings/import/:id/unit-features"
          component={UnitFeatures}
        ></Route>
        <Route
          path="/settings/import/:id/units"
          component={ImportUnits}
        ></Route>
        <Route
          path="/settings/import/:id/reservations"
          component={AllReservationsImport}
        ></Route>
        <Route path="/settings/import/:id/auto-pay" component={AutoPay}></Route>
        <Route
          path="/settings/import/:id/invoices"
          component={Invoices}
        ></Route>
        <Route
          path="/settings/import/:id/invoice-schedule"
          component={InvoiceSchedule}
        ></Route>
        <Route
          path="/settings/import/:id/delinquencies"
          component={DelinquenciesImport}
        ></Route>
        <Route path="/settings/import" component={StartImport}></Route>

        <Route
          path="/settings/payment-processing"
          component={PaymentProcessing}
        ></Route>

        {/* payment-processing */}
        <Route path="/" component={SettingsHome} />
      </Switch>
    </div>
  );
};

export default SettingsRoutes;
