import styles from "./ExpiringCards.module.scss";

const ExpiringCards = () => {
  return (
    <div
      className={`p-l-16 p-r-16  p-t-20 p-b-20  ${styles.card} ${styles.expiringCards}`}
    >
      <h4 className={`fs14 ffmsb m-b-10`}>Expiring Cards (next 30 days)</h4>
    </div>
  );
};

export default ExpiringCards;
