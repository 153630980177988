import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import Portal from "../../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../../components/inputs/NonFormikInput";

import checkMark from "../../../../../assets/images/checkmark.svg";

import styles from "./AddBracket.module.scss";
import CurrencyInput from "../../../../../components/inputs/CurrencyInput";

const AddBracket = ({ bracket, companyId, close }) => {
  const { user, reduxUser } = useSelector(({ user, reduxUser }) => ({
    user,
    reduxUser,
  }));

  const [coverageAmount, setCoverageAmount] = useState(
    bracket?.coverageAmount || 0
  );
  const [coveragePercentage, setCoveragePercentage] = useState(
    bracket?.coveragePercentage || ""
  );
  const [premium, setPremium] = useState(bracket?.premium || 0);
  const [active, setActive] = useState(bracket?.active || false);

  const save = async () => {
    // console.log(user.account, reduxUser.selectedLocation._id, name, active);

    if (bracket?._id) {
      await axios.put(`/api/insurance-bracket/${bracket._id}`, {
        coverageAmount,
        coveragePercentage,
        premium,
        active,
      });
    } else {
      await axios.post("/api/insurance-bracket", {
        coverageAmount,
        coveragePercentage,
        premium,
        active,
        accountId: user.account,
        locationId: reduxUser.selectedLocation._id,
        insuranceCompanyId: companyId,
      });
    }

    close();
  };

  return (
    <Portal
      zIndex="1"
      opacity="0.3"
      close={close}
      height="auto"
      width="400px"
      title="Add Insurance Provider"
    >
      <div className={`p-l-20 p-r-20 p-t-10 ${styles.addInsBracket}`}>
        {/* <NonFormikInput
          label="Covered amount"
          value={coverageAmount}
          change={(val) => setCoverageAmount(val)}
          id="ca"
        /> */}

        <CurrencyInput
          id="ca"
          value={coverageAmount}
          label="Covered amount"
          onChange={(val) => setCoverageAmount(val.fullNumber)}
        />

        <NonFormikInput
          label="Covered percentage"
          value={coveragePercentage}
          change={(val) => setCoveragePercentage(val)}
          id="cp"
        />
        {/* <NonFormikInput
          label="Premium cost"
          value={premium}
          change={(val) => setPremium(val)}
          id="pc"
        /> */}

        <CurrencyInput
          id="pc"
          value={premium}
          label="Premium cost"
          onChange={(val) => setPremium(val.fullNumber)}
        />

        <div
          className={`df acsa ${styles.activeSwitch} ${
            active ? styles.active : ""
          }`}
          onClick={() => setActive(!active)}
        >
          <div className={`df acc ${styles.activeCheckbox}`}>
            {active ? (
              <img className={`m-t-2`} src={checkMark} alt="" />
            ) : undefined}
          </div>
          <p className={`ffnsb fs14`}>Active</p>
        </div>

        <div className={`footer p-b-30`}>
          <button onClick={save} className="button primary w100 m-t-20 ttuc">
            Save
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default AddBracket;

// **** Insurance bracket **** //

// Endpoint: /api/insurance-bracket
// coverageAmount: String,
// coveragePercentage: Number,
// premium: String,
// active: Boolean,
// accountId: { type: Types.ObjectId, ref: "portalAccount" },
// insuranceCompanyId: { type: Types.ObjectId, ref: "insuranceCompany" },
