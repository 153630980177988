import Axios from "axios";
import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { Route, useHistory, useParams } from "react-router-dom";
import ClosePopup from "../../../assets/js-svgs/close-popup.svg";
import NoteSVG from "../../../assets/js-svgs/note.svg";
import PaymentSvg from "../../../assets/js-svgs/payment.svg";
import { formatFromCurrencyToMoney } from "../../../helpers";
import Invoices from "./invoices/Invoices";
import PastDueSection from "./past-due-section/PastDue";
import PaymentsPopup from "./payments-popup/PaymentsPopup";
import Payments from "./payments/Payments";
import RentalsSection from "./rentals-section/RentalsSection";
import "./TenantDetails.scss";
import { useSelector } from "react-redux";

const TenantDetails = ({ closeUrl }) => {
  const { user } = useSelector(({ user }) => user);
  const params = useParams();
  const history = useHistory();
  const [tenant, setUser] = useState({});
  const [selectedSection, setSelectedSection] = useState("rentals");
  const [refreshList, setRefreshList] = useState(1);
  // eslint-disable-next-line
  const [rentalId, setRentalId] = useState(undefined);
  const [rental, setRental] = useState(undefined);
  const [notes, setNotes] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [newNote, setNewNote] = useState("");

  const getUser = async () => {
    let tenant = await Axios.get(`/api/web-user-details/${params.id}`);
    setUser(tenant.data.data);
  };

  const getRental = async () => {
    let details = await Axios.get(`/api/detailed-reservation/${rentalId}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setRental(details.data.data);
  };

  const getNotes = async () => {
    let notes = await Axios.get(`/api/note/${params.id}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setNotes(notes.data);
  };

  useEffect(() => {
    if (!rentalId) return;
    getRental();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalId]);

  useEffect(() => {
    getUser();
    getNotes();
    // eslint-disable-next-line
  }, [params.id]);

  const checkKeypress = (e) => {
    if (e.keyCode === 27) history.push("/tenants");
  };

  useEffect(() => {
    window.addEventListener("keyup", checkKeypress);
    return () => {
      window.removeEventListener("keyup", checkKeypress);
    };
    // eslint-disable-next-line
  }, []);

  const saveNote = async () => {
    let note = await Axios.post(`/api/note`, {
      note: newNote,
      linkedTo: params.id,
      createdBy: user?._id,
    });

    setNotes([...notes, note.data]);
    setNewNote("");
  };

  return (
    <>
      <div className="tenant_details_popup" id="tenant_details_popup">
        <div className="tenant_details_popup_inner">
          {/* Tenant Details {params.id} */}
          <div className={`stickyWrpr`}>
            <div className="df acsa hdr ffnsb fs16">
              <div className="userDetailsWrpr">
                {tenant.fullName}
                {tenant.hasPastDue ? (
                  <span className={`star`}>*</span>
                ) : undefined}

                <div className="userDetailsPopover ffnl fs14">
                  <p className="line">{tenant.email}</p>
                  <p className="line">
                    {tenant?.defaultPhoneNumber?.number
                      ? formatPhoneNumber(tenant.defaultPhoneNumber.number)
                      : ""}
                  </p>
                  <p className="line">
                    {tenant?.defaultAddress ? (
                      <>
                        {tenant.defaultAddress.deliveryLine1}{" "}
                        {tenant.defaultAddress.lastLine}
                      </>
                    ) : undefined}
                  </p>
                </div>
              </div>
              <div className="df aic mla">
                <div
                  className="df acc notesWrpr"
                  onClick={() => setShowNotes(!showNotes)}
                >
                  {notes?.length ? (
                    <div className="df acc badge">{notes.length}</div>
                  ) : undefined}
                  <NoteSVG />
                </div>
                {showNotes ? (
                  <div className="notesPopup">
                    {notes?.map((note) => (
                      <div className="noteWrpr">{note.note}</div>
                    ))}
                    <div className="df acsa footer">
                      <input
                        type="text"
                        placeholder="Add a note"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                      />
                      <button onClick={saveNote}>Add</button>
                    </div>
                  </div>
                ) : undefined}
                <div
                  className="close_btn"
                  onClick={() => history.push(closeUrl)}
                >
                  <ClosePopup></ClosePopup>
                </div>
              </div>
            </div>
            <div className="df acsa overview_wrpr">
              <div className={`df acsa ffnr fs14 sec`}>
                <p className={`lbl`}>Balance:</p>
                <p className={`val`}>
                  {tenant.totalBalance
                    ? formatFromCurrencyToMoney(tenant.totalBalance)
                    : formatFromCurrencyToMoney(0)}
                </p>
              </div>
              {/* <div className={`df acsa ffnr fs14 sec`}>
            <p className={`lbl`}>Outstanding:</p>
            <p className={`val`}>
              ${tenant.grandTotal ? tenant.grandTotal.toFixed(2) : "0.00"}
            </p>
          </div> */}
              <div className={`df acsa ffnr fs14 sec`}>
                <p className={`lbl`}>Credits:</p>
                <p className={`val`}>
                  {formatFromCurrencyToMoney(tenant?.credits) ||
                    formatFromCurrencyToMoney(0)}
                </p>
              </div>

              <div
                className={`paymentSec mla`}
                onClick={() => history.push(`/tenants/${params.id}/payments`)}
              >
                <PaymentSvg fill="#30BE76" />
              </div>
            </div>
          </div>
          {/* <div className="df acsa overview_wrpr">
          <div className="right_sec">
            <div className="df acsa line">
              <p className="ffnl fs14">
                Balance:{" "}
                <span className="val">
                  ${tenant.grandTotal ? tenant.grandTotal.toFixed(2) : "0.00"}
                </span>
              </p>
            </div>

            <div className="df acsa line">
              <p className="ffnl fs14">
                Outstanding:{" "}
                <span className="val">
                  ${tenant.grandTotal ? tenant.grandTotal.toFixed(2) : "0.00"}
                </span>
              </p>
            </div>

            <div className="df acsa line">
              <p className="ffnl fs14">
                Credit:{" "}
                <span className="val">
                  ${tenant.creditsTotal?.toFixed(2) || "0.00"}
                </span>
              </p>
            </div>
          </div>

          <div className="left_sec">
            <div className="df acsa line">
              <p className="ffnl fs14">{tenant.email}</p>
            </div>

            <div className="df acsa line phone">
             
              <p className="ffnl fs14">
                {tenant.defaultPhoneNumber
                  ? formatPhoneNumber(tenant.defaultPhoneNumber.number)
                  : undefined}
              </p>
              {tenant?.defaultPhoneNumber?.canText ? (
                <span className="text_icon">
                  <TextMessage
                    fill="#1F2041"
                    height="11"
                    width="11"
                    opacity="0.80"
                  ></TextMessage>
                </span>
              ) : undefined}
            </div>

            <div className="df acsa line">
              <p className="ffnl fs14">
                {tenant.defaultAddress && tenant.defaultAddress.line1 ? (
                  <>
                    {tenant.defaultAddress.line1}
                    {tenant.defaultAddress.line2 &&
                    tenant.defaultAddress.line2.trim()
                      ? ` ${tenant.defaultAddress.line2}, `
                      : ", "}
                    {tenant.defaultAddress.city} {tenant.defaultAddress.state}{" "}
                    {tenant.defaultAddress.zip}
                  </>
                ) : undefined}
              </p>
            </div>
          </div>
        </div> */}
          {tenant.hasPastDue ? (
            <PastDueSection pastDue={tenant.pastDue} />
          ) : undefined}
          {/* */}
          <div className="df acc sections_tabs_wrpr">
            <div className="df acsa main_tbs_wrpr">
              {[
                { label: "Rentals", value: "rentals" },
                { label: "Payments", value: "payments" },
                { label: "Invoices", value: "invoices" },
              ].map((tab, i) => (
                <p
                  key={tab.label}
                  className={`df acc ffnr fs14 tab ${
                    selectedSection === tab.value ? "selected" : ""
                  }`}
                  onClick={() => setSelectedSection(tab.value)}
                >
                  {tab.label}
                </p>
              ))}

              {/* <p className="df acc ffnr fs14 tab ">Activity</p>
					<p className="df acc ffnr fs14 tab ">Invoices</p> */}
            </div>
          </div>
          {selectedSection === "rentals" ? (
            <>
              <div className="section_tab_wrpr">
                <RentalsSection
                  refreshAction={() => {
                    setRefreshList(refreshList + 1);
                    getUser();
                  }}
                  refreshList={refreshList}
                  rental={rental}
                  accountCredit={tenant?.credits?.totalAvailable || 0}
                ></RentalsSection>
              </div>

              <Route
                path="/tenants/:id/payments/:resId?"
                // component={PaymentsPopup}
                render={(props) => (
                  <PaymentsPopup
                    tenant={tenant}
                    {...props}
                    refreshData={() => {
                      setRefreshList(refreshList + 1);
                      getUser();
                    }}
                  />
                )}
              ></Route>
            </>
          ) : selectedSection === "invoices" ? (
            <Invoices userId={params.id} />
          ) : selectedSection === "payments" ? (
            <Payments userId={params.id} />
          ) : (
            "Ohno"
          )}
        </div>
      </div>
      <div className="RDOverlay" />
    </>
  );
};

export default TenantDetails;
