import React from "react";

export const PayPal = (props) => {
  return (
    <svg
      width={props.width || "489"}
      height={props.height || "137"}
      viewBox="0 0 489 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M381.601 24.4C369.601 24.4 360.001 27.6 352.001 29.2L349.601 47.6001C353.601 46.0001 364.801 42.8 374.401 42C384.001 42 389.601 43.6 388.001 52.4C359.201 52.4 340.001 58 336.001 77.2C330.401 109.2 365.601 110 379.201 95.6001L377.601 104.4H403.201L414.401 52.4C418.401 30.8 399.201 23.6 381.601 24.4ZM383.201 78C381.601 84.4 376.001 86.8001 369.601 87.6001C364.001 87.6001 359.201 84.4 362.401 78C365.601 73.2 372.801 73.2 378.401 73.2C380.001 73.2 382.401 73.2 384.801 73.2C384.001 72.4 383.201 75.6 383.201 78Z"
        fill="#32A6CE"
      />
      <path
        d="M349.601 47.6002C353.601 46.0002 364.801 42.8002 374.401 42.0002C384.001 42.0002 389.601 43.6002 388.001 52.4002C359.201 52.4002 340.001 58.0002 336.001 77.2002C330.401 109.2 365.601 110 379.201 95.6002L377.601 104.4H403.201L414.401 52.4002C419.201 30.8002 399.201 25.2002 381.601 25.2002L349.601 47.6002ZM383.201 78.0002C381.601 84.4002 376.001 86.8002 369.601 87.6002C364.001 87.6002 359.201 84.4002 362.401 78.0002C365.601 73.2002 372.801 73.2002 378.401 73.2002C380.001 73.2002 382.401 73.2002 384.801 73.2002C384.001 72.4002 383.201 75.6002 383.201 78.0002Z"
        fill="#1A80AD"
      />
      <path
        d="M435.999 0.400391L415.199 104.4H440.799L462.399 0.400391H435.999Z"
        fill="#32A6CE"
      />
      <path
        d="M454.399 0.400391L415.199 104.4H440.799L462.399 0.400391H435.999H454.399Z"
        fill="#1A80AD"
      />
      <path
        d="M316.801 0.400391H269.601L248.801 104.4H276.801L284.001 72.4004H304.001C323.201 72.4004 339.201 61.2004 343.201 41.2004C347.201 18.0004 330.401 0.400391 316.801 0.400391ZM316.001 36.4004C314.401 43.6004 307.201 48.4004 300.001 48.4004H287.201L292.801 24.4004H306.401C313.601 24.4004 317.601 29.2004 316.001 36.4004Z"
        fill="#32A6CE"
      />
      <path
        d="M316.8 0.400391H284.8L248 104.4H276L283.2 72.4004H303.2C322.4 72.4004 338.4 61.2004 342.4 41.2004C347.2 18.0004 330.4 0.400391 316.8 0.400391ZM316 36.4004C314.4 43.6004 307.2 48.4004 300 48.4004H287.2L292.8 24.4004H306.4C313.6 24.4004 317.6 29.2004 316 36.4004Z"
        fill="#1A80AD"
      />
      <path
        d="M131.999 24.4C119.999 24.4 110.399 27.6 103.199 29.2L100.799 47.6001C103.999 46.0001 115.999 42.8 125.599 42C135.199 42 140.799 43.6 139.199 52.4C111.199 52.4 91.9994 58 87.9994 77.2C82.3994 109.2 116.799 110 130.399 95.6001L128.799 104.4H154.399L165.599 52.4C169.599 30.8 149.599 23.6 131.999 24.4ZM134.399 78C132.799 84.4 127.199 86.8001 120.799 87.6001C115.199 87.6001 110.399 84.4 114.399 78C117.599 73.2 124.799 73.2 129.599 73.2C131.999 73.2 133.599 73.2 135.999 73.2C135.199 72.4 134.399 75.6 134.399 78Z"
        fill="#21789E"
      />
      <path
        d="M101.6 47.6002C104.8 46.0002 116.8 42.8002 126.4 42.0002C136 42.0002 141.6 43.6002 140 52.4002C112 52.4002 92.8002 58.0002 88.8002 77.2002C83.2002 109.2 117.6 110 131.2 95.6002L128.8 104.4H154.4L165.6 52.4002C170.4 30.8002 150.4 25.2002 132.8 25.2002L101.6 47.6002ZM134.4 78.0002C132.8 84.4002 127.2 86.8002 120.8 87.6002C115.2 87.6002 110.4 84.4002 114.4 78.0002C117.6 73.2002 124.8 73.2002 129.6 73.2002C132 73.2002 133.6 73.2002 136 73.2002C135.2 72.4002 134.4 75.6002 134.4 78.0002Z"
        fill="#1A5B80"
      />
      <path
        d="M176 24.4004H201.6L205.6 69.2004L231.2 24.4004H257.6L196.8 136.4H168L186.4 102.8L176 24.4004Z"
        fill="#21789E"
      />
      <path
        d="M201.6 26.0004L205.6 70.0004L231.2 24.4004H257.6L196.8 136.4H168L186.4 103.6"
        fill="#1A5B80"
      />
      <path
        d="M68.8 0.400391H20.8L0 104.4H28L35.2 72.4004H55.2C74.4 72.4004 90.4 61.2004 94.4 41.2004C99.2 18.0004 82.4 0.400391 68.8 0.400391ZM68 36.4004C66.4 43.6004 59.2 48.4004 52 48.4004H39.2L44.8 24.4004H58.4C65.6 24.4004 69.6 29.2004 68 36.4004Z"
        fill="#21789E"
      />
      <path
        d="M68.8 0.400391H47.2L0 104.4H28L35.2 72.4004H55.2C74.4 72.4004 90.4 61.2004 94.4 41.2004C99.2 18.0004 82.4 0.400391 68.8 0.400391ZM68 36.4004C66.4 43.6004 59.2 48.4004 52 48.4004H39.2L44.8 24.4004H58.4C65.6 24.4004 69.6 29.2004 68 36.4004Z"
        fill="#1A5B80"
      />
      <path
        d="M127.201 52.4004C105.601 54.0004 91.2013 60.4004 88.0013 76.4004C82.4013 108.4 116.801 109.2 130.401 94.8004L128.801 104.4H154.401L158.401 85.2004L127.201 52.4004ZM134.401 78.0004C132.801 84.4004 127.201 86.8004 120.801 87.6004C115.201 87.6004 110.401 84.4004 114.401 78.0004C117.601 73.2004 124.801 73.2004 129.601 73.2004C132.001 73.2004 133.601 73.2004 136.001 73.2004C135.201 72.4004 134.401 75.6004 134.401 78.0004Z"
        fill="#06435E"
      />
      <path
        d="M207.2 68.4004L205.6 70.8004L222.4 86.8004L257.6 24.4004H231.2L207.2 68.4004Z"
        fill="#06435E"
      />
      <path
        d="M28.0004 104.4L35.2004 72.4004L2.40039 104.4H28.0004Z"
        fill="#06435E"
      />
      <path
        d="M276 104.401L283.2 70.8008V71.6008L250.4 104.401H276Z"
        fill="#2273AA"
      />
      <path
        d="M376 52.4004C354.4 54.0004 340 60.4004 336.8 76.4004C331.2 108.4 366.4 109.2 380 94.8004L377.6 104.4H403.2L407.2 85.2004L376 52.4004ZM383.2 78.0004C381.6 84.4004 376 86.8004 369.6 87.6004C364 87.6004 359.2 84.4004 362.4 78.0004C365.6 73.2004 372.8 73.2004 378.4 73.2004C380 73.2004 382.4 73.2004 384.8 73.2004C384 72.4004 383.2 75.6004 383.2 78.0004Z"
        fill="#2273AA"
      />
      <path
        d="M433.601 57.2002L415.201 104.4H440.801L448.001 72.4002L433.601 57.2002Z"
        fill="#2273AA"
      />
      <path
        d="M468 17.1994V5.99942H464V4.39941H474.4V5.99942H470.4V17.1994H468Z"
        fill="#32A6CE"
      />
      <path
        d="M476 17.1996V4.3996H478.4L481.6 13.1996C481.6 13.9996 482.4 14.7996 482.4 14.7996C482.4 13.9996 482.4 13.9996 483.2 12.3996L486.4 3.59961H488.8V16.3996H487.2V5.1996L483.2 16.3996H480.8L476.8 5.1996V16.3996H476V17.1996Z"
        fill="#32A6CE"
      />
    </svg>
  );
};
