import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Portal from "../../../../components/portal/Portal";
import * as creditCards from "../../../../assets/images/credit-cards";

import styles from "./ProcessPayment.module.scss";
import dayjs from "dayjs";

const ProcessPayment = (props) => {
  const history = useHistory();
  const params = useParams();

  const [selectedPayMethodDetails, setSelectedPayMethodDetails] = useState();
  const [expectedMoveInDate, setExpectedMoveInDate] = useState();
  const [reservation, setReservation] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [activeReservation, setActiveReservation] = useState(undefined);

  // const activeReservation = useSelector(
  //   ({ activeReservation }) => activeReservation
  // );

  const setActiveReservationAction = async () => {
    let d = await axios.get(`/api/portal-reservation/${params.reservationId}`);
    setActiveReservation(d.data?.data);
  };

  const getReservationDetails = async () => {
    let d = await axios.get(
      `/api/reserve-unit/?unitid=${activeReservation?.unit?._id}&moveindate=${expectedMoveInDate}`
    );
    setReservation(d.data.data);
  };

  useEffect(() => {
    if (!activeReservation) {
      return setActiveReservationAction();
    }
    setExpectedMoveInDate(activeReservation.expectedMoveInDate);
    // eslint-disable-next-line
  }, [activeReservation]);

  useEffect(() => {
    if (!activeReservation || !expectedMoveInDate) return;
    getReservationDetails();
    // eslint-disable-next-line
  }, [expectedMoveInDate, activeReservation]);

  const getPayMethodDetails = async () => {
    let locatSelectedPayMethodDetails = await axios.get(
      `/api/payment-method/${params.paymethod}`
    );
    setSelectedPayMethodDetails(locatSelectedPayMethodDetails.data.data);
  };

  useEffect(() => {
    if (!params.paymethod) return;
    getPayMethodDetails();
    // eslint-disable-next-line
  }, [params.paymethod]);

  const getIcon = () => {
    if (!selectedPayMethodDetails) return;
    let CardLogo = creditCards[selectedPayMethodDetails.xCardType];
    return <CardLogo />;
  };

  const processPayment = async () => {
    setSubmitting(true);
    let res = await axios.post(
      `/api/reservation-initial-payment/${params.reservationId}`,
      {
        payDetails: {
          payMethod: params.paymethod,
          expectedTotal: reservation?.total,
        },
        reservationDetails: {
          user: activeReservation?.user?._id,
          unit: activeReservation.unit._id,
          locationId: activeReservation.unitLocation,
          webProfileId: activeReservation.user.webProfileId,
          accountId: activeReservation?.websiteProfile?.account,
          expectedMoveInDate: activeReservation?.expectedMoveInDate,
          initialPayment: false,
          address: activeReservation.user.defaultAddress._id,
          phoneNumber: activeReservation.user.defaultPhoneNumber._id,
        },
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    if (res.data.message === "error") return setSubmitting(false);

    history.push(`/tenants/add/thank-you/${params.reservationId}`);
  };

  return (
    <Portal
      zIndex="1"
      width="354px"
      height="auto"
      opacity="0.45"
      title="Process Payment"
      close={() => history.push("/tenants")}
      noCustomScrollbar={true}
    >
      {console.log(activeReservation)}
      <div className={`${styles.processPaymentPopup}`}>
        {reservation ? (
          <div className={styles.itemsWrpr}>
            {/* ************ */}
            {reservation.proRatedMonth ? (
              <div className={`df acsa ${styles.line}`}>
                <div className={`ffnr fs14 ${styles.leftSec}`}>
                  <p className={``}>
                    <span className={`ttuc`}>{reservation.unitId}</span>{" "}
                    {reservation?.unitSize?.label} (
                    {reservation?.unitSize?.length}x
                    {reservation?.unitSize?.width})
                  </p>
                  <p>
                    {dayjs(reservation.expectedMoveInDate).format("MM/DD/YY")} -{" "}
                    {dayjs(reservation.expectedMoveInDate)
                      .endOf("month")
                      .format("MM/DD/YY")}
                  </p>
                </div>
                <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                  ${Number(reservation.proRatedMonth)?.toFixed(2)}
                </div>
              </div>
            ) : undefined}
            {/* ************ */}
            <div className={`df acsa ${styles.line}`}>
              <div className={`ffnr fs14 ${styles.leftSec}`}>
                <p className={``}>
                  <span className={`ttuc`}>{reservation.unitId}</span>{" "}
                  {reservation?.unitSize?.label} (
                  {reservation?.unitSize?.length}x{reservation?.unitSize?.width}
                  )
                </p>
                {reservation.proRatedMonth ? (
                  <p>
                    {dayjs(reservation.expectedMoveInDate)
                      .add(1, "month")
                      .startOf("month")
                      .format("MM/DD/YY")}{" "}
                    -{" "}
                    {dayjs(reservation.expectedMoveInDate)
                      .add(1, "month")
                      .endOf("month")
                      .format("MM/DD/YY")}
                  </p>
                ) : (
                  <p>
                    {dayjs(reservation.expectedMoveInDate).format("MM/DD/YY")} -{" "}
                    {dayjs(reservation.expectedMoveInDate)
                      .add(1, "month")
                      .format("MM/DD/YY")}
                  </p>
                )}
              </div>
              <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                ${reservation.unitSize?.price?.toFixed(2)}
              </div>
            </div>
            {/* ************ */}
            {reservation?.unitLocation?.securityDeposit ? (
              <div className={`df acsa ${styles.line}`}>
                <div className={`ffnr fs14 ${styles.leftSec}`}>
                  <p className={``}>Security Deposit</p>
                </div>
                <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                  $
                  {Number(reservation.unitLocation?.securityDeposit)?.toFixed(
                    2
                  )}
                </div>
              </div>
            ) : undefined}
            {/* ********* */}
            {reservation.unitLocation?.adminFee ? (
              <div className={`df acsa ${styles.line}`}>
                <div className={`ffnr fs14 ${styles.leftSec}`}>
                  <p className={``}>Admin fee</p>
                </div>
                <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                  ${Number(reservation.unitLocation?.adminFee)?.toFixed(2)}
                </div>
              </div>
            ) : undefined}

            {/* ******** */}
            <div className={`df acsa ${styles.line}`}>
              <div className={`mla ffnr fs14 ${styles.rightSec}`}>
                <p className={`df acsa ${styles.totalLine}`}>
                  <span className={styles.label}>Subtotal</span>{" "}
                  <span className={`mla`}>
                    ${Number(reservation.totalBeforeTaxes)?.toFixed(2)}
                  </span>
                </p>
                <p className={`df acsa ${styles.totalLine}`}>
                  <span className={styles.label}>Taxes</span>
                  <span className={`mla`}>
                    ${Number(reservation?.taxes)?.toFixed(2)}
                  </span>
                </p>
                <p className={`df acsa ${styles.totalLine}`}>
                  <span className={styles.label}>Total due</span>
                  <span className={`mla`}>
                    ${Number(reservation?.total)?.toFixed(2)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : undefined}

        {selectedPayMethodDetails ? (
          <div className={`df acsa ${styles.selectedPayMethodWrpr}`}>
            <div className={`df acsa ffnr fs14`}>
              <div className={`${styles.iconWrpr}`}>{getIcon()}</div>
              {selectedPayMethodDetails.xCardType} ****
              {selectedPayMethodDetails.xMaskedCardNumber.substring(
                selectedPayMethodDetails.xMaskedCardNumber.length - 4,
                selectedPayMethodDetails.xMaskedCardNumber.length
              )}
            </div>
          </div>
        ) : undefined}

        <div className={`df ffc`}>
          <button
            className={`ffnb fs14 ttuc sbmt_btn primary`}
            onClick={processPayment}
            disabled={submitting}
          >
            Process Payment
          </button>

          {/* <button
            //   disabled={!selectedPayMethod}
            className={` ffnb fs14 ttuc sbmt_btn secondary m-t-10`}
            //   onClick={processPayment}
          >
            Process Payment
          </button> */}
        </div>
      </div>
    </Portal>
  );
};

export default ProcessPayment;
