import {
  GET_UNIT_TYPES,
  GET_UNIT_STATUSES,
  GET_UNIT_SIZES,
  GET_UNIT_FEATURES,
} from "../actions/types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case GET_UNIT_TYPES:
      return { ...state, unitTypes: action.payload };
    case GET_UNIT_STATUSES:
      return { ...state, unitStatuses: action.payload };
    case GET_UNIT_SIZES:
      return { ...state, unitSizes: action.payload };
    case GET_UNIT_FEATURES:
      return { ...state, unitFeatures: action.payload };
    default:
      return state;
  }
};

export default reducer;
