export const PartialCheckedInCircle = (props) => (
  <svg
    width={props.width || "15"}
    height={props.height || "15"}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM2.5 7.5C2.5 10.2563 4.74375 12.5 7.5 12.5C10.2563 12.5 12.5 10.2563 12.5 7.5C12.5 4.74375 10.2563 2.5 7.5 2.5C4.74375 2.5 2.5 4.74375 2.5 7.5ZM4.375 6.875V8.125H10.625V6.875H4.375Z"
      fill={props.fill || "#30BE76"}
    />
  </svg>
);
