import React, { useState } from "react";
import MoveOut from "./move-out/MoveOut";
import RentalDetails from "./rental-details/RentalDetails";
import RentalsTabs from "./rentals-tabs/RentalsTabs";

const RentalsSection = (props) => {
  const [selectedRental, setSelectedRental] = useState(null);
  const [isMovingOut, setIsMovingOut] = useState(false);

  const setMovingOutAction = () => setIsMovingOut(!isMovingOut);
  return (
    <div className="rentals_section">
      {!isMovingOut ? (
        <>
          <RentalsTabs
            refresh={props.refreshList}
            setSelectedRental={setSelectedRental}
            selectedRental={selectedRental}
          ></RentalsTabs>
          <RentalDetails
            selectedRental={selectedRental}
            setMovingOutAction={setMovingOutAction}
          ></RentalDetails>
        </>
      ) : (
        <MoveOut
          refreshAction={props.refreshAction}
          selectedRental={selectedRental}
          close={setMovingOutAction}
          accountCredit={props.accountCredit}
        ></MoveOut>
      )}
    </div>
  );
};

export default RentalsSection;
