import React, { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
// import ImageUploadPopup from "../../../components/image-upload/ImageUploadPopup";

import ChatSVG from "../../../assets/js-svgs/chat.svg";
import DocSVG from "../../../assets/js-svgs/doc.svg";
import Plus from "../../../assets/js-svgs/plus.svg";
import { StarSVG } from "../../../assets/js-svgs/star.svg";
import { StarFilledSVG } from "../../../assets/js-svgs/star-filled.svg";

import styles from "./Task.module.scss";
import {
  SET_TASK_COMPLETE,
  SET_TASK_STARRED,
} from "../../../store/actions/types";
import UploadThumb from "../../../components/image-upload/upload-thumb/UploadThumb";

const Task = (props) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("x-auth");

  const { user } = useSelector(({ user }) => ({ user }));

  const { task } = props;
  // eslint-disable-next-line
  // const [open, setOpen] = useState(props.open);
  // ${props.focusMode && !props.open ? styles.focusMode : ""}
  const [attachments, setAttachments] = useState([]);
  const [fields, setFields] = useState(task.addedFields || {});

  const setFieldValue = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    });
  };

  const completed = async () => {
    console.log("COMPLETED TASK");

    // return console.log("OOOK");

    let localTask = await axios.post(
      `/api/complete-task/${task._id}`,
      { addedAttachments: attachments, addedFields: fields },
      { headers: { "x-auth": token } }
    );

    dispatch({
      type: SET_TASK_COMPLETE,
      payload: { data: localTask.data.data },
    });
    // props.updateTask(localTask.data.data);
  };

  // SET_TASK_COMPLETE

  const star = async () => {
    // console.log(user._id, task.starredBy);
    let localTask = await axios.put(
      `/api/star-task/${task._id}/${user._id}/${task.starredBy?.includes(
        user._id
      )}`,
      {},
      { headers: { "x-auth": token } }
    );

    dispatch({
      type: SET_TASK_STARRED,
      payload: { data: localTask.data.data },
    });
  };

  const getFieldType = (field) => {
    switch (field.type) {
      case "text":
        return (
          <input
            type="text"
            className={`ffnsb fs14 ${styles[field.type]}`}
            value={fields[field.name]}
            onChange={(e) => setFieldValue(field.name, e.target.value)}
          />
        );
      case "textarea":
        return (
          <textarea
            className={`ffnsb fs14 ${styles[field.type]}`}
            value={fields[field.name]}
            onChange={(e) => setFieldValue(field.name, e.target.value)}
          />
        );
      default:
        return (
          <input
            type="text"
            className={`ffnsb fs14 ${styles.text}`}
            value={fields[field.name]}
            onChange={(e) => setFieldValue(field.name, e.target.value)}
          />
        );
    }
  };

  const isDisabled = () => {
    if (task.requiredAttachmentCount > 0) {
      console.log("TASK HAS ATTACHMENTS");
      if (attachments.length < task.requiredAttachmentCount) {
        return true;
      }
    }
    if (task.fields.length > 0) {
      let myFields = Object.keys(fields);
      let haveValues = myFields.filter((elem) => fields[elem].length);
      if (haveValues.length < task.fields.length) return true;
    }
    return false;
  };

  const setImages = (d) => {
    console.log("INDEX", d);
    let updated = update(attachments, {
      [d.idx]: {
        $set: { imgUrl: d.imgUrl, id: d.id },
      },
    });

    console.log(updated);
    setAttachments(updated);
  };

  const removeImage = (idx) => {
    console.log("REMOVE IMAGE", idx);
    const updated = update(attachments, {
      $splice: [[idx, 1]],
    });
    console.log(updated);
    setAttachments(updated);
  };

  return (
    <div
      className={`
      ${styles.task}`}
      onClick={() => props.setOpenTask(task._id)}
    >
      <div className={`df ${styles.hdr}`}>
        <p className={`ffnr fs16 ${styles.title}`}>
          {task.title}{" "}
          <p className={`fs14 m-t-3 m-b-8 ${styles.sub}`}>
            ({task?.customer?.fullName})
          </p>
        </p>

        <div className={`df acc mla ${styles.unit}`}>
          <div className={styles.circle}></div>
          <p className={`ttuc ffnr fs16`}>{task?.reservation?.unit?.unitId}</p>
        </div>
      </div>
      <div
        className={`ffnr ${styles.body} ${props.open ? "fs16" : "fs12"}`}
        dangerouslySetInnerHTML={{ __html: task.body }}
      ></div>
      {props.open ? (
        <>
          {task?.documents?.length ? (
            <div className={`m-t-15`}>
              {task?.documents.map((doc, idx) => (
                <a
                  className={`df acsa ffnb fs14 ${styles.docLink}`}
                  key={`key-${idx}`}
                  href={doc.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DocSVG
                    height="15"
                    width="13"
                    fill="#30be76"
                    fillOpacity="1"
                  />
                  <span className={`m-l-6`}>{doc.label}</span>
                </a>
              ))}
            </div>
          ) : undefined}
          {task.fields.length > 0 ? (
            <div className={`m-t-18 ${styles.taskFieldsWrpr}`}>
              {task.fields?.map((field, i) => (
                <div key={i} className={`m-b-15 ${styles.fieldWrpr}`}>
                  <p className={`ffmb fs12 ttuc m-b-4 ${styles.label}`}>
                    {field.label}
                  </p>
                  {/* <div className={`ffnr fs16 ${styles.fieldBody}`}> */}
                  {getFieldType(field)}
                  {/* </div> */}
                </div>
              ))}
            </div>
          ) : undefined}

          {task.requiredAttachmentCount > 0 ? (
            <div
              className={`${styles.uploadsWrpr}`}
              onClick={(e) => e.stopPropagation()}
            >
              {/* <button className={`ttuc ${styles.uploadBtn}`}>
                Upload photo{task.requiredAttachmentCount > 1 ? "s" : ""}
              </button> */}
              {attachments.map((att, idx) => (
                <UploadThumb
                  kkk={att.id}
                  key={att.id}
                  openDefault={true}
                  setImages={setImages}
                  idx={idx}
                  removeImage={removeImage}
                  imgUrl={att.imgUrl}
                />
              ))}
              {/* {console.log(attachments)} */}
              {attachments.length === 0 ||
              attachments[attachments.length - 1]?.imgUrl ? (
                <div
                  className={`df acc ffc ${styles.addImgWrpr}`}
                  onClick={() =>
                    setAttachments([
                      ...attachments,
                      {
                        id:
                          // Generate a random string
                          Math.random().toString(36).substring(2, 15) +
                          Math.random().toString(36).substring(2, 15),
                      },
                    ])
                  }
                >
                  <p className={`ffnsb fs12 ttuc m-b-8`}>Add image</p>
                  <Plus fill="#c6c7cf" />
                </div>
              ) : undefined}
            </div>
          ) : undefined}
          <div
            className={styles.actionWrpr}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              disabled={isDisabled()}
              onClick={completed}
              className={`medium primary button w100`}
            >
              {task.completedButtonText || "Done"}
            </button>
          </div>
        </>
      ) : undefined}
      <div className={`df acsa ffnr fs12 ${styles.ftr}`}>
        <p
          className={`${styles.dueDate} ${
            dayjs(new Date(task.dueAt))
              .endOf("day")
              .isBefore(dayjs(new Date()).endOf("day"))
              ? `ffnsb ${styles.pastDue}`
              : dayjs(new Date(task.dueAt)).isSame(
                  dayjs(new Date()).startOf("day"),
                  "day"
                )
              ? `ffnsb ${styles.dueToday}`
              : ""
          }`}
        >
          {task.dueAt ? dayjs(task.dueAt).format("MM/DD/YY") : "Pending"}
        </p>

        <div className={`df acsa mla`}>
          <div
            className={`df acsa cursor_pointer ${styles.comments} ${styles.sec}`}
          >
            <ChatSVG
              fill="#1F2041"
              height="15"
              width="15"
              fillOpacity="0.25"
            ></ChatSVG>
          </div>
          <div
            className={`df acsa cursor_pointer ${styles.stars} ${styles.sec}`}
            onClick={(e) => {
              e.stopPropagation();
              star();
            }}
          >
            {task.starredBy?.includes(user._id) ? (
              <StarFilledSVG />
            ) : (
              <StarSVG />
            )}
          </div>
        </div>
      </div>
      {/* <ImageUploadPopup /> */}
    </div>
  );
};

export default Task;
