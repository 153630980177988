import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import Cleave from "cleave.js/react";
import Portal from "../../../../../../components/portal/Portal";
import { NonFormikInput } from "../../../../../../components/inputs/NonFormikInput";

import styles from "./SendPopup.module.scss";

const SendPopup = (props) => {
  const params = useParams();

  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [address, setAddress] = useState(
    props.type === "text" ? props.phone.slice(2) : props.email
  );
  console.log(address);
  const send = async () => {
    console.log(address);

    await axios.post(`/api/send-external-sign-url`, {
      websiteProfileId: reduxUser?.selectedLocation?.defaultWebsiteProfile,
      type: props.type,
      reservationId: params.reservationId,
      address,
    });

    props.close();
    return;
  };
  return (
    <Portal customHeader={true} zIndex="999" height="auto" opacity="0.2">
      <div className={`p-t-10 p-l-20 p-r-20 ${styles.sendPopup}`}>
        <p className={`df aic ttcaps ffnsb fs14 m-t-10 m-b-10`}>
          {props.type} documents
          <span onClick={props.close} className={`mla cursor_pointer`}>
            X
          </span>
        </p>
        {props.type === "text" ? (
          <div className={`__inpt_wrpr small`}>
            {/* {address} */}
            <Cleave
              className={`ffnr fs14`}
              type="text"
              id="pn"
              placeholder="Please enter phone number"
              options={{
                blocks: [0, 3, 3, 4],
                delimiters: ["(", ") ", "-"],

                numericOnly: true,
                // prefix: "+1",
              }}
              value={address}
              onChange={(event) => {
                setAddress(event.target.value);
              }}
            />
          </div>
        ) : (
          <NonFormikInput
            size="small"
            change={setAddress}
            value={address}
          ></NonFormikInput>
        )}

        <button onClick={send} className={`${styles.sendButton}`}>
          Send
        </button>
      </div>
    </Portal>
  );
};

export default SendPopup;
