import { SET_REDUX_USER } from "../actions/types";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_REDUX_USER:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
