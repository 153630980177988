import React from "react";

const UnitsSvg = (props) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 0V3H20V16H12V12H8V16H0V3H3V0H17ZM14 14H18V5H15V2H5V5H2V14H6V10H14V14Z"
      fillOpacity="0.9"
      // fill={props.fill || "red"}
    />
  </svg>
);

export default UnitsSvg;
