import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { Link, NavLink, Route, Switch } from "react-router-dom";
// import { getTenantsByLocation, setBgBlur } from "../../store/actions";
import searchIcon from "../../assets/images/search-icon.png";
import axios from "axios";
// import components
import AddTenant from "./add-tenant/AddTenant";
import TenantDetails from "./tenant-details/TenantDetails";
//import scss
// import "./Tenants.scss";

// NEW IMPORTS
import styles from "./Tenants.module.scss";
import Portal from "../../components/portal/Portal";
import UnitTypeFilter from "./filters/unit-types/UnitTypes";
// import dayjs from "dayjs";
import TenantLine from "./tenant-line/TenantLine";
import Lottie from "react-lottie";

import loader from "../../assets/lotties/loader.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Tenants = () => {
  let token = localStorage.getItem("x-auth");

  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  // const getTenantsByLocations = async (locations) => {
  //   let token = await localStorage.getItem("x-auth");
  //   return await Axios.post("/api/tenants-by-locations", locations, {
  //     headers: { "x-auth": token },
  //   });
  // };
  // let { reduxUser } = getState();

  // let { _id } = reduxUser.selectedLocation;
  // let tenants = await getTenantsByLocations([_id]);

  const [searchTerm, setSearchTerm] = useState("");
  const [tenantList, setTenantList] = useState(undefined);
  // const [showAddTenant, setShowAddTenant] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const getUsers = async () => {
    let { _id } = reduxUser.selectedLocation;
    let localList = await axios.post(
      `/api/tenants-by-locations?types=${filters.types}&lights=pop`,
      [_id],
      {
        headers: { "x-auth": token },
      }
    );

    setTenantList(localList?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (!reduxUser) return;
    getUsers();
    // eslint-disable-next-line
  }, [reduxUser]);

  const updateFilters = async (key, vals) => {
    setFilters({ ...filters, [key]: vals });
  };

  return (
    <div className={`route_wrpr ${styles.tenantsList}`}>
      <div className="top_bar">
        <div className={`df acsa header ${styles.header}`}>
          <div className="ffnsb fs16">Tenants</div>
          <NavLink
            exact
            className={`ffnsb fs14 ${styles.navSec}`}
            activeClassName={styles.selected}
            to="/tenants"
          >
            List
          </NavLink>
          <NavLink
            exact
            className={`ffnsb fs14 ${styles.navSec}`}
            activeClassName={styles.selected}
            to="/tenants/adjust"
          >
            Adjust Rate
          </NavLink>
          <div className="df accsa mla actions_wrpr">
            <Link
              to="/tenants/add/user"
              className="df ffnb fs14 rwt main_action"
              onClick={() => {
                // this.props.setBgBlur(true);
                // this.setState({ showAddTenant: true });
              }}
            >
              Add Tenant
            </Link>
          </div>
        </div>
        <div className={`df acsa ${styles.filtersWrpr}`}>
          <p className={`ffnl fs14`}>Filter by:</p>
          <div className={styles.filterWrpr}>
            <div
              className={`m-l-10 df acsa ffnl fs14 ${styles.filterTypeWrpr}`}
              onClick={() => setShowFilter(true)}
            >
              <p>Unit Type</p>{" "}
              {filters?.types?.length ? (
                <p className={`df acc ffnb fs10 m-l-5 ${styles.countPill}`}>
                  {filters?.types?.length}
                </p>
              ) : undefined}
            </div>

            {showFilter ? (
              <Portal
                zIndex="9"
                opacity="0.2"
                close={() => setShowFilter(false)}
                height="auto"
                title="Select Unit Type"
                width="300px"
                customHeader={true}
              >
                <UnitTypeFilter
                  selected={filters?.types}
                  close={() => setShowFilter(false)}
                  updateFilter={(vals) => {
                    updateFilters("types", vals);
                  }}
                />
              </Portal>
            ) : undefined}
          </div>
          <div className="mla">
            <div className={`${styles.searchInputWrpr}`}>
              <div className={`${styles.searchIcon}`}>
                <img src={searchIcon} alt="" />
              </div>
              <input
                placeholder="Search tenants"
                type="text"
                className={`${styles.searchInput} ffnr fs14`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className={`p-t-100 ${styles.loaderWrpr}`}>
          <Lottie options={defaultOptions} height={60} width={60} />
        </div>
      ) : (
        <div className={`${styles.list_wrpr}`}>
          <div
            className={`df acsa ffnb fs12 ttuc ${styles.line} ${styles.hdr}`}
          >
            <div className={`${styles.sec} ${styles.cntct}`}>Name</div>
            <div className={`${styles.sec} ${styles.stts}`}>Status</div>
            <div className={`${styles.sec} ${styles.unit}`}>Units </div>
            <div className={`${styles.sec} ${styles.mv_in}`}>
              Customer Since
            </div>
            <div className={`${styles.sec} ${styles.cntrls}`}></div>
          </div>
          {tenantList
            ?.filter((el) =>
              el.fullName.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((tenant, idx) => (
              <TenantLine tenant={tenant} idx={idx} />
            ))}
        </div>
      )}

      <Switch>
        <Route path="/tenants/add" component={AddTenant}></Route>
        <Route
          path={`/tenants/:id`}
          render={() => <TenantDetails closeUrl="/tenants" />}
        ></Route>
      </Switch>
    </div>
  );
};

export default Tenants;
