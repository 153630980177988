import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styles from "./AddressAutoComplete.module.scss";
import DDPortal from "./DDPortal";

const AddressAutoComplete = ({ onUpdate }) => {
  const inputRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [deliveryLine1, setDeliveryLine1] = useState("");
  const [lastLine, setLastLine] = useState("");

  const getAddressList = async () => {
    const re = await axios.get(`/api/smarty-autocomplete/${deliveryLine1}`);
    setAddressList(re?.data?.suggestions || []);
  };

  useEffect(() => {
    if (deliveryLine1?.length <= 4) return;

    console.log("AddressAutoComplete", deliveryLine1);
    getAddressList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryLine1]);

  useEffect(
    () => {
      if (isEditing === false) {
        setAddressList([]);
      }
    },
    // eslint-disable-next-line
    [isEditing]
  );

  return (
    <div className={styles.addressAutoComplete}>
      <div className={`m-t-8`}>
        <input
          placeholder="Search address"
          onFocus={() => {
            setIsEditing(true);
          }}
          ref={inputRef}
          value={deliveryLine1}
          onChange={(e) => {
            setDeliveryLine1(e.target.value);
            onUpdate({ deliveryLine1: e.target.value });
          }}
        />
      </div>
      <div className={`m-t-8`}>
        <input
          placeholder="City state and zip"
          value={lastLine}
          onChange={(e) => {
            setLastLine(e.target.value);
            onUpdate({ lastLine: e.target.value });
          }}
        />
      </div>
      {isEditing && addressList?.length ? (
        <DDPortal close={() => setAddressList([])}>
          <div
            className={`menuWrapper ${styles.menuWrapper}`}
            style={{
              position: "fixed",
              width: `${inputRef?.current?.getBoundingClientRect().width}px`,
              top: `${
                inputRef?.current?.getBoundingClientRect().top +
                inputRef?.current?.clientHeight
              }px`,
              left: `${
                inputRef?.current?.getBoundingClientRect().left +
                +inputRef?.current?.clientWidth / 2 +
                1
              }px`,
              zIndex: 99999999,
            }}
          >
            {addressList.map((address, idx) => (
              <div
                className={`line`}
                onClick={() => {
                  setDeliveryLine1(
                    `${address.street_line}${
                      address.secondary ? ` ${address.secondary}` : ""
                    }`
                  );
                  setLastLine(
                    `${address.city} ${address.state} ${address.zipcode}`
                  );
                  onUpdate({
                    deliveryLine1: `${address.street_line}${
                      address.secondary ? ` ${address.secondary}` : ""
                    }`,
                    lastLine: `${address.city} ${address.state} ${address.zipcode}`,
                  });
                  setAddressList([]);
                  setIsEditing(false);
                }}
              >
                <p className="ffmr fs14 m-b-6 line_1">
                  {address.street_line}
                  {address.secondary ? ` ${address.secondary}` : ""}
                </p>
                <p className="ffmr fs12 line_2">
                  {address.city}, {address.state} {address.zipcode}
                </p>
              </div>
            ))}
          </div>
        </DDPortal>
      ) : undefined}
    </div>
  );
};

export default AddressAutoComplete;
