import styles from "./Import.module.scss";

import DocSVG from "../../../../assets/js-svgs/doc.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomersList from "./customers-list/CustomersList";
import ReservationsList from "./reservation-list/ReservationList";
import { formatFromCurrencyToMoney } from "../../../../helpers";

const Import = () => {
  const history = useHistory();
  const params = useParams();

  const { user, reduxUser } = useSelector(({ user, reduxUser }) => ({
    user,
    reduxUser,
  }));

  const [src, setSrc] = useState("");
  const [step, setStep] = useState(0);
  const [unitSizes, setUnitSizes] = useState([]);
  const [units, setUnits] = useState([]);
  const [job, setJob] = useState(undefined);

  const getJob = async () => {
    let job = await axios.get(`/api/new-import/job/${params.id}`);
    setJob(job.data);
    // console.log(job.data);
    return job.data;
  };

  const getUnits = async () => {
    let units = await axios.get(`/api/new-import/units/${params.id}`);
    setUnits(units.data);
  };

  const getUnitSizes = async () => {
    let unitSizes = await axios.get(`/api/new-import/unitsizes/${params.id}`);
    setUnitSizes(unitSizes.data);
  };

  useEffect(() => {
    getUnitSizes();
    getJob();
    // eslint-disable-next-line
  }, []);

  const getJobTimeout = (value) =>
    setTimeout(async () => {
      let result = await getJob();
      if (result.currentStep === value) {
        getJobTimeout(value);
      }
    }, 5000);

  useEffect(() => {
    if (!job?.currentStep) return;
    switch (job.currentStep) {
      case "createSizes":
        setStep(1);
        break;
      case "reviewUnitSizes":
        setStep(2);
        break;
      case "reviewUnits":
        getUnits();
        setStep(3);
        break;
      case "uploadCustomers":
        setStep(4);
        break;
      case "createCustomers":
        setStep(5);
        break;
      case "creatingCustomers":
        setStep(6);
        getJobTimeout("creatingCustomers");
        break;
      case "reviewCustomers":
        setStep(7);
        break;
      case "reviewReservations":
        setStep(8);
        break;
      default:
        setStep(0);
        break;
    }
    // eslint-disable-next-line
  }, [job?.currentStep]);

  const onSelectFile = (e, drop) => {
    if (drop) {
      let extension = e.dataTransfer.files[0].type.replace(/(.*)\//g, "");
      if (extension !== "csv") {
        alert("Please upload a CSV file");
        return;
      }
    } else {
      console.log("SELECTED");
    }
    let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (res) => setSrc(res.target.result);
  };

  const uploadFile = async () => {
    const i = src.indexOf("base64,");
    const buffer = Buffer.from(src.slice(i + 7), "base64");
    const file = new File([buffer], "name", {
      type: "application/vnd.ms-excel",
    });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("locationId", reduxUser.selectedLocation._id);
    fd.append("accountId", user.account);
    fd.append("jobId", params.id);

    let res = await axios.post(
      `/api/new-import/upload-file/?jobid=${params.id}&type=${
        step === 0 ? "units" : "customers"
      }`,
      fd
    );

    history.push(`/settings/admin/import/${params.id}/${res.data.fileId}`);
    setSrc("");
    setStep(step === 0 ? 1 : 5);
  };

  const createUnitSizes = async () => {
    let res = await axios.post(
      `/api/new-import/unitsizes/?jobid=${params.id}&fileid=${params.fileid}`
    );
    console.log(res.data);
    setUnitSizes(res.data.sizes);
    setJob(res.data.job);
    setStep(2);
    // setStep(2);
  };

  const createUnits = async () => {
    console.log("OOO");

    const res = await axios.post(
      `/api/new-import/units/?jobid=${params.id}&fileid=${params.fileid}`
    );
    console.log(res.data);

    // setStep(3);
    // setUnits(res.data.units);
    setJob(res.data.job);
  };

  // const goToCustomers = () => {
  //   let res = axios.put(`/api/new-import/job/${params.id}`, {
  //     currentStep: "uploadCustomers",
  //   });

  //   setJob(res.data);
  // };

  const createTenants = async () => {
    console.log("OOO Create Tenants");

    let res = await axios.post(
      `/api/new-import/tenants/?jobid=${params.id}&fileid=${params.fileid}`
    );

    setJob(res.data);
  };

  const createReservations = async () => {
    console.log("OOO Create Reservations");

    let res = await axios.post(
      `/api/new-import/reservations/?jobid=${params.id}&fileid=${params.fileid}`
    );

    setJob(res?.data?.job);
  };

  const done = () => {
    history.push(`/tenants`);
  };

  return (
    <div className={styles.import}>
      {step === 0 || step === 4 ? (
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>Easy Storage Migration</p>
              <p className={`ffnr fs12 m-t-10`}>
                You can import customer and unit info to your software. it's
                that easy.
              </p>
            </div>
          </div>
          <div className={`df aic ffc`}>
            <input
              onChange={onSelectFile}
              type="file"
              className={`dn ${styles.fileInput}`}
              id="upload"
              accept=".csv"
            />
            <label
              htmlFor="upload"
              className={`df acc ffc ${styles.uploadBtn}`}
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelectFile(e, true);
              }}
              onDragEnd={(e) => {
                e.preventDefault();
                e.stopPropagation();
                //   onSelectFile(e, true);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <DocSVG />
              <span className={`ffnr fs16 m-t-10 m-b-8`}>
                Select or drag Units CVS file to upload
              </span>
            </label>
          </div>

          <button
            className={`button primary w100 m-t-20 ttuc`}
            onClick={uploadFile}
          >
            Submit
          </button>
        </div>
      ) : undefined}
      {step === 1 ? (
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>
                Easy Storage Import | Units | Unit sizes
              </p>
              <p className={`ffnr fs12 m-t-10`}>Lets setup your units.</p>
            </div>
          </div>

          <div className={`df aic ffc`}>
            <button
              className={`button primary w100 m-t-20 ttuc`}
              onClick={createUnitSizes}
            >
              Create unit sizes
            </button>
          </div>
        </div>
      ) : undefined}
      {step === 2 ? (
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>
                Easy Storage Import | Units | Review unit sizes
              </p>
              <p className={`ffnr fs12 m-t-10`}>Lets setup your units.</p>
            </div>
          </div>

          <div className={`df aic ffc ${styles.contentWrpr}`}>
            <div
              className={`df acsa ffnr fs12 ttuc ${styles.hdr} ${styles.line}`}
            >
              <p className={styles.label}>Unit label</p>{" "}
              <p className={styles.size}>Unit size (lxw,h)</p>
              <p className={styles.price}>Price</p>
            </div>
            {unitSizes.map((unitSize) => (
              <div className={`df acsa ffnr fs14 ${styles.line}`}>
                <p className={styles.label}>{unitSize.label} </p>{" "}
                <p className={styles.size}>
                  {unitSize.length} X {unitSize.width}{" "}
                  {unitSize.height ? ` X ${unitSize.height}` : ""}
                </p>
                <p className={styles.price}>
                  {formatFromCurrencyToMoney(unitSize.price)}
                </p>
              </div>
            ))}

            <button
              className={`button primary w100 m-t-20 ttuc`}
              onClick={createUnits}
            >
              Looks good, setup units
            </button>
            {/* <button onClick={createUnitSizes}>Create unit sizes</button> */}
          </div>
        </div>
      ) : undefined}

      {step === 3 ? (
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>
                Easy Storage Import | Units | Review units
              </p>
              <p className={`ffnr fs12 m-t-10`}>Lets setup your units.</p>
            </div>
          </div>
          <div className={`df aic ffc ${styles.contentWrpr}`}>
            <div
              className={`df acsa ffnr fs12 ttuc ${styles.hdr} ${styles.line}`}
            >
              <p className={styles.id}>Unit ID</p>
              <p className={styles.size}>Unit size (lxw,h)</p>
              <p className={styles.price}>Price</p>

              <p className={styles.type}>Type</p>
              <p className={styles.features}>Features</p>
            </div>
            {units.map((unit) => (
              <div className={`df acsa ffnr fs14 ${styles.line}`}>
                <p className={styles.id}>{unit.unitId}</p>
                <p className={styles.size}>
                  {unit.unitSize.label} - {unit.unitSize.length}x
                  {unit.unitSize.width}x{unit.unitSize.height}
                </p>
                <p className={styles.price}>${unit.unitSize.price}</p>
                <p className={styles.type}>{unit.unitType.label}</p>
                <p className={styles.features}>
                  {unit.unitFeatures.map((feature, idx) => (
                    <span>
                      {feature.label}
                      {idx < unit.unitFeatures.length - 1 ? "," : ""}
                    </span>
                  ))}
                </p>
              </div>
            ))}
          </div>
          <button
            className={`button primary w100 m-t-20 ttuc`}
            onClick={createTenants}
          >
            Looks good, let's create customers next.
          </button>
        </div>
      ) : undefined}

      {step === 5 ? (
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>Easy Storage Import | Tenants</p>
              <p className={`ffnr fs12 m-t-10`}>Lets setup your tenants.</p>
            </div>
          </div>

          <div className={`df aic ffc ${styles.contentWrpr}`}>
            <button
              className={`button primary w100 m-t-20 ttuc`}
              onClick={createTenants}
            >
              Let's create your tenants
            </button>
            {/* <button onClick={createUnitSizes}>Create unit sizes</button> */}
          </div>
        </div>
      ) : undefined}

      {step === 6 ? (
        <div className={styles.card}>
          <div className={`df acsa ${styles.cardHdr}`}>
            <div>
              <p className={`ffnsb fs12 ttuc`}>
                Easy Storage Import | Creating tenants
              </p>
              <p className={`ffnr fs12 m-t-10`}>
                We are creating your tenants.
              </p>
            </div>
          </div>

          <div className={`df aic ffc ${styles.contentWrpr}`}>
            <p className="p-t-100 p-b-100 tac ffnr fs20">
              We are creating your customers please wait...
            </p>
            {/* <button onClick={createUnitSizes}>Create unit sizes</button> */}
          </div>
        </div>
      ) : undefined}
      {step === 7 ? (
        <CustomersList createReservations={createReservations} />
      ) : undefined}
      {step === 8 ? <ReservationsList done={done} /> : undefined}
    </div>
  );
};

export default Import;
