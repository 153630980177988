import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./Customers.scss";

const ImportCustomers = (props) => {
  // const history = useHistory();
  const params = useParams();
  const { reduxUser, user } = useSelector(({ reduxUser, user }) => ({
    reduxUser,
    user,
  }));

  const [customersList, setCustomersList] = useState([]);
  // const [file, setFile] = useState(null);
  // const [fileConvertError, setFileConvertError] = useState(null);
  // const [fileName, setFileName] = useState(null);
  const [src, setSrc] = useState(null);
  const [showFixAddressPopup, setShowFixAddressPopup] = useState(false);
  const [addressToFix, setAddressToFix] = useState({});
  // const [addressToFixCustomerIndex, setAddressToFixCustomerIndex] = useState(
  // 	null
  // );
  const [addressLine1, setAddressLine1] = useState("");

  // eslint-disable-next-line
  const [addressLine2, setAddressLine2] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressZip, setAddressZip] = useState("");
  // const [addressLine1, setAddressLine1] = useState("");

  const [addressPredictions, setAddressPredictions] = useState(null);

  const searchAddres = async (q) => {
    let list = await Axios.get(`/api/address/auto-complete/${q}`);
    // console.log(list.data.predictions);
    setAddressPredictions(list.data.predictions);
  };

  const getPlace = async (id) => {
    let place = await Axios.get(`/api/place/${id}`);

    setAddressPredictions(null);

    setAddressLine1(`${place.data.street_number} ${place.data.route}`);
    setAddressCity(
      place.data.locality ||
        place.data.neighborhood ||
        place.data.sublocality_level_1
    );

    setAddressState(place.data.administrative_area_level_1);
    setAddressZip(place.data.postal_code);
  };

  const getCustomersList = async () => {
    let list = await Axios.get(`/api/import/customer-list/${params.id}`);

    setCustomersList(list.data);
  };

  useEffect(() => {
    getCustomersList();
    // eslint-disable-next-line
  }, []);

  const onSelectFile = (e, drop) => {
    let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    let reader = new FileReader();

    // setFile(file);
    // setFileConvertError(null);
    // setFileName(file.name);

    reader.readAsDataURL(file);
    reader.onload = (res) => setSrc(res.target.result);
  };

  const uploadFile = () => {
    const i = src.indexOf("base64,");
    const buffer = Buffer.from(src.slice(i + 7), "base64");
    const file = new File([buffer], "name", {
      type: "application/vnd.ms-excel",
    });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("locationId", reduxUser.selectedLocation._id);
    fd.append("accountId", user.account);
    fd.append("jobId", params.id);

    Axios.post("/api/import/customer/all", fd);
  };

  const fixAddress = async (data, i) => {
    setShowFixAddressPopup(!showFixAddressPopup);
    setAddressToFix(data);
    // setAddressToFixCustomerIndex(i);
  };

  const submitFix = async () => {
    // console.log("FIXING.....", addressToFix);

    Axios.put(`/api/address/${addressToFix._id}`, {
      line1: addressLine1,
      line2: addressLine2,
      city: addressCity,
      state: addressState,
      zip: addressZip,
    });
  };

  return (
    <div className="df ffc importCustomerPage">
      <h1>Import Customers</h1>
      <div>
        <input
          type="file"
          accept=".csv, .txt"
          id="upld_file"
          onChange={onSelectFile}
        />
      </div>
      <button onClick={uploadFile}>Upload</button>

      <div className="customer_list">
        {customersList.map((customer, i) => (
          <div className="customer_line">
            <div className="df details_wrpr">
              <p className="st1">{customer.details.fullName}</p>
              <p className="nd2">{customer.details.email}</p>
            </div>
            {customer.address.length ? (
              <div className="df address_line">
                {customer.address[0].line1 ? (
                  <>
                    <div className="sec">
                      <p>Street</p>
                      <p>{customer.address[0].line1}</p>
                    </div>

                    <div className="sec">
                      <p>Line 2</p>
                      <p>{customer.address[0].line2}</p>
                    </div>

                    <div className="sec">
                      <p>City</p>
                      <p>{customer.address[0].city}</p>
                    </div>

                    <div className="sec">
                      <p>State</p>
                      <p>{customer.address[0].state}</p>
                    </div>

                    <div className="sec">
                      <p>Zip</p>
                      <p>{customer.address[0].zip}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <p>Raw address</p>
                      <p>{customer.address[0].importedString}</p>
                    </div>
                    <p onClick={() => fixAddress(customer.address[0], i)}>
                      Fix
                    </p>
                  </>
                )}
              </div>
            ) : (
              <div className="df address_line">Address not found</div>
            )}

            <div className="df phone_number">
              {customer.phoneNumbers.map((pn) => (
                <div className="sec">
                  <p>Phone Number</p>
                  <p>{pn.number}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {showFixAddressPopup ? (
        <div>
          <p>Fix Address: {addressToFix.importedString}</p>

          <div>
            <p>Street</p>
            <input
              type="text"
              value={addressLine1}
              onChange={(e) => {
                searchAddres(e.target.value);
                setAddressLine1(e.target.value);
              }}
            />
          </div>

          <div>
            {addressPredictions && addressPredictions.length
              ? addressPredictions.map((line) => (
                  <p onClick={() => getPlace(line.place_id)}>
                    {line.description}
                  </p>
                ))
              : undefined}
          </div>

          <div>
            <p>Line 2</p>
            <input type="text" />
          </div>

          <div>
            <p>City</p>
            <input
              value={addressCity}
              onChange={(e) => setAddressCity(e.target.value)}
              type="text"
            />
          </div>

          <div>
            <p>State</p>
            <input
              value={addressState}
              onChange={(e) => setAddressState(e.target.value)}
              type="text"
            />
          </div>

          <div>
            <p>Zip</p>
            <input
              value={addressZip}
              onChange={(e) => setAddressZip(e.target.value)}
              type="text"
            />
          </div>

          <div className="fix_btn" onClick={() => submitFix()}>
            Fix...
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default ImportCustomers;

// const [src, setSrc] = useState(null);

// {
/* <input
        accept=".csv, .txt,.jpg"
        // className="dn"
        type="file"
        id="upld_file"
        onChange={this.onSelectFile}
        ref={this.fileInput}>
    </input>
    <div
        onClick={() => this.uploadFile()}
        className="upload_btn"
        style={{
        height: "34px",
        backgroundColor: "red",
        marginRight: "20px",
        }}
    >
    Upload Customers
    </div> */
// }

// onSelectFile = (e, drop) => {
//     let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
//     if (!file) return;
//     let reader = new FileReader();
//     this.setState({
//         file: file,
//         fileConvertError: null,
//         fileName: file.name,
//     });
//     reader.readAsDataURL(file);
//     reader.onload = (res) =>
//         this.setState({
//             src: res.target.result,
//         });
// };

// uploadFile = () => {
//     const i = this.state.src.indexOf("base64,");
//     const buffer = Buffer.from(this.state.src.slice(i + 7), "base64");
//     const file = new File([buffer], "name", {
//         type: "application/vnd.ms-excel",
//     });

//     const fd = new FormData();
//     fd.append("file", file);
//     fd.append("locationId", this.props.reduxUser.selectedLocation._id);
//     fd.append("accountId", this.props.user.account);

//     Axios.post("/api/import/customer/all", fd);

// };
