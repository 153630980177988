import React from "react";

const ShowMoreSvg = (props) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z"
      fill={props.fill || ""}
      fillOpacity={props.fillOpacity || ""}
    />
  </svg>
);

export default ShowMoreSvg;
