import { useEffect, useState } from "react";
import axios from "axios";
import update from "immutability-helper";

import styles from "./UnitSizes.module.scss";
import { useSelector } from "react-redux";
import { formatFromCurrencyToMoney } from "../../../../helpers";
import AddUnitSizePopup from "./add-popup/AddPopup";

const UnitSizes = () => {
  const { reduxUser } = useSelector(({ reduxUser }) => ({ reduxUser }));

  const [showAdd, setShowAdd] = useState(false);
  const [sizes, setSizes] = useState(undefined);
  const [editUnitSize, setEditUnitSize] = useState(undefined);

  const getUnitSizes = async () => {
    console.log("Fetching unit sizes");

    let localSizes = await axios.get(
      `/api/unit-size/?locationId=${reduxUser.selectedLocation._id}`
    );

    setSizes(localSizes?.data?.data);
  };

  useEffect(() => {
    getUnitSizes();
    // eslint-disable-next-line
  }, []);

  const saved = (size) => {
    const updated = update(sizes, { $push: [size] });
    setSizes(updated);
  };

  const updated = (size) => {
    let index = sizes.findIndex((elem) => elem._id === size._id);

    console.log(index, size);
    const updated = update(sizes, { [index]: { $set: size } });
    setSizes(updated);
  };

  const deleteUnitSize = async (id) => {
    await axios.delete(`/api/unit-size/${id}`);

    let index = sizes.findIndex((elem) => elem.id === id);

    let updated = update(sizes, { $splice: [[index, 1]] });

    setSizes(updated);
  };

  return (
    <div className={styles.unitSettings}>
      <div className={styles.card}>
        <div className={`df acsa ${styles.cardHdr}`}>
          <div>
            <p className={`ffnsb fs12 ttuc`}>Unit sizes</p>
            <p className={`ffnr fs12 m-t-10`}>Manage unit sizes</p>
          </div>
          <button
            className={`mla ffnr s14 ${styles.addBtn}`}
            onClick={() => setShowAdd(true)}
          >
            Add unit size
          </button>

          {showAdd ? (
            <AddUnitSizePopup
              close={() => setShowAdd(false)}
              saved={saved}
              updated={updated}
              size={editUnitSize}
            />
          ) : undefined}
          {/* {showAddRule ? (
            <Portal
              title="Create a new delinquency rule"
              width="354px"
              height="auto"
              zIndex="100"
              opacity="0.45"
              close={() => {
                setShowAddRule(false);
                setEditRule(undefined);
              }}
              customClass={"delinquency-rule-portal"}
            >
              <DelinquencyRule
                close={() => {
                  setShowAddRule(false);
                  setEditRule(undefined);
                }}
                delinquencyRules={delinquencyRules}
                refreshRules={getDelinquencyRules}
                rule={editRule}
              />
            </Portal>
          ) : undefined} */}
        </div>

        {sizes?.map((size) => (
          <div
            className={`ffnr fs14 df acsa ${styles.sizeLine}`}
            key={size._id}
            onClick={() => {
              setEditUnitSize(size);
              setShowAdd(true);
            }}
          >
            <div className={`m-r-30 pw20 ${styles.sizeSec}`}>
              <p className={`fs12 ${styles.label}`}>Label</p>
              <p>{size.label}</p>
            </div>
            <div className={`m-r-30 pw20 ${styles.sizeSec}`}>
              <p className={`fs12 ${styles.label}`}>Dimensions (L x W x H)</p>
              <p>
                {size.length} x {size.width} x {size.height}
              </p>
            </div>
            <div className={`m-r-30 pw20 ${styles.sizeSec}`}>
              <p className={`fs12 ${styles.label}`}>SQ Footage</p>
              <p>{size.calculatedSQF}</p>
            </div>

            <div className={`m-r-30 pw20 ${styles.sizeSec}`}>
              <p className={`fs12 ${styles.label}`}>Price</p>
              <p>{formatFromCurrencyToMoney(size.price)}</p>
            </div>
            <p
              className={`mla`}
              onClick={(e) => {
                e.stopPropagation();
                deleteUnitSize(size._id);
              }}
            >
              delete
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnitSizes;
