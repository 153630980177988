import React from "react";

import "./NonFormikRadio.scss";

const NonFormikRadio = (props) => {
	return (
		<div
			className={`df acsa __radio_wrpr ${props.value ? "selected" : ""}`}
		>
			<input
				className="dn"
				type="radio"
				id={props.id}
				value={props.value}
				onChange={(e) => props.onChange(e, props.field)}
				name={props.name}
			></input>
			<label htmlFor={props.id} className="__inpt"></label>
			<label
				className={`${
					props.notBold ? "ffnr fs14 __lbl" : "ffmb fs12 ttuc __lbl"
				}`}
				htmlFor={props.id}
			>
				{props.label}
			</label>
		</div>
	);
};

export default NonFormikRadio;
